<div *ngIf="list?.length > 0">
  <div class="tab-pane fade show active">
    <cdk-virtual-scroll-viewport
      itemSize="130"
      class="notification-viewport custom-scrollbar"
      (scrolledIndexChange)="onScroll($event)"
    >
      <ng-container *cdkVirtualFor="let item of list">
        <app-notify-item [notification]="item"></app-notify-item>
      </ng-container>

      <ng-container *ngIf="notificationStore.loading$ | async">
        <ng-container *ngFor="let item of [0, 1]">
          <notification-skeleton></notification-skeleton>
        </ng-container>
      </ng-container>
    </cdk-virtual-scroll-viewport>
  </div>
</div>

<ng-container *ngIf="!list?.length && notificationStore.loading$ | async">
  <ng-container *ngFor="let item of [0, 1, 2]">
    <notification-skeleton></notification-skeleton>
  </ng-container>
</ng-container>

<ng-container
  *ngIf="!list.length && (notificationStore.loading$ | async) === false"
>
  <app-notify-empty [type]="type"></app-notify-empty>
</ng-container>
