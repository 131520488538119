<div
  class="dropdown topbar-head-dropdown ms-1 header-item"
  ngbDropdown
  (openChange)="isDropdownOpen = $event"
>
  <button
    type="button"
    class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
    id="page-header-notifications-dropdown"
    data-bs-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
    ngbDropdownToggle
  >
    <i class="bx bx-bell fs-22"></i>
    <ng-container *ngIf="unreadNotification$ | async as unreadCount">
      <span
        class="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger"
      >
        {{ unreadCount > 99 ? "99+" : unreadCount }}
        <span class="visually-hidden">unread messages</span>
      </span>
    </ng-container>
  </button>
  <div
    class="dropdown-menu dropdown-menu-xl dropdown-menu-end p-0"
    aria-labelledby="page-header-notifications-dropdown"
    ngbDropdownMenu
  >
    <div class="dropdown-head bg-primary bg-pattern rounded-top">
      <div class="p-3">
        <div class="row align-items-center">
          <div class="col">
            <h6 class="m-0 fs-16 fw-semibold text-white">Thông báo</h6>
          </div>
          <div class="col-auto dropdown-tabs">
            <div class="d-flex align-items-center">
              <span class="text-white me-2 fs-12">
                Chỉ hiện thông báo chưa xử lý
              </span>
              <div class="form-check form-switch form-check-warning">
                <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  (change)="toggleShowUnprocessed($event)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="px-2 pt-2">
        <ul
          ngbNav
          #nav="ngbNav"
          [activeId]="1"
          class="nav nav-tabs dropdown-tabs nav-tabs-custom"
        >
          <li [ngbNavItem]="1">
            <a ngbNavLink> Tất cả ({{ notificationData.total$ | async }})</a>
            <ng-template ngbNavContent>
              <app-notify-tab
                [type]="'all'"
                [list]="allTypes$ | async"
                [openTrigger]="isDropdownOpen"
              ></app-notify-tab>
            </ng-template>
          </li>
          <li [ngbNavItem]="2">
            <a ngbNavLink> Tin nhắn (0)</a>
            <ng-template ngbNavContent>
              <app-notify-tab
                [type]="'2'"
                [list]="[]"
                [openTrigger]="isDropdownOpen"
              ></app-notify-tab>
            </ng-template>
          </li>
          <li [ngbNavItem]="3" *ngIf="notifications$ | async as notifications">
            <a ngbNavLink>
              Thông báo ({{ notificationData.total$ | async }})
            </a>
            <ng-template ngbNavContent>
              <app-notify-tab
                [type]="'1'"
                [list]="notifications"
                [openTrigger]="isDropdownOpen"
              ></app-notify-tab>
            </ng-template>
          </li>
        </ul>
      </div>
    </div>

    <div class="tab-content text-muted">
      <div [ngbNavOutlet]="nav"></div>
    </div>
  </div>
</div>
