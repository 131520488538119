<form class="container-content row">
  <div class="mb-3 col-12">
    <label>Chọn khu vực</label>
    <ng-select
      [multiple]="true"
      class="col-md-5 p-0 mb-2 w-100"
      [items]="REGIONS"
      [searchable]="false"
    ></ng-select>
  </div>
  <div class="col-12 mb-3">
    <label>Tiêu đề</label>
    <input type="text" class="form-control" />
  </div>
  <div class="col-12 mb-3">
    <label>Chi tiết</label>
    <textarea class="form-control" rows="10"></textarea>
  </div>
  <div class="modal-footer p-0">
    <button
      type="button"
      class="btn btn-ghost-danger"
      (click)="handleCloseModal()"
    >
      Huỷ bỏ
    </button>
    <button type="submit" class="btn btn-success">Gửi</button>
  </div>
</form>
