import { StorageService } from "src/app/public/services/storage/storage.service";
import { Injectable } from "@angular/core";
import { CredentialHttpService } from "./credential-http.service";
import { JWT_TOKEN } from "src/app/public/constants/common.constant";

@Injectable({
  providedIn: "root",
})
export class CredentialService {
  readonly CREDENTIAL = "CREDENTIAL";

  constructor(
    private credentialHttpService: CredentialHttpService,
    private storageService: StorageService
  ) {}

  init(): void {
    if (this.storageService.get(JWT_TOKEN)) {
    }
    const credential = this.credential;
    if (!credential) this.getCredential();
  }

  get credential(): any {
    return this.storageService.get(this.CREDENTIAL);
  }

  getCredential(): void {
    this.credentialHttpService.getCredential().subscribe(({ items }) => {
      let _data: Record<string, any> = {};
      items?.map((i: any) => {
        _data = Object.assign({ [i?.cKey]: i?.cValue }, _data);
      });
      this.storageCredential(_data);
    });
  }

  storageCredential(data: any): void {
    this.storageService.set(this.CREDENTIAL, data);
  }
}
