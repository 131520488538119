import { Component, EventEmitter, Input, Output } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { USER_STATUS_FILTER } from "src/app/public/constants/user.constant";

@Component({
  selector: "app-search-filter-employee",
  templateUrl: "./search-filter-employee.component.html",
})
export class SearchFilterEmployeeComponent {
  public USER_STATUS = USER_STATUS_FILTER;

  @Input() searchCustomerParams: UntypedFormGroup;
  @Output() resetEvent = new EventEmitter();
  @Output() submitEvent = new EventEmitter();

  constructor() {}

  public getControl(control: string): UntypedFormControl {
    return this.searchCustomerParams.controls[control] as UntypedFormControl;
  }
}
