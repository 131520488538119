import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { Observable } from "rxjs";
import { RealEstateModel } from "src/app/public/models/real-estate.model";
import { ToastService } from "src/app/components/notifications/toast-service";
import { CheckStyleUrl } from "src/app/public/utils/check-file-type";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-copy-real-estate",
  templateUrl: "./copy-real-estate.component.html",
  styleUrls: ["./copy-real-estate.component.scss"],
})
export class CopyRealEstateComponent implements OnInit {
  @Input() realEstate$: Observable<RealEstateModel>;
  @ViewChild("bodyText") bodyText: ElementRef;
  @ViewChild("imageSlide") imageSlide: ElementRef;
  public item: RealEstateModel;
  public date: string;
  public imageIndex: number = 0;
  public readonly ADMIN_URL = "https://hoaland.com/";
  constructor(
    private toast: ToastService,
    public activeModal: NgbActiveModal
  ) {}
  CheckStyleUrl = CheckStyleUrl;

  ngOnInit() {
    this.realEstate$.subscribe((data) => {
      this.item = data;
    });
    this.date = `${new Date().getDate()}/${
      new Date().getMonth() + 1
    }/${new Date().getFullYear()}`;
  }

  realIndexChange(event: any) {
    this.imageIndex = event[0].activeIndex;
  }

  copyTextToClipboard() {
    window.getSelection().removeAllRanges();
    let range = document.createRange();
    range.selectNode(this.bodyText.nativeElement);
    window.getSelection().addRange(range);
    document.execCommand("copy");
    this.toast.show("Copy Nội dung thành công");
    window.getSelection().removeAllRanges();
  }
}
