import { DatePipe, NgIf } from "@angular/common";
import { Component, inject, Input, ViewChild } from "@angular/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { CallHistoryBuyRentComponent } from "@component/call-history-form-to-owner/call-history-buy-rent/call-history-buy-rent.component";
import { CallHistorySellRentComponent } from "@component/call-history-form-to-owner/call-history-sell-rent/call-history-sell-rent.component";
import { RealEstateHistoryFormHelper } from "@component/call-history-form-to-owner/call-history-sell-rent/form.helper";
import { USER_INFO } from "@constant/common.constant";
import { CustomerModel } from "@model/customer.model";
import { OrderModel } from "@model/order.model";
import { RealEstateModel } from "@model/real-estate.model";
import {
  NgbActiveModal,
  NgbModal,
  NgbNavChangeEvent,
  NgbNavModule,
} from "@ng-bootstrap/ng-bootstrap";
import { ConfirmModalComponent } from "@page/real-estate-management/widgets/confirm-modal/confirm-modal.component";
import { StorageService } from "@service/storage/storage.service";

@Component({
  selector: "call-history-form-container",
  templateUrl: "./call-history-container.component.html",
  styleUrls: ["./call-history-container.component.scss"],
  imports: [
    MatTooltipModule,
    NgbNavModule,
    CallHistorySellRentComponent,
    CallHistoryBuyRentComponent,
    NgIf,
  ],
  standalone: true,
  providers: [DatePipe, RealEstateHistoryFormHelper],
})
export class CallHistoryContainerComponent {
  private activeModal = inject(NgbActiveModal);
  private modalService = inject(NgbModal);
  private storageService = inject(StorageService);

  public historyCallRealEstate: CallRealEstateModel;
  public historyCallOrder: CallOrderModel;
  public ownerCall: OwnerCallModel;

  @ViewChild(CallHistoryBuyRentComponent)
  buyRentComponent: CallHistoryBuyRentComponent;

  @ViewChild(CallHistorySellRentComponent)
  sellRentComponent: CallHistorySellRentComponent;

  @Input() set data(value: any) {
    const { customers, contacts, numberToCall } = value;

    const customer = customers?.find((c: CustomerModel) =>
      c.address.find((add) => add?.phone === numberToCall)
    );
    const contact = contacts?.[0];
    const caller = this.storageService.get(USER_INFO)?.username;

    this.ownerCall = {
      customerId: customer?.id ?? contact?.id,
      phoneNumber: numberToCall,
      caller,
      alias: customer?.alias ?? contact?.alias,
      origin: customer?.origin ?? contact?.origin,
      fullName: customer?.fullName ?? contact?.fullName,
      refId: customer?.id ?? contact?.refId,
    };

    this.historyCallOrder = this.historyCallRealEstate = {
      ...value,
      ...this.ownerCall,
    };
  }

  public addressIndex: number;
  public activeTab = "sell-rent";

  onClickCloseModal(): void {
    this.activeModal.close();
  }

  onNavChange(event: NgbNavChangeEvent) {
    if (!event) return;

    event.preventDefault();

    const orderForm = this.buyRentComponent?.callHistoryForm;
    const realEstateForm = this.sellRentComponent?.formData;
    const isDirty = orderForm?.dirty || realEstateForm?.dirty;

    if (!isDirty) return (this.activeTab = event.nextId);

    this.confirmBeforeChangeTab().closed.subscribe((state: boolean) => {
      this.activeTab = state ? event.activeId : event.nextId;
    });
  }

  private confirmBeforeChangeTab() {
    const modal = this.modalService.open(ConfirmModalComponent, {
      size: "md",
      centered: true,
      backdrop: "static",
    });
    modal.componentInstance.title = "Lưu thông tin đã nhập?";
    modal.componentInstance.content =
      "Bạn có muốn ở lại lưu thông tin đã nhập trước khi thay đổi tab?";
    modal.componentInstance.hasClose = true;

    return modal;
  }
}

export interface CallRealEstateModel extends RealEstateModel {
  numberToCall: string;
}

export interface CallOrderModel extends OrderModel {
  numberToCall: string;
}

export interface OwnerCallModel {
  customerId: number;
  phoneNumber: string;
  caller: string;
  alias: string;
  origin: number;
  fullName: string;
  refId: number;
}
