import { NgForOf } from "@angular/common";
import { Component, inject, Input, OnInit } from "@angular/core";
import {
  FormArray,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import { RouterLink } from "@angular/router";
import { CallRealEstateModel } from "@component/call-history-form-to-owner/call-history-container.component";
import { InvisiblePhoneNumberDirective } from "@directive/invisible-phone-number.directive";
import { CustomerModel } from "@model/customer.model";
import { RealEstateModel } from "@model/real-estate.model";
import { NgbActiveModal, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { RealEstateService } from "@service/real-estate/real-estate.service";
import { UiSwitchModule } from "ngx-ui-switch";
import { filter, startWith, switchMap, tap } from "rxjs/operators";
import {
  ALIAS_PREFIX,
  ORIGIN_PROVIDE,
} from "src/app/public/constants/common.constant";

@Component({
  selector: "owner-info-component",
  templateUrl: "./owner-info.component.html",
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    UiSwitchModule,
    NgbTooltipModule,
    InvisiblePhoneNumberDirective,
    RouterLink,
    NgForOf,
  ],
})
export class OwnerInfoComponent implements OnInit {
  // Inject Services
  private modalService = inject(NgbActiveModal);
  private realEstateService = inject(RealEstateService);

  // Inject variable
  @Input() formData: FormGroup;
  public customer: CustomerModel;

  @Input() addressIndex: number;
  @Input() set realEstate(data: CallRealEstateModel) {
    this.customer = data?.customers?.find((c) => {
      return c.address.find((add) => add?.phone === data?.numberToCall);
    });
  }

  ngOnInit(): void {
    this.getOriginOfRealEstate();
  }

  submitForm() {
    this.modalService.close();
    console.log("Submit form");
  }

  onClickCancel($event: MouseEvent): void {
    this.modalService.close();
    console.log("Cancel form");
  }

  getControl(control: string) {
    return this.formData.controls[control];
  }

  getFormArray(control: string) {
    return (this.formData.controls[control] as FormArray)?.controls;
  }

  getValueControl(control: string, group?: string): any {
    // get value of form control from a form group
    if (!group) {
      return this.formData.controls[control].value;
    }
    return this.formData.controls[control].value[group];
  }

  compareFn(item: any, selected: any) {
    return item.key.toString() === selected.toString();
  }

  private getOriginOfRealEstate() {
    this.getControl("id")
      .valueChanges.pipe(
        startWith(this.getControl("id").value),
        filter((id) => !!id),
        switchMap((id) => this.realEstateService.getDetailRealEstate(id)),
        tap((realEstate: RealEstateModel) =>
          this.getControl("origin").setValue(realEstate.originProvide)
        )
      )
      .subscribe();
  }

  protected readonly ORIGIN_PROVIDE = ORIGIN_PROVIDE;
  protected readonly ALIAS_PREFIX = ALIAS_PREFIX;
}
