<div [formGroup]="formRealEstate" class="row">
  <div class="hl-mb-n2">
    <span class="badge badge-soft-primary fs-12"
      ><i class="mdi mdi-circle-medium"></i>Thông tin khác</span
    >
  </div>
  <div class="border border-dashed"></div>

  <div class="py-2 row">
    <div class="col-12 mt-2">
      <label class="form-label">Miêu tả gửi khách</label>
      <ckeditor
        formControlName="description"
        [class.disabled]="!isEdit && !!realEstateId"
        [editor]="Editor"
      ></ckeditor>
      <small
        *ngIf="
          controls['description'].touched && controls['description']?.errors
        "
        class="text-danger"
        >Trường thông tin bắt buộc</small
      >
    </div>
  </div>
</div>
