<div class="border border-dashed p-2 h-100">
  <form [formGroup]="callHistoryForm">
    <legend class="d-flex justify-content-between align-items-center">
      <span class="badge badge-soft-primary fs-14"
        ><i class="mdi mdi-circle-medium"></i>Khách hàng</span
      >
      <div>
        <button
          [attr.disabled]="getValueControl('brand') ? null : true"
          type="button"
          class="btn btn-success btn-sm"
          [ngbTooltip]="'Thêm mới khách hàng'"
        >
          <a
            [class.disabled-link]="!getValueControl('brand')"
            class="text-white"
            [routerLink]="['/crm/brands', controls['brand'].value, 'orders']"
            [queryParams]="{ id: controls['order'].value }"
            target="_blank"
          >
            <i class="ri-add-fill align-middle"></i>
          </a>
        </button>
      </div>
    </legend>

    <section class="row">
      <div class="d-flex gap-1">
        <div class="pb-2 col-6">
          <label class="form-label">Quý danh</label>
          <ng-select
            [items]="ALIAS"
            bindValue="key"
            [clearable]="false"
            formControlName="alias"
            bindLabel="value"
            [placeholder]="'Quý danh'"
          >
          </ng-select>
        </div>

        <div class="pb-2 col-6">
          <label class="form-label">Nguồn</label>
          <ng-select
            [items]="ORIGIN_PROVIDE"
            bindValue="key"
            [clearable]="false"
            formControlName="origin"
            bindLabel="label"
            placeholder="Nguồn"
            [class.disabled]="true"
          >
          </ng-select>
        </div>
      </div>

      <div class="pb-2 form-group" required>
        <label class="form-label">Họ và tên</label>
        <div class="input-group">
          <input
            formControlName="fullName"
            class="form-control"
            placeholder="Nhập họ & tên"
            [readonly]="true"
          />
          <button
            disabled
            class="btn btn-outline-primary"
            ngbTooltip="Chức năng tạm khoá"
          >
            <i class="mdi mdi-account-plus-outline"></i>
          </button>
        </div>
      </div>

      <div class="pb-2 form-group" required>
        <label class="form-label">Số điện thoại</label>
        <div class="input-group">
          <input
            appOnlyPhoneNumber
            type="text"
            formControlName="phone"
            class="form-control"
            invisiblePhoneNumber
            placeholder="Nhập số điện thoại"
            [class.is-invalid]="
              controls['phone'].touched && controls['phone']?.errors
            "
            [readonly]="true"
          />
          <a
            [class.disabled-link]="!getValueControl('brand')"
            ngbTooltip="Thêm mới SĐT"
            class="btn btn-outline-primary"
            [routerLink]="['/crm/brands', getValueControl('brand'), 'orders']"
            [queryParams]="{ id: getValueControl('order') }"
            target="_blank"
          >
            <i class="mdi mdi-plus"></i>
          </a>
        </div>

        <small
          *ngIf="controls['phone'].touched && controls['phone']?.errors"
          class="text-danger"
          >Trường thông tin bắt buộc</small
        >
      </div>
    </section>
  </form>
</div>
