import { Component, Input } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";

@Component({
  selector: "app-search-filter-agent",
  templateUrl: "./search-filter-agent.component.html",
})
export class SearchFilterAgentComponent {
  @Input() searchAgentParams: UntypedFormGroup;
  constructor() {}
}
