import { Component, Input } from "@angular/core";

@Component({
  selector: "app-skeleton-table",
  templateUrl: "./skeleton-table.component.html",
  styleUrls: ["./skeleton-table.component.scss"],
})
export class SkeletonTableComponent {
  @Input() tableType: string;
  public rowSkeleton: Array<any> = [];

  constructor() {
    for (let i = 0; i < 8; i++) {
      this.rowSkeleton.push(i);
    }
  }
}
