<div class="card-body">
  <!-- TABLE DATA-->
  <div class="table-card table-responsive mb-4">
    <table class="table align-middle table-nowrap mb-0" id="ticketTable">
      <!-- OUTLET HEADER TABLE-->
      <thead>
        <tr class="bg-light">
          <ng-container *ngTemplateOutlet="headers"></ng-container>
        </tr>
      </thead>
      <!-- OUTLET BODY TABLE-->
      <tbody class="list form-check-all" id="ticket-list-data">
        <ng-container *ngFor="let d of data">
          <tr class="cursor-pointer" (click)="rowClick.emit(d.id)">
            <ng-container
              *ngTemplateOutlet="content; context: { $implicit: d }"
            ></ng-container>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>

  <!-- SKELETON LOADING-->
  <ng-template [ngIf]="!data && logError === false">
    <app-skeleton-table tableType="tableUser"></app-skeleton-table>
  </ng-template>

  <!-- OUTLET KHÔNG CÓ DỮ LIỆU-->
  <ng-template [ngIf]="total === 0 && logError === false">
    <ng-container *ngTemplateOutlet="noData"></ng-container>
  </ng-template>

  <!-- OUTLET CALL API BỊ LỖI-->
  <ng-template [ngIf]="logError === true">
    <div class="d-flex justify-content-center align-items-center flex-column">
      <h3 class="text-danger">Server Error</h3>
      <span>Vui lòng thử lại sau, cảm ơn</span>
    </div>
  </ng-template>

  <!-- PAGINATION-->
  <div
    *ngIf="total > 0"
    class="row justify-content-md-between align-items-md-center"
  >
    <div class="col col-sm-6">
      <div
        class="dataTables_info mb-2"
        id="tickets-table_info"
        role="status"
        aria-live="polite"
      >
        Hiển thị
        {{ limit * (page - 1) }} tới {{ limit * (page - 1) + pageSize }} trong
        {{ total }}
        kết quả
      </div>
    </div>
    <!-- Pagination -->
    <div class="col col-sm-6">
      <div class="text-sm-right float-sm-end listjs-pagination">
        <ngb-pagination
          *ngIf="page"
          [collectionSize]="total"
          (pageChange)="pageChange.emit($event)"
          [maxSize]="10"
          [page]="page"
          [pageSize]="limit"
        >
        </ngb-pagination>
      </div>
    </div>
    <!-- End Pagination -->
  </div>
</div>
