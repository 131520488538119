import { Injectable } from "@angular/core";
import { BaseHttpRequest } from "../http/base-http-request.service";
import { API_URL } from "../../constants/api-url.constant";
import { Observable } from "rxjs";
import { NotificationModel } from "../../models/notification.model";
@Injectable({
  providedIn: "root",
})
export class NotificationService extends BaseHttpRequest {
  public sendNotification(payload: NotificationModel): Observable<any> {
    return this.post(API_URL.SEND_NOTIFICATION, payload);
  }
}
