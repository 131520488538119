<div class="modal-content border-0">
  <div class="modal-header">
    <h5 class="modal-title">Lịch sử chỉnh sửa</h5>
    <div class="modal-button">
      <button
        type="button"
        data-dismiss="modal"
        aria-label="Close"
        class="close m-0"
        (click)="onCloseModal()"
      >
        <span>×</span>
      </button>
    </div>
  </div>
  <hr class="m-0" />
  <div class="modal-body">
    <ng-container *ngIf="auditCompares">
      <div class="image">
        <div
          class="heading"
          (click)="toggleCollapse(0)"
          [attr.aria-expanded]="!collapsed[0]"
        >
          <span>Ảnh BĐS</span>
          <i
            class="ri-arrow-down-s-line fs-20"
            [@rotateIcon]="collapsed[0] ? 'collapsed' : 'expanded'"
          ></i>
        </div>
        <div #collapse="ngbCollapse" [(ngbCollapse)]="collapsed[0]">
          <div class="d-flex table-row">
            <div class="table-heading fw-500">
              <span>Ảnh BĐS</span>
            </div>
            <div class="d-flex table-data-container">
              <div class="table-data" *ngFor="let num of numOfRow">
                <div
                  class="pt-3 ps-2 pe-1"
                  appCompareEditField
                  [auditCompares]="auditCompares"
                  [index]="num"
                  field="photos"
                >
                  <ng-container
                    *ngFor="let photo of auditCompares[num]?.data?.photos"
                  >
                    <img [src]="photo.url" class="w-100 mb-3" alt="" />
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- THÔNG TIN CHUNG  -->
      <div class="overview">
        <div
          class="heading"
          (click)="toggleCollapse(1)"
          [attr.aria-expanded]="!collapsed[1]"
        >
          <span>Thông tin chung</span>
          <i
            class="ri-arrow-down-s-line fs-20"
            [@rotateIcon]="collapsed[1] ? 'collapsed' : 'expanded'"
          ></i>
        </div>
        <div #collapse="ngbCollapse" [(ngbCollapse)]="collapsed[1]">
          <div class="d-flex table-row">
            <div class="table-heading fw-500">
              <span>Địa chỉ</span>
            </div>
            <div class="d-flex table-data-container">
              <div class="table-data" *ngFor="let num of numOfRow">
                <span
                  appCompareEditField
                  [auditCompares]="auditCompares"
                  [index]="num"
                  field="address.fullAddress"
                >
                  {{ auditCompares[num]?.data?.address?.fullAddress }}
                </span>
              </div>
            </div>
          </div>

          <div class="d-flex table-row">
            <div class="table-heading fw-500">
              <span>Chủ sở hữu</span>
            </div>
            <div class="d-flex table-data-container">
              <div class="table-data" *ngFor="let num of numOfRow">
                <div
                  appCompareEditField
                  [auditCompares]="auditCompares"
                  [index]="num"
                  field="customers"
                >
                  <ng-container
                    *ngFor="let customer of auditCompares[num]?.data?.customers"
                  >
                    <div>
                      <span>
                        {{ customer.fullName }}
                      </span>
                      <span>- </span>
                      <ng-container *ngFor="let address of customer.address">
                        <span>{{ address.phone }}</span>
                      </ng-container>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex table-row">
            <div class="table-heading fw-500">
              <span>Kiểu BĐS</span>
            </div>
            <div class="d-flex table-data-container">
              <div class="table-data" *ngFor="let num of numOfRow">
                <span
                  appCompareEditField
                  [auditCompares]="auditCompares"
                  [index]="num"
                  field="purpose"
                  >{{ auditCompares[num]?.data?.purpose | landPurpose }}
                </span>
              </div>
            </div>
          </div>
          <div class="d-flex table-row">
            <div class="table-heading fw-500">
              <span>Trạng thái BĐS</span>
            </div>
            <div class="d-flex table-data-container">
              <div class="table-data" *ngFor="let num of numOfRow">
                <span
                  appCompareEditField
                  [auditCompares]="auditCompares"
                  [index]="num"
                  field="status"
                  >{{ auditCompares[num]?.data?.status | getFullStatus }}
                </span>
              </div>
            </div>
          </div>
          <div class="d-flex table-row">
            <div class="table-heading fw-500">
              <span>Giá bán</span>
            </div>
            <div class="d-flex table-data-container">
              <div class="table-data" *ngFor="let num of numOfRow">
                <span
                  appCompareEditField
                  [auditCompares]="auditCompares"
                  [index]="num"
                  field="price"
                  >{{
                    auditCompares[num]?.data?.price | transformPriceToString
                  }}</span
                >
              </div>
            </div>
          </div>
          <div class="d-flex table-row">
            <div class="table-heading fw-500">
              <span>Loại hình BĐS</span>
            </div>
            <div class="d-flex table-data-container">
              <div class="table-data" *ngFor="let num of numOfRow">
                <span
                  appCompareEditField
                  [auditCompares]="auditCompares"
                  [index]="num"
                  field="type"
                  >{{ auditCompares[num]?.data?.type | landType | async }}</span
                >
              </div>
            </div>
          </div>
          <div class="d-flex table-row">
            <div class="table-heading fw-500">
              <span>Diện tích xây dựng</span>
            </div>
            <div class="d-flex table-data-container">
              <div class="table-data" *ngFor="let num of numOfRow">
                <span
                  appCompareEditField
                  [auditCompares]="auditCompares"
                  [index]="num"
                  field="acreage"
                >
                  {{ auditCompares[num]?.data?.acreage }}m²
                </span>
              </div>
            </div>
          </div>
          <div class="d-flex table-row">
            <div class="table-heading fw-500">
              <span>Số tầng</span>
            </div>
            <div class="d-flex table-data-container">
              <div class="table-data" *ngFor="let num of numOfRow">
                <span
                  appCompareEditField
                  [auditCompares]="auditCompares"
                  [index]="num"
                  field="floors"
                  >{{ auditCompares[num]?.data?.floors }}</span
                >
              </div>
            </div>
          </div>
          <div class="d-flex table-row">
            <div class="table-heading fw-500">
              <span>Hướng nhà</span>
            </div>
            <div class="d-flex table-data-container">
              <div class="table-data" *ngFor="let num of numOfRow">
                <span
                  appCompareEditField
                  [auditCompares]="auditCompares"
                  [index]="num"
                  field="direction"
                  >{{ auditCompares[num]?.data?.direction || "--" }}</span
                >
              </div>
            </div>
          </div>
          <div class="d-flex table-row">
            <div class="table-heading fw-500">
              <span>Đường rộng</span>
            </div>
            <div class="d-flex table-data-container">
              <div class="table-data" *ngFor="let num of numOfRow">
                <span
                  appCompareEditField
                  [auditCompares]="auditCompares"
                  [index]="num"
                  field="streetWidth"
                  >{{ auditCompares[num]?.data?.streetWidth || "--" }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- KÍCH THƯỚC CHI TIẾT  -->
      <div class="detailed-dimensions">
        <div
          class="heading"
          (click)="toggleCollapse(2)"
          [attr.aria-expanded]="!collapsed[2]"
        >
          <span>Kích thước chi tiết</span>
          <i
            class="ri-arrow-down-s-line fs-20"
            [@rotateIcon]="collapsed[2] ? 'collapsed' : 'expanded'"
          ></i>
        </div>
        <div #collapse="ngbCollapse" [(ngbCollapse)]="collapsed[2]">
          <div class="d-flex table-row">
            <div class="table-heading fw-500">
              <span>Diện tích đất</span>
            </div>
            <div class="d-flex table-data-container">
              <div class="table-data" *ngFor="let num of numOfRow">
                <span
                  appCompareEditField
                  [auditCompares]="auditCompares"
                  [index]="num"
                  field="landArea"
                >
                  {{ auditCompares[num]?.data?.landArea || "--" }}m²
                </span>
              </div>
            </div>
          </div>
          <div class="d-flex table-row">
            <div class="table-heading fw-500">
              <span>Diện tích XD</span>
            </div>
            <div class="d-flex table-data-container">
              <div class="table-data" *ngFor="let num of numOfRow">
                <span
                  appCompareEditField
                  [auditCompares]="auditCompares"
                  [index]="num"
                  field="floorArea"
                  >{{ auditCompares[num]?.data?.floorArea || "--" }}m²</span
                >
              </div>
            </div>
          </div>
          <div class="d-flex table-row">
            <div class="table-heading fw-500">
              <span>Mặt tiền</span>
            </div>
            <div class="d-flex table-data-container">
              <div class="table-data" *ngFor="let num of numOfRow">
                <span
                  appCompareEditField
                  [auditCompares]="auditCompares"
                  [index]="num"
                  field="frontWidth"
                  >{{ auditCompares[num]?.data?.frontWidth || "--" }}m</span
                >
              </div>
            </div>
          </div>
          <div class="d-flex table-row">
            <div class="table-heading fw-500">
              <span>Mặt tiền(<abbr title="Thông Thuỷ">TT</abbr>)</span>
            </div>
            <div class="d-flex table-data-container">
              <div class="table-data" *ngFor="let num of numOfRow">
                <span
                  appCompareEditField
                  [auditCompares]="auditCompares"
                  [index]="num"
                  field="clearanceFw"
                  >{{ auditCompares[num]?.data?.clearanceFw }}m</span
                >
              </div>
            </div>
          </div>
          <div class="d-flex table-row">
            <div class="table-heading fw-500">
              <span>Mặt sau</span>
            </div>
            <div class="d-flex table-data-container">
              <div class="table-data" *ngFor="let num of numOfRow">
                <span
                  appCompareEditField
                  [auditCompares]="auditCompares"
                  [index]="num"
                  field="backWidth"
                  >{{ auditCompares[num]?.data?.backWidth || "--" }}m</span
                >
              </div>
            </div>
          </div>
          <div class="d-flex table-row">
            <div class="table-heading fw-500">
              <span>Mặt sau(<abbr title="Thông Thuỷ">TT</abbr>)</span>
            </div>
            <div class="d-flex table-data-container">
              <div class="table-data" *ngFor="let num of numOfRow">
                <span
                  appCompareEditField
                  [auditCompares]="auditCompares"
                  [index]="num"
                  field="clearanceBw"
                  >{{ auditCompares[num]?.data?.clearanceBw || "--" }}m</span
                >
              </div>
            </div>
          </div>
          <div class="d-flex table-row">
            <div class="table-heading fw-500">
              <span>Chiều dài</span>
            </div>
            <div class="d-flex table-data-container">
              <div class="table-data" *ngFor="let num of numOfRow">
                <span
                  appCompareEditField
                  [auditCompares]="auditCompares"
                  [index]="num"
                  field="length"
                >
                  {{ auditCompares[num]?.data?.length || "--" }}m
                </span>
              </div>
            </div>
          </div>
          <div class="d-flex table-row">
            <div class="table-heading fw-500">
              <span>Chiều dài(<abbr title="Thông Thuỷ">TT</abbr>)</span>
            </div>
            <div class="d-flex table-data-container">
              <div class="table-data" *ngFor="let num of numOfRow">
                <span
                  appCompareEditField
                  [auditCompares]="auditCompares"
                  [index]="num"
                  field="clearanceLength"
                >
                  {{ auditCompares[num]?.data?.clearanceLength || "--" }}m
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- NỘI THẤT  -->
      <div class="interior">
        <div
          class="heading"
          (click)="toggleCollapse(3)"
          [attr.aria-expanded]="!collapsed[3]"
        >
          <span>Nội thất</span>
          <i
            class="ri-arrow-down-s-line fs-20"
            [@rotateIcon]="collapsed[3] ? 'collapsed' : 'expanded'"
          ></i>
        </div>
        <div #collapse="ngbCollapse" [(ngbCollapse)]="collapsed[3]">
          <div class="d-flex table-row">
            <div class="table-heading fw-500">
              <span>Tình trạng nội thất</span>
            </div>
            <div class="d-flex table-data-container">
              <div class="table-data" *ngFor="let num of numOfRow">
                <span
                  appCompareEditField
                  [auditCompares]="auditCompares"
                  [index]="num"
                  field="furniture"
                  >{{ auditCompares[num]?.data?.furniture || "--" }}</span
                >
              </div>
            </div>
          </div>
          <div class="d-flex table-row">
            <div class="table-heading fw-500">
              <span>Phòng ngủ</span>
            </div>
            <div class="d-flex table-data-container">
              <div class="table-data" *ngFor="let num of numOfRow">
                <span
                  appCompareEditField
                  [auditCompares]="auditCompares"
                  [index]="num"
                  field="bedrooms"
                  >{{ auditCompares[num]?.data?.bedrooms || "--" }}</span
                >
              </div>
            </div>
          </div>
          <div class="d-flex table-row">
            <div class="table-heading fw-500">
              <span>Phòng vệ sinh</span>
            </div>
            <div class="d-flex table-data-container">
              <div class="table-data" *ngFor="let num of numOfRow">
                <span
                  appCompareEditField
                  [auditCompares]="auditCompares"
                  [index]="num"
                  field="toilets"
                  >{{ auditCompares[num]?.data?.toilets || "--" }}</span
                >
              </div>
            </div>
          </div>
          <div class="d-flex table-row">
            <div class="table-heading fw-500">
              <span>Nóng lạnh</span>
            </div>
            <div class="d-flex table-data-container">
              <div class="table-data" *ngFor="let num of numOfRow">
                <span
                  appCompareEditField
                  [auditCompares]="auditCompares"
                  [index]="num"
                  field="heaters"
                  >{{ auditCompares[num]?.data?.heaters || "--" }}</span
                >
              </div>
            </div>
          </div>
          <div class="d-flex table-row">
            <div class="table-heading fw-500">
              <span>Phòng bếp</span>
            </div>
            <div class="d-flex table-data-container">
              <div class="table-data" *ngFor="let num of numOfRow">
                <span
                  appCompareEditField
                  [auditCompares]="auditCompares"
                  [index]="num"
                  field="kitchens"
                  >{{ auditCompares[num]?.data?.kitchens || "--" }}</span
                >
              </div>
            </div>
          </div>
          <div class="d-flex table-row">
            <div class="table-heading fw-500">
              <span>Phòng khách</span>
            </div>
            <div class="d-flex table-data-container">
              <div class="table-data" *ngFor="let num of numOfRow">
                <span
                  appCompareEditField
                  [auditCompares]="auditCompares"
                  [index]="num"
                  field="livingRooms"
                >
                  {{ auditCompares[num]?.data?.livingRooms || "--" }}
                </span>
              </div>
            </div>
          </div>
          <div class="d-flex table-row">
            <div class="table-heading fw-500">
              <span>Điều hoà</span>
            </div>
            <div class="d-flex table-data-container">
              <div class="table-data" *ngFor="let num of numOfRow">
                <span
                  appCompareEditField
                  [auditCompares]="auditCompares"
                  [index]="num"
                  field="airConditioners"
                  >{{ auditCompares[num]?.data?.airConditioners || "--" }}</span
                >
              </div>
            </div>
          </div>
          <div class="d-flex table-row">
            <div class="table-heading fw-500">
              <span>Thang máy</span>
            </div>
            <div class="d-flex table-data-container">
              <div class="table-data" *ngFor="let num of numOfRow">
                <span
                  appCompareEditField
                  [auditCompares]="auditCompares"
                  [index]="num"
                  field="elevators"
                  >{{ auditCompares[num]?.data?.elevators || "--" }}</span
                >
              </div>
            </div>
          </div>
          <div class="d-flex table-row">
            <div class="table-heading fw-500">
              <span>Chất liệu sàn</span>
            </div>
            <div class="d-flex table-data-container">
              <div class="table-data" *ngFor="let num of numOfRow">
                <span
                  appCompareEditField
                  [auditCompares]="auditCompares"
                  [index]="num"
                  field="floorMaterial"
                >
                  {{ auditCompares[num]?.data?.floorMaterial || "--" }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- NGOẠI THẤT  -->
      <div class="exterior">
        <div
          class="heading"
          (click)="toggleCollapse(4)"
          [attr.aria-expanded]="!collapsed[4]"
        >
          <span>Ngoại thất</span>
          <i
            class="ri-arrow-down-s-line fs-20"
            [@rotateIcon]="collapsed[4] ? 'collapsed' : 'expanded'"
          ></i>
        </div>
        <div #collapse="ngbCollapse" [(ngbCollapse)]="collapsed[4]">
          <div class="d-flex table-row">
            <div class="table-heading fw-500">
              <span>Cửa sổ</span>
            </div>
            <div class="d-flex table-data-container">
              <div class="table-data" *ngFor="let num of numOfRow">
                <span
                  appCompareEditField
                  [auditCompares]="auditCompares"
                  [index]="num"
                  field="windows"
                  >{{ auditCompares[num]?.data?.windows || "--" }}</span
                >
              </div>
            </div>
          </div>
          <div class="d-flex table-row">
            <div class="table-heading fw-500">
              <span>Hướng ban công</span>
            </div>
            <div class="d-flex table-data-container">
              <div class="table-data" *ngFor="let num of numOfRow">
                <span
                  appCompareEditField
                  [auditCompares]="auditCompares"
                  [index]="num"
                  field="balconyDirection"
                >
                  {{ auditCompares[num]?.data?.balconyDirection || "--" }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- KHU VỰC ĐỖ XE Ô TÔ  -->
      <div class="parking">
        <div
          class="heading"
          (click)="toggleCollapse(5)"
          [attr.aria-expanded]="!collapsed[5]"
        >
          <span>Khu vực đỗ xe ô tô</span>
          <i
            class="ri-arrow-down-s-line fs-20"
            [@rotateIcon]="collapsed[5] ? 'collapsed' : 'expanded'"
          ></i>
        </div>
        <div #collapse="ngbCollapse" [(ngbCollapse)]="collapsed[5]">
          <div class="d-flex table-row">
            <div class="table-heading fw-500">
              <span>Đặc điểm khu vực đỗ xe</span>
            </div>
            <div class="d-flex table-data-container">
              <div class="table-data" *ngFor="let num of numOfRow">
                <span
                  appCompareEditField
                  [auditCompares]="auditCompares"
                  [index]="num"
                  field="parking"
                  >{{ auditCompares[num]?.data?.parking || "--" }}</span
                >
              </div>
            </div>
          </div>
          <div class="d-flex table-row">
            <div class="table-heading fw-500">
              <span>Khoảng cách từ nhà tới bãi đỗ xe</span>
            </div>
            <div class="d-flex table-data-container">
              <div class="table-data" *ngFor="let num of numOfRow">
                <span
                  appCompareEditField
                  [auditCompares]="auditCompares"
                  [index]="num"
                  field="parkingDistance"
                >
                  {{ auditCompares[num]?.data?.parkingDistance || "--" }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- THÔNG TIN KHÁC  -->
      <div class="other-infomation">
        <div
          class="heading"
          (click)="toggleCollapse(6)"
          [attr.aria-expanded]="!collapsed[6]"
        >
          <span>Thông tin khác</span>
          <i
            class="ri-arrow-down-s-line fs-20"
            [@rotateIcon]="collapsed[6] ? 'collapsed' : 'expanded'"
          ></i>
        </div>
        <div #collapse="ngbCollapse" [(ngbCollapse)]="collapsed[6]">
          <div class="d-flex table-row">
            <div class="table-heading fw-500">
              <span>Loại đường</span>
            </div>
            <div class="d-flex table-data-container">
              <div class="table-data" *ngFor="let num of numOfRow">
                <span
                  appCompareEditField
                  [auditCompares]="auditCompares"
                  [index]="num"
                  field="streetType"
                >
                  {{ auditCompares[num]?.data?.streetType || "--" }}
                </span>
              </div>
            </div>
          </div>
          <div class="d-flex table-row">
            <div class="table-heading fw-500">
              <span>Năm xây dựng</span>
            </div>
            <div class="d-flex table-data-container">
              <div class="table-data" *ngFor="let num of numOfRow">
                <span
                  appCompareEditField
                  [auditCompares]="auditCompares"
                  [index]="num"
                  field="constructYear"
                >
                  {{ auditCompares[num]?.data?.constructYear || "--" }}
                </span>
              </div>
            </div>
          </div>
          <div class="d-flex table-row">
            <div class="table-heading fw-500">
              <span>Hồ sơ pháp lý</span>
            </div>
            <div class="d-flex table-data-container">
              <div class="table-data" *ngFor="let num of numOfRow">
                <span
                  appCompareEditField
                  [auditCompares]="auditCompares"
                  [index]="num"
                  field="businessStatus"
                >
                  {{ auditCompares[num]?.data?.businessStatus || "--" }}
                </span>
              </div>
            </div>
          </div>
          <div class="d-flex table-row">
            <div class="table-heading fw-500">
              <span>Đơn vị đang thuê</span>
            </div>
            <div class="d-flex table-data-container">
              <div class="table-data" *ngFor="let num of numOfRow">
                <span
                  appCompareEditField
                  [auditCompares]="auditCompares"
                  [index]="num"
                  field="tenant"
                  >{{ auditCompares[num]?.data?.tenant || "--" }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
