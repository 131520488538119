import { Component, inject, Injector, Input, ViewChild } from "@angular/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { HISTORY_CALL_ORDER } from "@constant/common.constant";
import { CallHistoryOrderModel } from "@model/call-history-info.model";
import { HttpContactHistoryService } from "@service/call-history/http-contact-history.service";
import { StateContactHistoryService } from "@service/call-history/state-contact-history.service";
import { StorageService } from "@service/storage/storage.service";
import { ConvertDateTimeToString } from "@util/date-time.util";
import { SwalErrorUtil } from "@util/swal-error.util";
import { SwalSuccessUtil } from "@util/swal-success.util";
import { SwalWarningUtil } from "@util/swal-warning.util";
import { catchError } from "rxjs/operators";
import { CallHistoryOrderFormComponent } from "../containers/call-history-order-form/call-history-order-form.component";
import { CallHistoryOrderFormHelper } from "../containers/call-history-order-form/call-history-order-form.helper";
import { CallHistoryOrderListComponent } from "../containers/call-history-order-list/call-history-order-list.component";
import { OrderInfoComponent } from "../containers/order-info/order-info.component";
import { OrderOwnerComponent } from "../containers/order-owner/order-owner.component";

@Component({
  selector: "app-call-history-buy-rent",
  templateUrl: "./call-history-buy-rent.component.html",
  standalone: true,
  imports: [
    MatTooltipModule,
    OrderInfoComponent,
    CallHistoryOrderListComponent,
    OrderOwnerComponent,
    CallHistoryOrderFormComponent,
  ],
  providers: [CallHistoryOrderFormHelper, StateContactHistoryService],
})
export class CallHistoryBuyRentComponent {
  private formHelper = inject(CallHistoryOrderFormHelper);
  public injector = inject(Injector);
  private contactHistoryService = inject(HttpContactHistoryService);
  private storageService = inject(StorageService);

  @ViewChild(CallHistoryOrderListComponent)
  callHistoryOrder: CallHistoryOrderListComponent;

  public callHistoryForm = this.formHelper.form;

  @Input() set data(value: any) {
    if (value !== null) {
      this.formHelper.patchData = value;
    }
  }

  public submitForm() {
    this.updateTime();

    if (!this.callHistoryForm.valid) {
      SwalWarningUtil.show("Bạn cần điền hết những trường bắt buộc!");
      return;
    }

    this.handleSaveCallHistory();
  }

  private handleSaveCallHistory() {
    const { order, ...form } = this.callHistoryForm.getRawValue();
    const payload = this.getHistoryCallPayload(form);
    this.contactHistoryService
      .createHistoryCallOrder(order, payload)
      .pipe(
        catchError((error) => {
          SwalErrorUtil.show("Xảy ra lỗi");
          throw error;
        })
      )
      .subscribe((res: any) => {
        SwalSuccessUtil.show("Thành công");
        this.resetControl(["statusCall", "content"]);
        this.callHistoryOrder.loadContactHistories();
        this.addToLocalStorage(res.data.id);
      });
  }

  private addToLocalStorage(id: number) {
    const storedData = this.storageService.get(HISTORY_CALL_ORDER);
    const historyCallOrder = storedData ? JSON.parse(storedData) : [];

    historyCallOrder.push(id);

    this.storageService.set(
      HISTORY_CALL_ORDER,
      JSON.stringify(historyCallOrder)
    );
    SwalSuccessUtil.show("Thành công!", "Nội dung ghi âm đang được xử lý!");
  }

  private getHistoryCallPayload(value: CallHistoryOrderModel) {
    const endTime = ConvertDateTimeToString(new Date(), this.injector);

    return {
      customerId: value.customerId,
      title: value.title,
      content: value.content,
      method: value.method,
      statusCall: value.statusCall,
      caller: value.caller,
      phoneNumber: value.phoneNumber,
      record: value.record,
      ext: value.ext,
      groupId: value.groupId,
      startTime: value.startTime,
      endTime,
    };
  }

  private resetControl(fields: string[]) {
    fields.forEach((f) => {
      this.callHistoryForm.controls[f]
        ? this.callHistoryForm.controls[f].reset()
        : null;
    });
  }

  private updateTime(): void {
    this.callHistoryForm.patchValue({
      endTime: ConvertDateTimeToString(new Date(), this.injector),
    });
  }
}
