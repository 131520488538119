// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  defaultauth: "fackbackend",
  firebaseConfig: {
    apiKey: "",
    authDomain: "",
    databaseURL: "",
    projectId: "",
    storageBucket: "",
    messagingSenderId: "",
    appId: "",
    measurementId: "",
  },
  awsConfig: {
    bucketName: "hl-s3-dev",
    folderPath: "/land-images",
    signatureVersion: "",
    accessKeyId: "",
    secretAccessKey: "",
    region: "ap-southeast-1",
  },
  s3Config: {
    key: "",
    bucket: "hl-s3-dev",
    bucket_video: "hl-s3-dev/videos",
    // acl: "public-read",
  },
  domain: "https://admin-portal-dev.hoaland.com",
  handleHost: "https://d240ci0illjbr5.cloudfront.net/fit-in/0x0/",
  apiURL: "https://dev-api.hoaland.com/api/gateway/v1",
  googleApiKey: "AIzaSyAooXtTDymTYgxCaO68SpxWTlf6S2YnfeY",
  apiVersionV1: "v1",
  apiVersionV2: "v2",
  appVersion: "1.0.1",
};
