import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { LANDS_TYPE_MAP } from "src/app/public/constants/real-estate.constant";

@Component({
  selector: "app-filter-purpose-map",
  templateUrl: "./filter-purpose-map.component.html",
})
export class FilterPurposeMapComponent implements OnInit {
  @Input() childControl: UntypedFormControl = new UntypedFormControl();
  public items = LANDS_TYPE_MAP.map((i) => {
    return { id: i.cKey, title: i.cValue };
  });

  constructor() {}

  ngOnInit(): void {
    this.selectAllForDropdownItems(this.items);
  }

  selectAllForDropdownItems(items: any[]) {
    items.forEach((element: any) => {
      element["selectedAllGroup"] = "selectedAllGroup";
    });
  }
}
