import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "transformPriceToString",
})
export class TransformPriceToStringPipe implements PipeTransform {
  constructor() {}

  transform(value: number, currency: string = "VND"): string | number {
    if (value) {
      const length = value.toString().length;

      if (length > 9) {
        return (value / 1000000000)
          .toFixed(2)
          .replace(/\.0+$/, "")
          .toString()
          .replace(".", ",")
          .concat(" tỷ");
      } else if (length > 6) {
        return (value / 1000000)
          .toFixed(2)
          .toString()
          .replace(".", ",")
          .concat(" triệu");
      } else {
        return new Intl.NumberFormat("vi-VN", {
          style: "currency",
          currency: "VND",
        }).format(value);
      }
    }
    return "--";
  }
}
