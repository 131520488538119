<ng-container
  *ngIf="stateContactHistory.historyCallOrders$ | async as contactHistories"
>
  <ng-container *ngIf="contactHistories?.length">
    <app-table
      [data]="contactHistories"
      [total]="total$ | async"
      [pageSize]="pageSize$ | async"
      [limit]="limit$ | async"
      [page]="page$ | async"
      (pageChange)="updatePage($event)"
      [showSkeleton]="false"
    >
      <ng-template #headers>
        <ng-container *ngFor="let item of COLUMNS">
          <th>{{ item }}</th>
        </ng-container>
      </ng-template>

      <ng-template #content let-data>
        <td scope="row">
          <div class="text-truncate" [ngbTooltip]="data?.title">
            <span class="title-w d-block">
              {{ data?.title }}
            </span>
          </div>
        </td>
        <td>
          <div class="text-truncate bg-soft-info" [ngbTooltip]="data.content">
            <span class="content-w d-block">
              {{ data?.content }}
            </span>
          </div>
        </td>
        <td>{{ data?.caller }}</td>
        <td>
          {{ data?.phoneNumber | invisiblePhoneNumber | async }}
        </td>
        <td>
          <button
            ngbTooltip="Bấm để nghe"
            class="btn btn-sm btn-outline-success"
          >
            <i class="ri-volume-up-line fs-18 me-1"></i>
          </button>
        </td>
        <td>{{ data?.method.name }}</td>
        <td>{{ data?.statusCall.name }}</td>

        <td>
          {{ data?.startTime | date: " HH:mm" }} -
          {{ data?.endTime | date: " HH:mm" }}
        </td>
        <td>{{ data?.endTime | date: "dd/MM/YYYY" }}</td>
      </ng-template>
    </app-table>
  </ng-container>

  <div
    *ngIf="stateContactHistory.isLoading$ | async"
    class="card card-light my-4 m-0"
  >
    <div class="card-body text-center text-primary">
      <div>Đang tải dữ liệu</div>
      <i class="fs-24 text-info mdi mdi-loading"></i>
    </div>
    <app-skeleton-table tableType="tableUser"></app-skeleton-table>
  </div>

  <div *ngIf="!contactHistories?.length" class="card card-light my-4 m-0">
    <div class="card-body text-center text-primary">
      <div>Không có dữ liệu hiển thị</div>
      <i class="fs-24 mdi mdi-database-off-outline"></i>
    </div>
  </div>
</ng-container>

<ng-template>
  <audio controls>
    <source src="horse.ogg" type="audio/ogg" />
    <source src="horse.mp3" type="audio/mpeg" />
    Your browser does not support the audio element.
  </audio>
</ng-template>
