import {
  IMAGE_FILE_TYPE,
  VIDEO_FILE_TYPE,
} from "src/app/public/constants/common.constant";

export function CheckStyleUrl(url: string): string {
  const extension = url?.split(/[#?]/)[0].split(".").pop().trim();
  const isImage =
    IMAGE_FILE_TYPE.includes(extension?.toLowerCase()) ||
    IMAGE_FILE_TYPE.find((i) => url?.toLowerCase().includes(i));
  const isVideo =
    VIDEO_FILE_TYPE.includes(extension?.toLowerCase()) ||
    VIDEO_FILE_TYPE.find((i) => url?.toLowerCase().includes(i));

  if (isImage) {
    return "image";
  } else if (isVideo) {
    return "video";
  }
  return "";
}
