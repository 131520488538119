import { Pipe, PipeTransform } from "@angular/core";
import { LAND_PURPOSE } from "src/app/public/constants/common.constant";

@Pipe({
  name: "getClassPurpose",
})
export class GetClassPurposePipe implements PipeTransform {
  transform(value: string): string {
    if (LAND_PURPOSE.FOR_RENT === value) {
      return "badge-soft-info";
    } else if (LAND_PURPOSE.FOR_SELL === value) {
      return "badge-soft-warning";
    }
    return "badge-soft-danger";
  }
}
