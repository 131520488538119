import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "getAvatarImage",
  standalone: true,
})
export class GetAvatarImagePipe implements PipeTransform {
  AVATAR_SIZE = "100x100";

  transform(url: string): string {
    if (url) {
      return url.replace("0x0", this.AVATAR_SIZE);
    }
    return url;
  }
}
