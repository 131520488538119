import { AgmMap } from "@agm/core";
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { LAND_PURPOSE } from "src/app/public/constants/common.constant";
import { RealEstateModel } from "src/app/public/models/real-estate.model";

@Component({
  selector: "app-map-of-real-estate-detail",
  templateUrl: "./map-of-real-estate-detail.component.html",
  styleUrls: ["./map-of-real-estate-detail.component.scss"],
})
export class MapOfRealEstateDetailComponent {
  @Input() land: RealEstateModel;
  @Output() landSelected = new EventEmitter();
  user: any;
  public lat: number;
  public lng: number;
  public zoom: number = 16;
  public mapStyles: any = [
    {
      featureType: "all",
      elementType: "labels.text.stroke",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "all",
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }],
    },
  ];

  LAND_PURPOSE = LAND_PURPOSE;

  @ViewChild(AgmMap)
  public agmMap: AgmMap;

  @ViewChild("mapContainer")
  public mapContainer: ElementRef;

  map: any;
  coordinates: any[] = [];
  polygonPts: any;

  constructor(public dialog: MatDialog) {
  }

  onMouseOver(infoWindow: any) {
    infoWindow.open();
  }

  onMouseOut(infoWindow: any) {
    infoWindow.close();
  }

  // clickStreetView(tab: any) {
  //   this.dialog.open(ItemDetailMoreInfoModalComponent, {
  //     width: "100%",
  //     data: {
  //       land: this.land,
  //       user: this.user?.username,
  //       tab: tab,
  //     },
  //   });
  // }
}
