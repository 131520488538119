<div id="layout-wrapper">
  <!-- Top Bar -->
  <app-topbar (mobileMenuButtonClicked)="onToggleMobileMenu()"></app-topbar>
  <!-- Side Bar -->
  <!-- <app-sidebar></app-sidebar> -->
  <app-horizontal-topbar></app-horizontal-topbar>
  <div class="main-content">
    <div class="page-content">
      <div class="container-fluid">
        <!-- Main Content -->
        <router-outlet></router-outlet>
      </div>
    </div>
    <!-- Footer -->
    <app-footer></app-footer>
  </div>
</div>
<!-- Right Side Bar -->
<app-rightsidebar
  (settingsButtonClicked)="onSettingsButtonClicked()"
></app-rightsidebar>
