import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Params } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";
import { take } from "rxjs/operators";
import { AddBulkRealEstateModalComponent } from "src/app/pages/real-estate-management/components/add-bulk-real-estate-modal/add-bulk-real-estate-modal.component";
import { RealEstateCreationComponent } from "src/app/pages/real-estate-management/real-estate-creation/real-estate-creation.component";
import {
  PERMISSION_ACTIONS,
  REAL_ESTATE_RESOURCES,
} from "src/app/public/constants/permission.contants";
import {
  STATUS_IN_PROGRESS,
  STATUS_QUICK_UPLOAD,
  STATUS_WAITING_APPROVE,
} from "src/app/public/constants/real-estate.constant";
import { FormSearchRealEstateHelper } from "src/app/public/helpers/form-search-real-estate.helper";
import {
  convertFileXlsxToJsonUtil,
  DEFINED_COLUMN_XLSX,
} from "src/app/public/utils/convert-xlsx-to-json.util";
import { FullSearchComponent } from "./full-search/full-search.component";

@Component({
  selector: "app-search-filter-bar",
  templateUrl: "./search-filter-bar.component.html",
  styleUrls: ["./search-filter-bar.component.scss"],
})
export class SearchFilterBarComponent implements OnInit, OnDestroy {
  public subscription$: Subject<any> = new Subject();
  public STATUS_QUICK_UPLOAD = STATUS_QUICK_UPLOAD;
  public STATUS_IN_PROGRESS = STATUS_IN_PROGRESS;
  public STATUS_WAITING_APPROVED = STATUS_WAITING_APPROVE;
  public PERMISSION_ACTIONS = PERMISSION_ACTIONS;
  public REAL_ESTATE_RESOURCES = REAL_ESTATE_RESOURCES;

  @ViewChild("inputFileXlsx") fileXlsx: any;
  @Input() searchLandParams: UntypedFormGroup;
  @Input() titleForParams: string;
  @Output() createRealEstateEvent = new EventEmitter();

  constructor(
    private modalService: NgbModal,
    private formService: FormSearchRealEstateHelper,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {
    this.searchLandParams = this.formService.form;
  }

  ngOnDestroy(): void {
    this.subscription$.next(null);
    this.subscription$.complete();
  }

  ngOnInit() {
    // sync data form url.
    this.updateFormFromUrl();
  }

  public getControl(name: string) {
    return this.searchLandParams.get(name) as UntypedFormControl;
  }

  public updateFormFromUrl(): void {
    this.route.queryParams.pipe(take(1)).subscribe((params: Params) => {
      const keys = Object.keys(params);
      if (keys?.length) {
        keys.forEach((k) => {
          this.searchLandParams.patchValue(
            {
              [k]: params[k],
            },
            {
              emitEvent: false,
            }
          );
        });
      }
    });
  }

  // SỰ KIỆN MỞ MODAL
  public onShowFullSearchModal() {
    const modalRef = this.modalService.open(FullSearchComponent, {
      size: "xl",
      centered: true,
    });

    modalRef.componentInstance.onSearchEvent.subscribe((params: Params) => {
      this.searchLandParams.patchValue(params);
    });
  }

  public onEventFileSelected($event: any): void {
    const file = $event.target.files[0];
    const data = convertFileXlsxToJsonUtil(file);
    data.subscribe((data) => {
      const sheetJson = data[Object.keys(data)[0]];
      const modal = this.modalService.open(AddBulkRealEstateModalComponent, {
        fullscreen: "xl",
      });
      modal.componentInstance.data = this.convertData(sheetJson);
      modal.componentInstance.listData = this.convertData(sheetJson);
      modal.closed.subscribe((_) => {
        this.fileXlsx.nativeElement.value = "";
      });
    });
  }

  private convertData(data: any) {
    const columns = DEFINED_COLUMN_XLSX;
    return data.map((d: any) => {
      // @ts-ignore
      let newObject: any = {};
      // @ts-ignore
      Object.keys(d).map((k) => {
        const objectExist = columns.find(
          (column) => column.name.toUpperCase() === k.toUpperCase()
        );

        if (objectExist) {
          newObject[objectExist.key] = d[k];
        }
      });
      return newObject;
    });
  }

  public openCreateRealEstateModal() {
    const modalRef = this.dialog.open(RealEstateCreationComponent, {
      panelClass: "real-estate-creation",
      width: "1300px",
      height: "90%",
      disableClose: true,
    });

    modalRef.componentInstance.createRealEstateEvent.subscribe(() => {
      this.createRealEstateEvent.emit();
    });
  }

  // SỰ KIỆN CLEAR FORM SEARCH
  public clearFormRealEstate() {
    this.searchLandParams.reset();
  }
}
