export const APP_URL = {
  //  AUTH
  LOGIN: "/auth/login",

  //  REAL ESTATE
  REAL_ESTATE_MANAGEMENT: "/real-estate-management",
  REAL_ESTATE_DETAIL: "/real-estate-management/detail",
  REAL_ESTATE_QUICK_UP_DETAIL: "/real-estate-management/detail-quick-up",

  //  USER MANAGEMENT
  USER_MANAGEMENT: "/user-management",
  CUSTOMER_DETAIL: "/customer-management/detail",
  AGENT_DETAIL: "user-management/agent/detail",

  //GROUP
  GROUP_MANAGEMENT: "user-management/organization/group",
  GROUP_DETAIL: "user-management/organization/group/detail",
  GROUP_CREATION: "user-management/organization/group/creation",
};
