import { NgFor, NgIf } from "@angular/common";
import { Component } from "@angular/core";
import { DATA_MOCK } from "@component/notification-dropdown/data";
import { SimplebarAngularModule } from "simplebar-angular";
import { NotifyEmptyComponent } from "../notify-empty/notify-empty.component";
import { NotifyItemComponent } from "../notify-item/notify-item.component";

@Component({
  selector: "app-tab-notify",
  templateUrl: "./tab-notify.component.html",
  standalone: true,
  imports: [
    SimplebarAngularModule,
    NgIf,
    NgFor,
    NotifyEmptyComponent,
    NotifyItemComponent,
  ],
})
export class TabNotifyComponent {
  public DATA = DATA_MOCK;
}
