<div
  class="text-reset notification-item d-block dropdown-item position-relative"
>
  <div class="d-flex">
    <div class="avatar-xs me-3">
      <span class="avatar-title bg-soft-info text-info rounded-circle fs-16">
        <i class="bx bx-badge-check"></i>
      </span>
    </div>
    <div class="flex-1">
      <a href="javascript:void(0);" class="stretched-link"
        ><h6 class="mt-0 mb-1 fs-13 fw-semibold text-primary">
          {{ data.title }}
        </h6></a
      >
      <div class="fs-13 text-muted">
        <p class="mb-1">
          {{ data.body }}
        </p>
      </div>
      <p class="mb-0 fs-11 fw-medium text-uppercase text-muted">
        <span><i class="mdi mdi-clock-outline"></i> {{ data.time }}</span>
      </p>
    </div>
    <div class="px-2 fs-15">
      <div class="form-check notification-check">
        <input class="form-check-input" type="checkbox" id="notify-item" />
        <label class="form-check-label" for="notify-item"></label>
      </div>
    </div>
  </div>
</div>
