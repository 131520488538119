import { UserService } from "./../services/user/user.service";
import { StorageService } from "src/app/public/services/storage/storage.service";
import { JWT_TOKEN } from "./../constants/common.constant";
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  UrlSegment,
  UrlTree,
} from "@angular/router";

// Auth Services
import { AuthenticationService } from "src/app/public/services/auth/auth.service";
import { Observable } from "rxjs";
import { APP_URL } from "src/app/public/constants/url.constant";
import { AuthorizationService } from "../services/auth/authorization.service";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate, CanLoad {
  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private storageService: StorageService,
    private userService: UserService,
    private authorizationService: AuthorizationService
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const resource = route.data["resource"];
    const currentUser = this.userService.user$.value;

    const hasPermissionResource =
      this.authorizationService.hasPermissionResource(resource);

    if (currentUser && hasPermissionResource) {
      return true;
    } else {
      this.router.navigate([APP_URL.LOGIN]).then();
      return false;
    }
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const hasToken = Boolean(this.storageService.get(JWT_TOKEN));
    const hasPermission = this.authService.hasPermission([""]);

    // Nếu không có token or permission sẽ chạy sang login.
    if (!hasToken || !hasPermission) {
      this.router.navigate([APP_URL.LOGIN]).then();
      return false;
    }
    return true;
  }
}
