import { UntypedFormGroup } from "@angular/forms";

export function compareRange(numA: string, numB: string) {
  return (form: UntypedFormGroup) => {
    if (form.controls[numA].value > form.controls[numB].value) {
      return form.controls[numB].setErrors({ invalidNumber: true });
    } else {
      return form.controls[numB].setErrors(null);
    }
  };
}
