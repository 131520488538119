<div class="map-container w-100 h-100 position-relative">
  <div
    class="draw-action-bar d-flex align-items-center justify-content-between position-absolute text-white top-0 start-0 end-0"
    *ngIf="isShowConfirm"
  >
    <span class="ps-3 fs-14">Vẽ 1 hình xung quanh khu vực bạn muốn tìm</span>
    <div class="d-flex align-items-center">
      <button
        (click)="onClickCancelActionDraw()"
        class="btn btn-outline-primary d-flex align-items-center border-0 text-white p-2 fs-14"
      >
        <i class="ri-close-circle-line fs-20 me-1"></i>
        <span class="icon-text">Huỷ bỏ</span>
      </button>
      <button
        (click)="onClickApplySearch()"
        class="btn btn-outline-primary d-flex align-items-center border-0 text-white p-2 fs-14"
      >
        <i class="ri-search-line fs-20 me-1"></i>
        <span class="icon-text">Áp dụng</span>
      </button>
    </div>
  </div>
  <agm-map
    #agmMapRef
    [latitude]="lat"
    [longitude]="lng"
    [(zoom)]="zoom"
    [gestureHandling]="drawing ? 'none' : GESTURE_HANDLING"
    [disableDefaultUI]="true"
    [mapTypeControl]="true"
    [mapTypeControlOptions]="{ position: 9 }"
    [streetViewControl]="true"
    [scaleControl]="true"
    [panControl]="true"
    [styles]="MAP_STYLES"
    [scrollwheel]="!drawing"
    [disableDoubleClickZoom]="drawing"
    [mapTypeId]="'hybrid'"
    [maxZoom]="30"
    [minZoom]="7"
    (mapReady)="mapReady($event)"
    (boundsChange)="onHandleBoundsChange($event)"
    (zoomChange)="onHandleEventZoomChange($event)"
    (centerChange)="onHandleCenterChange($event)"
    (mapClick)="onHandlerMouseOut()"
  >
    <ng-container *ngTemplateOutlet="recommend_scroll"></ng-container>

    <ng-container *ngTemplateOutlet="search_filter_bar"></ng-container>

    <ng-container *ngTemplateOutlet="zoom_control"></ng-container>

    <ng-container *ngTemplateOutlet="real_estates"></ng-container>

    <ng-container *ngTemplateOutlet="map_buttons"></ng-container>

    <ng-container *ngIf="makers$ | async as realEstateMakers">
      <agm-marker-cluster
        *ngIf="show; else other_content"
        [averageCenter]="true"
        [calculator]="MAP_OPTION.calculator"
        [styles]="MAP_CLUSTER_STYLE"
        [minimumClusterSize]="1"
        [gridSize]="gridSize"
      >
        <agm-marker
          *ngFor="let lm of realEstateMakers; trackBy: trackByIndex"
          (markerClick)="landSelected.emit(lm.id)"
          [latitude]="lm?.latitude"
          [longitude]="lm?.longitude"
        >
        </agm-marker>
      </agm-marker-cluster>

      <ng-template #other_content>
        <agm-marker
          *ngFor="let lm of realEstateMakers; trackBy: trackByIndex"
          [latitude]="lm?.latitude"
          [longitude]="lm?.longitude"
          (markerClick)="onHandlerMouseOver(infoWindow)"
          [title]="'Click vào ảnh để xem chi tiết'"
          [iconUrl]="lm?.id | realEstateIconMaker: lm?.purpose"
        >
          <agm-info-window
            [disableAutoPan]="true"
            #infoWindow
            class="info-window"
          >
            <ng-container
              *ngTemplateOutlet="makerInfoWindow; context: { lm: lm }"
            ></ng-container>
          </agm-info-window>
        </agm-marker>
      </ng-template>

      <ng-container *ngIf="makersHover$ | async as makerHover">
        <agm-marker
          [style.z-index]="100"
          [latitude]="makerHover?.latitude"
          [longitude]="makerHover?.longitude"
          [markerDraggable]="false"
          (markerClick)="landSelected.emit(makerHover)"
          [iconUrl]="'./assets/images/icons/here.svg'"
        >
          <agm-info-window
            [isOpen]="true"
            [disableAutoPan]="true"
            class="info-window"
          >
            <ng-container
              *ngTemplateOutlet="makerInfoWindow; context: { lm: makerHover }"
            ></ng-container>
          </agm-info-window>
        </agm-marker>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="polygonPts?.length; else point_maker">
      <ng-container *ngFor="let p of polygonPts; trackBy: trackPolygon">
        <agm-polygon
          [strokeColor]="'red'"
          [strokeOpacity]="1"
          [fillColor]="'white'"
          [fillOpacity]="0.5"
          [strokeWeight]="1"
          [paths]="p"
          [geodesic]="true"
        ></agm-polygon>
      </ng-container>
    </ng-container>

    <ng-template #point_maker>
      <ng-container *ngIf="isShowMarkerPoint">
        <agm-marker
          [agmFitBounds]="true"
          [latitude]="lat"
          [longitude]="lng"
          [markerDraggable]="false"
        >
        </agm-marker>
      </ng-container>
    </ng-template>
  </agm-map>

  <ng-container *ngIf="isLoading$ | async">
    <div class="bg-transparent">
      <app-circle-loading></app-circle-loading>
    </div>
  </ng-container>
</div>
<!-- Elements -->

<ng-template #recommend_scroll>
  <div
    id="recommend_scroll"
    class="bg-base"
    [style.top]="isShowRealEstates ? '0px !important' : ''"
  >
    <ng-container *ngIf="show && !(address$ | async)?.length">
      <div class="bg-gray">
        <!-- Warning Alert -->
        <div
          class="alert alert-dismissible alert-additional fade show mb-0 text-white"
        >
          <div class="alert-body">
            <div class="d-flex">
              <div class="flex-shrink-0 me-3">
                <i class="ri-alert-line fs-16 align-middle"></i>
              </div>
              <div class="flex-grow-1">
                <h5 class="alert-heading">Bạn đang Zoom quá xa!</h5>
                <p class="mb-0">
                  Vui lòng Zoom sát vào khu vực cần tìm kiếm BĐS, thay vì zoom
                  quá lớn!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #makerInfoWindow let-lm="lm">
  <div class="point-img cursor-pointer" (click)="landSelected.emit(lm?.id)">
    <img
      height="170"
      [src]="lm?.photos[0]?.url"
      loading="lazy"
      alt="hinh-bds"
      class="object-fit-cover w-100"
    />
    <div class="px-3 py-2">
      <div class="fs-18 py-1 price">
        {{ lm?.price | transformPriceToString }}
      </div>

      <div class="py-1">
        DT:
        <span class="fs-14">{{ lm?.acreage || "--" }}</span> ㎡ | MT:
        <span class="fs-14">{{ lm?.frontWidth || "--" }}</span> m | PN:
        <span class="fs-14">{{ lm?.bedrooms || "--" }}</span> | WC:
        <span class="fs-14">{{ lm?.toilets || "--" }}</span>
      </div>
      <div class="py-1 fs-14 font-weight-normal address">
        {{ lm?.address?.fullAddress }}
      </div>
    </div>
  </div>
</ng-template>

<ng-template #search_filter_bar>
  <div
    class="d-flex justify-content-start gap-2 map-content position-relative"
    id="search_filter_bar"
  >
    <ng-container *ngIf="!isShowRealEstates">
      <div class="col-2 search-suggestion">
        <app-search-suggestion
          [suggestionRelative]="true"
          [searchForm]="formSearchAndFilter"
          [hasOverlay]="true"
        ></app-search-suggestion>
      </div>
      <div class="search-box col-1">
        <app-filter-range
          [dropdownRelative]="true"
          [fromControl]="getControl('acreageFrom')"
          [toControl]="getControl('acreageTo')"
          title="Diện tích"
          unit="㎡"
        ></app-filter-range>
      </div>
      <div class="search-box col-1">
        <app-filter-range
          [dropdownRelative]="true"
          title="Mặt tiền"
          unit="m"
          [fromControl]="getControl('frontWidthFrom')"
          [toControl]="getControl('frontWidthTo')"
        ></app-filter-range>
      </div>
      <div class="search-box col-1">
        <app-filter-status
          [formChild]="getControl('status')"
          [status]="STATUS_APPROVED"
        ></app-filter-status>
      </div>
      <div class="search-box col-1">
        <app-filter-purpose-map
          [childControl]="getControl('purpose')"
        ></app-filter-purpose-map>
      </div>
      <div class="search-box col-1">
        <ng-select
          [clearable]="false"
          [items]="REAL_ESTATE_MARK"
          bindLabel="cValue"
          bindValue="cKey"
          (change)="onDisplayRealEstateMark($event)"
          placeholder="Mặt bằng chấm điểm"
        ></ng-select>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #zoom_control>
  <div id="zoom_control" class="d-flex flex-column bg-white map-content">
    <button class="w-100 h-100 border-0" (click)="onZoomIn()">
      <i class="ri-add-fill fs-16"></i>
    </button>
    <div class="hr"></div>
    <button class="w-100 h-100 border-0" (click)="onZoomOut()">
      <i class="ri-subtract-fill fs-16"></i>
    </button>
  </div>
</ng-template>

<ng-template #real_estates>
  <div
    id="real_estates"
    class="map-content"
    [ngClass]="{
      'list-expand': showFullListInMap,
      'list-collapse': !showFullListInMap
    }"
  >
    <ng-container
      *ngIf="
        !isShowRealEstates && !show && realEstatesPaging$
          | async as realEstatePaging
      "
    >
      <ng-container *ngIf="realEstatePaging?.data?.length">
        <button
          class="btn bg-white position-absolute top-0"
          (click)="onToggleListInMap()"
        >
          <i
            class="fs-16"
            [ngClass]="showListInMap ? 'ri-eye-off-line' : 'ri-eye-line'"
          ></i>
        </button>
        <button
          *ngIf="showListInMap"
          class="btn bg-white position-absolute show-full-list-btn"
          (click)="showFullListInMap = !showFullListInMap"
        >
          <i
            class="fs-16 mdi"
            [ngClass]="
              showFullListInMap
                ? 'mdi-arrow-collapse-horizontal'
                : 'mdi-arrow-expand-horizontal'
            "
          ></i>
        </button>
        <ng-container *ngIf="!isShowRealEstates && showListInMap">
          <div
            class="real-estates-container p-3"
            [class.bg-filter-white]="realEstatePaging?.data && showListInMap"
          >
            <div class="list-real-estate mw-100">
              <div
                class="d-flex flex-wrap gap-2"
                [class.flex-column]="!showFullListInMap"
              >
                <div
                  *ngFor="let item of realEstatePaging?.data"
                  class="item"
                  (mouseenter)="hoverRealEstate(item)"
                  (mouseleave)="destroyHoverRealEstate()"
                >
                  <app-card-real-estate
                    (onSelectedRealEstate)="handleSelectedRealEstate($event)"
                    [realEstate]="item"
                  ></app-card-real-estate>
                </div>
              </div>
            </div>
            <div
              *ngIf="realEstatePaging?.data?.length"
              class="pagination d-flex pt-3 ps-3 listjs-pagination"
            >
              <ngb-pagination
                [collectionSize]="realEstatePaging?.totalItems"
                [page]="realEstatePaging?.pageNumber"
                [pageSize]="realEstatePaging?.pageSize"
                (pageChange)="pageChange($event)"
                [maxSize]="3"
              ></ngb-pagination>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #map_buttons>
  <div
    id="map_buttons"
    class="d-flex align-items-center justify-content-end map-content"
  >
    <ng-container *ngIf="!isShowConfirm && !polygonPts?.length && !show">
      <button
        *ngIf="isShowLand"
        (click)="onClickRemovePolygon()"
        class="btn btn-outline-primary bg-white d-flex align-items-center"
      >
        <span class="fs-12">Xoá khoanh vùng</span>
        <i class="ri-close-line fs-16 ms-2"></i>
      </button>
      <button
        class="btn btn-outline-primary bg-white d-flex align-items-center"
        (click)="onClickDrawPolygon()"
        *ngIf="!isShowLand"
      >
        <i class="ri-ball-pen-line fs-16 me-1"></i>
        <span class="fs-12">Vẽ khu vực</span>
      </button>
    </ng-container>
    <ng-container *ngIf="polygonPts?.length">
      <button
        (click)="onClickRemoveBorder()"
        class="btn btn-outline-primary bg-white d-flex align-items-center"
      >
        <span class="fs-12">Xoá Border</span>
        <i class="ri-close-line fs-16 ms-1"></i>
      </button>
    </ng-container>
    <button
      *ngIf="!isShowRealEstates"
      class="btn btn-outline-primary bg-white d-flex align-items-center ms-1"
      (click)="onFullScreenExit()"
    >
      <i class="ri-fullscreen-exit-line fs-16"></i>
    </button>
    <button
      *ngIf="isShowRealEstates"
      class="btn btn-outline-primary bg-white d-flex align-items-center ms-1"
      (click)="onFullScreen()"
    >
      <i class="ri-fullscreen-line fs-16"></i>
    </button>
  </div>
</ng-template>
