import { BaseHttpRequest } from "./../http/base-http-request.service";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { RealEstateModel } from "../../models/real-estate.model";
import { API_URL } from "src/app/public/constants/api-url.constant";

@Injectable({
  providedIn: "root",
})
export class RealEsateHttpService extends BaseHttpRequest {
  getRealEstates(params: any): Observable<RealEstateModel[]> {
    // Because paging from 0, so we need addtend 1.
    const { page } = params;

    return this.get(API_URL.LIST_REAL_ESTATE, {
      params: { ...params, page: Number(page - 1) },
    }) as Observable<RealEstateModel[]>;
  }

  getRealEstatesV2(params: any): Observable<RealEstateModel[]> {
    // Because paging from 0, so we need addtend 1.
    const { page } = params;

    return this.get(API_URL.ADMIN_REAL_ESTATE_SEARCH, {
      params: { ...params, page: Number(page - 1) },
    }) as Observable<RealEstateModel[]>;
  }

  getRealEstatePostGres(params: any): Observable<RealEstateModel[]> {
    // Because paging from 0, so we need addtend 1.
    const { page } = params;

    return this.get(API_URL.LIST_REAL_ESTATE_POSTGRES, {
      params: { ...params, page: Number(page - 1) },
    }) as Observable<RealEstateModel[]>;
  }

  getRealEstate(id: string | number): Observable<RealEstateModel> {
    return this.get<RealEstateModel>(
      `${API_URL.DETAIL_REAL_ESTATE}/${id}`
    ) as Observable<RealEstateModel>;
  }

  //search Name
  searchRealEstateName(object: any): Observable<RealEstateModel[]> {
    return this.get(API_URL.LIST_REAL_ESTATE, {
      params: object,
    }) as Observable<RealEstateModel[]>;
  }

  approveRealEstate({ mode, id }: any): Observable<any> {
    return this.post<any>(`${API_URL.APPROVED_REAL_ESTATE}/${mode}/${id}`, {});
  }

  updateRejectRealEstate({ id }: any): Observable<any> {
    return this.post<any>(`${API_URL.REJECT_REAL_ESTATE}/${id}`, {});
  }

  updateRealEstate(payload: RealEstateModel): Observable<any> {
    const { id } = payload;
    return this.post(API_URL.REAL_ESTATE, payload);
  }

  // Thêm mới BĐS | Add new a RealEstate
  addRealEstate(payload: RealEstateModel): Observable<any> {
    return this.post(API_URL.REAL_ESTATE, payload);
  }

  /*****************************************************************
   ********** BẤT ĐỘNG SẢN CHƯA XỬ LÝ (REAL ESTATE RAW) ************
   *****************************************************************/

  //get dữ liệu BĐS chờ xử lý
  getRealEstatesRaw(params: any): Observable<RealEstateModel[]> {
    const { page } = params;
    return this.get(API_URL.LIST_REAL_ESTATE_QUICK_UP, {
      params: { ...params, page: Number(page - 1) },
    }) as Observable<RealEstateModel[]>;
  }

  //Update bất động sản chờ xử lý
  updateRealEstateQuickUp(payload: RealEstateModel): Observable<any> {
    const { id } = payload;
    return this.post<any>(`${API_URL.REAL_ESTATE_QUICK_UP}/${id}`, payload);
  }

  getRealEstateRaw(id: string | number): Observable<RealEstateModel> {
    return this.get<RealEstateModel>(
      `${API_URL.DETAIL_REAL_ESTATE_RAW}/${id}`
    ) as Observable<RealEstateModel>;
  }

  // XÓA BẤT ĐỘNG SẢN ĐĂNG NHANH / DELETE REAL-ESTATE
  deleteRealEstateRaw(id: any): Observable<any> {
    return this.delete(`${API_URL.DELETE_REAL_ESTATE_RAW}/${id}`);
  }

  // Đồng bộ hoá DB với ElasticSearch
  syncDataWithElasticDB(payload: RealEstateModel[]): Observable<any> {
    return this.post<any>(`${API_URL.SYNC_REAL_ESTATE}`, payload);
  }

  getRealEstateInPolygons(payload: any, params?: any): Observable<any> {
    return this.post(API_URL.IN_POLYGON, payload, {
      params: params,
    });
  }
}
