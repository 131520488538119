import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import {
  SUBSCRIBE_SEARCH_ACTIVE_FILTER,
  SUBSCRIBE_SEARCH_CREATED_AT,
} from "src/app/public/constants/user.constant";

@Component({
  selector: "app-search-filter-history-subscribe",
  templateUrl: "./search-filter-history-subscribe.component.html",
})
export class SearchFilterHistorySubscribeComponent implements OnInit {
  @Input() formSearchAndFilter: FormGroup;
  @Input() SUBSCRIBE_ACTIVE = SUBSCRIBE_SEARCH_ACTIVE_FILTER;
  public SUBSCRIBE_CREATED = SUBSCRIBE_SEARCH_CREATED_AT;

  constructor() {}

  ngOnInit() {}
}
