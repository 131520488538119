import { inject, Injectable } from "@angular/core";
import { SwPush } from "@angular/service-worker";
import { WEB_PUSH_SUBSCRIPTION } from "@constant/common.constant";
import { StorageService } from "@service/storage/storage.service";
import { BehaviorSubject } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import {
  ParamOptions,
  SwNotificationHttpService,
  UpdateStatusNotification,
} from "./sw-notification-http.service";

@Injectable({
  providedIn: "root",
})
export class SwNotificationService {
  private swPush = inject(SwPush);
  private swNotificationHttp = inject(SwNotificationHttpService);
  private storageService = inject(StorageService);

  //
  public isSubscribed = false;

  private _total = new BehaviorSubject<number>(0);
  private _page = new BehaviorSubject<number>(0);
  private _pageSize = new BehaviorSubject<number>(0);
  private _limit = new BehaviorSubject<number>(0);

  constructor() {
    this.checkSubscription();
  }

  public checkSubscription() {
    this.swPush.subscription.subscribe((subscription) => {
      this.isSubscribed = !!subscription;
    });
  }

  public subscribeToNotifications() {
    const subscription = this.storageService.get(WEB_PUSH_SUBSCRIPTION);
    if (!subscription) {
      this.swPush
        .requestSubscription({
          serverPublicKey: environment.webPushNotification.publicKey,
        })
        .then((subscription) => {
          this.sendSubscriptionToServer(subscription);
          this.storageService.set(WEB_PUSH_SUBSCRIPTION, subscription);
          this.isSubscribed = true;
        })
        .catch((err) =>
          console.error("Could not subscribe to notifications", err)
        );
    }
  }

  private sendSubscriptionToServer(subscription: PushSubscription) {
    const subscriptionObj = JSON.parse(JSON.stringify(subscription));

    this.swNotificationHttp
      .registerNotificationFromServer({
        notificationEndpoint: subscriptionObj.endpoint,
        ...subscriptionObj.keys,
      })
      .subscribe({
        next: (response) => console.log("Subscribed successfully!", response),
        error: (err) => console.error("Subscription error:", err),
      });
  }

  public getNotificationOfUser(params: ParamOptions) {
    return this.swNotificationHttp.getNotificationOfUser(params).pipe(
      tap((data: any) => {
        const _total = data?.totalItems;
        const _page = params?.page || 0;
        const _pageSize = data?.data?.length;
        const _limit = params?.limit || 16;

        this._page.next(_page);
        this._total.next(_total);
        this._pageSize.next(_pageSize);
        this._limit.next(_limit);
      })
    );
  }

  public changeViewStatus(id: number, payload: UpdateStatusNotification) {
    return this.swNotificationHttp.changeViewStatus(id, payload);
  }
}
