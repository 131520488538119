<div class="card-body border border-dashed border-end-0 border-start-0">
  <form>
    <div class="row g-3">
      <div class="col-xxl-3 col-sm-12">
        <div class="search-box">
          <input
            class="form-control flatpickr-input"
            type="text"
            placeholder="Lựa chọn ngày tháng"
            mode="range"
            mwlFlatpickr
            altFormat="d/m/Y"
            [locale]="language"
            [altInput]="true"
            [convertModelValue]="false"
          />
          <i class="ri-calendar-2-line search-icon"></i>
        </div>
      </div>
      <div class="col-xxl-3 col-sm-12">
        <div class="search-box">
          <ng-select
            [items]="items"
            class="col-md-5 p-0 mb-2 w-100"
            placeholder="Loại gửi"
            bindLabel="cValue"
            [searchable]="false"
          ></ng-select>
        </div>
      </div>
      <div class="col-xxl-2 col-sm-4">
        <button type="submit" class="btn btn-primary">
          <i class="ri-search-2-line me-1 align-bottom"></i>
          Tìm kiếm
        </button>
      </div>
    </div>
  </form>
</div>
