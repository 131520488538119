<div
  class="dropdown ms-sm-3 header-item topbar-user"
  ngbDropdown
  *ngIf="userInfo$ | async as userInfo"
>
  <button
    type="button"
    class="btn"
    id="page-header-user-dropdown"
    data-bs-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
    ngbDropdownToggle
  >
    <span class="d-flex align-items-center">
      <img
        class="rounded-circle header-profile-user"
        src="{{ userInfo.avatar || '/assets/images/users/user-dummy-img.jpg' }}"
        alt="Header Avatar"
      />
      <span class="text-start ms-xl-2">
        <span class="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{{
          userInfo.username
        }}</span>
        <span class="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text"
          >Founder</span
        >
      </span>
    </span>
  </button>
  <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
    <h6 class="dropdown-header">Welcome {{ userInfo.username }}!</h6>
    <a class="dropdown-item" href="pages/profile"
      ><i class="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
      <span class="align-middle">Profile</span></a
    >
    <a class="dropdown-item" href="javascript: void(0);" (click)="logout()"
      ><i class="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
      <span class="align-middle" data-key="t-logout">Đăng xuất</span></a
    >
  </div>
</div>
