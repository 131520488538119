import {
  AfterViewInit,
  Component,
  inject,
  Input,
  OnChanges,
} from "@angular/core";
import { AbstractControl, FormGroup, UntypedFormControl } from "@angular/forms";
import { map } from "rxjs/operators";
import { CategoryService } from "src/app/public/services/categories/category.service";

@Component({
  selector: "app-filter-address",
  templateUrl: "./filter-address.component.html",
  styleUrls: ["./filter-address.component.scss"],
})
export class FilterAddressComponent implements AfterViewInit, OnChanges {
  private categoryService = inject(CategoryService);

  @Input() formSearchAndFilter: FormGroup;

  public cities$ = this.categoryService.getAllCity();
  public districts$ = this.categoryService.getAllDistrict();
  public towns$ = this.categoryService.getAllTown();

  public districtsControl$: AbstractControl;
  public citiesControl$: AbstractControl;
  public townsControl$: AbstractControl;
  public isChecked: boolean = false;

  ngAfterViewInit(): void {
    this.citiesControl$ = this.getControl("city");
    this.districtsControl$ = this.getControl("district");
    this.townsControl$ = this.getControl("town");

    this.citiesControl$.valueChanges.subscribe((data) => {
      this.getDistrict(data);
      this.townsControl$.reset();
      this.districtsControl$.reset();
    });

    this.districtsControl$.valueChanges.subscribe((districts: string[]) => {
      this.getTown(districts);
      this.townsControl$.reset();
    });
  }

  ngOnChanges(): void {
    const { district, city } = this.formSearchAndFilter.getRawValue();

    this.getDistrict(city);
    this.getTown(district);
  }

  public getControl(name: string) {
    return this.formSearchAndFilter.controls[name] as UntypedFormControl;
  }

  private getDistrict(city?: string): void {
    this.districts$ = this.categoryService.getAllDistrict().pipe(
      map((data) => {
        return data.filter((district: any) => district?.refValue === city);
      })
    );
  }

  private getTown(districts: string[] = []): void {
    this.towns$ = this.categoryService
      .getAllTown()
      .pipe(
        map((data) =>
          data.filter((town: any) => districts?.includes(town?.refValue))
        )
      );
  }
}
