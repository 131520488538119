import { HelpersModule } from "./public/helpers/helpers.module";
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  LOCALE_ID,
  NgModule,
} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppComponent } from "./app.component";
import { LayoutsModule } from "./public/layouts/layouts.module";
// Auth
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
// Language
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { AppRoutingModule } from "src/app/app.routing";
import {
  NgbDropdownModule,
  NgbModule,
  NgbPopoverModule,
  NgbProgressbarModule,
  NgbToastModule,
} from "@ng-bootstrap/ng-bootstrap";
import { HLDirectivesModule } from "./public/directives/directives.module";
import { RouterModule } from "@angular/router";
import { AppGetTokenInterceptor } from "./public/interceptors/get-token.interceptor";
import { AppInterceptor } from "src/app/public/interceptors/app.interceptor";
import {
  JWT_TOKEN,
  STORAGE_DB_KEY,
} from "src/app/public/constants/common.constant";
import { DBConfig, NgxIndexedDBModule } from "ngx-indexed-db";
import { CategoryService } from "src/app/public/services/categories/category.service";
import { JwtModule } from "@auth0/angular-jwt";
import { JwtTokenService } from "src/app/public/services/jwt-token.service";
import { ToastrModule } from "ngx-toastr";
import { CredentialService } from "./public/services/credential/credential.service";
import { AgmCoreModule } from "@agm/core";
import { environment } from "src/environments/environment";
import { ErrorInterceptor } from "src/app/public/interceptors/error.interceptor";
import { CommonModule, registerLocaleData } from "@angular/common";
import { CountToModule } from "angular-count-to";
import { NgApexchartsModule } from "ng-apexcharts";
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { SimplebarAngularModule } from "simplebar-angular";
import { SwiperModule } from "swiper/angular";
import { LightboxModule } from "ngx-lightbox";
import { defineLordIconElement } from "lord-icon-element";
import lottie from "lottie-web";
import { HlSharedModule } from "src/app/components/shared.module";
import { FlatpickrModule } from "angularx-flatpickr";
import { AgmJsMarkerClustererModule } from "@agm/js-marker-clusterer";
import { AddressRealEstateService } from "./public/services/address/address-real-estate.service";
import localeVi from "@angular/common/locales/vi";
import { CallCenterService } from "./public/services/socket/call-center.service";
import { UserService } from "./public/services/user/user.service";

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}

const dbConfig: DBConfig = {
  name: "hoaland",
  version: 15,
  objectStoresMeta: [
    {
      store: STORAGE_DB_KEY.districts,
      storeConfig: { keyPath: "id", autoIncrement: true },
      storeSchema: [
        { name: "cKey", keypath: "cKey", options: { unique: false } },
        { name: "cValue", keypath: "cValue", options: { unique: true } },
        {
          name: "categoryCode",
          keypath: "categoryCode",
          options: { unique: false },
        },
        { name: "refCode", keypath: "refCode", options: { unique: false } },
        { name: "refValue", keypath: "refValue", options: { unique: false } },
      ],
    },
    {
      store: STORAGE_DB_KEY.towns,
      storeConfig: { keyPath: "id", autoIncrement: true },
      storeSchema: [
        { name: "cKey", keypath: "cKey", options: { unique: false } },
        { name: "cValue", keypath: "cValue", options: { unique: true } },
        {
          name: "categoryCode",
          keypath: "categoryCode",
          options: { unique: false },
        },
        { name: "refCode", keypath: "refCode", options: { unique: false } },
        { name: "refValue", keypath: "refValue", options: { unique: false } },
      ],
    },
    {
      store: STORAGE_DB_KEY.cities,
      storeConfig: { keyPath: "id", autoIncrement: true },
      storeSchema: [
        { name: "cKey", keypath: "cKey", options: { unique: false } },
        { name: "cValue", keypath: "cValue", options: { unique: true } },
        {
          name: "categoryCode",
          keypath: "categoryCode",
          options: { unique: false },
        },
        { name: "refCode", keypath: "refCode", options: { unique: false } },
        { name: "refValue", keypath: "refValue", options: { unique: false } },
      ],
    },
    {
      store: STORAGE_DB_KEY.landType,
      storeConfig: { keyPath: "id", autoIncrement: true },
      storeSchema: [
        { name: "cKey", keypath: "cKey", options: { unique: false } },
        { name: "cValue", keypath: "cValue", options: { unique: true } },
        {
          name: "categoryCode",
          keypath: "categoryCode",
          options: { unique: false },
        },
        { name: "refCode", keypath: "refCode", options: { unique: false } },
        { name: "refValue", keypath: "refValue", options: { unique: false } },
      ],
    },
    {
      store: STORAGE_DB_KEY.direction,
      storeConfig: { keyPath: "id", autoIncrement: true },
      storeSchema: [
        { name: "cKey", keypath: "cKey", options: { unique: false } },
        { name: "cValue", keypath: "cValue", options: { unique: true } },
        {
          name: "categoryCode",
          keypath: "categoryCode",
          options: { unique: false },
        },
        { name: "refCode", keypath: "refCode", options: { unique: false } },
        { name: "refValue", keypath: "refValue", options: { unique: false } },
      ],
    },
    {
      store: STORAGE_DB_KEY.floorMaterial,
      storeConfig: { keyPath: "id", autoIncrement: true },
      storeSchema: [
        { name: "cKey", keypath: "cKey", options: { unique: false } },
        { name: "cValue", keypath: "cValue", options: { unique: true } },
        {
          name: "categoryCode",
          keypath: "categoryCode",
          options: { unique: false },
        },
        { name: "refCode", keypath: "refCode", options: { unique: false } },
        { name: "refValue", keypath: "refValue", options: { unique: false } },
      ],
    },
    {
      store: STORAGE_DB_KEY.street,
      storeConfig: { keyPath: "ids", autoIncrement: true },
      storeSchema: [
        { name: "cKey", keypath: "cKey", options: { unique: false } },
        { name: "cValue", keypath: "cValue", options: { unique: true } },
        {
          name: "categoryCode",
          keypath: "categoryCode",
          options: { unique: false },
        },
        { name: "refCode", keypath: "refCode", options: { unique: false } },
        { name: "refValue", keypath: "refValue", options: { unique: false } },
      ],
    },
    {
      store: STORAGE_DB_KEY.parking,
      storeConfig: { keyPath: "ids", autoIncrement: true },
      storeSchema: [
        { name: "cKey", keypath: "cKey", options: { unique: false } },
        { name: "cValue", keypath: "cValue", options: { unique: true } },
        {
          name: "categoryCode",
          keypath: "categoryCode",
          options: { unique: false },
        },
        { name: "refCode", keypath: "refCode", options: { unique: false } },
        { name: "refValue", keypath: "refValue", options: { unique: false } },
      ],
    },
    {
      store: STORAGE_DB_KEY.statusFurniture,
      storeConfig: { keyPath: "ids", autoIncrement: true },
      storeSchema: [
        { name: "cKey", keypath: "cKey", options: { unique: false } },
        { name: "cValue", keypath: "cValue", options: { unique: true } },
        {
          name: "categoryCode",
          keypath: "categoryCode",
          options: { unique: false },
        },
        { name: "refCode", keypath: "refCode", options: { unique: false } },
        { name: "refValue", keypath: "refValue", options: { unique: false } },
      ],
    },
    {
      store: STORAGE_DB_KEY.juridical,
      storeConfig: { keyPath: "ids", autoIncrement: true },
      storeSchema: [
        { name: "cKey", keypath: "cKey", options: { unique: false } },
        { name: "cValue", keypath: "cValue", options: { unique: true } },
        {
          name: "categoryCode",
          keypath: "categoryCode",
          options: { unique: false },
        },
        { name: "refCode", keypath: "refCode", options: { unique: false } },
        { name: "refValue", keypath: "refValue", options: { unique: false } },
      ],
    },
    {
      store: STORAGE_DB_KEY.cateStreet,
      storeConfig: { keyPath: "ids", autoIncrement: true },
      storeSchema: [
        { name: "cKey", keypath: "cKey", options: { unique: false } },
        { name: "cValue", keypath: "cValue", options: { unique: true } },
        {
          name: "categoryCode",
          keypath: "categoryCode",
          options: { unique: false },
        },
        { name: "refCode", keypath: "refCode", options: { unique: false } },
        { name: "refValue", keypath: "refValue", options: { unique: false } },
      ],
    },
  ],
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    TranslateModule.forRoot({
      defaultLanguage: "vi",
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    CommonModule,
    NgbToastModule,
    NgbProgressbarModule,
    FlatpickrModule.forRoot(),
    CountToModule,
    NgApexchartsModule,
    LeafletModule,
    NgbDropdownModule,
    SimplebarAngularModule,
    HlSharedModule,
    SwiperModule,
    LightboxModule,
    HLDirectivesModule,
    BrowserAnimationsModule,
    HttpClientModule,
    BrowserModule,
    NgbPopoverModule,
    AppRoutingModule,
    LayoutsModule,
    RouterModule,
    HelpersModule,
    ToastrModule.forRoot(),
    NgbModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
      },
    }),
    NgxIndexedDBModule.forRoot(dbConfig),
    AgmCoreModule.forRoot({
      apiKey: environment.googleApiKey,
      libraries: ["places"],
    }),
    AgmJsMarkerClustererModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "vi-VN" },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppGetTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    CategoryService,
    JwtTokenService,
    {
      provide: APP_INITIALIZER,
      useFactory: initFunction,
      deps: [CredentialService],
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: "vi-VN",
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initCallCenterService,
      deps: [CallCenterService, UserService],
      multi: true,
    },
    AddressRealEstateService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor() {
    defineLordIconElement(lottie.loadAnimation);
    registerLocaleData(localeVi);
  }
}

function tokenGetter(): string {
  return localStorage.getItem(JWT_TOKEN);
}

export function initFunction(config: CredentialService) {
  return () => config.init();
}

export function initCallCenterService(
  config: CallCenterService,
  userService: UserService
) {
  return async () => {
    userService.user$.subscribe((user) => {
      config.connect();
    });
  };
}