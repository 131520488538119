export const DATA_MOCK = [
  {
    title: "108 Nguyễn Trãi, Thanh Xuân, Hà Nội",
    body: "Nhà này còn phương án không em anh đi qua thấy bung biển hết rồi",
    time: "4 phút trước",
    type: "message",
  },
  {
    title: "Có bất động sản mới được nhập",
    body: "BĐS 12 Cát Linh, Cát Linh, Đống Đa, Hà Nội mới được nhập, đang ở trong chờ duyệt",
    time: "10 phút trước",
    type: "notification",
  },
  {
    title: "Có bất động sản mới được mở bán",
    body: "BĐS 12 Cát Linh, Cát Linh, Đống Đa, Hà Nội mới được mửo bán, có 5 khách hàng phù hợp",
    time: "11 phút trước",
    type: "notification",
  },
  {
    title: "Có 1 cuộc gọi nhỡ vào hệ thống",
    body: "Chị An: 090xxxxx122",
    time: "12 phút trước",
    type: "notification",
  },
];
