import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'convertToNumber'
})
export class ConvertToNumberPipe implements PipeTransform {

    transform(value: unknown): number {
        return Number(value);
    }

}
