<div class="card-body border border-dashed border-end-0 border-start-0">
  <form [formGroup]="searchCustomerParams">
    <div class="row g-3">
      <!--LOẠI TỔ CHỨC-->
      <div class="col-md-10">
        <div class="row">
          <div class="col-md-2 col-sm-12">
            <div class="search-box">
              <app-dropdown-wrapper
                [isMultipleChoose]="false"
                [items]="USER_TYPE"
                [control]="getControl('type')"
                [placeholder]="'Tổ chức'"
              ></app-dropdown-wrapper>
            </div>
          </div>

          <!--LOẠI TRẠNG THÁI NGƯỜI DÙNG-->
          <div
            class="col-md-2 col-sm-12"
            *ngIf="!getControl('status').disabled"
          >
            <div class="search-box">
              <app-dropdown-wrapper
                [isMultipleChoose]="false"
                [items]="USER_STATUS"
                [control]="getControl('status')"
                [placeholder]="'Trạng thái'"
              ></app-dropdown-wrapper>
            </div>
          </div>

          <!--HỌ VÀ TÊN-->
          <div class="col-md-2 col-sm-12" *ngIf="getControl('name')">
            <div class="search-box">
              <input
                type="text"
                class="form-control search"
                placeholder="Tên KH"
                formControlName="name"
              />
              <i class="ri-user-3-line search-icon"></i>
            </div>
          </div>

          <!--SỐ ĐIỆN THOẠI-->
          <div class="col-md-2 col-sm-12" *ngIf="getControl('phone')">
            <div class="search-box">
              <input
                type="text"
                class="form-control search"
                placeholder="Số điện thoại"
                formControlName="phone"
              />
              <i class="ri-phone-line search-icon"></i>
            </div>
          </div>

          <!--EMAIL-->
          <div class="col-md-2 col-sm-12" *ngIf="getControl('email')">
            <div class="search-box">
              <input
                type="text"
                class="form-control search"
                placeholder="Email"
                formControlName="email"
              />
              <i class="ri-mail-line search-icon"></i>
            </div>
          </div>

          <!--LỰA CHỌN NGÀY THÁNG-->
          <div
            class="col-md-2 col-sm-12"
            *ngIf="getControl('startDate') && getControl('endDate')"
          >
            <div class="form-control position-relative customize-range-date">
              <mat-date-range-input
                [formGroup]="searchCustomerParams"
                [rangePicker]="picker"
                [max]="maxDate"
              >
                <input
                  matStartDate
                  [formControl]="getControl('startDate')"
                  placeholder="Từ ngày"
                />
                <input
                  matEndDate
                  [formControl]="getControl('endDate')"
                  placeholder="Đến ngày"
                />
              </mat-date-range-input>
              <mat-datepicker-toggle
                matIconSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>

              <i
                class="ri-close-line delete-btn position-absolute fs-20"
                (click)="handleReset()"
                *ngIf="this.formatStartDate && this.formatEndDate"
              ></i>
            </div>
          </div>
        </div>
      </div>

      <!--ACTION TÌM KIẾM-->
      <div class="col-md-2">
        <button
          (click)="onClickSubmitSearch()"
          type="submit"
          class="btn btn-primary"
        >
          <i class="ri-search-2-line me-1 align-bottom"></i>
          Tìm kiếm
        </button>
        <button type="button" class="btn btn-danger ms-1" (click)="resetForm()">
          <i class="mdi mdi-restore me-1 align-bottom"></i>
          Đặt lại
        </button>
      </div>
    </div>
    <!--END ROW-->
  </form>
</div>
