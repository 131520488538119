import { Injectable } from "@angular/core";
import { BaseHttpRequest } from "../http/base-http-request.service";
import { API_URL } from "../../constants/api-url.constant";
import { EmailModel } from "../../models/email.model";
@Injectable({
  providedIn: "root",
})
export class EmailService extends BaseHttpRequest {
  public sendEmail(payload: EmailModel) {
    return this.post(API_URL.SEND_EMAIL, payload);
  }

  public sendEmailCustomer(payload: EmailModel) {
    return this.post(API_URL.EMAIL_CUSTOMER, payload);
  }
}
