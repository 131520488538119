import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from "@angular/core";

@Directive({
  selector: "[appHighlightSystem]",
})
export class HighlightSystemDirective implements OnChanges {
  @Input("appHighlightSystem") value: string;
  @Input() systems: { key: number; value: string; active: boolean }[];
  @Input() allSystems: { value: string; active: boolean }[];

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["value"] || changes["systems"]) {
      const isChecked = this.isSystemChecked(this.value);
      this.renderer.setProperty(this.el.nativeElement, "checked", isChecked);
      this.updateParentStyles(isChecked);
    }
  }

  private isSystemChecked(value: string): boolean {
    return (
      this.systems?.some((system) => system?.value === value) ||
      this.allSystems.some(
        (system) => system?.value === value && system?.active
      )
    );
  }

  private updateParentStyles(isChecked: boolean): void {
    const parentDiv = this.el.nativeElement.closest("div");
    if (parentDiv) {
      const bgColor = isChecked ? "" : "#ece9e9";
      const color = isChecked ? "" : "gray";
      this.renderer.setStyle(parentDiv, "background-color", bgColor);
      this.renderer.setStyle(parentDiv, "color", color);
    }
  }
}
