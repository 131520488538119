<header id="page-topbar">
  <div class="layout-width">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box horizontal-logo">
          <a href="" class="logo logo-dark">
            <span class="logo-sm">
              <img
                src="../../../../assets/images/logo-sm.png"
                alt=""
                height="22"
              />
            </span>
            <span class="logo-lg">
              <img
                src="../../../../assets/images/logo-dark.png"
                alt=""
                height="17"
              />
            </span>
          </a>

          <a href="" class="logo logo-light">
            <span class="logo-sm">
              <img
                src="../../../../assets/images/logo-sm.png"
                alt=""
                height="22"
              />
            </span>
            <span class="logo-lg">
              <img
                src="../../../../assets/images/logos/logo.svg"
                alt=""
                height="17"
              />
            </span>
          </a>
        </div>

        <button
          type="button"
          class="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
          id="topnav-hamburger-icon"
          (click)="toggleMobileMenu($event)"
        >
          <span class="hamburger-icon">
            <span></span>
            <span></span>
            <span></span>
          </span>
        </button>
      </div>

      <div class="d-flex align-items-center">
        <div
          class="dropdown d-md-none topbar-head-dropdown header-item"
          ngbDropdown
        >
          <button
            type="button"
            class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
            id="page-header-search-dropdown"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            ngbDropdownToggle
          >
            <i class="bx bx-search fs-22"></i>
          </button>
          <div
            class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
            aria-labelledby="page-header-search-dropdown"
            ngbDropdownMenu
          >
            <form class="p-3">
              <div class="form-group m-0">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search ..."
                    aria-label="Recipient's username"
                  />
                  <button class="btn btn-primary" type="submit">
                    <i class="mdi mdi-magnify"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="dropdown ms-1 topbar-head-dropdown header-item" ngbDropdown>
          <button
            type="button"
            class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            ngbDropdownToggle
          >
            <img
              *ngIf="flagvalue !== undefined"
              src="{{ flagvalue }}"
              alt="Header Language"
              height="20"
              class="rounded"
            />
            <img
              *ngIf="flagvalue === undefined"
              src="{{ valueset }}"
              alt="Header Language"
              height="20"
              class="rounded"
            />
          </button>
          <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
            <!-- item-->
            <a
              href="javascript:void(0);"
              class="dropdown-item notify-item language py-2"
              data-lang="eng"
              *ngFor="let item of listLang"
              (click)="setLanguage(item.text, item.lang, item.flag)"
              [ngClass]="{ active: cookieValue === item.lang }"
            >
              <img
                src="{{ item.flag }}"
                alt="user-image"
                class="me-2 rounded"
                height="18"
              />
              <span class="align-middle">{{ item.text }}</span>
            </a>
          </div>
        </div>

        <div class="ms-1 header-item d-none d-sm-flex">
          <button
            type="button"
            class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
            data-toggle="fullscreen"
            (click)="fullscreen()"
          >
            <i class="bx bx-fullscreen fs-22"></i>
          </button>
        </div>

        <div class="ms-1 header-item d-none d-sm-flex">
          <button
            type="button"
            class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode"
          >
            <i class="bx bx-moon fs-22" (click)="changeMode('dark')"></i>
            <i class="bx bx-sun fs-22" (click)="changeMode('light')"></i>
          </button>
        </div>

        <div class="dropdown topbar-head-dropdown ms-1 header-item" ngbDropdown>
          <button
            type="button"
            class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
            id="page-header-notifications-dropdown"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            ngbDropdownToggle
          >
            <i class="bx bx-bell fs-22"></i>
            <span
              class="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger"
              >3<span class="visually-hidden">unread messages</span></span
            >
          </button>
          <div
            class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
            aria-labelledby="page-header-notifications-dropdown"
            ngbDropdownMenu
          >
            <div class="dropdown-head bg-primary bg-pattern rounded-top">
              <div class="p-3">
                <div class="row align-items-center">
                  <div class="col">
                    <h6 class="m-0 fs-16 fw-semibold text-white">Thông báo</h6>
                  </div>
                  <div class="col-auto dropdown-tabs">
                    <span class="badge badge-soft-light fs-13">
                      4 Thông báo mới</span
                    >
                  </div>
                </div>
              </div>

              <div class="px-2 pt-2">
                <ul
                  ngbNav
                  #nav="ngbNav"
                  [activeId]="1"
                  class="nav nav-tabs dropdown-tabs nav-tabs-custom"
                  id="notificationItemsTab"
                >
                  <li [ngbNavItem]="1">
                    <a ngbNavLink> Tất cả (4) </a>
                    <ng-template ngbNavContent>
                      <div
                        class="tab-pane fade show active py-2 ps-2"
                        id="all-noti-tab"
                        role="tabpanel"
                      >
                        <ngx-simplebar style="max-height: 300px">
                          <div class="pe-2">
                            <div
                              class="text-reset notification-item d-block dropdown-item position-relative"
                            >
                              <div class="d-flex">
                                <div class="avatar-xs me-3">
                                  <span
                                    class="avatar-title bg-soft-info text-info rounded-circle fs-16"
                                  >
                                    <i class="bx bx-badge-check"></i>
                                  </span>
                                </div>
                                <div class="flex-1">
                                  <a
                                    href="javascript:void(0);"
                                    class="stretched-link"
                                  >
                                    <h6 class="mt-0 mb-2 lh-base">
                                      Your <b>Elite</b> author Graphic
                                      Optimization
                                      <span class="text-secondary">reward</span>
                                      is ready!
                                    </h6>
                                  </a>
                                  <p
                                    class="mb-0 fs-11 fw-medium text-uppercase text-muted"
                                  >
                                    <span
                                      ><i class="mdi mdi-clock-outline"></i>
                                      Just 30 sec ago</span
                                    >
                                  </p>
                                </div>
                                <div class="px-2 fs-15">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                  />
                                </div>
                              </div>
                            </div>

                            <div
                              class="text-reset notification-item d-block dropdown-item position-relative"
                            >
                              <div class="d-flex">
                                <img
                                  src="../../../../assets/images/users/avatar-2.jpg"
                                  class="me-3 rounded-circle avatar-xs"
                                  alt="user-pic"
                                />
                                <div class="flex-1">
                                  <a
                                    href="javascript:void(0);"
                                    class="stretched-link"
                                    ><h6 class="mt-0 mb-1 fs-13 fw-semibold">
                                      Angela Bernier
                                    </h6></a
                                  >
                                  <div class="fs-13 text-muted">
                                    <p class="mb-1">
                                      Answered to your comment on the cash flow
                                      forecast's graph 🔔.
                                    </p>
                                  </div>
                                  <p
                                    class="mb-0 fs-11 fw-medium text-uppercase text-muted"
                                  >
                                    <span
                                      ><i class="mdi mdi-clock-outline"></i> 48
                                      min ago</span
                                    >
                                  </p>
                                </div>
                                <div class="px-2 fs-15">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                  />
                                </div>
                              </div>
                            </div>

                            <div
                              class="text-reset notification-item d-block dropdown-item position-relative"
                            >
                              <div class="d-flex">
                                <div class="avatar-xs me-3">
                                  <span
                                    class="avatar-title bg-soft-danger text-danger rounded-circle fs-16"
                                  >
                                    <i class="bx bx-message-square-dots"></i>
                                  </span>
                                </div>
                                <div class="flex-1">
                                  <a
                                    href="javascript:void(0);"
                                    class="stretched-link"
                                  >
                                    <h6 class="mt-0 mb-2 fs-13 lh-base">
                                      You have received
                                      <b class="text-success">20</b> new
                                      messages in the conversation
                                    </h6>
                                  </a>
                                  <p
                                    class="mb-0 fs-11 fw-medium text-uppercase text-muted"
                                  >
                                    <span
                                      ><i class="mdi mdi-clock-outline"></i> 2
                                      hrs ago</span
                                    >
                                  </p>
                                </div>
                                <div class="px-2 fs-15">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                  />
                                </div>
                              </div>
                            </div>

                            <div
                              class="text-reset notification-item d-block dropdown-item position-relative"
                            >
                              <div class="d-flex">
                                <img
                                  src="../../../../assets/images/users/avatar-8.jpg"
                                  class="me-3 rounded-circle avatar-xs"
                                  alt="user-pic"
                                />
                                <div class="flex-1">
                                  <a
                                    href="javascript:void(0);"
                                    class="stretched-link"
                                    ><h6 class="mt-0 mb-1 fs-13 fw-semibold">
                                      Maureen Gibson
                                    </h6></a
                                  >
                                  <div class="fs-13 text-muted">
                                    <p class="mb-1">
                                      We talked about a project on linkedin.
                                    </p>
                                  </div>
                                  <p
                                    class="mb-0 fs-11 fw-medium text-uppercase text-muted"
                                  >
                                    <span
                                      ><i class="mdi mdi-clock-outline"></i> 4
                                      hrs ago</span
                                    >
                                  </p>
                                </div>
                                <div class="px-2 fs-15">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                  />
                                </div>
                              </div>
                            </div>

                            <div class="my-3 text-center">
                              <button
                                type="button"
                                class="btn btn-soft-success waves-effect waves-light"
                              >
                                View All Notifications
                                <i class="ri-arrow-right-line align-middle"></i>
                              </button>
                            </div>
                          </div>
                        </ngx-simplebar>
                      </div>
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="2">
                    <a ngbNavLink> Tin nhắn </a>
                    <ng-template ngbNavContent>
                      <div
                        class="tab-pane fade show active py-2 ps-2"
                        id="all-noti-tab"
                        role="tabpanel"
                      >
                        <ngx-simplebar style="max-height: 300px">
                          <div class="pe-2">
                            <div
                              class="text-reset notification-item d-block dropdown-item"
                            >
                              <div class="d-flex">
                                <img
                                  src="../../../../assets/images/users/avatar-3.jpg"
                                  class="me-3 rounded-circle avatar-xs"
                                  alt="user-pic"
                                />
                                <div class="flex-1">
                                  <a
                                    href="javascript:void(0);"
                                    class="stretched-link"
                                    ><h6 class="mt-0 mb-1 fs-13 fw-semibold">
                                      James Lemire
                                    </h6></a
                                  >
                                  <div class="fs-13 text-muted">
                                    <p class="mb-1">
                                      We talked about a project on linkedin.
                                    </p>
                                  </div>
                                  <p
                                    class="mb-0 fs-11 fw-medium text-uppercase text-muted"
                                  >
                                    <span
                                      ><i class="mdi mdi-clock-outline"></i> 30
                                      min ago</span
                                    >
                                  </p>
                                </div>
                                <div class="px-2 fs-15">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                  />
                                </div>
                              </div>
                            </div>

                            <div
                              class="text-reset notification-item d-block dropdown-item"
                            >
                              <div class="d-flex">
                                <img
                                  src="../../../../assets/images/users/avatar-2.jpg"
                                  class="me-3 rounded-circle avatar-xs"
                                  alt="user-pic"
                                />
                                <div class="flex-1">
                                  <a
                                    href="javascript:void(0);"
                                    class="stretched-link"
                                    ><h6 class="mt-0 mb-1 fs-13 fw-semibold">
                                      Angela Bernier
                                    </h6></a
                                  >
                                  <div class="fs-13 text-muted">
                                    <p class="mb-1">
                                      Answered to your comment on the cash flow
                                      forecast's graph 🔔.
                                    </p>
                                  </div>
                                  <p
                                    class="mb-0 fs-11 fw-medium text-uppercase text-muted"
                                  >
                                    <span
                                      ><i class="mdi mdi-clock-outline"></i> 2
                                      hrs ago</span
                                    >
                                  </p>
                                </div>
                                <div class="px-2 fs-15">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                  />
                                </div>
                              </div>
                            </div>

                            <div
                              class="text-reset notification-item d-block dropdown-item"
                            >
                              <div class="d-flex">
                                <img
                                  src="../../../../assets/images/users/avatar-6.jpg"
                                  class="me-3 rounded-circle avatar-xs"
                                  alt="user-pic"
                                />
                                <div class="flex-1">
                                  <a
                                    href="javascript:void(0);"
                                    class="stretched-link"
                                    ><h6 class="mt-0 mb-1 fs-13 fw-semibold">
                                      Kenneth Brown
                                    </h6></a
                                  >
                                  <div class="fs-13 text-muted">
                                    <p class="mb-1">
                                      Mentionned you in his comment on 📃
                                      invoice #12501.
                                    </p>
                                  </div>
                                  <p
                                    class="mb-0 fs-11 fw-medium text-uppercase text-muted"
                                  >
                                    <span
                                      ><i class="mdi mdi-clock-outline"></i> 10
                                      hrs ago</span
                                    >
                                  </p>
                                </div>
                                <div class="px-2 fs-15">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                  />
                                </div>
                              </div>
                            </div>

                            <div
                              class="text-reset notification-item d-block dropdown-item"
                            >
                              <div class="d-flex">
                                <img
                                  src="../../../../assets/images/users/avatar-8.jpg"
                                  class="me-3 rounded-circle avatar-xs"
                                  alt="user-pic"
                                />
                                <div class="flex-1">
                                  <a
                                    href="javascript:void(0);"
                                    class="stretched-link"
                                    ><h6 class="mt-0 mb-1 fs-13 fw-semibold">
                                      Maureen Gibson
                                    </h6></a
                                  >
                                  <div class="fs-13 text-muted">
                                    <p class="mb-1">
                                      We talked about a project on linkedin.
                                    </p>
                                  </div>
                                  <p
                                    class="mb-0 fs-11 fw-medium text-uppercase text-muted"
                                  >
                                    <span
                                      ><i class="mdi mdi-clock-outline"></i> 3
                                      days ago</span
                                    >
                                  </p>
                                </div>
                                <div class="px-2 fs-15">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                  />
                                </div>
                              </div>
                            </div>

                            <div class="my-3 text-center">
                              <button
                                type="button"
                                class="btn btn-soft-success waves-effect waves-light"
                              >
                                View All Messages
                                <i class="ri-arrow-right-line align-middle"></i>
                              </button>
                            </div>
                          </div>
                        </ngx-simplebar>
                      </div>
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="3">
                    <a ngbNavLink> Thông báo </a>
                    <ng-template ngbNavContent>
                      <div
                        class="tab-pane p-4"
                        id="alerts-tab"
                        role="tabpanel"
                        aria-labelledby="alerts-tab"
                      >
                        <div class="w-25 w-sm-50 pt-3 mx-auto">
                          <img
                            src="../../../../assets/images/svg/bell.svg"
                            class="img-fluid"
                            alt="user-pic"
                          />
                        </div>
                        <div class="text-center pb-5 mt-2">
                          <h6 class="fs-18 fw-semibold lh-base">
                            Hey! You have no any notifications
                          </h6>
                        </div>
                      </div>
                    </ng-template>
                  </li>
                </ul>
              </div>
            </div>

            <div class="tab-content" id="notificationItemsTabContent">
              <div class="tab-content text-muted">
                <div [ngbNavOutlet]="nav"></div>
              </div>
            </div>
          </div>
        </div>

        <app-user-info></app-user-info>
      </div>
    </div>
  </div>
</header>
