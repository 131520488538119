<div [formGroup]="formRealEstate" class="row">
  <div class="hl-mb-n2">
    <span class="badge badge-soft-primary fs-12"
      ><i class="mdi mdi-circle-medium"></i>Thông tin chi tiết</span
    >
  </div>
  <div class="border border-dashed"></div>

  <div class="py-2 row">
    <div class="col-4 mb-3">
      <label class="form-label">Loại tiền</label>
      <ng-select
        [items]="CURRENCY"
        placeholder="Loại tiền"
        bindLabel="label"
        bindValue="key"
        [readonly]="!isEdit && !!realEstateId"
        formControlName="currency"
      ></ng-select>
    </div>
    <div [ngClass]="priceClass">
      <label class="form-label">Tổng giá</label>
      <div class="d-flex align-items-center gap-3">
        <div class="form-check">
          <input
            type="checkbox"
            class="form-check-input"
            (change)="onChangeCheckBox($event)"
            [class.disabled]="
              controls['price']?.value || (!isEdit && !!realEstateId)
            "
            formControlName="negotiate"
            [checked]="controls['negotiate']?.value === 1"
          />
          <label class="form-check-label">Thương lượng</label>
        </div>
        <div class="d-flex gap-1 flex-1">
          <div class="col-8">
            <input
              type="text"
              class="form-control"
              placeholder="Tổng giá"
              [readonly]="(!isEdit && !!realEstateId) || isChecked"
              formControlName="price"
            />
          </div>
          <div class="col-4">
            <app-price-unit
              [unitForm]="unitForm"
              [disabled]="!isEdit && !!realEstateId"
            ></app-price-unit>
          </div>
        </div>

        <small
          *ngIf="controls['price'].touched && controls['price']?.errors"
          class="text-danger"
          >Trường thông tin bắt buộc</small
        >
      </div>
    </div>
    <div class="col-4 mb-3">
      <label class="form-label">Diện tích đất</label>
      <div class="input-group">
        <input
          type="number"
          class="form-control"
          placeholder="Diện tích đất"
          formControlName="landArea"
          [readonly]="!isEdit && !!realEstateId"
        />
        <span class="input-group-text bg-soft-light text-muted prefix-width"
          >(m2)</span
        >
        <small
          *ngIf="controls['landArea'].touched && controls['landArea']?.errors"
          class="text-danger"
          >Trường thông tin bắt buộc</small
        >
      </div>
    </div>
    <div class="col-4 mb-3">
      <label class="form-label">Diện tích XD</label>
      <div class="input-group">
        <input
          type="number"
          class="form-control"
          placeholder="Diện tích xây dựng"
          formControlName="buildArea"
          [readonly]="!isEdit && !!realEstateId"
        />
        <span class="input-group-text bg-soft-light text-muted prefix-width"
          >(m2)</span
        >
        <small
          *ngIf="controls['buildArea'].touched && controls['buildArea']?.errors"
          class="text-danger"
          >Trường thông tin bắt buộc</small
        >
      </div>
    </div>
    <div class="col-4 mb-3">
      <label class="form-label">Nhóm khách hàng</label>
      <ng-select
        [items]="OWNER_GROUPS"
        placeholder="Nhóm khách hàng"
        bindLabel="label"
        bindValue="key"
        formControlName="ownerGroup"
        [readonly]="!isEdit && !!realEstateId"
      ></ng-select>
      <small
        *ngIf="controls['ownerGroup'].touched && controls['ownerGroup']?.errors"
        class="text-danger"
        >Trường thông tin bắt buộc</small
      >
    </div>
    <div class="col-4 mb-3 form-group" required>
      <label class="form-label">Nguồn</label>
      <ng-select
        [items]="ORIGIN_PROVIDE"
        placeholder="Nguồn"
        bindLabel="label"
        bindValue="key"
        formControlName="originProvide"
        [readonly]="!isEdit && !!realEstateId"
      ></ng-select>
      <small
        *ngIf="
          controls['originProvide'].touched && controls['originProvide']?.errors
        "
        class="text-danger"
        >Trường thông tin bắt buộc</small
      >
    </div>

    <div class="col-4 mb-3">
      <label class="form-label">Tầng hầm</label>
      <ng-select
        [items]="ACTIVE_STATUS_OPTIONS"
        bindLabel="label"
        bindValue="key"
        placeholder="Tầng hầm"
        formControlName="basement"
        [readonly]="!isEdit && !!realEstateId"
      ></ng-select>
      <small
        *ngIf="controls['basement'].touched && controls['basement']?.errors"
        class="text-danger"
        >Trường thông tin bắt buộc</small
      >
    </div>

    <div class="col-4 mb-3">
      <label class="form-label">Căn góc</label>
      <ng-select
        [items]="ACTIVE_STATUS_OPTIONS"
        bindLabel="label"
        bindValue="key"
        placeholder="Căn góc"
        formControlName="corner"
        [readonly]="!isEdit && !!realEstateId"
      ></ng-select>
      <small
        *ngIf="controls['corner'].touched && controls['corner']?.errors"
        class="text-danger"
        >Trường thông tin bắt buộc</small
      >
    </div>

    <div class="col-4 mb-3">
      <label class="form-label">Thang máy</label>
      <ng-select
        [items]="ACTIVE_STATUS_OPTIONS"
        bindLabel="label"
        bindValue="key"
        placeholder="Thang máy"
        formControlName="elevators"
        [readonly]="!isEdit && !!realEstateId"
      ></ng-select>
      <small
        *ngIf="controls['elevators'].touched && controls['elevators']?.errors"
        class="text-danger"
        >Trường thông tin bắt buộc</small
      >
    </div>

    <div class="col-4 mb-3">
      <label class="form-label">Đã ký hợp đồng môi giới</label>
      <ng-select
        [items]="ACTIVE_STATUS_OPTIONS"
        bindLabel="label"
        bindValue="key"
        placeholder="Đã ký hợp đồng môi giới"
        formControlName="signedBrokerageContract"
        [readonly]="!isEdit && !!realEstateId"
      ></ng-select>
      <small
        *ngIf="
          controls['signedBrokerageContract'].touched &&
          controls['signedBrokerageContract']?.errors
        "
        class="text-danger"
        >Trường thông tin bắt buộc</small
      >
    </div>

    <div class="col-4 mb-3">
      <label class="form-label">Lịch sử cho thuê</label>
      <ng-select
        placeholder="Lịch sử cho thuê"
        [readonly]="!isEdit && !!realEstateId"
        formControlName="tenant"
      ></ng-select>
      <small
        *ngIf="controls['tenant'].touched && controls['tenant']?.errors"
        class="text-danger"
        >Trường thông tin bắt buộc</small
      >
    </div>
  </div>
</div>
