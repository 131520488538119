import { inject, Injectable, Injector } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PHONE_CALL_STATUS } from "@constant/status-call.constant";
import { ConvertDateTimeToString } from "@util/date-time.util";

@Injectable()
export class CallHistoryOrderFormHelper {
  private readonly formGroup: FormGroup;
  private fb = inject(FormBuilder);
  private inject = inject(Injector);

  constructor() {
    this.formGroup = this.initForm();
  }

  get form() {
    return this.formGroup as FormGroup;
  }

  set patchData(data: any) {
    this.formGroup.patchValue({
      phoneNumber: data?.phoneNumber,
      customerId: data?.customerId,
      caller: data?.caller,
      brand: data?.brand,
      order: data?.order,
      title: data?.phoneNumber
        ? `Gọi điện cho ${data?.fullName}`
        : "Note lịch sử",
      phone: data?.phoneNumber,
      fullName: data?.fullName,
      refId: data?.refId,
      alias: data?.alias ? Number(data?.alias) : null,
      origin: data?.originProvide,
      ext: data?.ext,
      groupId: data?.groupId,
      statusCall: !data?.phoneNumber
        ? PHONE_CALL_STATUS.NOT_CALL
        : data?.statusCall,
    });
  }

  // @ts-ignore
  private initForm(): FormGroup {
    return this.fb.group({
      customerId: [""],
      title: ["", [Validators.required]],
      content: ["", [Validators.required]],
      method: [1, [Validators.required]],
      statusCall: [null, [Validators.required]],
      ext: [null, Validators.required],
      groupId: [null, Validators.required],
      caller: ["", [Validators.required]],
      phoneNumber: [""],
      record: ["https://google.com", [Validators.required]],
      startTime: [
        ConvertDateTimeToString(new Date(), this.inject),
        [Validators.required],
      ],
      endTime: ["", [Validators.required]],
      order: [null],
      brand: [null],
      phone: ["", Validators.required],
      fullName: [""],
      origin: [null],
      alias: [null],
      refId: [null],
    });
  }
}
