import { Component, Input } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";

@Component({
  selector: "app-dropdown-wrapper",
  templateUrl: "./dropdown-wrapper.component.html",
  styleUrls: ["./dropdown-wrapper.component.scss"],
})
export class DropdownWrapperComponent {
  @Input() control = new UntypedFormControl();
  @Input() isMultipleChoose: boolean = false;
  @Input() disabled: boolean;
  @Input() placeholder: string;

  @Input() set items(data: any[]) {
    this.elements = data.map((i) => {
      return { id: i.key, title: i.label };
    });
  }
  elements: any;

  compareFn(item: any, selected: any) {
    // const i1 = item.id.sort();
    return item.id.toString() === selected.toString();
  }
}
