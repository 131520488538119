import { LAND_PURPOSE } from "./common.constant";
import { REAL_ESTATE_RESOURCES } from "./permission.constant";

export enum LAND_STATUS {
  NEW = "01",
  APPROVED_SELL = "02", // mở bán
  REJECTED = "03", // Từ chối
  DRAFT = "04", // bản nháp
  PRICE_DEAL = "05", //Thuyết phục phí
  EVALUATING = "06", //Mặt bằng chấm điểm
  DEPOSITED = "07", //Đã đặt cọc
  NEGOTIATE = "08", //Đang đàm phán
  STOP_SELL = "09", //Ngừng giao dịch
  SOLD = "10", //Đã giao dịch
  APPROVED_DATA = "11", // Approved data for sale (haven't sell)
  RAW_DATA = "12", // Raw data
}

export enum REAL_ESTATE_STATUS_ALLOW_TO_SELL {
  APPROVED_SELL = "02", // mở bán
  NEGOTIATE = "08", //Đang đàm phán
}

export enum OWNER_GROUPS_DEFINE {
  PERSONAL = 1,
  COMPANY = 2,
  GOVERNMENT = 3,
  OTHER = 4,
}

export const STATUS_WAITING_APPROVE = [
  LAND_STATUS.APPROVED_DATA,
  LAND_STATUS.NEW,
  LAND_STATUS.REJECTED,
];

export const STATUS_IN_PROGRESS = [
  LAND_STATUS.APPROVED_SELL,
  LAND_STATUS.PRICE_DEAL,
  LAND_STATUS.EVALUATING,
  LAND_STATUS.DEPOSITED,
  LAND_STATUS.NEGOTIATE,
  LAND_STATUS.STOP_SELL,
  LAND_STATUS.SOLD,
];

export const STATUS_IN_MAP_FOR_APPROVED_PAGE = [
  LAND_STATUS.APPROVED_SELL,
  LAND_STATUS.PRICE_DEAL,
  LAND_STATUS.EVALUATING,
  LAND_STATUS.DEPOSITED,
  LAND_STATUS.NEGOTIATE,
  LAND_STATUS.STOP_SELL,
  LAND_STATUS.SOLD,
  LAND_STATUS.APPROVED_DATA,
  LAND_STATUS.RAW_DATA,
];

export const STATUS_QUICK_UPLOAD = [LAND_STATUS.RAW_DATA];

export const REAL_ESTATE_STATUS_QUICK_UP = [
  {
    cKey: LAND_STATUS.NEW,
    cValue: "Chờ duyệt",
  },
  {
    cKey: LAND_STATUS.RAW_DATA,
    cValue: "Chưa xử lý",
  },
];

export const REAL_ESTATE_STATUS = [
  {
    cKey: LAND_STATUS.NEW,
    cValue: "Chờ duyệt",
  },
  {
    cKey: LAND_STATUS.APPROVED_DATA,
    cValue: "Đã duyệt",
  },
  {
    cKey: LAND_STATUS.REJECTED,
    cValue: "Từ chối",
  },
  {
    cKey: LAND_STATUS.DRAFT,
    cValue: "Bản nháp",
  },
  {
    cKey: LAND_STATUS.APPROVED_SELL,
    cValue: "Mở bán",
  },
  {
    cKey: LAND_STATUS.PRICE_DEAL,
    cValue: "Thuyết phục phí",
  },
  {
    cKey: LAND_STATUS.EVALUATING,
    cValue: "Mặt bằng chấm điểm",
  },
  {
    cKey: LAND_STATUS.DEPOSITED,
    cValue: "Đã đặt cọc",
  },
  {
    cKey: LAND_STATUS.NEGOTIATE,
    cValue: "Đang đàm phán",
  },
  {
    cKey: LAND_STATUS.STOP_SELL,
    cValue: "Ngừng giao dịch",
  },
  {
    cKey: LAND_STATUS.SOLD,
    cValue: "Đã giao dịch",
  },
  {
    cKey: LAND_STATUS.RAW_DATA,
    cValue: "Chưa xử lý",
  },
];

// Tóm tắt 3 trạng thái chính /
export const REAL_ESTATE_STATUS_SUMMARY = [
  {
    cKey: LAND_STATUS.NEW,
    cValue: "Chờ duyệt",
  },
  {
    cKey: LAND_STATUS.REJECTED,
    cValue: "Từ chối",
  },
  {
    cKey: LAND_STATUS.APPROVED_DATA,
    cValue: "Đã Duyệt",
  },
];

export const REAL_ESTATE_STATUS_FOR_ADMIN_APPROVED = [
  {
    cKey: LAND_STATUS.NEW,
    cValue: "Chờ duyệt",
    resources: [REAL_ESTATE_RESOURCES.WAITING_APPROVE],
  },
  {
    cKey: LAND_STATUS.REJECTED,
    cValue: "Từ chối",
    resources: [REAL_ESTATE_RESOURCES.WAITING_APPROVE],
  },
  {
    cKey: LAND_STATUS.APPROVED_DATA,
    cValue: "Đã duyệt",
    resources: [
      REAL_ESTATE_RESOURCES.WAITING_APPROVE,
      REAL_ESTATE_RESOURCES.MAP_MANAGEMENT,
    ],
  },
  {
    cKey: LAND_STATUS.APPROVED_SELL,
    cValue: "Mở bán",
    resources: [
      REAL_ESTATE_RESOURCES.IN_PROGRESS,
      REAL_ESTATE_RESOURCES.MAP_MANAGEMENT,
    ],
  },
  {
    cKey: LAND_STATUS.PRICE_DEAL,
    cValue: "Thuyết phục phí",
    resources: [
      REAL_ESTATE_RESOURCES.IN_PROGRESS,
      REAL_ESTATE_RESOURCES.MAP_MANAGEMENT,
    ],
  },
  {
    cKey: LAND_STATUS.EVALUATING,
    cValue: "Mặt bằng chấm điểm",
    resources: [
      REAL_ESTATE_RESOURCES.IN_PROGRESS,
      REAL_ESTATE_RESOURCES.MAP_MANAGEMENT,
    ],
  },
  {
    cKey: LAND_STATUS.DEPOSITED,
    cValue: "Đã đặt cọc",
    resources: [
      REAL_ESTATE_RESOURCES.IN_PROGRESS,
      REAL_ESTATE_RESOURCES.MAP_MANAGEMENT,
    ],
  },
  {
    cKey: LAND_STATUS.NEGOTIATE,
    cValue: "Đang đàm phán",
    resources: [
      REAL_ESTATE_RESOURCES.IN_PROGRESS,
      REAL_ESTATE_RESOURCES.MAP_MANAGEMENT,
    ],
  },
  {
    cKey: LAND_STATUS.STOP_SELL,
    cValue: "Ngừng giao dịch",
    resources: [
      REAL_ESTATE_RESOURCES.IN_PROGRESS,
      REAL_ESTATE_RESOURCES.MAP_MANAGEMENT,
    ],
  },
  {
    cKey: LAND_STATUS.SOLD,
    cValue: "Đã giao dịch",
    resources: [
      REAL_ESTATE_RESOURCES.IN_PROGRESS,
      REAL_ESTATE_RESOURCES.MAP_MANAGEMENT,
    ],
  },
  {
    cKey: LAND_STATUS.RAW_DATA,
    cValue: "BĐS đăng nhanh",
    resources: [
      REAL_ESTATE_RESOURCES.QUICK_UPLOAD,
      REAL_ESTATE_RESOURCES.MAP_MANAGEMENT,
    ],
  },
];

export const LANDS_TYPE = [
  {
    cValue: "Cần bán",
    cKey: LAND_PURPOSE.FOR_SELL,
  },
  {
    cValue: "Cho thuê",
    cKey: LAND_PURPOSE.FOR_RENT,
  },
];

export const LANDS_TYPE_MAP = [
  {
    cValue: "Cần bán",
    cKey: LAND_PURPOSE.FOR_SELL,
  },
  {
    cValue: "Cho thuê",
    cKey: LAND_PURPOSE.FOR_RENT,
  },
  {
    cValue: "Đăng nhanh",
    cKey: LAND_PURPOSE.QUICK_UPLOAD,
  },
];

export const LAND_DEFINED_FILTER = [
  {
    title: "Mới nhất",
    orderColumns: "created",
    orderDirects: "DESC",
  },
  {
    title: "Cũ nhất",
    orderColumns: "created",
    orderDirects: "ASC",
  },
  {
    title: "Giá cao",
    orderColumns: "price",
    orderDirects: "DESC",
  },
  {
    title: "Giá thấp",
    orderColumns: "price",
    orderDirects: "ASC",
  },
];

export const FILTER_FAVORITE_LAND = [
  {
    cValue: "Tất cả",
    cKey: "",
  },
  {
    cValue: "BĐS quan tâm",
    cKey: "favorite",
  },
  {
    cValue: "BĐS không quan tâm",
    cKey: "nonFavorite",
  },
];

export const TYPES_ROAD = [
  {
    title: "Mặt tiền đường",
    id: "",
  },
  {
    title: "Đường nội bộ",
    id: "",
  },
  {
    title: "Đường nội hẻm lớn",
    id: "",
  },
  {
    title: "Đường nội hẻm nhỏ",
    id: "",
  },
  {
    title: "Khác",
    id: "",
  },
];

export const TYPES_DIRECTION = [
  {
    title: "Hướng đông",
    id: "",
  },
  {
    title: "Hướng tây",
    id: "",
  },
  {
    title: "Hướng nam",
    id: "",
  },
  {
    title: "Hướng bắc",
    id: "",
  },
  {
    title: "Hướng đông bắc",
    id: "",
  },
  {
    title: "Hướng tây bắc",
    id: "",
  },
  {
    title: "Hướng tây nam",
    id: "",
  },
  {
    title: "Hướng đông nam",
    id: "",
  },
];

export const TYPES_PARKING = [
  {
    title: "Đỗ xe tầng hầm",
    id: "",
  },
  {
    title: "Đỗ xe lối vào phía sau",
    id: "",
  },
  {
    title: "Đỗ xe lối vào phía trước",
    id: "",
  },
  {
    title: "Đỗ xe trong nhà",
    id: "",
  },
  {
    title: "Đỗ xe ngoài đường",
    id: "",
  },
  {
    title: "Đỗ xe ngoài bãi",
    id: "",
  },
  {
    title: "Khác",
    id: "",
  },
];

export const TYPES_FLOOR = [
  {
    title: "Sàn gỗ",
    id: "",
  },
  {
    title: "Sàn gạch",
    id: "",
  },
  {
    title: "Sàn nhựa",
    id: "",
  },
  {
    title: "Khác",
    id: "",
  },
];

export const TYPES_FURNITURE = [
  {
    title: "Chưa có",
    id: "",
  },
  {
    title: "Cơ bản",
    id: "",
  },
  {
    title: "Đầy đủ",
    id: "",
  },
  {
    title: "Khác",
    id: "",
  },
];

export const TYPE_OF_NOTIFICATION = [
  {
    cValue: "Theo agency",
  },
  {
    cValue: "Theo khu vực",
  },
];

export const OWNER_GROUPS = [
  {
    label: "Cá nhân",
    key: OWNER_GROUPS_DEFINE.PERSONAL,
  },
  {
    label: "Doanh nghiệp",
    key: OWNER_GROUPS_DEFINE.COMPANY,
  },
  {
    label: "Nhà nước",
    key: OWNER_GROUPS_DEFINE.GOVERNMENT,
  },
  {
    label: "Khác",
    key: OWNER_GROUPS_DEFINE.OTHER,
  },
];

export const CURRENCY = [
  {
    label: "VND",
    key: "VND",
  },
  {
    label: "USD",
    key: "USD",
  },
];

export enum REAL_ESTATE_URL {
  QUICK_UPLOAD = "quick-upload",
  IN_PROGRESS = "in-progress",
  WAITING_APPROVE = "waiting-approve",
  MAP_MANAGEMENT = "map-management",
}

export const REAL_ESTATE_MARK_FILTER = [
  {
    cValue: "Tất cả BĐS",
    cKey: false,
  },
  {
    cValue: "Mặt bằng chấm điểm",
    cKey: true,
  },
];

export enum REAL_ESTATE_GROUP_TYPE {
  DEFAULT,
  WAITING_APPROVE,
  QUICK_UPLOAD,
  IN_PROGRESS,
}

export const STATUS_BY_GROUP: any = {
  [REAL_ESTATE_GROUP_TYPE.DEFAULT]: null,
  [REAL_ESTATE_GROUP_TYPE.WAITING_APPROVE]: STATUS_WAITING_APPROVE,
  [REAL_ESTATE_GROUP_TYPE.IN_PROGRESS]: STATUS_IN_PROGRESS,
  [REAL_ESTATE_GROUP_TYPE.QUICK_UPLOAD]: STATUS_QUICK_UPLOAD,
};

export const LANDS_GROUP_TYPE = [
  {
    cValue: "Mặc định",
    cKey: REAL_ESTATE_GROUP_TYPE.DEFAULT,
    resource: REAL_ESTATE_RESOURCES.WAITING_APPROVE,
  },
  {
    cValue: "Chờ duyệt",
    cKey: REAL_ESTATE_GROUP_TYPE.WAITING_APPROVE,
    resource: REAL_ESTATE_RESOURCES.WAITING_APPROVE,
  },
  {
    cValue: "Đăng nhanh",
    cKey: REAL_ESTATE_GROUP_TYPE.QUICK_UPLOAD,
    resource: REAL_ESTATE_RESOURCES.QUICK_UPLOAD,
  },
  {
    cValue: "Đang xử lý",
    cKey: REAL_ESTATE_GROUP_TYPE.IN_PROGRESS,
    resource: REAL_ESTATE_RESOURCES.IN_PROGRESS,
  },
];

export const CREATE_REAL_ESTATE_STATUS = [
  {
    label: "Chờ duyệt",
    key: LAND_STATUS.NEW,
  },
  {
    label: "Thuyết phục phí",
    key: LAND_STATUS.PRICE_DEAL,
  },
  {
    label: "Mở bán",
    key: LAND_STATUS.APPROVED_SELL,
  },
  {
    label: "Ngừng giao dịch",
    key: LAND_STATUS.STOP_SELL,
  },
];
