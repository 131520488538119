import { CUSTOMER_RESOURCES } from "./permission.constant";

export interface UserReq {
  username: string;
  password: string;
}

export const APP_USER_STATUS = {
  ACTIVE: "01",
  INACTIVE: "02",
  PENDING_OTP: "03", // Đang chờ nhập OTP
  VERIFIED: "04", //
  CANCEL: "05", // ADmin không duyệt cho vào xem
  REVIEWING: "06", // chờ Admin duyệt sau khi đã nhập OTP thành công
};

export const APP_ACCOUNT_TYPE = {
  SUPER_ADMIN: "SP",
  ADMIN: "AD",
  PERSONAL: "PE",
  AGENT: "AG",
  BUSINESS: "BU",
};

export const CONTACT_SCHEDULE_TYPE = {
  KEY: "1",
  LABEL: "",
};

export const BOOKING_SCHEDULE_TYPE = {
  KEY: "2",
  LABEL: {
    VIDEO: "Qua video",
    LIVE: "Trực tiếp",
  },
};

export const USER_STATUS_FILTER = [
  {
    label: "Đã duyệt",
    key: [APP_USER_STATUS.ACTIVE, APP_USER_STATUS.INACTIVE],
    resource: CUSTOMER_RESOURCES.ACTIVE,
  },
  {
    label: "Chờ duyệt",
    key: [APP_USER_STATUS.REVIEWING],
    resource: CUSTOMER_RESOURCES.WAITING_APPROVE,
  },
  {
    label: "Từ chối",
    key: [APP_USER_STATUS.CANCEL],
    resource: CUSTOMER_RESOURCES.REJECTED,
  },
];

export const USER_ACTIVE_TYPES_FILTER = [
  {
    label: "Tất cả",
    key: null,
  },
  {
    label: "Hoạt động",
    key: APP_USER_STATUS.ACTIVE,
  },
  {
    label: "Không hoạt động",
    key: APP_USER_STATUS.INACTIVE,
  },
];

export const USER_TYPE_FILTER = [
  {
    label: "Tất cả",
    key: [APP_ACCOUNT_TYPE.PERSONAL, APP_ACCOUNT_TYPE.BUSINESS],
  },
  {
    label: "Cá nhân",
    key: APP_ACCOUNT_TYPE.PERSONAL,
  },
  {
    label: "Công ty",
    key: APP_ACCOUNT_TYPE.BUSINESS,
  },
];

export const USER_GENDER = [
  {
    id: "1",
    label: "Nam",
  },
  {
    id: "2",
    label: "Nữ",
  },
  {
    id: "3",
    label: "Khác",
  },
];

export const EMAIL_VERIFICATION_ACTIVE_FILTER = [
  {
    label: "Đã duyệt",
    key: "true",
  },
  {
    label: "Từ chối",
    key: "false",
  },
];

export const SUBSCRIBE_SEARCH_ACTIVE_FILTER = [
  {
    label: "Tất cả",
    key: "",
  },
  {
    label: "Đã duyệt",
    key: "true",
  },
  {
    label: "Từ chối",
    key: "false",
  },
];

export const SUBSCRIBE_SEARCH_CREATED_AT = [
  {
    label: "Mới nhất",
    key: "DESC",
  },
  {
    label: "Cũ nhất",
    key: "ASC",
  },
];

export const USER_TYPES = [
  {
    label: "Cá nhân",
    key: APP_ACCOUNT_TYPE.PERSONAL,
  },
  {
    label: "Doanh nghiệp",
    key: APP_ACCOUNT_TYPE.BUSINESS,
  },
];

export const BUSINESS_DOMAIN = [
  { label: "Giáo dục", key: 1 },
  { label: "Nhà hàng / Hàng ăn", key: 2 },
  { label: "Ngân hàng / Bảo hiểm / Tài chính", key: 3 },
  { label: "Dịch vụ viễn thông", key: 4 },
  { label: "Vàng bạc đá quý", key: 5 },
  { label: "Showroom xe", key: 6 },
  { label: "Y dược / Bệnh viện", key: 7 },
  { label: "Thú cưng", key: 8 },
  { label: "Tiệm bánh", key: 9 },
  { label: "Thời trang", key: 10 },
  { label: "Nội thất / Gia dụng", key: 11 },
  { label: "Siêu thị / Cửa hàng thực phẩm", key: 12 },
  { label: "Đồ uống", key: 13 },
  { label: "Spa / Thẩm mỹ viện", key: 14 },
  { label: "Văn phòng", key: 15 },
  { label: "Thiết bị điện tử", key: 16 },
  { label: "Phụ kiện thời trang", key: 17 },
  { label: "Bán lẻ", key: 18 },
  { label: "Đồ chơi trẻ em, mẹ và bé", key: 19 },
  { label: "Gym / Thể thao", key: 20 },
  { label: "Bar / Pub / Karaoke", key: 21 },
  { label: "Giặt là", key: 22 },
  { label: "Khách sạn / Nhà nghỉ", key: 23 },
  { label: "Sửa xe", key: 24 },
  { label: "Giao hàng / Kho", key: 25 },
  { label: "Dịch vụ du lịch", key: 26 },
  { label: "Vật liệu xây dựng", key: 27 },
  { label: "Nha khoa", key: 28 },
  { label: "Rạp chiếu phim / Khu vui chơi", key: 29 },
  { label: "Hoa / Cây cối", key: 30 },
  { label: "Mỹ phẩm", key: 31 },
  { label: "Kính mắt", key: 32 },
  { label: "Khác", key: 33 },
];
