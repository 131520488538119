<div [formGroup]="formCommon" class="row">
  <div class="hl-mb-n2">
    <span class="badge badge-soft-primary fs-12"
      ><i class="mdi mdi-circle-medium"></i>Thông tin cơ bản</span
    >
  </div>
  <div class="border border-dashed"></div>

  <div class="py-2">
    <div class="row my-2">
      <div class="col-md-4">
        <label class="form-label"></label>
      </div>
      <div class="col-md-8 d-flex gap-2 mt-2">
        <div class="form-check mb-2 form-radio-info">
          <input
            [attr.disabled]="(isEdit && productId) || !productId ? null : true"
            class="form-check-input"
            type="radio"
            name="purpose"
            formControlName="purpose"
            [value]="'2'"
            style="opacity: 1"
            id="flexRadioDefault1"
          />
          <label
            class="form-check-label text-info"
            style="opacity: 1"
            for="flexRadioDefault1"
          >
            Cho Thuê
          </label>
        </div>

        <div class="form-check form-radio-warning">
          <input
            [attr.disabled]="(isEdit && productId) || !productId ? null : true"
            class="form-check-input"
            type="radio"
            formControlName="purpose"
            name="purpose"
            style="opacity: 1"
            [value]="'1'"
            id="flexRadioDefault2"
          />
          <label
            class="form-check-label text-warning"
            for="flexRadioDefault2"
            style="opacity: 1"
          >
            Bán
          </label>
        </div>
        <small
          *ngIf="controls['purpose'].touched && controls['purpose']?.errors"
          class="text-danger"
          >Trying thông tin bắt buộc</small
        >
      </div>
    </div>

    <div class="row my-2">
      <div class="col-md-4">
        <label class="form-label">Loại hình BĐS</label>
      </div>

      <div class="col-md-8">
        <ng-select
          [readonly]="!isEdit && !!productId"
          formControlName="type"
          [items]="typeOfRealEstate$ | async"
          bindValue="cKey"
          bindLabel="cValue"
          placeholder="Chọn loại hình BĐS..."
        ></ng-select>
        <small
          *ngIf="controls['type'].touched && controls['type']?.errors"
          class="text-danger"
          >Trường thông tin bắt buộc</small
        >
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-md-4">
        <label class="form-label">Diện tích cho thuê</label>
      </div>
      <div class="col-md-8">
        <div class="input-group">
          <input
            [readonly]="!isEdit && !!productId"
            type="name"
            formControlName="acreage"
            class="form-control"
            placeholder="Diện tích cho thuê"
          />
          <span class="input-group-text bg-soft-light text-muted prefix-width"
            >(m2)</span
          >
          <small
            *ngIf="controls['acreage'].touched && controls['acreage']?.errors"
            class="text-danger"
            >Trường thông tin bắt buộc</small
          >
        </div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-md-4">
        <label class="form-label">Số tầng</label>
      </div>
      <div class="col-md-8">
        <div
          class="input-step full-width"
          [ngClass]="{ 'group-disabled': !isEdit && productId }"
        >
          <button
            *ngIf="isEdit"
            type="button"
            class="minus"
            (click)="decrement(formCommon.controls['floors'])"
          >
            –
          </button>
          <input
            [readonly]="!isEdit && !!productId"
            type="number"
            class="product-quantity"
            formControlName="floors"
            min="0"
            max="100"
            placeholder="Số tầng"
          />
          <button
            *ngIf="isEdit"
            type="button"
            class="plus"
            (click)="increment(formCommon.controls['floors'])"
          >
            +
          </button>
        </div>
      </div>
    </div>

    <div class="row mt-2 form-group" required>
      <div class="col-md-4">
        <label class="form-label">Mặt tiền</label>
      </div>
      <div class="col-md-8">
        <div class="input-group">
          <input
            [readonly]="!isEdit && !!productId"
            type="number"
            formControlName="frontWidth"
            class="form-control"
            placeholder="Mặt tiền"
          />
          <span class="input-group-text bg-soft-light text-muted prefix-width"
            >(m)</span
          >
        </div>
        <small
          *ngIf="
            controls['frontWidth'].touched && controls['frontWidth']?.errors
          "
          class="text-danger"
          >Trường thông tin bắt buộc</small
        >
      </div>
    </div>

    <div class="row my-2 form-group" required>
      <div class="col-md-4">
        <label class="form-label">Trạng thái</label>
      </div>

      <div class="col-md-8">
        <ng-select
          [readonly]="!isEdit && !!productId"
          formControlName="status"
          [items]="REAL_ESTATE_STATUS"
          bindValue="key"
          bindLabel="label"
          placeholder="Chọn trạng thái BĐS..."
        ></ng-select>
        <small
          *ngIf="controls['status'].touched && controls['status']?.errors"
          class="text-danger"
          >Trường thông tin bắt buộc</small
        >
      </div>
    </div>
  </div>
</div>
