<div class="modal-header p-3">
  <h5 class="modal-title" id="composemodalTitle">Gửi thông báo</h5>
  <button type="button" class="btn-close" (click)="handleCloseModal()"></button>
</div>
<div class="px-3">
  <mat-tab-group
    (selectedTabChange)="selectedTabChange($event)"
    [selectedIndex]="tab"
  >
    <mat-tab label="Theo agency"></mat-tab>
    <mat-tab label="Theo khu vực"></mat-tab>
  </mat-tab-group>
</div>
<div class="modal-body">
  <!-- GỬI THÔNG BÁO THEO AGENCY  -->
  <ng-container *ngIf="tab === 0">
    <app-agency-notification></app-agency-notification>
  </ng-container>

  <!-- GỬI THÔNG BÁO THEO KHU VỰC  -->
  <ng-container *ngIf="tab === 1">
    <app-area-notification></app-area-notification>
  </ng-container>
</div>
