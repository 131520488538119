import { Pipe, PipeTransform } from "@angular/core";
import { LAND_STATUS } from "src/app/public/constants/real-estate.constant";

@Pipe({
  name: "getShortStatus",
})
export class GetShortStatusPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): string {
    switch (value) {
      case LAND_STATUS.REJECTED:
        return "Từ chối";
      case LAND_STATUS.NEW:
        return "Chờ duyệt";
      case LAND_STATUS.APPROVED_DATA:
        return "Đã duyệt";
      case LAND_STATUS.STOP_SELL:
        return "Ngừng giao dịch";
      case LAND_STATUS.SOLD:
        return "Đã giao dịch";
      case LAND_STATUS.NEGOTIATE:
        return "Đang đàm phán";
      case LAND_STATUS.DEPOSITED:
        return "Đặt cọc";
      case LAND_STATUS.EVALUATING:
        return "Chấm điểm";
      case LAND_STATUS.PRICE_DEAL:
        return "Thuyết phục phí";
      case LAND_STATUS.DRAFT:
        return "Bản nháp";
      case LAND_STATUS.APPROVED_SELL:
        return "Mở bán";
      default:
        return "Đăng nhanh";
    }
  }
}
