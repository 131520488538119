import { Observable } from "rxjs";
import { CategoryService } from "src/app/public/services/categories/category.service";
import { tap } from "rxjs/operators";

export function getCodeTypeOfRealEstate(
  realEstateType: string,
  categoryService: CategoryService
) {
  return new Observable((observable) => {
    if (realEstateType) {
      categoryService
        .getAllType()
        .pipe(
          tap((types) => {
            const result = types.find((t) => {
              return (
                t.cValue.toLowerCase() === realEstateType.trim().toLowerCase()
              );
            });
            observable.next(result?.cKey);
          })
        )
        .subscribe((_) => {});
    } else {
      observable.next(realEstateType);
    }
  });
}
