import { LAND_PURPOSE } from "src/app/public/constants/common.constant";
import { LatLngBounds } from "@agm/core/services/google-maps-types";
import { RealEstateModel } from "src/app/public/models/real-estate.model";

export class MapAbstract {
  public GESTURE_HANDLING: any = "greedy";
  public MAP_STYLES: any = [
    {
      featureType: "all",
      elementType: "labels.text.stroke",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "all",
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }],
    },
  ];

  public POSITION_DEFAULT = {
    lat: 21.0277644,
    lng: 105.8341598,
  };

  public LAND_PURPOSE = LAND_PURPOSE;

  public MAP_CLUSTER_STYLE = [
    {
      url: "./assets/images/icons/circle-small.svg",
      height: 28,
      width: 24,
      textColor: "white",
      textSize: 12,
      backgroundPosition: "center",
    },
    {
      url: "./assets/images/icons/circle-medium.svg",
      height: 35,
      width: 35,
      textColor: "white",
      textSize: 12,
      backgroundPosition: "center",
    },
    {
      url: "./assets/images/icons/circle-big.svg",
      height: 50,
      width: 50,
      textColor: "white",
      textSize: 12,
      backgroundPosition: "center",
    },
    {
      url: "./assets/images/icons/circle-bigest.svg",
      height: 75,
      width: 75,
      textColor: "white",
      textSize: 12,
      backgroundPosition: "center",
    },
  ];

  public MAP_OPTION = {
    calculator: (markers: string | any[]) => {
      let index = 1;
      if (markers.length > 1000) {
        index = 4;
      } else if (markers.length > 100) {
        index = 3;
      } else if (markers.length > 1) {
        index = 2;
      }

      // @ts-ignore
      const length = markers.length > 1 ? markers.length : "";
      const text: string =
        '<div style="display: inline-block;">' + length + "</div>";
      return {
        text: text,
        index: index,
      };
      // index: 1 -> for green icon
      // index: 2 -> for red icon
    },
  };

  getDistance(bounds: LatLngBounds): number {
    if (!bounds) return 0;

    const p1 = bounds.getSouthWest(); // điểm hướng bắc.
    const p2 = bounds.getCenter(); // điểm trung tâm.

    const R = 6378137; // Earth’s mean radius in meter
    const dLat = this.rad(p2.lat() - p1.lat());
    const dLong = this.rad(p2.lng() - p1.lng());
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.rad(p1.lat())) *
        Math.cos(this.rad(p2.lat())) *
        Math.sin(dLong / 2) *
        Math.sin(dLong / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return Math.round(d / 1000); // returns the distance in meter
  }

  rad(x: number) {
    return (x * Math.PI) / 180;
  }

  transformDataForMaker(data: RealEstateModel) {
    const {
      photos,
      price,
      address,
      purpose,
      id,
      toilets,
      bedrooms,
      acreage,
      frontWidth,
    } = data;
    return {
      photos,
      latitude: address?.latitude || null,
      longitude: address?.longitude || null,
      purpose,
      id,
      toilets,
      bedrooms,
      acreage,
      frontWidth,
      price,
      address: address.houseNbr + " " + address.street,
    };
  }

  getBoundsZoomLevel(bounds: any, mapDim: any, height: number, width: number) {
    const WORLD_DIM = {
      height,
      width
    };
    const ZOOM_MAX = 21;

    function latRad(lat: any) {
      const sin = Math.sin((lat * Math.PI) / 180);
      const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
      return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    }

    function zoom(mapPx: number, worldPx: number, fraction: number) {
      return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
    }

    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();
    const latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;
    const lngDiff = ne.lng() - sw.lng();
    const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

    const latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
    const lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

    return Math.min(latZoom, lngZoom, ZOOM_MAX);
  }

}
