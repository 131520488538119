import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "pricePerSquareMeter",
})
export class PricePerSquareMeterPipe implements PipeTransform {
  transform(price: number, acreage: number): number {
    if (!price || !acreage || acreage === 0) {
      return 0;
    }
    return +(price / acreage).toFixed();
  }
}
