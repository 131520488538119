import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { catchError, tap } from "rxjs/operators";
import { NotificationModel } from "src/app/public/models/notification.model";
import { NotificationService } from "src/app/public/services/messages/notification.service";
import { ToastrService } from "ngx-toastr";
import { Observable, Subject, throwError } from "rxjs";
import { UserService } from "src/app/public/services/user/user.service";
import { AgentModel } from "src/app/public/models/agent.model";
import { UserModel } from "src/app/public/models/user.model";

@Component({
  selector: "app-agency-notification",
  templateUrl: "./agency-notification.component.html",
})
export class AgencyNotificationComponent implements OnInit {
  public readonly SIZE_DEFAULT = 200;
  public listAgents$ = new Subject<any>();
  public eventGetAllData$ = new Subject<boolean>();
  public notificationForm: UntypedFormGroup;

  public type: string;
  public page = 1;
  public listResults: AgentModel[] = [];
  public phones: string[] = [];

  constructor(
    private modalService: NgbModal,
    private fb: UntypedFormBuilder,
    private notificationService: NotificationService,
    private toastr: ToastrService,
    private userService: UserService
  ) {
    this.obseverEventGetAllData();
  }

  ngOnInit(): void {
    this.notificationForm = this.fb.group({
      name: [""],
      notification: this.fb.group({
        title: ["", Validators.required],
        body: ["", Validators.required],
        image: [""],
      }),
      data: this.fb.group({}),
      tokens: this.fb.array([]),
      usernames: this.fb.array([]),
    });
  }

  public getControl(name: string): UntypedFormControl {
    return this.notificationForm.get(name) as UntypedFormControl;
  }

  public handleCloseModal() {
    this.modalService.dismissAll();
  }

  public handleOnChange(event: any) {
    this.type = event.value;
  }

  public onSubmitForm(data: NotificationModel): void {
    if (this.notificationForm.valid) {
      this.eventGetAllData$.next(true);
      this.listAgents$.subscribe((res) => {
        res.forEach((d: AgentModel) => {
          this.phones.push(d.phone);
        });

        const payload = {
          ...data,
          usernames: this.phones,
        };
        this.notificationService
          .sendNotification(payload)
          .pipe(
            tap(() => {
              this.toastr.success("Gửi thông báo thành công");
            }),
            catchError((error) => {
              this.toastr.error("Gửi thông báo thất bại");
              return throwError(error);
            })
          )
          .subscribe();
        this.handleCloseModal();
      });
    }
  }

  private obseverEventGetAllData() {
    const event$ = this.eventGetAllData$.asObservable();
    event$.subscribe((_) => {
      const res$ = this.getAgentList(this.page, this.SIZE_DEFAULT);
      res$.subscribe((res: any) => {
        const { totalItems, totalPages, data } = res;

        this.listResults = this.listResults.concat(data);

        const totalItemsCurrent = this.listResults.length;

        if (totalItemsCurrent < totalItems && this.page < totalPages) {
          this.page++;
          this.eventGetAllData$.next(true);
        } else {
          this.listAgents$.next(this.listResults);
        }
      });
    });
  }

  private getAgentList(page: number, limit: number): Observable<UserModel[]> {
    return this.userService.getListUserAgents({ page, limit });
  }
}
