import { AsyncPipe, DatePipe, NgFor, NgIf } from "@angular/common";
import { Component, inject, Input, OnDestroy, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { COLUMN_OF_CALL_HISTORY_ORDER } from "@constant/table.constant";
import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { InvisiblePhoneNumberPipe } from "@pipe/invisible-phone-number.pipe";
import { StateContactHistoryService } from "@service/call-history/state-contact-history.service";
import { combineLatest, Observable, Subject } from "rxjs";
import { startWith, takeUntil } from "rxjs/operators";
import { TableComponent } from "../../../tables/table/table.component";
import { SkeletonTableComponent } from "@component/animation/loadingSkeleton/skeleton-table.component";

@Component({
  selector: "call-history-order-list",
  templateUrl: "./call-history-order-list.component.html",
  styleUrls: ["./call-history-order-list.component.scss"],
  standalone: true,
  imports: [
    NgbTooltipModule,
    InvisiblePhoneNumberPipe,
    AsyncPipe,
    DatePipe,
    NgFor,
    NgIf,
    TableComponent,
    SkeletonTableComponent,
  ],
  providers: [StateContactHistoryService],
})
export class CallHistoryOrderListComponent implements OnInit, OnDestroy {
  public stateContactHistory = inject(StateContactHistoryService);
  public subscription = new Subject();
  readonly COLUMNS = COLUMN_OF_CALL_HISTORY_ORDER;

  @Input() callHistoryForm: FormGroup;

  public page$: Observable<number> = this.stateContactHistory.page$;
  public pageSize$: Observable<number> = this.stateContactHistory.pageSize$;
  public limit$: Observable<number> = this.stateContactHistory.limit$;
  public total$: Observable<number> = this.stateContactHistory.total$;

  ngOnInit(): void {
    this.loadContactHistories();
  }

  get controls() {
    return this.callHistoryForm.controls;
  }

  public loadContactHistories() {
    combineLatest([this.getChangeOfControl("order")])
      .pipe(takeUntil(this.subscription))
      .subscribe(([order]) => {
        if (!order) {
          this.stateContactHistory.historyCallOrders$.next([]);
          return;
        }
        this.stateContactHistory.historyCallOrder(order);
      });
  }

  private getChangeOfControl(control: string): Observable<any> {
    return this.controls[control].valueChanges.pipe(
      startWith(this.controls[control].value)
    );
  }

  public updatePage(page: number): void {
    this.stateContactHistory.historyCallOrder(
      this.controls["order"].value,
      page
    );
  }

  ngOnDestroy(): void {
    this.subscription.next(null);
    this.subscription.complete();
  }
}
