import { inject, Injectable } from "@angular/core";
import * as SockJS from "sockjs-client";
import { Client, Message } from "@stomp/stompjs";
import { BehaviorSubject, Observable } from "rxjs";
import { IPublishParams } from "@stomp/stompjs/src/types";
import {
  ACTION_TYPE,
  CallToCustomerModel,
  STATUS_CALL,
} from "../../models/call-to-customer.model";
import { SOCKET_URL } from "../../constants/api-url.constant";
import { StorageService } from "../storage/storage.service";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class CallCenterService {
  private storageService = inject(StorageService);

  // @ts-ignore
  get status(): Observable<STATUS_CALL> {
    return this._status$.asObservable();
  }

  set status(value: STATUS_CALL) {
    this._status$.next(value);
  }

  private _status$ = new BehaviorSubject<STATUS_CALL>(STATUS_CALL.WAITING);
  private sessionId: string = null;
  private client: Client;
  private ext: string = this.storageService.get("EXT");

  private messages: BehaviorSubject<string> = new BehaviorSubject<string>("");

  constructor() {
    // this.connect();
  }

  connect() {
    if (!this.ext) return;

    const params = new URLSearchParams({
      ext: this.ext,
    });

    const url: string = `${environment.apiURL}/${
      SOCKET_URL.CRM.CONNECT
    }?${params.toString()}`;
    this.client = new Client({
      // @ts-ignore
      webSocketFactory: () => new SockJS(url),
      reconnectDelay: 5000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
    });

    this.client.onConnect = (frame) => {
      this.sessionId = frame?.headers["user-name"];

      if (this.sessionId) {
        this.observerSession();
        this.getSession();
      }
    };

    this.client.onDisconnect = (frame) => {
      console.log("Disconnected from WebSocket");
    };

    this.client.activate(); // Kích hoạt kết nối
  }

  public callToCustomer(phone: string) {
    const body: CallToCustomerModel = {
      type: ACTION_TYPE.REQUEST,
      action: "PHONE_CALL",
      status: "WAITING",
      requestUser: this.sessionId,
      from: "",
      to: phone,
      ext: this.ext,
    };
    const params: IPublishParams = {
      destination: `${SOCKET_URL.CRM.GET_CALL}/${this.sessionId}`,
      body: JSON.stringify(body),
    };
    this.client.publish(params);
  }

  private observerSession() {
    if (this.client.connected) {
      this.client.subscribe(
        `${SOCKET_URL.CRM.OBSERVER_SESSION}`,
        (message: Message) => {
          this.messages.next(message.body);
          this.observerCallResult(message.body);
        }
      );
    } else {
      setTimeout(() => {
        this.observerSession();
      }, 5000);
      console.warn(
        "Không thể gửi yêu cầu getSession. WebSocket chưa được kết nối."
      );
    }
  }

  private observerCallResult(requestUser: string) {
    this.client.subscribe(
      `${SOCKET_URL.CRM.OBSERVER_CALL}/${requestUser}`,
      (message: Message) => {
        console.log("Phone Call" + message.body);
        this.messages.next(message.body);
      }
    );
  }

  private getSession() {
    if (this.client.connected) {
      const params: IPublishParams = {
        destination: `${SOCKET_URL.CRM.GET_SESSION}`,
      };
      this.client.publish(params);
    } else {
      setTimeout(() => {
        this.getSession();
      }, 1000);
      console.warn(
        "Không thể gửi yêu cầu getSession. WebSocket chưa được kết nối."
      );
    }
  }
}
