import { Injectable, OnDestroy } from "@angular/core";
import { UserService } from "src/app/public/services/user/user.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
  PermissionModel,
  PermissionResourceModel,
} from "src/app/public/models/permission";
import { UserModel } from "src/app/public/models/user.model";
import { APP_ACCOUNT_TYPE } from "src/app/public/constants/user.constant";

@Injectable({
  providedIn: "root",
})
export class AuthorizationService implements OnDestroy {
  private permissions: PermissionModel[];
  private resources: PermissionResourceModel[] = [];
  private services: string[];
  private subject = new Subject();
  private userInfo: UserModel;

  constructor(private userService: UserService) {
    this.getResourceOfUser();
  }

  hasPermissionAction(resourceName: string, actionName: string): boolean {
    if (this.userInfo.type === APP_ACCOUNT_TYPE.SUPER_ADMIN) return true;
    return !!this.resources.find((resource: PermissionResourceModel) => {
      if (
        resource.key.toLowerCase().trim() ===
          resourceName.toLowerCase().trim() ||
        resourceName.includes("*")
      ) {
        return resource.actions.some(
          (r) =>
            r.toLowerCase().includes("*") ||
            r.toLowerCase().includes(actionName.toLowerCase().trim())
        );
      }
      return false;
    });
  }

  hasPermissionResource(resource: string): boolean {
    if (this.userInfo.type === APP_ACCOUNT_TYPE.SUPER_ADMIN || resource === "*")
      return true;

    if (resource === undefined || resource === null) return false;
    return !!this.resources.find((rs) => {
      return (
        rs.key.includes("*") ||
        resource.toLowerCase().trim() == rs.key.toLowerCase().trim()
      );
    })?.key;
  }

  hasPermissionService(serviceName: string): boolean {
    if (
      this.userInfo.type === APP_ACCOUNT_TYPE.SUPER_ADMIN ||
      this.services.includes("*") ||
      serviceName === "*"
    ) {
      return true;
    } else if (serviceName === undefined || serviceName === null) {
      return false;
    } else {
      return (
        this.services.includes(serviceName.toLowerCase()) ||
        this.resources.some(
          (rs) =>
            rs.key.toLowerCase().trim() === serviceName.toLowerCase().trim()
        )
      );
    }
  }

  isSuperAdmin(): boolean {
    return this.userInfo.type === APP_ACCOUNT_TYPE.SUPER_ADMIN;
  }

  getCurrentUserLogin(): UserModel {
    return this.userInfo;
  }

  ngOnDestroy(): void {
    this.subject.next();
    this.subject.complete();
  }

  private getResourceOfUser() {
    this.userService.user$.pipe(takeUntil(this.subject)).subscribe((data) => {
      this.userInfo = data;
      this.permissions = data?.policies;
      this.services = this.permissions?.map((permission) => permission.service);
      this.permissions?.forEach((permission) => {
        this.resources = this.resources?.concat(permission.resources);
      });
    });
  }
}
