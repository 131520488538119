import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "[imgPreview]",
})
export class ImagePreviewDirective implements OnChanges {
  @Input() imgPreview: any;

  constructor(private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    let reader = new FileReader();
    let el = this.el;

    reader.onloadend = (e) => {
      el.nativeElement.src = reader.result;
    };

    if (this.imgPreview) {
      return reader.readAsDataURL(this.imgPreview);
    }
  }
}
