import { Component } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-send-notification-modal",
  templateUrl: "./send-notification-modal.component.html",
})
export class SendNotificationModalComponent {
  public tab: number = 0;

  constructor(private modalService: NgbModal) {}

  public selectedTabChange(event: any) {
    this.tab = event.index;
  }

  public handleCloseModal() {
    this.modalService.dismissAll();
  }
}
