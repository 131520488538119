<div class="input-search-container">
  <input
    autocomplete="off"
    id="searchInput"
    [formControl]="searchTextControl"
    class="form-control"
    placeholder="Tìm kiếm theo địa chỉ"
    (keydown.enter)="onSelectedSuggestion()"
    (focus)="handleFocusInputElement()"
    (focusout)="handleFocusOut()"
    #suggestionSearch
  />
  <div
    *ngIf="isShowSuggestion && isFocus"
    class="search-dropdown-list h-100 bg-white"
    [ngClass]="suggestionRelative ? 'position-relative' : 'position-absolute'"
  >
    <ng-container *ngIf="addressSuggestions$ | async as addressSuggestions">
      <ng-container *ngIf="addressSuggestions.length">
        <div class="text-muted header-block">Khu vực đề xuất</div>
        <div
          *ngFor="let item of addressSuggestions; index as i"
          class="search-dropdown-item"
          (mousedown)="onSelectedSuggestion(item)"
          [ngbTooltip]="item.description"
          [class.text-truncate]="suggestionRelative"
        >
          <span>
            <i class="fs-20 text-info-bold align-middle ri-map-pin-2-line"></i>
            {{ item.description }}
          </span>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="realEstateSuggestion$ | async as realEstates">
      <ng-container *ngIf="realEstates.length">
        <div class="text-muted header-block">BĐS đề xuất</div>
        <div
          *ngFor="let item of realEstates"
          class="search-dropdown-item"
          (mousedown)="onClickToOpenDetail(item.id)"
          [ngbTooltip]="item.address?.fullAddress"
          [class.text-truncate]="suggestionRelative"
        >
          <span>
            <i class="fs-20 text-info-bold align-middle ri-building-line"></i>
            {{ item.address?.fullAddress || item }}
          </span>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <button
    class="clear-search-text position-absolute fs-20 border-0"
    (click)="onClickClearSearch()"
    *ngIf="searchTextControl.value"
  >
    <i class="ri-close-line"></i>
  </button>
</div>

<ng-container *ngIf="showOverlay && hasOverlay">
  <div class="overlay" [@fadeInOut]></div>
</ng-container>
