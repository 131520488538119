import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatRadioModule } from "@angular/material/radio";
import { MatTabsModule } from "@angular/material/tabs";
import {
  NgbAccordionModule,
  NgbCollapseModule,
  NgbDropdownModule,
  NgbNavModule,
  NgbPaginationModule,
  NgbPopoverModule,
  NgbToastModule,
  NgbTooltipModule,
  NgbTypeaheadModule,
} from "@ng-bootstrap/ng-bootstrap";
import { HLDirectivesModule } from "./../public/directives/directives.module";

// Swiper Slider

// Counter
import { AgmCoreModule } from "@agm/core";
import { AgmJsMarkerClustererModule } from "@agm/js-marker-clusterer";
import { OverlayModule } from "@angular/cdk/overlay";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MAT_DATE_LOCALE, MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { RouterModule } from "@angular/router";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { NgSelectModule } from "@ng-select/ng-select";
import { AgGridModule } from "ag-grid-angular";
import { CountToModule } from "angular-count-to";
import { FlatpickrModule } from "angularx-flatpickr";
import { SkeletonTableComponent } from "src/app/components/animation/loadingSkeleton/skeleton-table.component";
import { ToastsContainerComponent } from "src/app/components/notifications/toasts-container.component";
import { QuickSearchModalComponent } from "src/app/components/quick-search-modal/quick-search-modal.component";
import { GetThumbnailImagePipe } from "src/app/public/pipes/get-thumbnail-image.pipe";
import { PipesModule } from "src/app/public/pipes/pipes.module";
import { SwiperModule } from "swiper/angular";
import { CopyRealEstateComponent } from "../pages/real-estate-management/components/copy-real-estate/copy-real-estate.component";
import { FormCommonInfoComponent } from "../pages/real-estate-management/widgets/form-common-info/form-common-info.component";
import { FormDetailInfoComponent } from "../pages/real-estate-management/widgets/form-detail-info/form-detail-info.component";
import { FormInternalInfoComponent } from "../pages/real-estate-management/widgets/form-internal-info/form-internal-info.component";
import { FormOtherInfoComponent } from "../pages/real-estate-management/widgets/form-other-info/form-other-info.component";
import { FormRealEstateInfoComponent } from "../pages/real-estate-management/widgets/form-real-estate-info/form-real-estate-info.component";
import { MapSelectLocationComponent } from "../pages/real-estate-management/widgets/map-select-location/map-select-location.component";
import { SliderImageDetailComponent } from "../pages/real-estate-management/widgets/slider-image-detail/slider-image-detail.component";
import { UserService } from "../public/services/user/user.service";
import { CircleLoadingComponent } from "./animation/circle-loading/circle-loading.component";
import { BreadcrumbsComponent } from "./breadcrumbs/breadcrumbs.component";
import { CardRealEstateComponent } from "./card-real-estate/card-real-estate.component";
import { DropdownWrapperComponent } from "./filter/dropdown-wrapper/dropdown-wrapper.component";
import { SearchFilterAgentComponent } from "./filter/search-filter-agent/search-filter-agent.component";
import { FilterDateRangeComponent } from "./filter/search-filter-bar/filter-date-range/filter-date-range.component";
import { FilterPurposeMapComponent } from "./filter/search-filter-bar/filter-purpose-map/filter-purpose-map.component";
import { FilterPurposeComponent } from "./filter/search-filter-bar/filter-purpose/filter-purpose.component";
import { FilterRangeComponent } from "./filter/search-filter-bar/filter-range/filter-range.component";
import { RealEstateFilterStateComponent } from "./filter/search-filter-bar/filter-state/real-estate-filter-state.component";
import { FilterStatusComponent } from "./filter/search-filter-bar/filter-status/filter-status.component";
import { FilterTypeComponent } from "./filter/search-filter-bar/filter-type/filter-type.component";
import { FilterAddressComponent } from "./filter/search-filter-bar/full-search/filter-address/filter-address.component";
import { FilterCommonInfoComponent } from "./filter/search-filter-bar/full-search/filter-common-info/filter-common-info.component";
import { FilterDetailInfoComponent } from "./filter/search-filter-bar/full-search/filter-detail-info/filter-detail-info.component";
import { FilterImportantInfoComponent } from "./filter/search-filter-bar/full-search/filter-important-info/filter-important-info.component";
import { FilterInternalComponent } from "./filter/search-filter-bar/full-search/filter-internal/filter-internal.component";
import { FullSearchComponent } from "./filter/search-filter-bar/full-search/full-search.component";
import { SearchFilterBarComponent } from "./filter/search-filter-bar/search-filter-bar.component";
import { SearchFilterCustomerComponent } from "./filter/search-filter-customer/search-filter-customer.component";
import { SearchFilterEmployeeComponent } from "./filter/search-filter-employee/search-filter-employee.component";
import { SearchFilterHistorySubscribeComponent } from "./filter/search-filter-history-subscribe/search-filter-history-subscribe.component";
import { SearchFilterMapComponent } from "./filter/search-filter-map/search-filter-map.component";
import { SearchFilterNotificationComponent } from "./filter/search-filter-notification/search-filter-notification.component";
import { SearchFilterRealEstateAuditComponent } from "./filter/search-filter-real-estate-audit/search-filter-real-estate-audit.component";
import { MapOfRealEstateDetailComponent } from "./map-of-real-estate-detail/map-of-real-estate-detail.component";
import { MapRealEstateComponent } from "./map-real-estate/map-real-estate.component";
import { NotificationsComponent } from "./notifications/notifications.component";
import { RealEstateDetailOverviewComponent } from "./real-estate-detail-overview/real-estate-detail-overview.component";
import { RealEstateDetailTabsComponent } from "./real-estate-detail-tabs/real-estate-detail-tabs.component";
import { SearchSuggestionComponent } from "./search-suggestion/search-suggestion.component";
import { SendEmailModalComponent } from "./send-email-modal/send-email-modal.component";
import { AgencyNotificationComponent } from "./send-notification-modal/agency-notification/agency-notification.component";
import { AreaNotificationComponent } from "./send-notification-modal/area-notification/area-notification.component";
import { SendNotificationModalComponent } from "./send-notification-modal/send-notification-modal.component";
import { TableReastateComponent } from "./tables/realstate/table-reastate.component";
import { TableSuppliersComponent } from "./tables/suppliers/table-suppliers.component";
import { TableComponent } from "./tables/table/table.component";
import { PriceUnitComponent } from "./price-unit/price-unit.component";

@NgModule({
  declarations: [
    BreadcrumbsComponent,
    TableReastateComponent,
    TableSuppliersComponent,
    SearchFilterBarComponent,
    FilterPurposeComponent,
    FilterPurposeMapComponent,
    FilterRangeComponent,
    FilterTypeComponent,
    FullSearchComponent,
    SkeletonTableComponent,
    ToastsContainerComponent,
    TableComponent,
    SearchFilterCustomerComponent,
    SearchFilterAgentComponent,
    FilterStatusComponent,
    MapRealEstateComponent,
    QuickSearchModalComponent,
    SearchSuggestionComponent,
    MapOfRealEstateDetailComponent,
    RealEstateDetailOverviewComponent,
    FormCommonInfoComponent,
    FormOtherInfoComponent,
    FormDetailInfoComponent,
    FormInternalInfoComponent,
    SliderImageDetailComponent,
    FormRealEstateInfoComponent,
    MapSelectLocationComponent,
    CircleLoadingComponent,
    SendEmailModalComponent,
    RealEstateDetailTabsComponent,
    NotificationsComponent,
    SearchFilterNotificationComponent,
    SendNotificationModalComponent,
    AgencyNotificationComponent,
    AreaNotificationComponent,
    CopyRealEstateComponent,
    CardRealEstateComponent,
    SearchFilterMapComponent,
    SearchFilterHistorySubscribeComponent,
    FilterDateRangeComponent,
    FilterAddressComponent,
    FilterImportantInfoComponent,
    FilterDetailInfoComponent,
    FilterCommonInfoComponent,
    FilterInternalComponent,
    SearchFilterEmployeeComponent,
    SearchFilterRealEstateAuditComponent,
    RealEstateFilterStateComponent,
    DropdownWrapperComponent,
    FilterInternalComponent,
    FilterCommonInfoComponent,
    FilterImportantInfoComponent,
    FilterAddressComponent,
    FilterDetailInfoComponent,
    PriceUnitComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    NgbNavModule,
    NgbAccordionModule,
    NgbDropdownModule,
    SwiperModule,
    CountToModule,
    FormsModule,
    NgbTypeaheadModule,
    NgbPaginationModule,
    FlatpickrModule,
    ReactiveFormsModule,
    AgGridModule,
    PipesModule,
    NgbTooltipModule,
    NgbPopoverModule,
    HLDirectivesModule,
    NgSelectModule,
    OverlayModule,
    MatInputModule,
    NgbToastModule,
    NgbCollapseModule,
    AgmCoreModule,
    AgmJsMarkerClustererModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatDividerModule,
    MatIconModule,
    MatTabsModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    CKEditorModule,
    GetThumbnailImagePipe,
  ],
  exports: [
    BreadcrumbsComponent,
    TableReastateComponent,
    TableSuppliersComponent,
    SearchFilterBarComponent,
    ToastsContainerComponent,
    SkeletonTableComponent,
    TableComponent,
    SearchFilterCustomerComponent,
    MapRealEstateComponent,
    QuickSearchModalComponent,
    SearchSuggestionComponent,
    FormCommonInfoComponent,
    FormOtherInfoComponent,
    FormDetailInfoComponent,
    FormInternalInfoComponent,
    SearchFilterAgentComponent,
    SliderImageDetailComponent,
    FormRealEstateInfoComponent,
    MapSelectLocationComponent,
    CircleLoadingComponent,
    AgmCoreModule,
    SendEmailModalComponent,
    SearchFilterNotificationComponent,
    SendNotificationModalComponent,
    AgencyNotificationComponent,
    AreaNotificationComponent,
    CopyRealEstateComponent,
    CardRealEstateComponent,
    SearchFilterMapComponent,
    FilterRangeComponent,
    FilterPurposeComponent,
    FilterTypeComponent,
    FilterStatusComponent,
    SearchFilterHistorySubscribeComponent,
    FilterDateRangeComponent,
    FilterAddressComponent,
    FilterImportantInfoComponent,
    FilterDetailInfoComponent,
    FilterCommonInfoComponent,
    FilterInternalComponent,
    SearchFilterEmployeeComponent,
    SearchFilterRealEstateAuditComponent,
    RealEstateFilterStateComponent,
    FilterInternalComponent,
    FilterCommonInfoComponent,
    FilterImportantInfoComponent,
    FilterAddressComponent,
    FilterDetailInfoComponent,
    PriceUnitComponent,
  ],
  providers: [
    UserService,
    {
      provide: MAT_DATE_LOCALE,
      useValue: "vi-VN",
    },
  ],
})
export class HlSharedModule {}
