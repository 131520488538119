<div class="border border-dashed p-1 h-100">
  <form [formGroup]="formData" (ngSubmit)="submitForm()">
    <legend>
      <div class="d-flex align-items-center justify-content-between">
        <span class="badge badge-soft-primary fs-14"
          ><i class="mdi mdi-circle-medium"></i>Chủ nhà</span
        >

        <button
          [attr.disabled]="getValueControl('realEstateId') ? null : true"
          type="button"
          class="btn btn-success btn-sm"
          ngbTooltip="Thêm thông tin chủ nhà"
        >
          <a
            [class.disabled-link]="!getValueControl('realEstateId')"
            class="text-white"
            [routerLink]="[
              '/real-estate-management/detail',
              getValueControl('realEstateId')
            ]"
            target="_blank"
          >
            <i class="mdi mdi-account-multiple-plus"></i>
          </a>
        </button>
      </div>
    </legend>

    <section class="row">
      <div class="d-flex gap-1">
        <div class="pb-2 col-6">
          <label class="form-label">Quý danh</label>
          <ng-select
            [items]="ALIAS_PREFIX"
            bindValue="key"
            [clearable]="false"
            formControlName="alias"
            bindLabel="value"
            [placeholder]="'Quý danh'"
          >
          </ng-select>
        </div>

        <div class="pb-2 col-6">
          <label class="form-label">Nguồn</label>
          <ng-select
            [items]="ORIGIN_PROVIDE"
            bindValue="key"
            [clearable]="false"
            formControlName="origin"
            bindLabel="label"
            placeholder="Nguồn"
            [class.disabled]="true"
          >
          </ng-select>
        </div>
      </div>

      <div class="pb-2 form-group">
        <label class="form-label">Họ và tên</label>
        <div class="input-group">
          <input
            class="form-control"
            formControlName="fullName"
            [readonly]="true"
          />
          <button
            disabled
            class="btn btn-outline-primary"
            ngbTooltip="Chức năng tạm khóa"
          >
            <i class="mdi mdi-account-plus-outline"></i>
          </button>
        </div>
      </div>

      <div class="pb-2">
        <label class="form-label">Số điện thoại</label>
        <ng-container formArrayName="phone">
          <ng-container *ngFor="let _ of getFormArray('phone'); index as i">
            <div class="input-group py-1">
              <input
                class="form-control"
                invisiblePhoneNumber
                [formControlName]="i"
                [readonly]="true"
              />
              <a
                [class.disabled-link]="!getValueControl('realEstateId')"
                class="btn btn-outline-primary"
                type="button"
                ngbTooltip="Thêm mới SĐT"
                target="_blank"
                [routerLink]="[
                  '/real-estate-management/detail',
                  getValueControl('realEstateId')
                ]"
              >
                <i class="mdi mdi-plus"></i>
              </a>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </section>
  </form>
</div>
