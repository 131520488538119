import { inject, Injectable, Injector } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConvertDateTimeToString } from "@util/date-time.util";

@Injectable()
export class CallHistoryOrderFormHelper {
  private readonly formGroup: FormGroup;
  private fb = inject(FormBuilder);
  private inject = inject(Injector);

  constructor() {
    this.formGroup = this.initForm();
  }

  get form() {
    return this.formGroup as FormGroup;
  }

  set patchData(data: any) {
    this.formGroup.patchValue({
      phoneNumber: data?.phoneNumber,
      customerId: data?.customerId,
      caller: data?.caller,
      brand: data?.brand,
      order: data?.order,
      title: `Gọi tới ${data?.fullName}`,
      phone: data?.phoneNumber,
      fullName: data?.fullName,
      refId: data?.refId,
    });
  }

  // @ts-ignore
  private initForm(): FormGroup {
    return this.fb.group({
      customerId: ["", [Validators.required]],
      title: ["", [Validators.required]],
      content: ["", [Validators.required]],
      method: [1, [Validators.required]],
      statusCall: [null, [Validators.required]],
      caller: ["", [Validators.required]],
      phoneNumber: ["", [Validators.required]],
      record: ["https://google.com", [Validators.required]],
      startTime: [
        ConvertDateTimeToString(new Date(), this.inject),
        [Validators.required],
      ],
      endTime: ["", [Validators.required]],
      order: [null],
      brand: [null],
      phone: ["", Validators.required],
      fullName: ["", Validators.required],
      origin: [null],
      alias: [1],
      refId: [null],
    });
  }
}
