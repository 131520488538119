import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "getSystemColor",
})
export class GetSystemColorPipe implements PipeTransform {
  transform(value: string | number): string {
    switch (value) {
      case "ADMIN":
        return "danger";
      case "CLIENT":
        return "success";
      case "AGENCY":
        return "info";
      default:
        return "";
    }
  }
}
