import { Component, Input } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-confirm-modal",
  templateUrl: "./confirm-modal.component.html",
  styleUrls: ["./confirm-modal.component.scss"],
})
export class ConfirmModalComponent {
  @Input() title: string;
  @Input() content: string;
  @Input() hasClose: boolean;

  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal
  ) {}

  confirmModal(confirm?: boolean): void {
    this.activeModal.close(confirm);
  }

  StaticModal(StaticDataModal: any) {
    this.modalService.open(StaticDataModal, { centered: true });
  }

  closeModal() {
    this.activeModal.close(false);
  }
}
