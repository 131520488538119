import { Pipe, PipeTransform } from "@angular/core";
import { LAND_STATUS } from "src/app/public/constants/real-estate.constant";

@Pipe({
  name: "getShortStatusColor",
})
export class GetShortStatusColorPipe implements PipeTransform {
  transform(value: string | number): string {
    switch (value) {
      case LAND_STATUS.REJECTED:
        return "dark";
      case LAND_STATUS.NEW:
        return "warning";
      case LAND_STATUS.DRAFT:
        return "secondary";
      case LAND_STATUS.RAW_DATA:
        return "danger";
      default:
        return "success";
    }
  }
}
