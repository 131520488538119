import {
  Component,
  ElementRef,
  Inject,
  Optional,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { S3UploadService } from "src/app/public/services/aws/s3-upload.service";

// Swiper Slider
import {
  AbstractControl,
  FormControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  BehaviorSubject,
  combineLatest,
  fromEvent,
  Observable,
  of,
  throwError,
} from "rxjs";
import {
  catchError,
  map,
  pairwise,
  switchMap,
  take,
  tap,
} from "rxjs/operators";
import { ToastService } from "src/app/components/notifications/toast-service";
import { ConfirmModalComponent } from "src/app/pages/real-estate-management/widgets/confirm-modal/confirm-modal.component";
import { FileImageUploadsComponent } from "src/app/pages/real-estate-management/widgets/file-image-uploads/file-image-uploads.component";
import { BC_REAL_ESTATE_DETAIL } from "src/app/public/constants/bread-crums";
import { PERMISSION_ACTIONS } from "src/app/public/constants/permission.contants";
import { LAND_STATUS } from "src/app/public/constants/real-estate.constant";
import { removeVietnameseTones } from "src/app/public/decorators/helper.decorator";
import { AddressModel } from "src/app/public/models/address.model";
import { CustomerModel } from "src/app/public/models/customer.model";
import {
  RealEstateModel,
  RealEstatePhotoModel,
} from "src/app/public/models/real-estate.model";
import { AddressRealEstateService } from "src/app/public/services/address/address-real-estate.service";
import { AuthorizationService } from "src/app/public/services/auth/authorization.service";
import { CategoryService } from "src/app/public/services/categories/category.service";
import { RealEstateService } from "src/app/public/services/real-estate/real-estate.service";
import { UserService } from "src/app/public/services/user/user.service";
import {
  APPROVE_TYPE,
  GetApproveType,
} from "src/app/public/utils/approve-type";
import { maskPhoneNumber } from "src/app/public/utils/hidden-phone-number";
import { environment } from "src/environments/environment";
import { CopyRealEstateComponent } from "../components/copy-real-estate/copy-real-estate.component";
import { SliderImageDetailOriginalComponent } from "../widgets/slider-image-detail-original/slider-image-detail-original.component";
import { BOOLEAN_OPTIONS } from "src/app/public/constants/common.constant";
import {
  convertPriceUnit,
  convertPriceUnitReverse,
} from "src/app/public/utils/convert-price-unit.util";

@Component({
  selector: "app-product-detail",
  templateUrl: "./real-estate-detail.component.html",
  styleUrls: ["./real-estate-detail.component.scss"],
})
export class RealEstateDetailComponent {
  // constants
  public BREAD_CRUMB_ITEMS: Array<{}> = BC_REAL_ESTATE_DETAIL;
  readonly PERMISSION_ACTIONS = PERMISSION_ACTIONS;
  readonly CONFIRM_TEXT = "Bạn có chắc chắn với thay đổi?";
  readonly CONFIRM_TEXT_REJECT = "Bạn có chắc chắn huỷ các thay đổi?";
  readonly LAND_STATUS = LAND_STATUS;
  readonly HANDLER_HOST = environment.handleHost;

  public formRealEstate: UntypedFormGroup;
  public isEdit: boolean = false;
  public id: string;
  public productDetail$ = new BehaviorSubject<RealEstateModel>(null);
  public photosRealEstate: RealEstatePhotoModel[] = [];
  public statusRealEstate$: Observable<any>;
  public isEditRealEstate$ = new BehaviorSubject<Boolean>(false);
  public isEditStatusRealEstate$ = new BehaviorSubject<Boolean>(false);
  public unitForm = new FormControl("Triệu");

  public realEstateStorage: RealEstateModel;
  public photoUploads: Map<Number, File | Blob | any> = new Map();
  public activeTab = "general";

  @ViewChild("inputFileEdit") inputFileEdit: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    public toastService: ToastService,
    private realEstateService: RealEstateService,
    private fb: UntypedFormBuilder,
    private categoryService: CategoryService,
    private s3UploadService: S3UploadService,
    private addressRealEstateService: AddressRealEstateService,
    private userService: UserService,
    public authorizationService: AuthorizationService,
    public dialogService: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogInputData: any
  ) {
    this.initForm();
    this.getStatusRealEstate();
    this.onChangeStatus();
    this.subscribeParamChange();
    this.getPermissionEdit();
    this.addParamsTab();
  }

  public onTabChange(): void {
    this.addParamsTab();
  }

  private addParamsTab() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { tab: this.activeTab },
      queryParamsHandling: "merge",
      replaceUrl: true,
    });
  }

  public getPermissionEdit() {
    const hasPermission = this.authorizationService.hasPermissionAction(
      "*",
      this.PERMISSION_ACTIONS.UPDATE
    );
    this.isEditRealEstate$.next(hasPermission);
  }

  /**
   * @function: Open modal to show  images
   * @param event
   * @param photos
   */
  public onOpenModalSlider(event: any, photos: any[]): void {
    const swiper = event[0];

    if (swiper) {
      const content = this.modalService.open(
        SliderImageDetailOriginalComponent,
        {
          centered: true,
          fullscreen: true,
          backdrop: true,
        }
      );
      content.componentInstance.photos = photos;
      content.componentInstance.currentSlide = Number(swiper?.activeIndex - 1);
    }
  }

  /**
   * @function: Update trạng thái Reject or approved cho BĐS
   * @param mode:
   * @param id: id của BĐS
   */
  private onUpdateStatus(mode: string, id: string): void {
    let request$: Observable<any>;
    if (APPROVE_TYPE.REJECT === mode) {
      request$ = this.realEstateService.updateRejectRealEstate({
        id,
      }) as Observable<any>;
    } else {
      request$ = this.realEstateService.updateStatusRealEstate({
        id,
        mode,
      }) as Observable<any>;
    }

    request$
      .pipe(
        tap((_) => {
          this.showSuccess();
          this.fetchRealEstateDetail(this.dialogInputData?.id || this.id);
        })
      )
      .subscribe();
  }

  private fetchRealEstateDetail(id: string): void {
    this.realEstateService
      .getDetailRealEstate(id)
      .pipe(
        take(1),
        tap((realEstate) => {
          const mapToBooleanOption = (value: any) =>
            value === true
              ? BOOLEAN_OPTIONS.TRUE
              : value === false
              ? BOOLEAN_OPTIONS.FALSE
              : null;

          const corner = mapToBooleanOption(realEstate.corner);
          const signedBrokerageContract = mapToBooleanOption(
            realEstate.signedBrokerageContract
          );
          const basement = realEstate.basement
            ? Number(realEstate.basement)
            : null;
          const price = convertPriceUnitReverse(
            this.unitForm,
            realEstate?.price
          );

          this.formRealEstate.patchValue({
            ...realEstate,
            corner,
            signedBrokerageContract,
            basement,
            price,
          });

          this.photosRealEstate = realEstate?.photos
            ? JSON.parse(JSON.stringify(realEstate.photos))
            : null;

          this.patchCustomersIntoForm(realEstate.customers);
        }),
        switchMap((realEstate) => {
          if (realEstate.createdBy) {
            return this.userService
              .getListUserName({ phoneNumbers: [realEstate.createdBy] })
              .pipe(
                map((user) => ({
                  ...realEstate,
                  agencyName: user?.items[0]?.fullName || "",
                }))
              );
          }
          return of({ ...realEstate, agencyName: "" });
        })
      )
      .subscribe((data) => {
        this.realEstateStorage = JSON.parse(JSON.stringify(data));
        this.productDetail$.next(data);
      });
  }

  private getNewCustomers(customers: CustomerModel[]): CustomerModel[] {
    if (!customers || !Array.isArray(customers)) return customers;

    return customers.map((customer) => ({
      ...customer,
      address: customer.address
        ? customer.address.map((a: AddressModel) => ({
            ...a,
            phone: a.phone ? maskPhoneNumber(a.phone) : "",
          }))
        : [],
    }));
  }

  /**
   * @function: Update chi tiết BĐS
   */

  // HÀM LƯU BẤT ĐỘNG SẢN
  public onSave(): void {
    this.formRealEstate.markAllAsTouched();

    const photoUpload$: any = [];
    const keys: any = [];
    this.photoUploads.forEach((value) => {
      if (value instanceof File || value instanceof Blob) {
        photoUpload$.push(this.s3UploadService.fileUpload(value));
      }
    });

    if (photoUpload$.length) {
      combineLatest(photoUpload$).subscribe((res) => {
        if (res) {
          const realEstate = this.getPayload();

          realEstate.photos.forEach((value, key) => {
            if (!value.id) keys.push(key);
          });

          const newPhotos = res.map((r: any) => {
            return {
              url: r.Location,
              changedAt: undefined,
              changedBy: "",
              createdAt: new Date(),
              createdBy:
                this.authorizationService.getCurrentUserLogin().username,
              height: 0,
              id: 0,
              latitude: null,
              longitude: null,
              pickedLocation: "",
              position: "",
              refId: "",
              size: 0,
              type: "",
              width: 0,
              status: "1",
            };
          });

          // set ảnh cho BĐS
          keys.forEach((key: number, index: number) => {
            if (!realEstate.photos) realEstate.photos = [];

            const file = realEstate.photos[key];
            //BĐS mới sẽ k có ID
            if (!file?.id) {
              realEstate.photos[key] = newPhotos[index];
            } else {
              realEstate.photos[key] = { ...file };
            }
          });

          // So sánh với list ảnh ban đầu nếu cái nào k có trong list mới thì sẽ set status = 0
          this.photosRealEstate &&
            this.photosRealEstate.forEach((photo) => {
              const hasImg = realEstate.photos.find((p) => p.id === photo.id);
              if (!hasImg) {
                realEstate.photos.push({
                  ...photo,
                  status: "0",
                });
              }
            });

          // set ảnh đầu tiên là mặc định
          const hasFrontImage = realEstate?.photos.find(
            (p) => (p.position = "01")
          );
          if (!hasFrontImage) {
            realEstate.photos = realEstate?.photos.map((photo, index) => {
              if (index === 0) photo.position = "01";
              return photo;
            });
          }
          this.postRealEstate(realEstate);
        }
      });
    } else if (this.formRealEstate.valid) {
      const realEstate = this.getPayload();
      this.photosRealEstate &&
        this.photosRealEstate.forEach((photo) => {
          const hasImg = realEstate.photos.find((p) => p.id === photo.id);
          if (!hasImg) {
            realEstate.photos.push({
              ...photo,
              status: "0",
            });
          }
        });

      this.postRealEstate(realEstate);
    } else {
      this.showDanger("Bạn phải điền hết các trường bắt buộc!");
    }
  }

  // HÀM CẬP NHẬT BẤT ĐỘNG SẢN BÌNH THƯỜNG
  private postRealEstate(realEstate: RealEstateModel): void {
    this.realEstateService
      .updateRealEstate(realEstate)
      .pipe(
        take(1),
        tap((_) => {
          this.isEdit = !this.isEdit;
          this.formRealEstate.enable();
        }),
        tap((_) => {
          this.showSuccess();
          this.fetchRealEstateDetail(this.dialogInputData?.id || this.id);
        }),
        catchError((error) => {
          this.showDanger("Lỗi không xác định!");
          return throwError(error);
        })
      )
      .subscribe((_) => this.clean());
  }

  private getPayload(): RealEstateModel {
    const siteHandoverTime = this.f["siteHandoverTime"].value
      ? new Date(this.f["siteHandoverTime"].value).toISOString()
      : null;

    const contractTerm = this.f["contractTerm"].value
      ? new Date(this.f["contractTerm"].value).toISOString()
      : null;

    const price = convertPriceUnit(this.unitForm.value, this.f["price"].value);

    const result: RealEstateModel = {
      ...this.productDetail$.getValue(),
      ...this.formRealEstate.getRawValue(),
      siteHandoverTime,
      contractTerm,
      price,
    };

    result.yard = Number(result?.yard) || null;
    result.balcony = Number(result?.balcony) || null;
    result.garden = Number(result?.garden) || null;
    result.basement = Number(result?.basement) || null;
    result.price = Number(result?.price) || null;

    const _city = this.addressRealEstateService.getNameOfCity(
      result.address.city
    );
    const _district = this.addressRealEstateService.getNameOfDistrict(
      result.address.district
    );
    const _town = this.addressRealEstateService.getNameOfTown(
      result.address.town
    );

    const fullAddress = [
      `${result.address?.houseNbr} ${result.address?.street}`,
      _town,
      _district,
      _city,
    ]
      .filter(Boolean)
      .join(", ");
    const shortAddress = [_town, _district, _city].filter(Boolean).join(", ");

    const fullAddressNoneSign = removeVietnameseTones(fullAddress);

    result.address.fullAddressNoneSign = fullAddressNoneSign;
    result.address.fullAddress = fullAddress;
    result.address.shortAddress = shortAddress;

    if (!result.photos) result.photos = [];

    return result;
  }

  /**
   * @function: Huỷ các thay đổi đang sửa chi tiết BĐS - Real Estate
   */
  public onCancelChanged(): void {
    // Open modal and confirm click
    this.openModalToConfirm(this.CONFIRM_TEXT)
      .closed.pipe(
        take(1),
        tap((state: boolean) => {
          this.isEdit = !state;
        }),
        tap((state: boolean) => {
          // Nếu click confirm - Yes
          state && this.resetFactory();
        })
      )
      .subscribe((_) => {});
  }

  /**
   * @function: Reset form data -> before edit.
   */
  private resetFactory(): void {
    const customers = this.getNewCustomers(this.realEstateStorage.customers);
    this.formRealEstate.reset();
    this.formRealEstate.patchValue(
      { ...this.realEstateStorage },
      { emitEvent: false }
    );
    this.patchCustomersIntoForm(customers);
    this.productDetail$.next(this.realEstateStorage);
  }

  /**
   * @function: run when selected and change status.
   * @param param
   */
  private onChangeStatus(): void {
    const control$ = this.formRealEstate.get("status")
      .valueChanges as Observable<any>;

    control$
      .pipe(
        pairwise(),
        tap(([prev, curr]: [any, any]) => {
          const avoidConfirm =
            prev !== LAND_STATUS.NEW ||
            curr === null ||
            prev === null ||
            prev === curr;

          if (avoidConfirm) return;
          const modal =
            curr === LAND_STATUS.REJECTED
              ? this.openModalToConfirm(this.CONFIRM_TEXT)
              : this.openModalToConfirm(this.CONFIRM_TEXT_REJECT);

          modal.closed
            .pipe(
              tap((result) => {
                if (!result) {
                  this.formRealEstate.get("status").setValue(prev);
                } else {
                  const { id } = this.route.snapshot.params;
                  // Update status của BĐS
                  const mode = GetApproveType(curr);
                  this.onUpdateStatus(mode, id);
                }
              })
            )
            .subscribe((_) => {
              this.isEditStatusRealEstate$.next(false);
            });
        })
      )
      .subscribe((_) => {});
  }

  private openModalToConfirm(title: string, content?: string) {
    const modal = this.modalService.open(ConfirmModalComponent, {
      centered: true,
      backdrop: "static",
      keyboard: false,
    });
    modal.componentInstance.title = title;
    modal.componentInstance.content = content;

    return modal;
  }

  /**
   * @function: get status of real-esate
   */
  private getStatusRealEstate(): void {
    this.statusRealEstate$ = this.categoryService.getAllType();
  }

  /**
   * @function: Patch data of customer follow model of form array.
   * @param fields
   */

  private patchCustomersIntoForm(fields: any): void {
    const control = this.removeFormArrayElement(
      <UntypedFormArray>this.formRealEstate.get("customers")
    );

    fields?.forEach((x: any) => {
      const groupAdr = x.address?.map((adr: { [key: string]: any }) =>
        this.fb.group(adr)
      );
      // @ts-ignore
      const _address = this.fb.array([]);
      groupAdr.map((gr: AbstractControl) => _address.push(gr));
      control.push(this.fb.group({ ...x, address: _address }));
    });
  }

  /**
   * @function: util remove all element in form-array
   * @returns: form-array with empty control
   */
  private removeFormArrayElement(form: UntypedFormArray): UntypedFormArray {
    while (form?.length !== 0) {
      form.removeAt(0);
    }
    return form;
  }

  private showSuccess(): void {
    this.toastService.show("Cập nhật thành công BĐS!", {
      classname: "bg-success text-center text-white",
      delay: 5000,
    });
  }

  private showDanger(message: string): void {
    this.toastService.show(message, {
      classname: "bg-danger text-center text-white",
      delay: 5000,
    });
  }

  /**
   * @function: Init form
   */
  private initForm(): void {
    this.formRealEstate = this.fb.group({
      purpose: ["", [Validators.required]],
      type: [""],
      note: [""],
      status: [],
      elevators: [null],
      photos: [],
      landArea: [""],
      floorArea: [""],
      buildArea: [""],
      basement: [null],
      businessStatus: [null],
      floors: [null],
      price: [null],
      acreage: [null],
      frontWidth: [null],
      customers: this.fb.array([]),
      address: this.fb.group({
        changedAt: [""],
        changedBy: [""],
        city: ["", [Validators.required]],
        createdAt: [""],
        createdBy: [""],
        district: ["", [Validators.required]],
        houseNbr: ["", [Validators.required]],
        fullAddress: [""],
        shortAddress: [""],
        fullAddressNoneSign: [""],
        id: [""],
        latitude: [""],
        longitude: [""],
        streetView: [""],
        mobile: [""],
        phone: [""],
        refId: [""],
        street: ["", [Validators.required]],
        town: ["", [Validators.required]],
        type: [""],
      }),
      tenant: [null],
      negotiate: [0],
      currency: [null],
      originProvide: [null, Validators.required],
      ownerGroup: [null],
      description: [""],
      corner: [null],
      signedBrokerageContract: [null],
      manager: [null, Validators.required],
      brokerage: [null, Validators.required],
      brokerageFee: [null],
      siteHandoverTime: [null],
      contractTerm: [null],
    });
  }

  get f() {
    return this.formRealEstate.controls;
  }

  public eventEditImage(index: number): void {
    fromEvent(this.inputFileEdit.nativeElement, "change")
      .pipe()
      .subscribe((event) => {
        const realEstate = this.productDetail$.getValue();
        // @ts-ignore
        const file = event?.target.files[0];
        file.oldName = this.getRootLinkImage(realEstate.photos[index].url);

        this.photoUploads.set(index, file);

        const reader = new FileReader();
        reader.onload = (e) => {
          realEstate.photos[index] = {
            ...realEstate.photos[index],
            url: reader.result as any,
            changedBy: "",
            status: "1",
          };

          this.productDetail$.next(realEstate);
        };
        reader.readAsDataURL(file as any);
      });

    this.inputFileEdit.nativeElement.click();
  }

  public eventAddImage(): void {
    const modal = this.modalService.open(FileImageUploadsComponent, {
      centered: true,
      backdrop: "static",
      keyboard: true,
    });
    modal.closed.subscribe((data) => {
      const realEstate = this.productDetail$.getValue();
      const files = data;

      // kiểm tra xem mảng hiện tại có đang trống không.
      const hasImage: boolean = this.photoUploads.size > 0;
      // Sửa key-index của mảng hiện có vì vừa tăng thêm files?.length.
      if (hasImage) {
        let newMap = new Map();
        for (let [key, value] of this.photoUploads) {
          newMap.set(key + files.length, value);
        }
        this.photoUploads = newMap;
      }

      data.map((d: any, index: number) => {
        // set key = index để còn update vào mảng chứa ảnh sau khi upload lên S3.
        this.photoUploads.set(index, d);

        const reader = new FileReader();
        reader.onload = (e) => {
          if (!realEstate.photos) realEstate.photos = [];

          realEstate.photos.push({
            url: reader.result as any,
            changedAt: undefined,
            changedBy: "",
            createdAt: undefined,
            createdBy: "",
            height: 0,
            id: 0,
            latitude: null,
            longitude: null,
            pickedLocation: "",
            position: "",
            refId: "",
            size: 0,
            type: "",
            width: 0,
            status: "1",
          });
          this.productDetail$.next(realEstate);
        };
        reader.readAsDataURL(d);
      });
    });
  }

  public eventDeleteImage(index: number): void {
    const product = this.productDetail$.getValue();
    product?.photos.splice(index, 1);

    this.productDetail$.next(product);
  }

  public eventSelectImgFront(index: number): void {
    const product = this.productDetail$.getValue();
    const before = product.photos[index];
    const after = product.photos[0];

    product.photos.splice(index, 1);
    product.photos.splice(0, 1);

    product.photos.unshift({ ...after, position: "" });
    product.photos.unshift({ ...before, position: "01" });

    this.productDetail$.next(null);
    this.productDetail$.next(product);
  }

  private getRootLinkImage(image: string): string {
    if (image) {
      const imageName = image.replace(this.HANDLER_HOST, "");
      return imageName;
    }
    return image;
  }

  private clean(): void {
    this.photoUploads.clear();
  }

  private subscribeParamChange() {
    this.id = this.getProductId();
    this.fetchRealEstateDetail(this.dialogInputData?.id || this.id);

    this.route.params.subscribe((param) => {
      const { id } = param;
      if (id && id !== this.id) {
        this.fetchRealEstateDetail(id);
      } else this.id = id;
    });
  }

  private getProductId(): string {
    const { id } = this.route.snapshot.params;
    return id || "";
  }

  public onCopy() {
    const modalRef = this.modalService.open(CopyRealEstateComponent, {
      size: "lg",
      centered: true,
      windowClass: "modal-copy-realestate-for-zalo",
    });
    modalRef.componentInstance.realEstate$ = this.productDetail$;
  }

  public onClickEdit() {
    this.isEdit = !this.isEdit;
  }
}
