import { ActivatedRoute, Router } from "@angular/router";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { DEFINE_TYPE_ADDRESS } from "src/app/public/constants/common.constant";

export function SyncQueryParam(
  config: any = {
    parseIgnore: [],
    manualUpdate: false,
    fieldCheckUpdate: "submit",
    arraySplitSign: ";",
  }
): Function {
  return function (target: any, propertyKey: string): any {
    let srcSearchObj: any;
    let activatedRoute: ActivatedRoute;
    let router: Router;
    const ngOnInitUnPatched = target.ngOnInit;

    target.ngOnInit = function (this) {
      activatedRoute = this.injector.get(ActivatedRoute);
      router = this.injector.get(Router);

      // sync form to params
      if (
        target[propertyKey] instanceof UntypedFormGroup &&
        target[propertyKey]
      ) {
        target[propertyKey].valueChanges.subscribe((v: any) => {
          const params = target[propertyKey].getRawValue();
          syncObjectToParams(
            activatedRoute,
            router,
            params,
            config.arraySplitSign
          );
        });

        const objectSearchParams = target[propertyKey]?.getRawValue();
        const activeQueryParams = activatedRoute.snapshot.queryParams;
        if (
          Object.keys(objectSearchParams)?.length &&
          !Object.keys(activeQueryParams)?.length
        ) {
          syncObjectToParams(
            activatedRoute,
            router,
            objectSearchParams,
            config.arraySplitSign
          );
        }
      }

      // subscribe URL params change to sync search form
      activatedRoute.queryParams.subscribe((params) => {
        const searchObjParams = srcSearchObj?.getRawValue();
        const tmp = JSON.parse(JSON.stringify(params));
        const _params = { ...searchObjParams, ...tmp };

        Object.keys(_params)?.forEach((k) => {
          if (_params[k] && Array.from(_params[k]).indexOf(";") !== -1) {
            _params[k] = _params[k]?.split(";");
            _params[k] = _params[k]?.filter((i: any) => !!i);

            const _isTypeNumber = typeof _params[k] === "number";
            if (_isTypeNumber)
              _params[k] = _params[k]?.map((i: number) => (isNaN(i) ? i : +i));
          } else {
            if (!config.parseIgnore.includes(k)) {
              _params[k] =
                isNaN(_params[k]) || !_params[k] ? _params[k] : +_params[k];
            }
          }
        });
        if (
          target[propertyKey] instanceof UntypedFormGroup &&
          target[propertyKey]
        ) {
          target[propertyKey].patchValue(_params, { emitEvent: false });

          if (typeof _params === "object" && Object.keys(_params).length > 0) {
            if (target[propertyKey].get("skipDefaultValue")) {
              target[propertyKey].get("skipDefaultValue").setValue(true);
            } else {
              target[propertyKey].addControl(
                "skipDefaultValue",
                new UntypedFormControl(true)
              );
            }
          }
        } else if (typeof target[propertyKey] === "object") {
          target[propertyKey] = _params;
        }
      });
      if (ngOnInitUnPatched) {
        return ngOnInitUnPatched.call(this);
      }
    };
    function getter() {
      return srcSearchObj;
    }
    function setter(value: any) {
      srcSearchObj = value;
    }
    Object.defineProperty(target, propertyKey, {
      get: getter,
      set: setter,
      enumerable: true,
      configurable: true,
    });
  };
}

function syncObjectToParams(
  activatedRoute: ActivatedRoute,
  router: Router,
  obj: any,
  arraySplitSign?: string
) {
  if (activatedRoute) {
    const params = {};
    buildHTTPGetParamsFromObject(obj, params, arraySplitSign);
    router
      .navigate(["."], {
        relativeTo: activatedRoute,
        queryParams: params,
      })
      .then();
  }
}

export function buildHTTPGetParamsFromObject(
  obj: { [x: string]: any },
  params: any,
  arraySplitSign?: string
) {
  params = params || {};
  Object.keys(obj).forEach((key) => {
    const controlValue = obj[key];
    if (Array.isArray(controlValue)) {
      params[key] = "";
      if (controlValue.length > 0) {
        controlValue.forEach((v) => {
          params[key] += arraySplitSign ? String(v) + arraySplitSign : v + ";";
        });
      }
      return;
    }

    if (controlValue instanceof Date) {
      // params[key] = toISOStringWithoutTimeZone(controlValue).toISOString();
      params[key] = controlValue.toISOString();
    } else if (typeof controlValue === "object" && controlValue) {
      buildHTTPGetParamsFromObject(controlValue, params);
    } else if (controlValue !== null) {
      params[key] = controlValue;
    }
  });
}

/**
 * @function: get type of result search is ward | district | city
 */
export function getAddressType(data: any): string {
  const types = data?.types || data?.type;
  if (types) {
    if (
      types.find((t: string | string[]) => t.includes("sublocality")) ||
      types.find((t: string | string[]) => t.includes("sublocality_level_1")) ||
      types.find((t: string | string[]) =>
        t.includes("administrative_area_level_3")
      )
    ) {
      return DEFINE_TYPE_ADDRESS.WARD;
    } else if (
      types.find((t: string | string[]) =>
        t.includes("administrative_area_level_2")
      )
    ) {
      return DEFINE_TYPE_ADDRESS.DISTRICT;
    } else if (
      types.find((t: string | string[]) => t.includes("locality")) ||
      types.find((t: string | string[]) =>
        t.includes("administrative_area_level_1")
      )
    ) {
      return DEFINE_TYPE_ADDRESS.CITY;
    } else return DEFINE_TYPE_ADDRESS.OTHER;
  }
  return DEFINE_TYPE_ADDRESS.OTHER;
}

export function removeVietnameseTones(str: string) {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g, "");
  str = str.trim();
  // Remove punctuations
  // Bỏ dấu câu, kí tự đặc biệt
  str = str.replace(
    /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
    ","
  );
  return str;
}
