import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { BaseHttpRequest } from "../http/base-http-request.service";
import { API_URL } from "../../constants/api-url.constant";
import { map, tap } from "rxjs/operators";
import { FormGroup } from "@angular/forms";
import { FormSearchRealEstateAudit } from "../../helpers/form-search-real-estate-audit.helper";

@Injectable({
  providedIn: "root",
})
export class RealEstateAuditService extends BaseHttpRequest {
  private _total = new BehaviorSubject<number>(0);
  private _page = new BehaviorSubject<number>(0);
  private _pageSize = new BehaviorSubject<number>(0);
  private _limit = new BehaviorSubject<number>(0);
  private _items = new BehaviorSubject<any>([]);

  private _params: FormGroup<FormSearchRealEstateAudit>;

  private execute() {
    this._params?.valueChanges
      .pipe(
        map((params) => {
          const _params = Object.fromEntries(
            Object.entries(params)
              .map(([key, value]) => [key, value === "" ? null : value])
              .filter(([_, value]) => value !== null)
          );

          return _params;
        })
      )
      .subscribe((params) => this.getRealEstateAudit(params));
  }

  set params(value: FormGroup<FormSearchRealEstateAudit>) {
    this._params = value;
    this.execute();
  }

  get items$() {
    return this._items.asObservable();
  }

  get total$() {
    return this._total.asObservable();
  }

  get page$() {
    return this._page.asObservable();
  }

  get pageSize$() {
    return this._pageSize.asObservable();
  }

  get limit$() {
    return this._limit.asObservable();
  }

  public getRealEstateAudit(params: any) {
    const { page, realEstateId, ...requestParams } = params;

    if (!realEstateId) return null;

    return this.get(
      `${API_URL.LAND_MANAGEMENT}/${API_URL.ADMIN_PORTAL}/${realEstateId}/${API_URL.AUDITS}`,
      {
        params: { ...requestParams, page: Number(page - 1) },
      }
    )
      .pipe(
        tap((response: any) => {
          const _total = response?.totalItems;
          const _page = params?.page;
          const _pageSize = response?.data?.length;
          const _limit = params?.limit;
          const _data = response?.data;

          this._page.next(_page);
          this._total.next(_total);
          this._pageSize.next(_pageSize);
          this._limit.next(_limit);
          this._items.next(_data);
        })
      )
      .subscribe();
  }

  public getRealEstateAuditDetail(realEstateId: number, auditId: number) {
    return this.get(
      `${API_URL.LAND_MANAGEMENT}/${API_URL.ADMIN_PORTAL}/${realEstateId}/${API_URL.AUDITS}/${auditId}`
    ).pipe(
      map((data: any) => {
        return { ...data?.item };
      })
    );
  }
}
