export const ERRORS_SERVER = {
  LAND_DUPLICATED: "BĐS bị trùng",
};

export const USER_ERROR_MESSAGE = [
  {
    field: "tbl_user_phone_key",
    translateMessage: "Số điện thoại đã tồn tại!",
  },
  {
    field: "tbl_user_username_key",
    translateMessage: "Tên người dùng đã tồn tại!",
  },
  {
    field: "tbl_user_email_key",
    translateMessage: "Email đã tồn tại!",
  },
  {
    field: "tbl_user_id_number_key",
    translateMessage: "CMND/CCCD đã tồn tại!",
  },
  {
    field: "tbl_user_bank_account_key",
    translateMessage: "Số tài khoản ngân hàng đã tồn tại!",
  },
];
