<div
  class="tab-pane p-4"
  id="alerts-tab"
  role="tabpanel"
  aria-labelledby="alerts-tab"
>
  <div class="w-25 w-sm-50 pt-3 mx-auto">
    <img src="assets/images/svg/bell.svg" class="img-fluid" alt="user-pic" />
  </div>
  <div class="text-center pb-5 mt-2">
    <h6 class="fs-18 fw-semibold lh-base">Bạn không có thông báo nào.</h6>
  </div>
</div>
