import { Component, Input, OnInit } from "@angular/core";
import { CategoryService } from "src/app/public/services/categories/category.service";
import { UntypedFormControl } from "@angular/forms";

@Component({
  selector: "app-filter-type",
  templateUrl: "./filter-type.component.html",
  styleUrls: ["./filter-type.component.scss"],
})
export class FilterTypeComponent implements OnInit {
  @Input() formChild: UntypedFormControl;
  @Input() disabled: boolean;
  @Input() isMutipleChoose: boolean = true;
  @Input() isInvalid: boolean;
  public items: { id: string; title: string }[] = [];

  constructor(private categoryService: CategoryService) {
    this.getTypeData();
  }

  private getTypeData() {
    this.categoryService.getAllType().subscribe((data) => {
      this.items = data.map((i) => {
        return { id: i.cKey, title: i.cValue };
      });
    });
  }

  ngOnInit(): void {
    this.selectAllForDropdownItems(this.items);
  }

  selectAllForDropdownItems(items: any[]) {
    items.forEach((element: any) => {
      element["selectedAllGroup"] = "selectedAllGroup";
    });
  }
}
