export enum LEVEL_RESOURCE {
  SERVICE = "service",
  OTHER = "",
}

export enum PERMISSION_ACTIONS {
  VIEW_DETAIL = "GET",
  VIEW_LIST = "GET",
  CREATE = "POST",
  DELETE = "DELETE",
  ALL = "*",
  UPDATE = "PATCH",
  POST_EMAIL = "POST_EMAIL",
  SYNC_DATA = "SYNC_DATA",
  IMPORT_PDF = "IMPORT_PDF",
}

export enum REAL_ESTATE_RESOURCES {
  WAITING_APPROVE = "waiting-approve",
  IN_PROGRESS = "in-progress",
  QUICK_UPLOAD = "quick-upload",
  MAP_MANAGEMENT = "map-management",
}

export enum AGENT_RESOURCES {
  ACTIVE = "agent/active",
  WAITING_APPROVE = "agent/waiting-approve",
  REJECT = "agent/reject",
  DETAIL = "agent/detail",
  DETAIL_WAITING_APPROVE = "agent/detail/waiting-approve",
  DETAIL_IN_PROGRESS = "agent/detail/in-progress",
  DETAIL_RECOMMENDATION = "agent/detail/recommendation",
}

export enum CUSTOMER_RESOURCES {
  ACTIVE = "customer/active",
  WAITING_APPROVE = "customer/waiting-approve",
  REJECTED = "customer/rejected",
  DETAIL_ACCESS = "customer/detail/access",
  DETAIL_PRIVACY = "customer/detail/privacy",
  DETAIL_PASSWORD = "customer/detail/password",
  DETAIL_SEARCH_HISTORY = "customer/detail/search-history",
  NOTIFICATIONS = "notifications",
}

export enum MESSAGE_RESOURCES {
  NOTIFICATIONS = "notifications",
}

export enum ORGANIZATION_RESOURCES {
  DIVISION = "organization/division",
  EMPLOYEE = "organization/employee",
  GROUP = "organization/group",
}

export enum CONFIGURATION_RESOURCES {
  ACTIONS = "configuration/actions",
  SERVICES = "configuration/services",
  RESOURCES = "configuration/resources",
  POLICIES = "configuration/policies",
  ROLES = "configuration/roles",
}

export enum OTHER_RESOURCE {
  OTHER = "other",
}

export enum SERVICE_URL {
  API_RESOURCE = "api-resource",
  OTHER = "other",
  API_AUTH = "api-auth",
  API_MESSAGE = "api-message",
  API_USER = "api-user-management",
  API_CRM = "api-crm",
  API_LAND = "api-land-management",
}
