<div
  class="property-item h-100 w-100 position-relative d-flex flex-row bg-white cursor-pointer overflow-hidden"
  (click)="onSelectedRealEstate.emit(realEstate?.id)"
>
  <!--  BLOCK HÌNH BẤT ĐỘNG SẢN  -->
  <div class="item-image overflow-hidden">
    <div class="property-status">
      <span>{{ realEstate?.purpose | landPurpose }}</span>
    </div>
    <div
      class="mat-card-image w-100 m-0 position-relative h-100 w-100 d-flex justify-content-center overflow-hidden"
    >
      <div
        *ngIf="!isVideo"
        class="blur-image w-100 h-100"
        [ngStyle]="{
          'background-image':
            'url(' +
            mediaUrl +
            '), url(./assets/images/products/coming-soon.png)'
        }"
      ></div>

      <div *ngIf="isVideo">
        <video
          [src]="mediaUrl"
          width="100%"
          height="100%"
          style="max-height: 180px"
          controls
          muted
        >
          <source [src]="mediaUrl" type="video/mp4" />
          <source [src]="mediaUrl" type="video/mpeg4" />
        </video>
      </div>

      <div class="blur-image w-100 h-100"></div>
    </div>
  </div>
  <!------------------------------->

  <!--  BLOCK INFOR BẤT ĐỘNG SẢN  -->
  <div class="customize-card-info position-relative">
    <div class="h-100 d-flex flex-column justify-content-between">
      <div class="mb-0">
        <div class="detail-block position-relative">
          <div class="title fs-16 mb-3">
            <div>
              <span class="text text-color-primary">
                {{ realEstate?.price | transformPriceToString }}
              </span>
              <ng-container
                *ngIf="
                  realEstate?.purpose === LAND_PURPOSE.FOR_RENT &&
                  realEstate?.price > 0
                "
              >
                <span class="text-size-small">/tháng</span>
              </ng-container>
            </div>
          </div>
          <div class="detail-block-info pt-2">
            <div class="address">
              <span class="abbr-square-feet">
                Diện tích:
                <span class="customize-address"
                  >{{ " " + realEstate?.acreage | convertToNumber }} m²</span
                >
                | Mặt tiền:
                <span class="customize-address"
                  >{{ " " + realEstate?.frontWidth | convertToNumber }}m</span
                >
              </span>
            </div>
            <div class="address">
              <span class="customize-address">
                {{ realEstate?.address | getFullAddress | async }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!hiddenFooter" class="card-footer m-0 p-0">
        <div class="date mt-2">
          <span class="fs-12">
            Cập nhật:
            {{
              realEstate?.address?.changedAt
                ? (realEstate?.address?.changedAt | date: "d/MM/yyyy")
                : "Chưa cập nhật"
            }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="group-btn-func">
    <div class="d-flex align-items-start justify-content-end gap">
      <button
        [btnCheckRealEstateMark]="realEstate?.id"
        (click)="onClickAddToMark($event)"
        class="btn btn-success btn-icon my-1"
        ngbTooltip="Thêm/Bỏ chấm điểm"
      >
        <i class="ri-add-box-line"></i>
      </button>
      <button
        (click)="onCopy($event)"
        class="btn btn-icon waves-effect waves-light m-1"
        ngbTooltip="Copy Zalo"
      >
        <img
          src="/assets/images/zalo-image.png"
          alt="zalo-icon"
          class="w-100 h-100"
        />
      </button>
    </div>
  </div>
</div>
