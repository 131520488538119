import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbPaginationModule } from "@ng-bootstrap/ng-bootstrap";
import { SkeletonTableComponent } from "@component/animation/loadingSkeleton/skeleton-table.component";

@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  standalone: true,
  imports: [CommonModule, NgbPaginationModule, SkeletonTableComponent],
})
export class TableComponent {
  // Content child
  @ContentChild("headers")
  headers: TemplateRef<any> | null;
  @ContentChild("content")
  content: TemplateRef<any> | null;
  @ContentChild("noData")
  noData: TemplateRef<any> | null;

  // Input
  @Input() data: any[] = [];
  @Input() page: number = 0; //Page hiện tại
  @Input() total: number; // Tổng số bản ghi
  @Input() pageSize: number = 0; // Số lượng bản ghi ở page hiện tại
  @Input() limit: number = 0; // limit của mỗi page
  @Input() logError: any; // log lỗi
  @Input() showSkeleton: boolean = true;
  @Input() maxSize = 10;
  @Input() footerCol = "col-sm-6";
  // Output
  @Output() pageChange = new EventEmitter();
  @Output() rowClick = new EventEmitter();
}
