<div id="layout-wrapper">
  <!-- Top Bar -->
  <app-topbar (mobileMenuButtonClicked)="onToggleMobileMenu()"></app-topbar>
  <!-- Side Bar -->
  <app-sidebar></app-sidebar>
  <div class="main-content">
    <div
      class="page-content"
      [style.padding]="mapManagementScreen && (showFullMap$ | async) ? '0' : ''"
    >
      <div class="container-fluid h-100">
        <!-- Main Content -->
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
