<!-- Start Breadcrumbs -->
<div class="row">
  <div class="col-lg-12">
    <div class="card mb-0">
      <div class="card-header">
        <h4 class="card-title mb-0">Upload ảnh cho BĐS</h4>
      </div>
      <!-- end card header -->

      <div class="card-body">
        <input
          #inputFile
          style="display: none"
          type="file"
          multiple
          class="file-upload"
          (change)="onFileSelected($event)"
        />
        <div>
          <div
            (click)="inputFile.click()"
            class="dz-wrapper dropzone dz-multiple"
          >
            <div class="text-center">
              <i class="bx bx-cloud-upload fs-32"></i>
            </div>
            <div class="text-center fs-18">
              Click hoặc Kéo thả ảnh để upload ảnh BĐS
            </div>
          </div>

          <div class="swiper">
            <div class="swiper-wrapper custom-swiper-upload">
              <div
                style="width: 200px; height: 200px; float: left"
                class="swiper-slide"
                *ngFor="let file of files; let i = index"
              >
                <img
                  *ngIf="CheckStyleUrl(file?.name) === 'image'"
                  src=""
                  [imgPreview]="file"
                  width="200"
                  height="200"
                  alt=""
                />

                <video
                  *ngIf="CheckStyleUrl(file?.name) === 'video'"
                  width="200"
                  height="200"
                  controls
                >
                  <source [appVideoPreview]="file" src="" />
                </video>

                <button
                  (click)="onDeleteFile(i)"
                  ngbTooltip="Xoá ảnh đã chọn"
                  class="btn btn-icon text-danger ag-row-position-absolute btn-delete-custom"
                >
                  <i class="ri-delete-bin-line fs-14"></i>
                </button>
              </div>
            </div>
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
          </div>
        </div>

        <div class="d-flex justify-content-center pt-2">
          <button
            #btnSubmitUpload
            type="submit"
            class="mx-2 btn btn-danger"
            (click)="modalService.dismissAll()"
          >
            Huỷ
          </button>

          <button
            #btnSubmitUpload
            type="submit"
            class="btn mx-2 btn-info"
            (click)="approvedSubmit()"
          >
            Đồng ý
          </button>
        </div>
        <!-- end dropzon-preview -->
      </div>
      <!-- end card body -->
    </div>
    <!-- end card -->
  </div>
  <!-- end col -->
</div>
<!-- end row -->
