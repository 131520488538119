<ng-container *ngIf="DATA.length > 0; else noNotify">
  <div class="tab-pane fade show active py-2 ps-2">
    <ngx-simplebar style="max-height: 300px">
      <div class="pe-2">
        <ng-container *ngFor="let data of DATA">
          <app-notify-item [data]="data"></app-notify-item>
        </ng-container>
      </div>
    </ngx-simplebar>
  </div>
</ng-container>

<ng-template #noNotify>
  <app-notify-empty></app-notify-empty>
</ng-template>
