import { Injectable } from "@angular/core";
import { StorageService } from "src/app/public/services/storage/storage.service";
import { JWT_TOKEN } from "src/app/public/constants/common.constant";
import { User } from "src/app/public/models/auth.models";
import { JwtHelperService } from "@auth0/angular-jwt";
import { APP_URL } from "src/app/public/constants/url.constant";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class JwtTokenService {
  constructor(
    private router: Router,
    private jwt: JwtHelperService,
    private storageService: StorageService
  ) {}

  get jwtToken(): User {
    return this.storageService.get(JWT_TOKEN);
  }

  encodeJwt(): any {
    const jwtToken = this.jwtToken;
    if (!jwtToken) {
      this.router.navigate([APP_URL.LOGIN]).then();
      return;
    }
    return this.jwt.decodeToken(JSON.stringify(jwtToken));
  }
}
