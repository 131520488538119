import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { APP_URL } from "src/app/public/constants/url.constant";
import { RealEstateService } from "src/app/public/services/real-estate/real-estate.service";
import { ToastService } from "../notifications/toast-service";
import { GetParameterByName } from "src/app/public/utils/get-parameter-by-name.util";
import { catchError } from "rxjs/operators";
import { of } from "rxjs";

@Component({
  selector: "app-quick-search-modal",
  templateUrl: "./quick-search-modal.component.html",
  styleUrls: ["./quick-search-modal.component.scss"],
})
export class QuickSearchModalComponent {
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private toastService: ToastService,
    private realEstateService: RealEstateService
  ) {}

  private getRealEstateDetail(id: string) {
    return this.realEstateService.getDetailRealEstate(id);
  }

  public redirectToDetail(event: any) {
    const rightBar = document.getElementById("theme-settings-offcanvas");

    const inputValue = event?.target?.value;
    const itemId = GetParameterByName("itemId", inputValue);
    const urlRegex =
      /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;

    if (urlRegex.test(inputValue) && itemId !== null) {
      this.getRealEstateDetail(itemId)
        .pipe(
          catchError((err) => {
            return of(err);
          })
        )
        .subscribe((res) => {
          if (!res.error) {
            const url = APP_URL.REAL_ESTATE_DETAIL + "/" + itemId;
            this.handleRoutingAndCloseModal(url);
          } else {
            this.handleShowPopup("error");
            this.handleRoutingAndCloseModal("/");
          }
        });
    } else {
      this.handleShowPopup("error-url");
      return;
    }

    if (rightBar != null) {
      rightBar.classList.remove("show");
      rightBar.removeAttribute("style");
    }
  }

  private handleRoutingAndCloseModal(page: string) {
    this.modalService.dismissAll();
    this.router.navigate([page]).then();
  }

  // hàm handle popup lỗi
  private handleShowPopup(type: string) {
    switch (type) {
      case "error":
        this.toastService.show("Bất động sản không có trong hệ thống!", {
          classname: "bg-danger text-center text-white",
          delay: 2000,
        });

        break;
      case "error-url":
        this.toastService.show("Nhập sai URL, vui lòng nhập lại!", {
          classname: "bg-danger text-center text-white",
          delay: 2000,
        });
        break;
      default:
        break;
    }
  }
}
