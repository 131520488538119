import { Component, Output, EventEmitter } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ExportRealEstateHelper } from "../../helpers/export-realestates.helper";
import { LoadingService } from "../../services/loading.service";
import { QuickSearchModalComponent } from "src/app/components/quick-search-modal/quick-search-modal.component";
import {
  OTHER_RESOURCE,
  PERMISSION_ACTIONS,
} from "src/app/public/constants/permission.contants";

@Component({
  selector: "app-rightsidebar",
  templateUrl: "./rightsidebar.component.html",
  styleUrls: ["./rightsidebar.component.scss"],
})
export class RightsidebarComponent {
  public readonly PERMISSION_ACTIONS = PERMISSION_ACTIONS;
  public readonly OTHER_RESOURCE = OTHER_RESOURCE;
  @Output() settingsButtonClicked = new EventEmitter();
  public loading$ = this.loader.loading$;

  constructor(
    private modalService: NgbModal,
    private exportRealEstatsHelper: ExportRealEstateHelper,
    private loader: LoadingService
  ) {}

  //Toggles the right sidebar
  public toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  //Hide the sidebar
  public hide() {
    document.body.classList.remove("right-bar-enabled");
    const rightBar = document.getElementById("theme-settings-offcanvas");
    if (rightBar != null) {
      rightBar.classList.remove("show");
      rightBar.removeAttribute("style");
    }
  }

  public showSearchModal() {
    this.modalService.open(QuickSearchModalComponent, {
      size: "xl",
      windowClass: "customize-quick-search",
    });
  }

  public handleExportFile() {
    const event$ = this.exportRealEstatsHelper.export();
    // loading animation
    if (event$) this.loader.show();

    event$.subscribe((data) => {
      if (data) this.loader.hide();
    });
  }
}
