import { MapsAPILoader } from "@agm/core";
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Params } from "@angular/router";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  takeUntil,
  tap,
} from "rxjs/operators";
import { RealEstateService } from "src/app/public/services/real-estate/real-estate.service";
import { GoogleMapsService } from "src/app/public/services/google-maps/google-maps.service";
import { PolygonAreaService } from "src/app/public/services/google-maps/polygon-area.service";
import { RealEstateModel } from "src/app/public/models/real-estate.model";
import { RealEstateDetailComponent } from "src/app/pages/real-estate-management/real-estate-detail/real-estate-detail.component";
import { MatDialog } from "@angular/material/dialog";
import { dmsToDecimal } from "src/app/public/utils/convert-dms-todecimal.until";
import { MapState } from "src/app/public/services/google-maps/map.state";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from "@angular/animations";

declare var google: any;

@Component({
  selector: "app-search-suggestion",
  templateUrl: "./search-suggestion.component.html",
  styleUrls: ["./search-suggestion.component.scss"],
  animations: [
    trigger("fadeInOut", [
      state(
        "void",
        style({
          opacity: 0,
        })
      ),
      transition(":enter, :leave", [animate("300ms ease")]),
    ]),
  ],
})
export class SearchSuggestionComponent implements OnInit, OnDestroy {
  @Output() focusOnEventEmit = new EventEmitter();
  @Output() focusOutEventEmit = new EventEmitter();
  @Input() searchForm: UntypedFormGroup;
  @Input() takeFistSuggestion: boolean = false;
  @Input() suggestionRelative: boolean = false;
  @Input() hasOverlay: boolean = false;
  @ViewChild("suggestionSearch")
  public searchElementRef: ElementRef;
  public showOverlay: boolean = false;

  public realEstateSuggestion$: Observable<RealEstateModel[]>;
  public subscription$ = new Subject();
  public DEFAULT_ADDRESS = "Hà Nội, Việt Nam";
  public addressSuggestions$: BehaviorSubject<any[]> = new BehaviorSubject<
    any[]
  >([]);
  public suggestions: Observable<any>;

  public isFocus: boolean;
  public isShowSuggestion: boolean;
  public searchTextControl: UntypedFormControl = new UntypedFormControl();

  constructor(
    private realEstateService: RealEstateService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private mapsAPILoader: MapsAPILoader,
    private googleMapService: GoogleMapsService,
    private polygonService: PolygonAreaService, // @TODO: xem day sang map
    private mapState: MapState,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.syncParams();
    // Setup sceriano when running app.
    this.addressChangeObserver();
  }

  // @function: Subscribe address change adn fetch RealEstate & Locaiton recommend by GOogle.
  public addressChangeObserver(): void {
    this.mapsAPILoader.load().then(() => {
      this.searchTextControl.valueChanges
        .pipe(
          takeUntil(this.subscription$),
          debounceTime(300),
          tap((value) => {
            this.isShowSuggestion = value;
          }),
          distinctUntilChanged()
        )
        .subscribe((address: string) => {
          this.realEstateSuggestion$ = this.getListRealEstate(address);
          this.getLocationFromGoogleApi(address);

          if (address?.length === 0) {
            this.isShowSuggestion = false;
            this.addressSuggestions$.next([]);
          }
        });
    });
  }

  public getLocationFromGoogleApi(input: string) {
    const maker = new google.maps.places.AutocompleteService();
    maker.getPlacePredictions(
      {
        input: input,
        componentRestrictions: { country: "vn" },
      },
      (predictions: string | any[]) => {
        this.addressSuggestions$.next((predictions?.slice(0, 3) as any) || []);
      }
    );
  }

  public onClickToOpenDetail(id: number) {
    this.dialog.open(RealEstateDetailComponent, {
      data: {
        id: id,
      },
      panelClass: "modal-detail-realestate",
      width: "1300px",
      height: "90%",
    });
    this.modalService.dismissAll();
  }

  private syncParams(): void {
    let count = 0;
    this.route.queryParams
      .pipe(
        takeUntil(this.subscription$),
        tap((params: Params) => {
          if (count == 0 && params["address"])
            this.getAddressFromText(params["address"]);
          count++;
        })
      )
      .subscribe((params: Params) => {
        this.searchTextControl.patchValue(params["address"], {
          onlySelf: true,
          emitEvent: Boolean(count === 1),
        });
      });
  }

  private getListRealEstate(address: string): Observable<RealEstateModel[]> {
    const statusValue = this.searchForm?.controls["status"]?.value;
    const status = Array.isArray(statusValue) ? statusValue.join(";") : "";
    const params = {
      status,
      address,
    };
    return this.realEstateService
      .getNameRealEstate(params)
      .pipe(map((res: any) => res?.data.slice(0, 5)));
  }

  public onSelectedSuggestion(data: any = this.searchTextControl.value): void {
    if (this.takeFistSuggestion && data == this.searchTextControl.value) {
      data = this.addressSuggestions$.getValue()[0];
    }
    const searchText = this.searchTextControl.value;

    const { lat, lng } = dmsToDecimal(searchText);
    const [_lat, _lng] = searchText
      .split(",")
      .map((coord: string) => +coord.trim());

    const location =
      _lat && _lng ? { lat: Number(_lat), lng: Number(_lng) } : { lat, lng };

    if (location.lat && location.lng) {
      this.polygonService.setLocation = location;
      this.searchForm.controls["address"].setValue(
        [location.lat, location.lng].join(",")
      );
    } else {
      this.getAddressFromText(data?.description || data);
    }
    this.searchElementRef.nativeElement.blur();
    this.modalService.dismissAll();
  }

  private getAddressFromText(text: string) {
    this.googleMapService.getAddressDetailFromText(text).then((res: any) => {
      this.fireEvent(res);
      let address;
      if (res.street) {
        address = [res.street, res.cityName].map(Boolean).join(", ");
      } else address = text;

      const values = {
        ...(res as Object),
        address,
      };

      // update text search cho filed inpuut
      this.searchForm?.patchValue(values);
      // update text search cho formGroup sync tự động lên url.
      this.searchForm?.controls["address"]?.setValue(text);
    });
  }

  public handleFocusInputElement() {
    const textSearch = this.searchTextControl.value;
    if (textSearch) {
      this.isShowSuggestion = textSearch;
      this.realEstateSuggestion$ = this.getListRealEstate(textSearch);
      this.getLocationFromGoogleApi(textSearch);
    }
    this.isFocus = true;
    this.showOverlay = true;
  }

  public handleFocusOut() {
    this.isFocus = false;
    this.showOverlay = false;
  }

  public onClickClearSearch(): void {
    this.searchTextControl.reset();
    this.mapState.polygon$ = null;
    this.resetAddress();
  }

  ngOnDestroy(): void {
    this.subscription$.next(null);
    this.subscription$.complete();
  }

  private fireEvent(data: any): void {
    const { location } = data;
    this.polygonService.setArea = data;
    this.polygonService.setZoom = data;
    this.polygonService.setLocation = location;

    const polygons = this.polygonService.getPointsOfArea(data) || null;
    if (polygons) {
      this.mapState.polygon$ = polygons[0];
      this.mapState.allowCallApi$ = true;
    }
  }

  private resetAddress(): void {
    this.searchForm.controls["address"]?.setValue("");
    this.searchForm.controls["street"]?.setValue("");
    this.searchForm.controls["district"]?.setValue("");
    this.searchForm.controls["city"]?.setValue("");
    this.searchForm.controls["town"]?.setValue("");
  }
}
