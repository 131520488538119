import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";

// import Swiper core and required modules
import { RealEstatePhotoModel } from "src/app/public/models/real-estate.model";
import { CheckStyleUrl } from "src/app/public/utils/check-file-type";
import SwiperCore, { FreeMode, Navigation, Thumbs } from "swiper";
// install Swiper modules
SwiperCore.use([FreeMode, Navigation, Thumbs]);

@Component({
  selector: "app-slider-image-detail",
  templateUrl: "./slider-image-detail.component.html",
  styleUrls: ["./slider-image-detail.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliderImageDetailComponent {
  [x: string]: any;

  private _photos: RealEstatePhotoModel[] = [];
  public CheckStyleUrl = CheckStyleUrl;
  @Output() onSelectedImage = new EventEmitter<any>();
  @Output() onDeleteImage = new EventEmitter<any>();
  @Output() onAddImage = new EventEmitter<any>();
  @Output() onSelectImgFront = new EventEmitter<any>();
  @Output() onUpdateImage = new EventEmitter<any>();

  @Input() isEdit: boolean;
  @Input() closeBtn: boolean;
  @Input() productId: string | number;

  // @ts-ignore
  get images(): RealEstatePhotoModel[] {
    return this._photos;
  }
  @Input() set photos(data: RealEstatePhotoModel[]) {
    this._photos = data;
  }

  @ViewChild("mainSwiper") mainSwiper: any;
  @ViewChild("subSwiper") subSwiper: any;

  currentSlide: number = 0;
  isHoverEdit: boolean;
  thumbsSwiper: any;

  realIndexChange($event: any) {
    this.currentSlide = Number($event[0]?.realIndex);
  }

  mouseover(): void {
    if (this.isEdit || !this.productId) this.isHoverEdit = true;
  }

  mouseleave(): void {
    this.isHoverEdit = false;
  }
}
