import { NgIf } from "@angular/common";
import { Component, inject, Input, OnInit } from "@angular/core";
import { FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterLink } from "@angular/router";
import { ALIAS_PREFIX, ORIGIN_PROVIDE } from "@constant/common.constant";
import { InvisiblePhoneNumberDirective } from "@directive/invisible-phone-number.directive";
import { OnlyPhoneNumberDirective } from "@directive/only-phone-number.directive";
import { BrandModel } from "@model/brand.model";
import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { BrandService } from "@service/brand/brand.service";
import { UiSwitchModule } from "ngx-ui-switch";
import { filter, startWith, switchMap, tap } from "rxjs/operators";

@Component({
  selector: "app-order-owner",
  templateUrl: "./order-owner.component.html",
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    UiSwitchModule,
    NgbTooltipModule,
    RouterLink,
    OnlyPhoneNumberDirective,
    InvisiblePhoneNumberDirective,
    NgIf,
  ],
})
export class OrderOwnerComponent implements OnInit {
  private brandService = inject(BrandService);

  readonly ALIAS = ALIAS_PREFIX;
  readonly ORIGIN_PROVIDE = ORIGIN_PROVIDE;

  @Input() callHistoryForm: FormGroup;

  get controls() {
    return this.callHistoryForm.controls;
  }

  ngOnInit(): void {
    this.getOriginOfBrand();
  }

  private getOriginOfBrand() {
    this.controls["brand"].valueChanges
      .pipe(
        startWith(this.controls["brand"].value),
        filter((brand) => !!brand),
        switchMap((brand) => this.brandService.getDetailBrand(brand)),
        tap((brand: BrandModel) =>
          this.controls["origin"].setValue(brand.origin)
        )
      )
      .subscribe();
  }
}
