import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { RealEstateModel } from "src/app/public/models/real-estate.model";
import { StorageService } from "src/app/public/services/storage/storage.service";
import { EVENT_BUS_EVENTS } from "src/app/public/services/common/event-bus.service";

@Injectable({
  providedIn: "root",
})
export class RealEstateMarkService {
  constructor(private storageService: StorageService) {
    const values = storageService.get(EVENT_BUS_EVENTS.REAL_ESTATE_MARK);
    this._realEstatesMark$.next(values);
  }

  private _realEstatesMark$ = new BehaviorSubject<RealEstateModel[]>(null);

  get realEstatesMark$(): Observable<RealEstateModel[]> {
    return this._realEstatesMark$.asObservable();
  }

  get rawRealEstatesMark(): RealEstateModel[] {
    return this._realEstatesMark$.value;
  }

  clear(): void {
    this._realEstatesMark$.next(null);
    this.clearStorage();
  }

  init(data: RealEstateModel[]): void {
    this._realEstatesMark$.next(data);
    this.updateStorage();
  }

  add(data: RealEstateModel): void {
    const currentValue = this._realEstatesMark$.value || [];
    if (data) {
      const index = currentValue?.findIndex((v) => v.id === data.id);
      if (index < 0) this._realEstatesMark$.next(currentValue.concat(data));
      else {
        currentValue.splice(index, 1)
        this._realEstatesMark$.next(currentValue);
      }
      this.updateStorage();
    }
  }

  private updateStorage(): void {
    this.storageService.set(
      EVENT_BUS_EVENTS.REAL_ESTATE_MARK,
      this.rawRealEstatesMark
    );
  }

  private clearStorage(): void {
    this.storageService.delete(EVENT_BUS_EVENTS.REAL_ESTATE_MARK);
  }
}
