<!-- Start Breadcrumbs -->
<app-breadcrumbs
  title="Notifications"
  [breadcrumbItems]="breadCrumbItems"
></app-breadcrumbs>
<!-- End Breadcrumbs -->

<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-header align-items-center d-flex">
        <h4 class="card-title mb-0 flex-grow-1">Bootstrap Toasts</h4>
        <div class="flex-shrink-0">
          <div class="form-check form-switch form-switch-right form-switch-md">
            <label for="bootstraptoast-showcode" class="form-label text-muted"
              >Show Code</label
            >
            <input
              class="form-check-input code-switcher"
              type="checkbox"
              id="bootstraptoast-showcode"
              (click)="ShowCode($event)"
            />
          </div>
        </div>
      </div>
      <!-- end card header -->

      <div class="card-body">
        <p></p>
        <div class="live-preview">
          <div class="row g-3">
            <div class="col-xxl-6">
              <h5 class="fs-14 mb-3">Default Toast</h5>
              <p class="text-muted">
                Use <code>toast</code> class to set a default toast.
              </p>
              <!-- Basic Toasts Example -->
              <ngb-toast
                *ngIf="show"
                [autohide]="false"
                (hidden)="show = false"
              >
                <ng-template ngbToastHeader>
                  <img
                    src="assets/images/logo-sm.png"
                    alt=""
                    class="me-2"
                    height="18"
                  />
                  <span class="fw-semibold me-auto">Velzon</span>
                  <small>06 mins ago</small>
                </ng-template>
                Hello, world! This is a toast message.
              </ngb-toast>

              <div class="mt-4">
                <h5 class="fs-14">Translucent</h5>
                <p class="text-muted">
                  Toasts are slightly translucent, too, so they blend over
                  whatever they might appear over.
                </p>
                <!-- Toasts Translucent -->
                <div class="p-3 bg-light">
                  <ngb-toast
                    *ngIf="translucent"
                    [autohide]="false"
                    (hidden)="translucent = false"
                  >
                    <ng-template ngbToastHeader>
                      <img
                        src="assets/images/logo-sm.png"
                        alt=""
                        class="me-2"
                        height="18"
                      />
                      <span class="fw-semibold me-auto">Velzon</span>
                      <small>11 mins ago</small>
                    </ng-template>
                    Hello, world! This is a toast message.
                  </ngb-toast>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-xxl-6">
              <div>
                <h5 class="fs-14">Stacking</h5>
                <p class="text-muted">
                  When you have multiple toasts, we default to vertically
                  stacking them in a readable manner.
                </p>

                <div class="p-3 bg-light">
                  <!-- Toasts Stacking -->
                  <ngb-toast
                    *ngIf="stacking"
                    [autohide]="false"
                    (hidden)="stacking = false"
                  >
                    <ng-template ngbToastHeader>
                      <img
                        src="assets/images/logo-sm.png"
                        class="rounded me-2"
                        alt="..."
                        height="20"
                      />
                      <span class="fw-semibold me-auto">Velzon</span>
                      <small>Just now</small>
                    </ng-template>
                    See? Just like this.
                  </ngb-toast>

                  <ngb-toast
                    *ngIf="stackingsecond"
                    [autohide]="false"
                    (hidden)="stackingsecond = false"
                  >
                    <ng-template ngbToastHeader>
                      <img
                        src="assets/images/logo-sm.png"
                        class="rounded me-2"
                        alt="..."
                        height="20"
                      />
                      <span class="fw-semibold me-auto">Velzon</span>
                      <small>2 seconds ago</small>
                    </ng-template>
                    Heads up, toasts will stack automatically
                  </ngb-toast>
                </div>
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->

          <div class="mt-5">
            <h5 class="fs-14">Placement</h5>
            <div class="row g-3">
              <div class="col-xxl-6">
                <div>
                  <p class="text-muted">
                    Place toasts with custom CSS as you need them. The top right
                    is often used for notifications, as is the top middle. If
                    you’re only ever going to show one toast at a time, put the
                    positioning styles right on the <code>.toast</code>.
                  </p>

                  <!-- Toasts Placement -->
                  <div
                    class="bg-light"
                    aria-live="polite"
                    aria-atomic="true"
                    style="position: relative; min-height: 200px"
                  >
                    <div
                      class="toast fade show"
                      role="alert"
                      aria-live="assertive"
                      aria-atomic="true"
                      data-bs-toggle="toast"
                      style="position: absolute; top: 16px; right: 16px"
                    >
                      <ngb-toast
                        *ngIf="placement"
                        [autohide]="false"
                        (hidden)="placement = false"
                      >
                        <ng-template ngbToastHeader>
                          <img
                            src="assets/images/logo-sm.png"
                            class="rounded me-2"
                            alt="..."
                            height="20"
                          />
                          <span class="fw-semibold me-auto">Velzon</span>
                          <small>06 mins ago</small>
                        </ng-template>
                        Hello, world! This is a toast message.
                      </ngb-toast>
                    </div>
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-xxl-6">
                <div>
                  <p class="text-muted">
                    You can also get fancy with flexbox utilities to align
                    toasts horizontally and/or vertically.
                  </p>

                  <!-- Flexbox container for aligning the toasts -->
                  <div
                    aria-live="polite"
                    aria-atomic="true"
                    class="bg-light d-flex justify-content-center align-items-center"
                    style="height: 200px"
                  >
                    <ngb-toast
                      *ngIf="placementSec"
                      [autohide]="false"
                      (hidden)="placementSec = false"
                    >
                      <ng-template ngbToastHeader>
                        <img
                          src="assets/images/logo-sm.png"
                          class="rounded me-2"
                          alt="..."
                          height="20"
                        />
                        <span class="fw-semibold me-auto">Velzon</span>
                        <small>06 mins ago</small>
                      </ng-template>
                      Hello, world! This is a toast message.
                    </ngb-toast>
                  </div>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
        </div>

        <div class="d-none code-view">
          <pre class="language-markup" style="height: 275px">
<code>&lt;!-- Default Toast --&gt;
&lt;div class=&quot;toast fade show&quot; role=&quot;alert&quot; aria-live=&quot;assertive&quot;
data-bs-autohide=&quot;false&quot; aria-atomic=&quot;true&quot;&gt;
&lt;div class=&quot;toast-header&quot;&gt;
&lt;img src=&quot;assets/images/logo-sm.png&quot; class=&quot;rounded me-2&quot;
alt=&quot;...&quot; height=&quot;20&quot;&gt;
&lt;span class=&quot;fw-semibold me-auto&quot;&gt;Velzon&lt;/span&gt;
&lt;small&gt;06 mins ago&lt;/small&gt;
&lt;button type=&quot;button&quot; class=&quot;btn-close&quot;
data-bs-dismiss=&quot;toast&quot; aria-label=&quot;Close&quot;&gt;&lt;/button&gt;
&lt;/div&gt;
&lt;div class=&quot;toast-body&quot;&gt;
Hello, world! This is a toast message.
&lt;/div&gt;
&lt;/div&gt;</code>

<code>&lt;!-- Translucent Toast --&gt;
&lt;div class=&quot;p-3 bg-light&quot;&gt;
&lt;div class=&quot;toast fade show&quot; role=&quot;alert&quot; aria-live=&quot;assertive&quot;
data-bs-autohide=&quot;false&quot; aria-atomic=&quot;true&quot;&gt;
&lt;div class=&quot;toast-header&quot;&gt;
&lt;img src=&quot;assets/images/logo-sm.png&quot; class=&quot;rounded me-2&quot;
alt=&quot;...&quot; height=&quot;20&quot;&gt;
&lt;span class=&quot;fw-semibold me-auto&quot;&gt;Velzon&lt;/span&gt;
&lt;small&gt;11 mins ago&lt;/small&gt;
&lt;button type=&quot;button&quot; class=&quot;btn-close&quot;
data-bs-dismiss=&quot;toast&quot; aria-label=&quot;Close&quot;&gt;&lt;/button&gt;
&lt;/div&gt;
&lt;div class=&quot;toast-body&quot;&gt;
Hello, world! This is a toast message.
&lt;/div&gt;
&lt;/div&gt;
&lt;/div&gt;</code>

<code>&lt;!-- Stacking Toast --&gt;
&lt;div class=&quot;toast fade show&quot; role=&quot;alert&quot; aria-live=&quot;assertive&quot;
data-bs-autohide=&quot;false&quot; aria-atomic=&quot;true&quot;&gt;
&lt;div class=&quot;toast-header&quot;&gt;
&lt;img src=&quot;assets/images/logo-sm.png&quot; class=&quot;rounded me-2&quot;
alt=&quot;...&quot; height=&quot;20&quot;&gt;
&lt;span class=&quot;fw-semibold me-auto&quot;&gt;Velzon&lt;/span&gt;
&lt;small&gt;Just now&lt;/small&gt;
&lt;button type=&quot;button&quot; class=&quot;btn-close&quot;
data-bs-dismiss=&quot;toast&quot; aria-label=&quot;Close&quot;&gt;&lt;/button&gt;
&lt;/div&gt;
&lt;div class=&quot;toast-body&quot;&gt;
See? Just like this.
&lt;/div&gt;
&lt;/div&gt;</code>

<code>&lt;!-- Placement Toast --&gt;
&lt;div class=&quot;bg-light&quot; aria-live=&quot;polite&quot; aria-atomic=&quot;true&quot;
style=&quot;position: relative; min-height: 200px;&quot;&gt;
&lt;div class=&quot;toast fade show&quot; role=&quot;alert&quot; aria-live=&quot;assertive&quot;
aria-atomic=&quot;true&quot; data-bs-toggle=&quot;toast&quot;
style=&quot;position: absolute; top: 16px; right: 16px;&quot;&gt;
&lt;div class=&quot;toast-header&quot;&gt;
&lt;img src=&quot;assets/images/logo-sm.png&quot;
class=&quot;rounded me-2&quot; alt=&quot;...&quot; height=&quot;20&quot;&gt;
&lt;span class=&quot;fw-semibold me-auto&quot;&gt;Velzon&lt;/span&gt;
&lt;small&gt;06 mins ago&lt;/small&gt;
&lt;button type=&quot;button&quot; class=&quot;btn-close&quot;
data-bs-dismiss=&quot;toast&quot; aria-label=&quot;Close&quot;&gt;&lt;/button&gt;
&lt;/div&gt;
&lt;div class=&quot;toast-body&quot;&gt;
Hello, world! This is a toast message.
&lt;/div&gt;
&lt;/div&gt;
&lt;/div&gt;</code>

<code> &lt;!-- Flexbox container for aligning the toasts --&gt;
&lt;div aria-live=&quot;polite&quot; aria-atomic=&quot;true&quot;
class=&quot;bg-light d-flex justify-content-center align-items-center&quot;
style=&quot;height: 200px;&quot;&gt;
&lt;div class=&quot;toast fade show&quot; role=&quot;alert&quot; aria-live=&quot;assertive&quot;
data-bs-autohide=&quot;false&quot; aria-atomic=&quot;true&quot;&gt;
&lt;div class=&quot;toast-header&quot;&gt;
&lt;img src=&quot;assets/images/logo-sm.png&quot;
class=&quot;rounded me-2&quot; alt=&quot;...&quot; height=&quot;20&quot;&gt;
&lt;span class=&quot;fw-semibold me-auto&quot;&gt;Velzon&lt;/span&gt;
&lt;small&gt;11 mins ago&lt;/small&gt;
&lt;button type=&quot;button&quot; class=&quot;btn-close&quot;
data-bs-dismiss=&quot;toast&quot; aria-label=&quot;Close&quot;&gt;&lt;/button&gt;
&lt;/div&gt;
&lt;div class=&quot;toast-body&quot;&gt;
Hello, world! This is a toast message.
&lt;/div&gt;
&lt;/div&gt;
&lt;/div&gt;</code></pre>
        </div>
      </div>
      <!-- end card-body -->
    </div>
    <!-- end card -->
  </div>
  <!-- end col -->
</div>
<!-- end row -->

<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-header align-items-center d-flex">
        <h4 class="card-title mb-0 flex-grow-1">Toastify JS</h4>
        <div class="flex-shrink-0">
          <div class="form-check form-switch form-switch-right form-switch-md">
            <label for="toastify-showcode" class="form-label text-muted"
              >Show Code</label
            >
            <input
              class="form-check-input code-switcher"
              type="checkbox"
              id="toastify-showcode"
              (click)="ShowCode($event)"
            />
          </div>
        </div>
      </div>
      <!-- end card header -->

      <div class="card-body">
        <p class="text-muted">
          Use <code>data-toast</code> <code>data-toast-text=""</code>
          <code>data-toast-gravity=""</code>
          <code>data-toast-position=""</code>
          <code>data-toast-className=""</code>
          <code>data-toast-duration=""</code>
          <code>data-toast-close="close"</code>
          <code>data-toast-style="style"</code> as per your toast requirement.
        </p>
        <div class="live-preview">
          <div class="hstack flex-wrap gap-2">
            <button
              type="button"
              data-toast
              data-toast-text="Welcome Back! This is a Toast Notification"
              data-toast-gravity="top"
              data-toast-position="right"
              data-toast-className="primary"
              data-toast-duration="3000"
              data-toast-close="close"
              data-toast-style="style"
              class="btn btn-light w-xs"
              (click)="showStandard()"
            >
              Default
            </button>
            <button
              type="button"
              data-toast
              data-toast-text="Your application was successfully sent"
              data-toast-gravity="top"
              data-toast-position="center"
              data-toast-className="success"
              data-toast-duration="3000"
              class="btn btn-light w-xs"
              (click)="showSuccess()"
            >
              Success
            </button>
            <button
              type="button"
              data-toast
              data-toast-text="Warning ! Something went wrong try again"
              data-toast-gravity="top"
              data-toast-position="center"
              data-toast-className="warning"
              data-toast-duration="3000"
              class="btn btn-light w-xs"
              (click)="showWarning()"
            >
              Warning
            </button>
            <button
              type="button"
              data-toast
              data-toast-text="Error ! An error occurred."
              data-toast-gravity="top"
              data-toast-position="center"
              data-toast-className="danger"
              data-toast-duration="3000"
              class="btn btn-light w-xs"
              (click)="showDanger()"
            >
              Error
            </button>
          </div>
          <!--end row-->
        </div>
        <div class="d-none code-view">
          <pre class="language-markup" style="height: 275px">
<code>&lt;!-- Toast --&gt;
&lt;div class=&quot;hstack flex-wrap gap-2&quot;&gt;
&lt;button type=&quot;button&quot; data-toast data-toast-text=&quot;Welcome Back! This is a Toast Notification&quot; data-toast-gravity=&quot;top&quot; data-toast-position=&quot;right&quot; data-toast-className=&quot;primary&quot; data-toast-duration=&quot;3000&quot; data-toast-close=&quot;close&quot; data-toast-style=&quot;style&quot; class=&quot;btn btn-light w-xs &quot;&gt;Default&lt;/button&gt;
&lt;button type=&quot;button&quot; data-toast data-toast-text=&quot;Your application was successfully sent&quot; data-toast-gravity=&quot;top&quot; data-toast-position=&quot;center&quot; data-toast-className=&quot;success&quot; data-toast-duration=&quot;3000&quot; class=&quot;btn btn-light w-xs&quot;&gt;Success&lt;/button&gt;
&lt;button type=&quot;button&quot; data-toast data-toast-text=&quot;Warning ! Something went wrong try again&quot; data-toast-gravity=&quot;top&quot; data-toast-position=&quot;center&quot; data-toast-className=&quot;warning&quot; data-toast-duration=&quot;3000&quot; class=&quot;btn btn-light w-xs&quot;&gt;Warning&lt;/button&gt;
&lt;button type=&quot;button&quot; data-toast data-toast-text=&quot;Error ! An error occurred.&quot; data-toast-gravity=&quot;top&quot; data-toast-position=&quot;center&quot; data-toast-className=&quot;danger&quot; data-toast-duration=&quot;3000&quot; class=&quot;btn btn-light w-xs&quot;&gt;Error&lt;/button&gt;
&lt;/div&gt;</code>

<code>&lt;!-- Display Position --&gt;
&lt;div class=&quot;hstack flex-wrap gap-2&quot;&gt;
&lt;button type=&quot;button&quot; data-toast data-toast-text=&quot;Welcome Back ! This is a Toast Notification&quot; data-toast-gravity=&quot;top&quot; data-toast-position=&quot;left&quot; data-toast-duration=&quot;3000&quot; data-toast-close=&quot;close&quot; class=&quot;btn btn-light w-xs&quot;&gt;Top Left&lt;/button&gt;
&lt;button type=&quot;button&quot; data-toast data-toast-text=&quot;Welcome Back ! This is a Toast Notification&quot; data-toast-gravity=&quot;top&quot; data-toast-position=&quot;center&quot; data-toast-duration=&quot;3000&quot; data-toast-close=&quot;close&quot; class=&quot;btn btn-light w-xs&quot;&gt;Top Center&lt;/button&gt;
&lt;button type=&quot;button&quot; data-toast data-toast-text=&quot;Welcome Back ! This is a Toast Notification&quot; data-toast-gravity=&quot;top&quot; data-toast-position=&quot;right&quot; data-toast-duration=&quot;3000&quot; data-toast-close=&quot;close&quot;  class=&quot;btn btn-light w-xs&quot;&gt;Top Right&lt;/button&gt;
&lt;button type=&quot;button&quot; data-toast data-toast-text=&quot;Welcome Back ! This is a Toast Notification&quot; data-toast-gravity=&quot;bottom&quot; data-toast-position=&quot;left&quot; data-toast-duration=&quot;3000&quot; data-toast-close=&quot;close&quot; class=&quot;btn btn-light w-xs&quot;&gt;Bottom Left&lt;/button&gt;
&lt;button type=&quot;button&quot; data-toast data-toast-text=&quot;Welcome Back ! This is a Toast Notification&quot; data-toast-gravity=&quot;bottom&quot; data-toast-position=&quot;center&quot; data-toast-duration=&quot;3000&quot; data-toast-close=&quot;close&quot; class=&quot;btn btn-light w-xs&quot;&gt;Bottom Center&lt;/button&gt;
&lt;button type=&quot;button&quot; data-toast data-toast-text=&quot;Welcome Back ! This is a Toast Notification&quot; data-toast-gravity=&quot;bottom&quot; data-toast-position=&quot;right&quot; data-toast-duration=&quot;3000&quot; data-toast-close=&quot;close&quot; class=&quot;btn btn-light w-xs&quot;&gt;Bottom Right&lt;/button&gt;
&lt;/div&gt;</code>

<code>&lt;!-- Offset Position --&gt;
&lt;button type=&quot;button&quot; data-toast data-toast-text=&quot;Welcome Back ! This is a Toast Notification&quot; data-toast-gravity=&quot;top&quot; data-toast-position=&quot;right&quot; data-toast-duration=&quot;3000&quot; data-toast-offset data-toast-close=&quot;close&quot; class=&quot;btn btn-light w-xs&quot;&gt;Click Me&lt;/button&gt;;</code>

<code>&lt;!-- Close icon Display --&gt;
&lt;button type=&quot;button&quot; data-toast data-toast-text=&quot;Welcome Back ! This is a Toast Notification&quot; data-toast-position=&quot;right&quot; data-toast-duration=&quot;3000&quot; data-toast-close=&quot;close&quot; class=&quot;btn btn-light w-xs&quot;&gt;Click Me&lt;/button&gt;</code>

<code>&lt;!-- Duration --&gt;
&lt;button type=&quot;button&quot; data-toast data-toast-text=&quot;Toast Duration 5s&quot; data-toast-gravity=&quot;top&quot; data-toast-position=&quot;right&quot; data-toast-duration=&quot;5000&quot; class=&quot;btn btn-light w-xs&quot;&gt;Click Me&lt;/button&gt;</code></pre>
        </div>
      </div>
      <!-- end card-body -->
    </div>
    <!-- end card -->
  </div>
  <!-- end col -->
</div>
<!-- end row -->

<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
