import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";

@Directive({
  standalone: true,
  selector: "[isNewItem]",
})
/**
 * Directive: Kiểm tra xem item có phải mới nhất không?
 */
export class IsNewItemDirective {
  readonly TODAY = new Date();

  private _days: number = 3;

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>
  ) {}

  @Input() set isNewItem(date: string | Date) {
    const _date = new Date(date);
    const _isNew = Boolean(
      _date.getFullYear() === this.TODAY.getFullYear() &&
        _date.getMonth() === this.TODAY.getMonth() &&
        this.TODAY.getDate() - _date.getDate() <= this._days
    );

    if (_isNew) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  @Input() set isNewItemDays(value: number) {
    this._days = value || 3;
  }
}
