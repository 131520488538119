import {
  AfterViewInit,
  Component,
  ElementRef,
  Injector,
  Input,
  ViewChild,
} from "@angular/core";
import { RealEstateService } from "src/app/public/services/real-estate/real-estate.service";
import {
  BehaviorSubject,
  combineLatest,
  fromEvent,
  Observable,
  Subject,
  throwError,
} from "rxjs";
import { catchError, debounceTime, map, tap } from "rxjs/operators";
import * as XLSX from "xlsx";

import { GetCodeOfCityUtil } from "src/app/public/utils/get-code-of-city.util";
import { CategoryService } from "src/app/public/services/categories/category.service";
import { getCodeOfDistrictUtil } from "src/app/public/utils/get-code-of-district.util";
import { getCodeOfTownUtil } from "src/app/public/utils/get-code-of-town.util";
import { LandPurposePipe } from "src/app/public/pipes/land-purpose.pipe";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ERRORS_SERVER } from "src/app/public/constants/handle-error.constants";
import { LAND_PURPOSE } from "src/app/public/constants/common.constant";
import { convertJsonToXlsxFile } from "src/app/public/utils/convert-xlsx-to-json.util";
import { LAND_STATUS } from "src/app/public/constants/real-estate.constant";
import { getCodeTypeOfRealEstate } from "src/app/public/utils/get-code-real-estate-type.util";
import { ToastService } from "src/app/components/notifications/toast-service";

@Component({
  selector: "app-add-bulk-real-estate-modal",
  templateUrl: "./add-bulk-real-estate-modal.component.html",
  styleUrls: ["./add-bulk-real-estate-modal.component.scss"],
  providers: [LandPurposePipe],
})
export class AddBulkRealEstateModalComponent implements AfterViewInit {
  jsonData$ = new BehaviorSubject<any>([]);
  newJsonData$ = new BehaviorSubject<any>([]);
  jsonDataErrors$ = new BehaviorSubject<any>([]);
  dataValid$ = new Subject();
  dataInvalid$ = new Subject<any>();
  hasErrorsUpload = false;

  @Input() set data(data: any) {
    this.jsonData$.next(data);
  }

  @ViewChild("btnSaveRealEstate") btnSaveRealEstate: ElementRef;
  @ViewChild("fileError") fileError: ElementRef;

  constructor(
    public activeModal: NgbActiveModal,
    private realEstateService: RealEstateService,
    private injector: Injector,
    public toastService: ToastService
  ) {
    this.observerInputData$();
  }

  ngAfterViewInit(): void {
    this.observerClickSave$();
  }

  public observerInputData$(): void {
    const newJsonData$ = new Observable((observable) => {
      this.jsonData$
        .pipe(
          map((data: any) => {
            const observers = data.map((d: any) => {
              return this.transFormData(d) as Observable<any>;
            });
            return combineLatest(observers)
              .pipe(
                tap((results) => {
                  observable.next(results);
                })
              )
              .subscribe();
          })
        )
        .subscribe();
    }) as BehaviorSubject<any>;

    // @ts-ignore
    newJsonData$.subscribe((data) => {
      this.newJsonData$.next(data);
    });
  }

  public observerClickSave$(): void {
    const click$ = fromEvent(this.btnSaveRealEstate.nativeElement, "click");
    click$.subscribe((_) => {
      this.addBulkRealEstate(this.newJsonData$.value);
    });
  }

  showToastError(error: any): void {
    // @ts-ignore
    this.toastService.show(ERRORS_SERVER[error?.code], {
      classname: "bg-danger text-center text-white",
      delay: 5000,
    });
  }

  showToastSuccess(): void {
    // @ts-ignore
    this.toastService.show("Tạo mới BĐS thành công!", {
      classname: "bg-success text-center text-white",
      delay: 5000,
    });
  }

  private transFormData(data: any): Observable<any> {
    return new Observable((observable) => {
      combineLatest(
        getCodeOfTownUtil(data?.town, this.injector.get(CategoryService)),
        getCodeOfDistrictUtil(
          data?.district,
          this.injector.get(CategoryService)
        ),
        GetCodeOfCityUtil(data?.city, this.injector.get(CategoryService)),
        getCodeTypeOfRealEstate(data?.type, this.injector.get(CategoryService))
      ).subscribe((results) => {
        // Get address customer-1
        const addressCustomer1 = [];
        if (data?.phone1_1)
          addressCustomer1.push({
            type: "CM",
            phone: data?.phone1_1?.toString(),
          });
        if (data?.phone1_2)
          addressCustomer1.push({
            type: "CM",
            phone: data?.phone1_2?.toString(),
          });
        if (data?.phone1_3)
          addressCustomer1.push({
            type: "CM",
            phone: data?.phone1_3?.toString(),
          });

        const customers = [
          {
            fullName: data.customerName,
            address: addressCustomer1,
          },
        ];

        // Get address customer-2
        const addressCustomer2 = [];
        if (data?.phone2_1)
          addressCustomer2.push({
            type: "CM",
            phone: data?.phone2_1?.toString(),
          });
        if (data?.phone2_2)
          addressCustomer2.push({
            type: "CM",
            phone: data?.phone2_2?.toString(),
          });
        if (data?.phone2_3)
          addressCustomer2.push({
            type: "CM",
            phone: data?.phone2_3?.toString(),
          });

        if (addressCustomer2.length) {
          customers.push({
            fullName: data.customerName1,
            address: addressCustomer2,
          });
        }

        // Tim đúng theo tên quận huyện.
        const proviceId = results[2];
        const districtId = (results[1] as Array<any>)?.find(
          (d) => d.refValue === proviceId
        )?.cKey;
        const townId = (results[0] as Array<any>)?.find(
          (t) => t.refValue === districtId
        )?.cKey;

        // Lấy vị trí lat-long.
        const position = data?.position?.split(",");
        const tenant = data?.tenant
          ? {
              name: data?.tenant,
            }
          : null;

        // @ts-ignore
        const result: any = {
          customers,
          address: {
            houseNbr: data?.houseNbr,
            phone: data?.phone1_1.toString(),
            street: data?.street,
            city: proviceId,
            district: districtId,
            town: townId,
            latitude: this.getFirstValue(position),
            longitude: this.getSecondValue(position),
            type: "LD",
            createdBy: data.phoneOfCollobra?.toString(),
          },
          photos: [],
          createdBy: data.phoneOfCollobrate?.toString(),
          purpose: AddBulkRealEstateModalComponent.getPurpose(data?.purpose),
          channel: "AG",
          price: data.price,
          acreage: data.acreage,
          note: data.note,
          frontWidth: data.frontWidth,
          status: LAND_STATUS.PRICE_DEAL,
          tenant: tenant,
          floors: data?.floors,
          type: results[3],
        };
        observable.next(result);
      });
    });
  }

  private getFirstValue(data: any) {
    if (data && data.length) {
      return data[0].trim();
    }
    return 0;
  }

  private getSecondValue(data: any) {
    if (data && data.length) {
      return data[1].trim();
    }
    return 0;
  }

  private addBulkRealEstate(data: Array<any>): void {
    this.jsonDataErrors$.next([]);

    data.map((d, index) => {
      return this.realEstateService
        .addRealEstate(d)
        .pipe(
          debounceTime(1),
          catchError(({ error }) => {
            const { errors } = error;
            errors.map((error: any) => {
              this.showToastError(error);
              this.jsonDataErrors$.next(
                this.jsonDataErrors$
                  .getValue()
                  .concat([this.jsonData$.getValue()[index]])
              );
            });
            return throwError(errors);
          })
        )
        .subscribe((_) => this.showToastSuccess());
    });

    setTimeout(() => {
      if (!this.jsonDataErrors$.getValue().length) {
        this.closeModal();
      }
    }, 1000);
  }

  private closeModal() {
    if (!this.jsonDataErrors$.value) {
      this.activeModal.close();
    }
  }

  private static getPurpose(data: string): string {
    const _data = data.trim().toLowerCase();
    switch (_data) {
      case "cho thuê":
        return LAND_PURPOSE.FOR_RENT;
      case "bán":
        return LAND_PURPOSE.FOR_SELL;
      default:
        return LAND_PURPOSE.FOR_RENT;
    }
  }

  downloadFileError(): void {
    const file = convertJsonToXlsxFile(this.jsonDataErrors$.getValue());
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, file, "Sheet1");
    XLSX.writeFileXLSX(workbook, "danh-sach-bds-upload-khong-thanh-cong.xlsx");

    this.closeModal();
  }
}
