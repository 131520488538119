import {
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef
} from "@angular/core";
import { AuthorizationService } from "src/app/public/services/auth/authorization.service";

@Directive({
  selector: "[hasPermissionResource]",
})
export class CheckPermissionResourceDirective {
  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private authorizationService: AuthorizationService
  ) {}

  // @ts-ignore
  @Input() set hasPermissionResource(resource?: string) {
    if (this.authorizationService.hasPermissionResource(resource)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
