import { Directive, ElementRef, Input, Renderer2 } from "@angular/core";

@Directive({
  selector: "[appCompareEditField]",
})
export class CompareEditFieldDirective {
  @Input() index: number;
  @Input() field: string;
  @Input() auditCompares: any[];

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnChanges() {
    this.handleCompareField();
  }

  private handleCompareField() {
    const [newData, oldData] = this.auditCompares.map((compare) =>
      this.getNestedValue(compare?.data, this.field)
    );
    const isArray = Array.isArray(newData) && Array.isArray(oldData);

    const isShowBgColor = !isArray
      ? newData !== oldData
      : newData?.length !== oldData?.length;

    if (isShowBgColor) {
      this.applyHighlight();
    }
  }

  private applyHighlight() {
    const bgColor = this.index === 0 ? "#2ea04326" : "#f8514926";
    const color = this.index === 0 ? "green" : "red";
    this.setStyle("color", color);
    this.setStyle("background-color", bgColor);
    this.setStyle("padding", "4px 8px");
  }

  private setStyle(type: string, style: string) {
    this.renderer.setStyle(this.el.nativeElement, type, style);
  }

  private getNestedValue(obj: any, path: string): any {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj);
  }
}
