import { Injectable } from "@angular/core";
import { MapState } from "src/app/public/services/google-maps/map.state";
import { BehaviorSubject, combineLatest, Observable, of } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { FormSearchRealEstate } from "src/app/public/models/form-search-realestate.model";
import { API_URL } from "src/app/public/constants/api-url.constant";
import { RealEstateMaker } from "src/app/public/models/real-estate-maker.mode";
import { BaseHttpRequest } from "src/app/public/services/http/base-http-request.service";
import { filter, switchMap, tap } from "rxjs/operators";
import LatLngBounds = google.maps.LatLngBounds;
import {
  EVENT_BUS_EVENTS,
  EventBusService,
} from "src/app/public/services/common/event-bus.service";

@Injectable({
  providedIn: "root",
})
export class MapSyncDataRealEstateService {
  readonly PAGE_DEFAULT = 0;
  readonly LIMIT_DEFAULT = 100;
  readonly DEFAULT_CACHING_PAGE = 4;

  private _realEstatePaging = new BehaviorSubject<any>(null);
  private _realEstateMakers = new BehaviorSubject<any[]>(null);
  private _realEstateCached = new Map<String, any>();
  private _allowGetApi: Boolean = true;
  private _isLoading$ = new BehaviorSubject<boolean>(false);

  constructor(
    private httpRequest: BaseHttpRequest,
    private mapState: MapState,
    private route: ActivatedRoute,
    private eventBus: EventBusService
  ) {
    this.observerMapState();
    this.observerRealEstateMark();
  }

  set realEstatePaging(value: any) {
    this._realEstatePaging.next(value);
  }

  set realEstateMakers(value: RealEstateMaker[]) {
    this._realEstateMakers.next(value);
  }

  getRealEstatePaging(): Observable<any> {
    return this._realEstatePaging.asObservable();
  }

  getMakers(): Observable<RealEstateMaker[]> {
    return this._realEstateMakers.asObservable();
  }

  getLoading(): Observable<boolean> {
    return this._isLoading$.asObservable();
  }

  observerRealEstateMark(): void {
    this.eventBus.on(EVENT_BUS_EVENTS.REAL_ESTATE_MARK).subscribe((event) => {
      // Nếu bật chế độ chỉ xem BĐS chấm điểm thì tắt k cho call API khi drag-drop trên map.
      this._allowGetApi = !event;
      // Nếu tắt đi thì trigger để call API tại vị trí nó đang đứng trên bản đồ.
      if (!event) this.mapState.allowCallApi$ = this._allowGetApi;
    });
  }

  observerMapState(): void {
    const boundaries$ = this.mapState.boundaries$;
    const polygons$ = this.mapState.polygon$;
    const param$ = this.route.queryParams;
    const allowCallApi$ = this.mapState.allowCallApi$;
    // Nếu bật chế độ xem bđs đã đánh dấu thì k gọi mới API

    combineLatest([boundaries$, polygons$, param$, allowCallApi$])
      .pipe(
        switchMap((data: any) => {
          const params = data[2];
          const page = Number(params["page"] || 1);
          const allowCallApi = data[3];

          if (!allowCallApi || !this._allowGetApi) return of(null);
          if (page > 1) {
            const dataCaching = this.getDataFromCache(String(page - 1));
            if (dataCaching) {
              this.realEstatePaging = dataCaching;
              return of(null);
            }
          }

          return of(data);
        }),
        filter((d) => d !== null)
      )
      .subscribe((changed: any[]) => {
        if (changed === null) return;

        const boundaries = changed[0];
        const polygons = changed[1];
        const params = changed[2];

        this.getRealEstate(
          boundaries,
          polygons ? polygons : this.populationPolygon(boundaries),
          params
        );
      });
  }

  private getDataFromCache(key: string): any {
    return this._realEstateCached.get(key);
  }

  private callApi(body: any[], params: any): Observable<any> {
    this._isLoading$.next(true);
    return this.httpRequest.post(API_URL.IN_POLYGON, body, { params }).pipe(
      tap({
        next: () => this._isLoading$.next(false),
        error: () => this._isLoading$.next(false),
      })
    );
  }

  private populationPolygon(boundaries: LatLngBounds): any[] {
    if (!boundaries) return null;
    return this.getPolygonOfMapFrame(boundaries);
  }

  private getRealEstate(boundaries: any, polygons: any, params: any) {
    const parameters = this.prepareDataForRequest(params, boundaries) as any;

    this.callApi(polygons, parameters).subscribe((response) => {
      this.handleResponse({ polygons, params }, response);
      this.calculateCaching({ polygons, params, boundaries }, response);
    });
  }

  private calculateCaching(request: any, response: any) {
    const totalPage = response?.totalPages;
    const currentPage = !isNaN(Number(request?.params?.page))
      ? Number(request?.params?.page)
      : 1;

    if (totalPage > 1 && currentPage === 1) {
      let apis$ = [];
      const params = this.prepareDataForRequest(
        request?.params,
        request.boundaries
      );
      for (let i = 1; i < this.DEFAULT_CACHING_PAGE; i++) {
        if (i > totalPage) break;
        apis$.push(this.callApi(request?.polygons, { ...params, page: i }));
      }

      combineLatest(apis$).subscribe((res) => {
        res.forEach((data, key) => {
          this._realEstateCached.set(String(key + 1), {
            data: data?.data,
            totalItems: data?.totalItems,
            pageNumber: key + 2,
            pageSize: Number(request?.params["limit"]),
          });
        });
        this.realEstateMakers = this.transformToMakers();
      });
    }
  }

  private handleResponse(request: any, response: any) {
    const currentPage = request?.params?.page || 1;

    if (Number(currentPage) === 1) {
      this._realEstateCached.clear();
      this._realEstateMakers.next(null);
      this.realEstateMakers = null;
    }

    const newData = {
      data: response?.data,
      totalItems: response?.totalItems,
      pageNumber: Number(request?.params["page"]),
      pageSize: Number(request?.params["limit"]),
    };
    this.realEstatePaging = newData;
    this._realEstateCached.set(String(currentPage - 1), newData);
    this.realEstateMakers = this.transformToMakers();
  }

  private transformToMakers(): any[] {
    let makers: any[] = this._realEstateMakers.value || [];
    this._realEstateCached.forEach((realEstates: any) => {
      const _makers = realEstates?.data?.map(
        (realEstate: {
          photos: any;
          address: { latitude: any; longitude: any };
          purpose: any;
          id: any;
          toilets: any;
          bedrooms: any;
          acreage: any;
          frontWidth: any;
          price: any;
        }) => {
          return {
            photos: realEstate?.photos,
            latitude: realEstate?.address?.latitude,
            longitude: realEstate?.address?.longitude,
            purpose: realEstate?.purpose,
            id: realEstate?.id,
            toilets: realEstate?.toilets,
            bedrooms: realEstate?.bedrooms,
            acreage: realEstate?.acreage,
            frontWidth: realEstate?.frontWidth,
            price: realEstate?.price,
            address: realEstate?.address,
          };
        }
      );

      makers = makers.concat(...(_makers || []));
    });

    return makers;
  }

  private prepareDataForRequest(
    params: any,
    boundaries: LatLngBounds
  ): FormSearchRealEstate {
    const _params = { ...params };
    _params["page"] = !isNaN(Number(params["page"]))
      ? Number(params["page"]) - 1
      : this.PAGE_DEFAULT;
    _params["limit"] = params["limit"] || this.LIMIT_DEFAULT;
    _params["search"] = "";
    _params["address"] = "";
    _params["city"] = "";
    _params["district"] = "";

    if (boundaries) {
      _params["centerLat"] = boundaries.getCenter().lat();
      _params["centerLng"] = boundaries.getCenter().lng();
      _params["radius"] = this.getDistance(boundaries);
    }

    return _params as FormSearchRealEstate;
  }

  private getPolygonOfMapFrame(bounds: LatLngBounds) {
    const northEast = bounds.getNorthEast();
    const southWest = bounds.getSouthWest();
    const southEast = new google.maps.LatLng(southWest.lat(), northEast.lng());
    const northWest = new google.maps.LatLng(northEast.lat(), southWest.lng());

    return [
      northEast.toJSON(),
      southEast.toJSON(),
      southWest.toJSON(),
      northWest.toJSON(),
    ];
  }

  private getDistance(bounds: LatLngBounds): number {
    const rad = (x: number) => {
      return (x * Math.PI) / 180;
    };

    if (!bounds) return 0;

    const p1 = bounds.getSouthWest(); // điểm hướng bắc.
    const p2 = bounds.getCenter(); // điểm trung tâm.

    const R = 6378137; // Earth’s mean radius in meter
    const dLat = rad(p2.lat() - p1.lat());
    const dLong = rad(p2.lng() - p1.lng());
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(rad(p1.lat())) *
        Math.cos(rad(p2.lat())) *
        Math.sin(dLong / 2) *
        Math.sin(dLong / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return parseFloat((d / 1000).toFixed(2)); // returns the distance in meter
  }
}
