<div
  class="text-reset notification-item d-block dropdown-item position-relative"
  [ngClass]="{
    'bg-white': !data?.readStatus,
    'bg-light': data?.readStatus
  }"
  (mousedown)="onChangeViewStatus($event, data?.readStatus, data?.id)"
>
  <div class="d-flex">
    <div class="me-3 position-relative">
      <span
        *isNewItem="data?.notification?.createdAt"
        class="position-absolute topbar-badge-custom fs-10 translate-middle badge rounded-pill bg-info"
        >mới<span class="visually-hidden">unread messages</span>
      </span>
      <img
        [src]="notificationData.image | getAvatarImage"
        alt=""
        class="avatar-sm"
      />
    </div>
    <div class="flex-1">
      <a
        class="stretched-link"
        [routerLink]="[
          '/real-estate-management/detail',
          notificationData?.realEstateId
        ]"
        [queryParams]="{ tab: 'match-orders' }"
        ><h6 class="mt-0 mb-1 fs-13 fw-semibold text-primary">
          {{ notificationData?.title }}
        </h6></a
      >
      <div class="fs-13 text-muted">
        <p class="mb-1">
          {{ notificationData?.body }}
        </p>
      </div>
      <div class="avatar-group mb-2 position-relative" style="z-index: 1">
        <div
          class="avatar-group-item"
          *ngFor="let order of notificationData?.orders?.listOrdersResponse"
        >
          <div
            class="avatar-xs"
            [ngbTooltip]="order.brand.name"
            container="body"
          >
            <img
              [src]="
                order.brand.avatar || 'assets/images/users/user-dummy-img.jpg'
              "
              class="rounded-circle w-100 h-100 object-cover"
            />
          </div>
        </div>
      </div>
      <p class="mb-0 fs-11 fw-medium text-uppercase text-muted">
        <span
          ><i class="mdi mdi-clock-outline"></i>
          {{ data?.createdAt | transformToTimeAgo }}</span
        >
      </p>
    </div>
    <div class="px-2 fs-15">
      <div class="form-check notification-check">
        <input
          class="form-check-input"
          type="checkbox"
          [checked]="data?.doneStatus"
          [disabled]="data?.doneStatus"
          (mousedown)="onChangeDoneStatus($event, data?.doneStatus, data?.id)"
          (click)="$event.stopPropagation()"
        />
      </div>
    </div>
  </div>
</div>
