import { inject, Injectable } from "@angular/core";
import { SOCKET_URL } from "@constant/api-url.constant";
import { USER_INFO } from "@constant/common.constant";
import {
  EVENT_BUS_EVENTS,
  EventBusService,
} from "@service/common/event-bus.service";
import { StorageService } from "@service/storage/storage.service";
import { Client, IPublishParams, Message } from "@stomp/stompjs";
import { ToastrService } from "ngx-toastr";
import * as SockJS from "sockjs-client";
import { environment } from "src/environments/environment";
import { NotificationDataService } from "./notification-data.service";

@Injectable({
  providedIn: "root",
})
export class SocketNotificationService {
  private storageService = inject(StorageService);
  private toastr = inject(ToastrService);
  private eventBus = inject(EventBusService);
  private notificationData = inject(NotificationDataService);

  //
  private stompClient: Client;

  connect() {
    const user = this.storageService.get(USER_INFO);
    if (!user) return;

    const params = new URLSearchParams({
      deviceId: user?.id,
    });

    const url: string = `${environment.apiURL}/${
      SOCKET_URL.MESSAGE.CONNECT
    }?${params.toString()}`;

    this.stompClient = new Client({
      // @ts-ignore
      webSocketFactory: () => new SockJS(url),
      reconnectDelay: 5000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 400,
    });

    this.stompClient.onConnect = (frame) => {
      this.registerNotifications();
      // this.publisherSendNotifications();

      this.registerLatestNotification();
      this.publisherLatestNotification();
    };

    this.stompClient.activate();
  }

  /**
   * Listen to message from server.
   * @private
   */
  private registerNotifications() {
    this.stompClient.subscribe(
      `/client/queue/notifications`,
      (message: Message) => {
        // nhận list notification mới ở đây
      }
    );
  }

  /**
   * Send message to server to get message.
   * @private
   */
  private publisherSendNotifications() {
    const params: IPublishParams = {
      destination: `/app-message/notifications`,
      body: JSON.stringify({ name: "" }),
    };
    this.stompClient.publish(params);
  }

  public registerLatestNotification() {
    this.stompClient.subscribe(`/client/queue/alert`, (message: Message) => {
      const data = JSON.parse(message.body).success;
      this.eventBus.emit({
        name: EVENT_BUS_EVENTS.GET_UNREAD_NOTIFICATION,
        value: data.totalUnread,
      });

      if (data.body && data.title) {
        this.toastr.info(data.body, data.title);
        this.notificationData.reloadList();
      }
    });
  }

  public publisherLatestNotification() {
    const params: IPublishParams = {
      destination: `/app-message/alert`,
      body: JSON.stringify({}),
    };

    setTimeout(() => {
      this.stompClient.publish(params);
    }, 2000);
  }
}
