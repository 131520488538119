<ng-container>
  <div class="modal-header">
    <h5 class="modal-title" id="myModalLabel">Copy nhanh nội dung</h5>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      (click)="activeModal.close()"
    ></button>
  </div>

  <!-- Accordions Bordered -->

  <!------------------------------->

  <div
    class="img-copy p-2 position-relative"
    *ngIf="item?.photos.length; else noImage"
  >
    <div
      class="alert alert-primary alert-dismissible alert-label-icon rounded-label fade show custom-tag-info"
      role="alert"
    >
      <i class="ri-alert-line fs-16 label-icon"></i>Click<strong>
        chuột phải</strong
      >
      và bấm <strong>Copy Image</strong> để copy ảnh!
    </div>

    <swiper
      #mainSwiper
      [loop]="false"
      [spaceBetween]="10"
      (slideChange)="realIndexChange($event)"
      [navigation]="true"
      [pagination]="true"
    >
      <ng-container *ngFor="let media of item?.photos">
        <ng-template swiperSlide>
          <img
            *ngIf="CheckStyleUrl(media.url) === 'image'"
            [src]="media.url"
            #imageSlide
            onerror="this.onerror=null; this.src='./assets/images/image-not-available.png'"
            alt="image"
          />
          <video *ngIf="CheckStyleUrl(media.url) === 'video'" controls>
            <source [src]="media.url" type="video/mp4" />
            <source [src]="media.url" type="video/ogg" />
          </video>
        </ng-template>
      </ng-container>
    </swiper>
  </div>
  <div class="p-2">
    <div #bodyText class="body-text">
      <!-- Bordered Tables -->
      <table class="table table-bordered table-nowrap">
        <tbody>
          <tr>
            <th scope="row">Địa chỉ:</th>
            <td>{{ item?.address.fullAddress }}</td>
          </tr>
          <tr>
            <th scope="row">Mặt tiền chủ nhà báo:</th>
            <td>{{ item?.frontWidth }} m</td>
          </tr>
          <tr>
            <th scope="row">Diện tích chủ nhà báo:</th>
            <td>{{ item?.acreage }} m<sup>2</sup></td>
          </tr>
          <tr>
            <th scope="row">Số tầng:</th>
            <td>{{ item?.floors || "--" }}</td>
          </tr>
          <tr>
            <th scope="row">Giá thuê chủ nhà báo:</th>
            <td>{{ item?.price | transformPriceToString }} /tháng</td>
          </tr>
          <tr>
            <th scope="row">Thời gian bàn giao:</th>
            <td>--</td>
          </tr>
          <tr>
            <th scope="row">Định vị:</th>
            <td class="truncate" *ngIf="item.address.latitude">
              https://www.google.com/maps/search/?api=1&query={{
                item?.address?.latitude
              }}%2C{{ item.address?.longitude }}
            </td>
            <td *ngIf="!item.address.latitude"></td>
          </tr>
          <tr>
            <th scope="row">Thông tin chi tiết:</th>
            <td>{{ ADMIN_URL }}search?itemId={{ item?.id }}</td>
          </tr>
<!--          <tr>-->
<!--            <th scope="row">Ghi chú:</th>-->
<!--            <td class="p-0">-->
<!--              <textarea-->
<!--                class="form-control"-->
<!--                name="note"-->
<!--                id="note"-->
<!--                cols="30"-->
<!--                rows="3"-->
<!--                [value]="item.note ? item.note : ''"-->
<!--              ></textarea>-->
<!--            </td>-->
<!--          </tr>-->
        </tbody>
      </table>

      <div class="d-flex">
        <button
          class="d-flex align-items-center btn btn-primary copy-text"
          (click)="copyTextToClipboard()"
        >
          <i class="ri-file-copy-2-fill me-1"></i> Copy nội dung
        </button>
      </div>
    </div>
  </div>
  <ng-template #noImage>
    <div class="img">
      <img src="./assets/images/products/coming-soon.png" />
    </div>
  </ng-template>
</ng-container>
