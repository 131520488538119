import { Component } from "@angular/core";
import { EVENT_BUS_EVENTS, EventBusService } from "src/app/public/services/common/event-bus.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  constructor(private eventBus: EventBusService) {
    eventBus.emit({ name: EVENT_BUS_EVENTS.GET_RESOURCE_INITIAL, value: "" });
  }
}
