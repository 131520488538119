<form
  class="container-content row px-3"
  (keydown.enter)="$event.preventDefault()"
  (submit)="onSubmitForm(notificationForm.value)"
  [formGroup]="notificationForm"
>
  <div class="mb-3 col-12">
    <mat-radio-group
      name="type"
      class="row"
      color="primary"
      (change)="handleOnChange($event)"
    >
      <mat-radio-button class="col-2" checked value="all"
        >Tất cả</mat-radio-button
      >
      <mat-radio-button class="col-2" value="personal"
        >Cá nhân</mat-radio-button
      >
      <mat-radio-button class="col-2" value="group">Nhóm</mat-radio-button>
    </mat-radio-group>
  </div>

  <div class="p-0">
    <div class="col-12 mb-3" *ngIf="type === 'group'">
      <label>Chọn nhóm</label>
      <ng-select
        class="col-md-5 p-0 mb-2 w-100"
        [searchable]="false"
      ></ng-select>
    </div>
    <div class="col-12 mb-3 position-relative" *ngIf="type === 'personal'">
      <label>Tài khoản</label>
      <input type="text" class="form-control" />
    </div>
    <ng-container formGroupName="notification">
      <div class="col-12 mb-3">
        <label>Tiêu đề</label>
        <input
          type="text"
          class="form-control"
          formControlName="title"
          [class.is-invalid]="
            getControl('notification.title').errors &&
            getControl('notification.title').touched
          "
        />
      </div>
      <div class="col-12 mb-3">
        <label>Chi tiết</label>
        <textarea
          class="form-control"
          rows="10"
          formControlName="body"
          [class.is-invalid]="
            getControl('notification.body').errors &&
            getControl('notification.body').touched
          "
        ></textarea>
      </div>
    </ng-container>
  </div>

  <div class="modal-footer p-0">
    <button
      type="button"
      class="btn btn-ghost-danger"
      (click)="handleCloseModal()"
    >
      Huỷ bỏ
    </button>
    <button type="submit" class="btn btn-success">Gửi</button>
  </div>
</form>
