import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "truncateString",
})
export class TruncateStringPipe implements PipeTransform {
  constructor() {}

  transform(value: string, limit: number): string {
    if (value?.length > limit) {
      return value.substring(0, limit) + "...";
    } else {
      return value;
    }
  }
}
