import { inject, Injectable, Injector } from "@angular/core";
import { CallToRealEstateOwnerPayload } from "@model/call-history-info.model";
import { HttpHistoryCallService } from "@service/call-history/http-history-call.service";
import { ConvertDateTimeToString } from "@util/date-time.util";
import { Observable } from "rxjs";

@Injectable()
export class CallHistorySellRentService {
  // inject http-service
  private httpHistoryCallService = inject(HttpHistoryCallService);
  private injector = inject(Injector);

  // create a method to save
  saveCallHistory(data: any): Observable<any> {
    const payload = this.transformDataToPayload(data);
    return this.saveData(payload);
  }

  /**
   * @Override: Cho phép các Service child có quyền override lại method để xử
   * lý cho đúng payload.
   * @param data type any;
   */
  protected transformDataToPayload(data: any): CallToRealEstateOwnerPayload {
    const endTime = ConvertDateTimeToString(new Date(), this.injector);
    return {
      id: data["id"],
      realEstateId: data["realEstateId"],
      customerId: data["customerId"],
      title: data["title"],
      content: data["content"],
      method: data["method"],
      statusCall: data["statusCall"],
      propertyStatusAfter: data["propertyStatusAfter"],
      propertyStatusBefore: data["propertyStatusBefore"],
      caller: data["caller"],
      manager: data["manager"],
      phoneNumber: data["phoneNumber"],
      record: data["record"],
      ext: data["ext"],
      groupId: data["groupId"],
      startTime: data["startTime"],
      endTime,
    } as unknown as CallToRealEstateOwnerPayload;
  }

  /**
   * @override: Cho service children có thể override để thay đổi enpoint hoặc
   * payload model nếu cần.
   * @param payload
   * @private
   */
  private saveData(payload: CallToRealEstateOwnerPayload): Observable<any> {
    return this.httpHistoryCallService.saveCallToRealEstateOwner(payload);
  }
}
