import { AbstractControl } from "@angular/forms";

export function emailValidator(control: AbstractControl): {
  [key: string]: any;
} {
  const emailRegexp = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
  if (control.value && !emailRegexp.test(control.value)) {
    return { invalidEmail: true };
  }
  return null;
}

export function minLengthValidator(control: AbstractControl) {
  const value = control.value;

  if (value && value.length < 3) {
    return { minLength: true };
  }

  return null;
}
