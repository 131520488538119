<div class="customizer-setting d-none d-md-block">
  <div
    class="btn-info btn-rounded shadow-lg btn btn-icon btn-lg p-2"
    data-bs-toggle="offcanvas"
    data-bs-target="#theme-settings-offcanvas"
    aria-controls="theme-settings-offcanvas"
    (click)="toggleRightSidebar()"
  >
    <i class="mdi mdi-spin mdi-cog-outline fs-22"></i>
  </div>
</div>

<div
  class="offcanvas offcanvas-end p-2 customize-offcanvas-rightsidebar"
  tabindex="-1"
  id="theme-settings-offcanvas"
>
  <div class="d-flex align-items-center px-3 pt-3 pb-2 offcanvas-header">
    <h5 class="m-0 me-2">Phím tắt nâng cao</h5>

    <button
      type="button"
      class="btn-close ms-auto"
      data-bs-dismiss="offcanvas"
      aria-label="Close"
      (click)="hide()"
    ></button>
  </div>
  <div class="offcanvas-body p-3">
    <div class="row justify-content-center">
      <div class="col-10">
        <div class="btn-search mb-2">
          <button
            type="button"
            class="btn btn-primary w-100"
            (click)="showSearchModal()"
          >
            Tìm kiếm nhanh
          </button>
        </div>
      </div>
      <div
        class="col-10"
        *hasPermissionAction="
          PERMISSION_ACTIONS.SYNC_DATA;
          resource: OTHER_RESOURCE.OTHER
        "
      >
        <div class="mb-2">
          <a
            type="button"
            class="btn btn-primary w-100 btn-export"
            (click)="handleExportFile()"
          >
            Sync data to elasticsearch
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="progress-spinner-container" *ngIf="loading$ | async">
  <div class="progress-spinner-overlay"></div>
  <div class="progress-spinner">
    <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
  </div>
</div>
