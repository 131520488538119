<div [formGroup]="formRealEstate" class="row">
  <div class="hl-mb-n2">
    <span class="badge badge-soft-primary fs-12"
      ><i class="mdi mdi-circle-medium"></i>Quản lý nội bộ</span
    >
  </div>
  <div class="border border-dashed"></div>

  <div class="py-2 row">
    <div class="col-4 mb-3 form-group" required>
      <label class="form-label">Nhân viên quản lý</label>
      <ng-select
        [items]="employeeList"
        placeholder="Nhân viên quản lý"
        bindLabel="fullName"
        bindValue="username"
        formControlName="manager"
        [readonly]="!isEdit && !!realEstateId"
      ></ng-select>
      <small
        *ngIf="controls['manager'].touched && controls['manager']?.errors"
        class="text-danger"
        >Trường thông tin bắt buộc</small
      >
    </div>
    <div class="col-4 mb-3 form-group" required>
      <label class="form-label">Nhân viên môi giới</label>
      <ng-select
        [items]="employeeList"
        placeholder="Nhân viên môi giới"
        bindLabel="fullName"
        bindValue="username"
        formControlName="brokerage"
        [readonly]="!isEdit && !!realEstateId"
      ></ng-select>
      <small
        *ngIf="controls['brokerage'].touched && controls['brokerage']?.errors"
        class="text-danger"
        >Trường thông tin bắt buộc</small
      >
    </div>
    <div class="col-4 mb-3">
      <label class="form-label">Pháp lý</label>
      <ng-select
        [items]="jurdical$ | async"
        placeholder="Pháp lý"
        bindValue="cKey"
        bindLabel="cValue"
        formControlName="businessStatus"
        [readonly]="!isEdit && !!realEstateId"
      ></ng-select>
      <small
        *ngIf="
          controls['businessStatus'].touched &&
          controls['businessStatus']?.errors
        "
        class="text-danger"
        >Trường thông tin bắt buộc</small
      >
    </div>
    <div class="col-4 mb-3">
      <label class="form-label">Phí môi giới</label>
      <div class="input-group">
        <input
          type="number"
          class="form-control"
          placeholder="Phí môi giới"
          formControlName="brokerageFee"
          [readonly]="!isEdit && !!realEstateId"
        />
        <span class="input-group-text bg-soft-light text-muted prefix-width"
          >%</span
        >
        <small
          *ngIf="
            controls['brokerageFee'].touched && controls['brokerageFee']?.errors
          "
          class="text-danger"
          >Trường thông tin bắt buộc</small
        >
      </div>
    </div>
    <div class="col-4 mb-3">
      <label class="form-label">Thời gian bàn giao mặt bằng</label>
      <div class="search-box">
        <input
          class="flatpickr-input form-control"
          type="text"
          placeholder="Chọn thời gian bàn giao mặt bằng"
          mwlFlatpickr
          altFormat="d/m/Y"
          [locale]="language"
          [altInput]="true"
          [convertModelValue]="false"
          formControlName="siteHandoverTime"
        />
        <i class="ri-calendar-2-line search-icon"></i>
        <small
          *ngIf="
            controls['siteHandoverTime'].touched &&
            controls['siteHandoverTime']?.errors
          "
          class="text-danger"
          >Trường thông tin bắt buộc</small
        >
      </div>
    </div>
    <div class="col-4 mb-3">
      <label class="form-label">Thời hạn hợp đồng</label>
      <div class="search-box">
        <input
          class="flatpickr-input form-control"
          type="text"
          placeholder="Chọn hạn hợp đồng"
          mwlFlatpickr
          altFormat="d/m/Y"
          [locale]="language"
          [altInput]="true"
          [convertModelValue]="false"
          formControlName="contractTerm"
        />
        <i class="ri-calendar-2-line search-icon"></i>
        <small
          *ngIf="
            controls['contractTerm'].touched && controls['contractTerm']?.errors
          "
          class="text-danger"
          >Trường thông tin bắt buộc</small
        >
      </div>
    </div>
    <div class="col-12 mb-3">
      <label class="form-label">Ghi chú nội bộ</label>
      <ckeditor [editor]="Editor" formControlName="note"></ckeditor>
    </div>
  </div>
</div>
