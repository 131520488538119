import { Component, Input } from "@angular/core";

@Component({
  selector: "app-notify-empty",
  templateUrl: "./notify-empty.component.html",
  standalone: true,
})
export class NotifyEmptyComponent {
  private messageMap: Record<string, string> = {
    "1": "Bạn không có thông báo nào.",
    "2": "Bạn không có tin nhắn nào.",
  };

  @Input() type: string = "1";
  public content: string;

  ngOnChanges(): void {
    this.content = this.messageMap[this.type] || "Bạn không có thông báo nào.";
  }
}
