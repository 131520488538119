<div class="table-responsive table-card">
  <table class="table" *ngIf="tableType === 'tableRealEstate'">
    <tbody>
      <tr *ngFor="let i of rowSkeleton">
        <th
          class="tg-cly1 d-flex align-items-center justify-content-start gap-3"
        >
          <div
            class="line"
            style="width: 60px; height: 40px; margin-left: 25px"
          ></div>
          <div
            class="line"
            style="height: 40px; width: 100px; margin-left: 10px"
          >
            <div
              class="line"
              style="
                width: 80px;
                background: #80787826 !important;
                margin-left: 10px;
              "
            ></div>
          </div>
          <div class="line" style="width: 75px; height: 40px">
            <div
              class="line"
              style="
                width: 52px;
                background: #80787826 !important;
                margin-left: 10px;
              "
            ></div>
          </div>
          <div
            class="line"
            style="
              margin-left: 10px;
              margin-right: 10px;
              width: 85px;
              height: 40px;
            "
          >
            <div
              class="line"
              style="
                width: 60px;
                background: #80787826 !important;
                margin-left: 10px;
              "
            ></div>
          </div>
          <div class="line" style="width: 115px; height: 40px">
            <div
              class="line"
              style="
                width: 95px;
                background: #80787826 !important;
                margin-left: 10px;
              "
            ></div>
          </div>
          <div class="line" style="width: 140px; height: 40px">
            <div
              class="line"
              style="
                width: 115px;
                background: #80787826 !important;
                margin-left: 10px;
              "
            ></div>
          </div>
          <div class="line" style="width: 155px; height: 40px">
            <div
              class="line"
              style="
                width: 135px;
                background: #80787826 !important;
                margin-left: 10px;
              "
            ></div>
          </div>
          <div class="line" style="width: 95px; height: 40px">
            <div
              class="line"
              style="
                width: 75px;
                background: #80787826 !important;
                margin-left: 10px;
              "
            ></div>
          </div>
          <div class="line" style="width: 95px; height: 40px">
            <div
              class="line"
              style="
                width: 75px;
                background: #80787826 !important;
                margin-left: 10px;
              "
            ></div>
          </div>
          <div class="line" style="width: 90px; height: 40px">
            <div
              class="line"
              style="
                width: 70px;
                background: #80787826 !important;
                margin-left: 10px;
              "
            ></div>
          </div>
          <div class="line" style="width: 65px; height: 40px">
            <div
              class="line"
              style="
                width: 45px;
                background: #80787826 !important;
                margin-left: 10px;
              "
            ></div>
          </div>
          <div class="line" style="width: 105px; height: 40px">
            <div
              class="line"
              style="
                width: 85px;
                background: #80787826 !important;
                margin-left: 10px;
              "
            ></div>
          </div>
          <div class="line" style="width: 110px; height: 40px">
            <div
              class="line"
              style="
                width: 90px;
                background: #80787826 !important;
                margin-left: 10px;
              "
            ></div>
          </div>
          <div
            class="line"
            style="width: 120px; height: 40px; margin-left: 10px"
          >
            <div
              class="line"
              style="
                width: 100px;
                background: #80787826 !important;
                margin-left: 10px;
              "
            ></div>
          </div>
          <div
            class="line align-items-center d-flex justify-content-center"
            style="width: 55px; height: 45px; margin-right: 10px"
          >
            <div class="second-square"></div>
          </div>
        </th>
      </tr>
    </tbody>
  </table>

  <table class="table" *ngIf="tableType === 'tableUser'">
    <tbody>
      <tr *ngFor="let i of rowSkeleton">
        <th class="d-flex align-items-center justify-content-start gap-3">
          <div class="text-line skeleton"></div>
        </th>
      </tr>
    </tbody>
  </table>
</div>
