import {
  Directive,
  ElementRef,
  Input,
  TemplateRef,
  ViewContainerRef,
} from "@angular/core";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "[isNewRealEstate]",
})
/**
 * Directive: Kiểm tra xem BĐS có phải mới nhất không? sản phẩm mới là được tạo 3 ngày gần đây.
 */
export class IsNewRealEstateDirective {
  readonly TODAY = new Date();
  readonly CONDITION_NEW = 3;

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private _el: ElementRef
  ) {}

  @Input() set isNewRealEstate(date: string) {
    const _date = new Date(date);
    const _isNew = Boolean(
      _date.getFullYear() === this.TODAY.getFullYear() &&
        _date.getMonth() === this.TODAY.getMonth() &&
        this.TODAY.getDate() - _date.getDate() <= this.CONDITION_NEW
    );

    if (_isNew) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
