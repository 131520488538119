import { Component, Input } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { LANDS_TYPE } from "src/app/public/constants/real-estate.constant";

@Component({
  selector: "app-filter-purpose",
  templateUrl: "./filter-purpose.component.html",
  styleUrls: ["./filter-purpose.component.scss"],
})
export class FilterPurposeComponent {
  @Input() childControl = new UntypedFormControl();
  @Input() isMutipleChoose: boolean = true;
  @Input() disabled: boolean;

  public items = LANDS_TYPE.map((i) => {
    return { id: i.cKey, title: i.cValue };
  });
}
