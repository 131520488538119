import { Component, Input } from "@angular/core";
import { FormGroup, UntypedFormControl } from "@angular/forms";
import {
  OWNER_GROUPS,
  REAL_ESTATE_STATUS_FOR_ADMIN_APPROVED,
} from "src/app/public/constants/real-estate.constant";

@Component({
  selector: "app-filter-common-info",
  templateUrl: "./filter-common-info.component.html",
})
export class FilterCommonInfoComponent {
  @Input() formSearchAndFilter: FormGroup;

  public STATUS_APPROVED = REAL_ESTATE_STATUS_FOR_ADMIN_APPROVED;
  public OWNER_GROUPS = OWNER_GROUPS;

  public getControl(name: string) {
    return this.formSearchAndFilter.controls[name] as UntypedFormControl;
  }
}
