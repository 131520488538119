import { AsyncPipe, DatePipe, NgForOf, NgIf } from "@angular/common";
import { Component, inject, Input } from "@angular/core";
import { MatTableModule } from "@angular/material/table";
import { CallHistoryContainerComponent } from "@component/call-history-form-to-owner/call-history-container.component";
import { TableComponent } from "@component/tables/table/table.component";
import { REQUEST_DEFAULT } from "@constant/http-request.constant";
import { COLUMNS_USER_HISTORY_INFO } from "@constant/table.constant";
import { RealEstateModel } from "@model/real-estate.model";
import {
  NgbModal,
  NgbPaginationModule,
  NgbTooltipModule,
} from "@ng-bootstrap/ng-bootstrap";
import { GetFullStatusPipe } from "@pipe/get-full-status.pipe";
import { InvisiblePhoneNumberPipe } from "@pipe/invisible-phone-number.pipe";
import { ContactSearchParams } from "@service/call-history/http-history-call.service";
import { StateContactHistoryService } from "@service/call-history/state-contact-history.service";

@Component({
  selector: "call-history-list-component",
  templateUrl: "./call-history-list.component.html",
  styleUrls: ["./call-history-list.component.scss"],
  imports: [
    NgForOf,
    MatTableModule,
    AsyncPipe,
    DatePipe,
    NgIf,
    NgbTooltipModule,
    InvisiblePhoneNumberPipe,
    GetFullStatusPipe,
    NgbPaginationModule,
    TableComponent,
  ],
  providers: [StateContactHistoryService],
  standalone: true,
})
export class HistoryTableComponent {
  private contactHistoryService = inject(StateContactHistoryService);
  private modalService = inject(NgbModal);

  protected readonly COLUMNS = COLUMNS_USER_HISTORY_INFO;

  public contactHistories$ = this.contactHistoryService.contactHistories$;
  public total$ = this.contactHistoryService.total$;
  public pageSize$ = this.contactHistoryService.pageSize$;
  public limit$ = this.contactHistoryService.limit$;
  public page$ = this.contactHistoryService.page$;
  public isLoading$ = this.contactHistoryService.isLoading$;
  public paramsState: ContactSearchParams = null;
  public realEstateData: RealEstateModel;

  @Input() pageSize: number = REQUEST_DEFAULT.EXTRA_SMALL_LIMIT;
  @Input() set realEstate(data: RealEstateModel) {
    if (data != null && data?.id) {
      this.paramsState = {
        realEstateId: data.id,
        page: 0,
        limit: this.pageSize,
      };

      this.realEstateData = data;
      this.contactHistoryService.getContactHistoriesWithPropertyOwner(
        this.paramsState
      );
    }
  }

  pageChange($event: any) {
    this.contactHistoryService.getContactHistoriesWithPropertyOwner({
      ...this.paramsState,
      page: $event - 1,
    });
  }

  openCallHistoryModal() {
    const modalRef = this.modalService.open(CallHistoryContainerComponent, {
      centered: true,
      backdrop: "static",
      windowClass: "call-history-modal",
    });

    modalRef.componentInstance.data = this.realEstateData;
  }
}
