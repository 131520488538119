import { NgIf } from "@angular/common";
import { Component, inject, Injector, Input } from "@angular/core";
import {
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
} from "@angular/forms";
import { FEE_TYPE, USER_INFO } from "@constant/common.constant";
import { GroupModel } from "@model/group.model";
import { NgbActiveModal, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { TransformPriceToStringPipe } from "@pipe/transform-price-to-string.pipe";
import { HttpHistoryCallService } from "@service/call-history/http-history-call.service";
import { StorageService } from "@service/storage/storage.service";
import { ConvertDateTimeToString } from "@util/date-time.util";
import { FlatpickrModule } from "angularx-flatpickr";
import Vietnam from "flatpickr/dist/l10n/vn";
import { CALL_METHODS } from "src/app/public/constants/call-method.constant";
import {
  REAL_ESTATE_STATUS,
  REAL_ESTATE_STATUS_FOR_CUSTOMER_MANAGEMENT,
} from "src/app/public/constants/real-estate.constant";
import { STATUS_CALL } from "src/app/public/constants/status-call.constant";

@Component({
  selector: "call-history-form-component",
  templateUrl: "./home-call-insight.component.html",
  styleUrls: ["./home-call-insight.component.scss"],
  standalone: true,
  providers: [HttpHistoryCallService],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgIf,
    NgbTooltipModule,
    FlatpickrModule,
    TransformPriceToStringPipe,
  ],
})
export class HomeCallInsightComponent {
  // Inject Services
  private modalService = inject(NgbActiveModal);
  private contactHistoryService = inject(HttpHistoryCallService);
  private injector = inject(Injector);
  private storageService = inject(StorageService);
  // Inject variable
  @Input() formData: FormGroup;

  //
  public REAL_ESTATE_STATUS_BEFORE = REAL_ESTATE_STATUS;
  public REAL_ESTATE_STATUS_AFTER = REAL_ESTATE_STATUS;

  constructor() {
    const userGroup = this.getUserGroups();
    const isSuperAdmin = userGroup.some(
      (group: GroupModel) => group.id === 2 || group.id === 4
    );

    if (!isSuperAdmin) {
      this.REAL_ESTATE_STATUS_AFTER =
        REAL_ESTATE_STATUS_FOR_CUSTOMER_MANAGEMENT;
    }
  }

  private getUserGroups(): GroupModel[] {
    const userGroups = this.storageService.get(USER_INFO).groups;

    return userGroups ? userGroups : [];
  }

  submitForm() {
    this.updateTime();

    if (this.formData.valid) {
      this.contactHistoryService
        .saveCall(this.formData.getRawValue())
        .subscribe((_) => {
          this.modalService.close();
        });
    }
  }

  get controls() {
    return this.formData.controls;
  }

  private updateTime(): void {
    this.formData.patchValue({
      endTime: ConvertDateTimeToString(new Date(), this.injector),
    });
  }

  protected readonly CALL_METHODS = CALL_METHODS;
  protected readonly STATUS_CALL = STATUS_CALL;
  protected readonly FEE_TYPE = FEE_TYPE;
  public language: any = Vietnam.vn;

  getControl(control: string) {
    return this.formData.controls[control] as UntypedFormControl;
  }
}
