import { Injectable } from "@angular/core";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpStatusCode,
} from "@angular/common/http";
import { Observable, Subject, throwError } from "rxjs";
import { catchError, filter, switchMap, take, tap } from "rxjs/operators";
import { AuthenticationService } from "src/app/public/services/auth/auth.service";
import { StorageService } from "src/app/public/services/storage/storage.service";
import {
  JWT_REFRESH_TOKEN,
  JWT_TOKEN,
} from "src/app/public/constants/common.constant";
import {
  EVENT_BUS_EVENTS,
  EventBusService,
} from "src/app/public/services/common/event-bus.service";
import { API_URL } from "src/app/public/constants/api-url.constant";

@Injectable({
  providedIn: "any",
})
export class ErrorInterceptor implements HttpInterceptor {
  private isLoadingRefresh: boolean = false;
  private refreshTokensSubject$: Subject<any> = new Subject<any>();

  constructor(
    private authenticationService: AuthenticationService,
    private storageService: StorageService,
    private eventBus: EventBusService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((res) => {
        const isRefreshRequest = this.getTokenKey(request.url);
        if (isRefreshRequest && res instanceof HttpResponse) {
          const authorization = res.headers
            .get("authorization")
            ?.replace("Bearer", "")
            .trim();
          const refreshTk = res.headers.get("refresh-token");

          this.storageService.set(JWT_REFRESH_TOKEN, refreshTk);
          this.storageService.set(JWT_TOKEN, authorization);

          this.refreshTokensSubject$.next(authorization);
        }
      }),
      // @ts-ignore
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          return this.handleError(err, request, next);
        }
        return throwError(err);
      })
    );
  }

  handleError(error: any, request: HttpRequest<any>, next: HttpHandler) {
    switch (error.status) {
      case HttpStatusCode.Forbidden:
        return this.onHandleError403();
      case HttpStatusCode.Unauthorized:
        return this.onHandleError401(request, next);
      default:
        return this.onHandleErrorOther(error);
    }
  }

  private onHandleError401(request: HttpRequest<any>, next: HttpHandler) {
    const refreshToken = this.storageService.get(JWT_REFRESH_TOKEN);
    if (refreshToken) {
      if (!this.isLoadingRefresh) {
        this.isLoadingRefresh = true;
        return this.authenticationService.refreshToken({ refreshToken }).pipe(
          // @ts-ignore
          switchMap((_) => {
            this.isLoadingRefresh = false;
            const token = this.storageService.get(JWT_TOKEN);
            return next.handle(this.addTokenHeader(request, token));
          }),
          catchError((err) => {
            throw err;
          })
        );
      }
      return this.refreshTokensSubject$.pipe(
        filter((token) => token !== null),
        take(1),
        switchMap((token) => next.handle(this.addTokenHeader(request, token)))
      );
    }

    //  If is loading get token.
    return this.eventBus.on(EVENT_BUS_EVENTS.LOGIN_SUCCESS, {}).pipe(
      switchMap((_) => {
        this.isLoadingRefresh = false;
        const token = this.storageService.get(JWT_TOKEN);
        return next.handle(this.addTokenHeader(request, token));
      })
    );
  }

  private onHandleError403() {
    localStorage.clear();
    window.location.reload();
  }

  private onHandleErrorOther(error: any) {
    throw error;
  }

  private addTokenHeader(
    request: HttpRequest<any>,
    token: string
  ): HttpRequest<any> {
    /* for Spring Boot back-end */
    return request.clone({
      headers: request.headers.set("Authorization", "Bearer " + token),
    });
  }

  private getTokenKey(name: string) {
    if (name.includes(API_URL.REFRESH_TOKEN)) {
      return JWT_REFRESH_TOKEN;
    }
    return null;
  }
}
