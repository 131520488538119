import { Pipe, PipeTransform } from "@angular/core";
import { RealEstateMarkService } from "src/app/public/services/real-estate/real-estate-mark.service";
import { LAND_PURPOSE } from "src/app/public/constants/common.constant";

@Pipe({
  name: "realEstateIconMaker",
})
export class RealEstateIconMakerPipe implements PipeTransform {
  private points: number[] = [];
  constructor(private makerService: RealEstateMarkService) {
    this.points = this.makerService.rawRealEstatesMark?.map(r => r.id) || [];
  }

  // @TODO: Xử lý tiếp
  transform(id: number, purpose: string): string {
    const realEstateMark = this.points.find((p) => p === id);
    if (realEstateMark) {
      return "./assets/images/icons/for-point.svg";
    } else {
      return purpose === LAND_PURPOSE.FOR_SELL
        ? "./assets/images/icons/for-rent.svg"
        : purpose === LAND_PURPOSE.FOR_RENT
        ? "./assets/images/icons/for-sell.svg"
        : "./assets/images/icons/for-quick-upload.svg";
    }
  }
}
