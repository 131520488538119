import { AGENT_RESOURCES } from "./permission.contants";

export const JWT_TOKEN = "JWT_TOKEN";
export const JWT_REFRESH_TOKEN = "JWT_REFRESH_TOKEN";
export const JWT_RESET_PWD = "JWT_RESET_PWD";
export const USER_INFO = "USER_INFO";
export const EXT = "EXT";
export const TYPES_KEY = {
  REFRESH_TOKEN: "REFRESH_TOKEN_KEY",
  TOKEN: "TOKEN_KEY",
  USER_LOGIN: "USER_LOGIN",
  USER_PERMISSIONS: "USER_PERMISSIONS",
  APP_VERSION: "APP_VERSION",
};

export enum BOOLEAN_OPTIONS {
  FALSE = 0,
  TRUE = 1,
}

export enum ORIGIN_PROVIDE_DEFIND {
  WEBSITE = 1,
  HOTLINE = 2,
  REFERRAL_CUSTOMER = 3,
  SELF_EXPLOITATION = 4,
  OTHER = 5,
}

export const LAND_PURPOSE = {
  QUICK_UPLOAD: "0",
  FOR_SELL: "1",
  FOR_RENT: "2",
  FOR_MARK_POINT: "3",
};

export const STORAGE_DB_KEY = {
  landType: "landType",
  street: "street",
  districts: "districts",
  cities: "cities",
  towns: "towns",
  direction: "direction",
  floorMaterial: "floorMaterial",
  parking: "parking",
  statusFurniture: "statusFurniture",
  cateStreet: "cateStreet",
  juridical: "juridical",
};

export const VIDEO_FILE_TYPE = [
  "3gp",
  "mkv",
  "mp4",
  "ts",
  "webm",
  "ogg",
  "MPEG-4",
  "mpeg-4",
  "MPEG",
  "mpeg",
  "M4V",
  "m4v",
  "H.264",
  "h.264",
  "HEVC",
  "hevc",
];
export const IMAGE_FILE_TYPE = [
  "bmp",
  "gif",
  "png",
  "jpeg",
  "jpg",
  "png",
  "webp",
];

export const CONTACT_TYPE = {
  BOOK_ORDER: 1, //Đặt lịch
  CONSULTANT: 2, // Tư vấn,
  OTHER: 0, // chưa định nghĩa,
};

export const BOOK_ORDER_TYPE = {
  VIDEO: 2, //qua video
  DIRECT: 1, // truc tiep
};

export const DEFINE_TYPE_ADDRESS = {
  WARD: "WARD",
  DISTRICT: "DISTRICT",
  CITY: "CITY",
  OTHER: "OTHER",
};

export const PAGE_DEFAULT = 1;
export const SIZE_PAGE_DEFAULT = 16;
export const SIZE_PAGE_DEFAULT_FOR_USER = 16;
export const SIZE_GET_ALL_DEFAULT = 100;

export const FORM_CONTROL_NAME = [
  "purpose",
  "orderColumns",
  "orderDirects",
  "types",
  "address",
  "city",
  "district",
  "street",
  "frontWidthFrom",
  "frontWidthTo",
  "acreageFrom",
  "acreageTo",
];

export const CUSTOMER_TAB = [
  {
    label: "Yêu cầu",
    link: "orders",
  },
  {
    label: "BĐS đã xem",
    link: "access",
  },
  {
    label: "Tìm kiếm đã lưu",
    link: "history-search",
  },
  {
    label: "Đăng ký nhận thông báo",
    link: "subscribe-search",
  },
  {
    label: "BĐS đang đàm phán",
    link: "real-estate-negotiate",
  },
];

export const AGENT_TAB = [
  {
    label: "Thông tin chung",
    link: "general",
    resource: "*",
  },
  {
    label: "BĐS chờ duyệt",
    link: "waiting-approve",
    resource: AGENT_RESOURCES.DETAIL_WAITING_APPROVE,
  },
  {
    label: "BĐS đang xử lý",
    link: "in-progress",
    resource: AGENT_RESOURCES.DETAIL_IN_PROGRESS,
  },
  {
    label: "Người giới thiệu",
    link: "recommendation",
    resource: AGENT_RESOURCES.DETAIL_RECOMMENDATION,
  },
];

export const EMPLOYEE_CREATION_TAB = [
  {
    label: "Thông tin chi tiết",
    link: "profile",
  },
  {
    label: "Nhóm",
    link: "group",
  },
  {
    label: "Mật khẩu",
    link: "password",
  },
];

export const EMPLOYEE_EDIT_TAB = [
  {
    label: "Thông tin chi tiết",
    link: "profile",
  },
  {
    label: "Nhóm",
    link: "group",
  },
];

export const GROUP_DETAIL_TAB = [
  {
    label: "Thông tin chung",
    link: "general",
  },
  {
    label: "Nhân viên",
    link: "users",
  },
  {
    label: "Quyền",
    link: "permissions",
  },
];

export const TASK_DETAIL_TAB = [
  {
    label: "Yêu cầu khách hàng (5)",
    link: "requests",
  },
  {
    label: "Tệp đính kèm (4)",
    link: "files",
  },
];

export const REGIONS = ["Miền Bắc", "Miền Trung", "Miền Nam"];

export const TYPE_OF_DAILY = [
  {
    cValue: "Mọi lúc",
  },
  {
    cValue: "Hơn 1 tuần trước",
  },
  {
    cValue: "Hơn 1 tháng trước",
  },
  {
    cValue: "Hơn 6 tháng trước",
  },
  {
    cValue: "Hơn 1 tháng trước",
  },
];

export const KEY_MATCH_LIST = [
  { name: "Diện tích", control: "area" },
  { name: "Mặt tiền", control: "front" },
  { name: "Giá tiền", control: "price" },
];

export const TYPE_OF_DATE = [
  { title: "Thứ 2", value: 2 },
  { title: "Thứ 3", value: 3 },
  { title: "Thứ 4", value: 4 },
  { title: "Thứ 5", value: 5 },
  { title: "Thứ 6", value: 6 },
  { title: "Thứ 7", value: 7 },
  { title: "Chủ nhật", value: 8 },
];

export const PRICE_OPTIONS = [
  {
    value: "Triệu",
    name: "Triệu",
  },
  {
    value: "Tỷ",
    name: "Tỷ",
  },
];

export const ACCESS_SYSTEMS = [
  {
    value: "ADMIN",
    active: true,
  },
  {
    value: "CLIENT",
    active: true,
  },
  {
    value: "AGENCY",
    active: false,
  },
];

export const ACTIVE_STATUS_FILTER = [
  {
    label: "Có",
    key: "true",
  },
  {
    label: "Không",
    key: "false",
  },
  {
    label: "Tất cả",
    key: null,
  },
];

export const ACTIVE_STATUS_OPTIONS = [
  {
    label: "Có",
    key: BOOLEAN_OPTIONS.TRUE,
  },
  {
    label: "Không",
    key: BOOLEAN_OPTIONS.FALSE,
  },
];

export const ACTIVE_STATUS_OPTIONS_FILTER = [
  ...ACTIVE_STATUS_OPTIONS,
  {
    label: "Tất cả",
    key: null,
  },
];

export const TABS_TITLE_SEARCH_ADVANCED = [
  "Địa chỉ",
  "Các chỉ số quan trọng",
  "Thông tin chi tiết",
  "Thông tin cơ bản",
  "Quản lý nội bộ",
];

export const UTILITIES = [
  {
    label: "Trường học",
  },
  {
    label: "Chung cư",
  },
  {
    label: "Văn phòng",
  },
  {
    label: "Điểm du lịch",
  },
  {
    label: "Chợ",
  },
];

export const ORIGIN_PROVIDE = [
  {
    label: "Website",
    key: ORIGIN_PROVIDE_DEFIND.WEBSITE,
  },
  {
    label: "Hotline",
    key: ORIGIN_PROVIDE_DEFIND.HOTLINE,
  },
  {
    label: "Khách cũ giới thiệu",
    key: ORIGIN_PROVIDE_DEFIND.REFERRAL_CUSTOMER,
  },
  {
    label: "Tự khai thác",
    key: ORIGIN_PROVIDE_DEFIND.SELF_EXPLOITATION,
  },
  {
    label: "Khác",
    key: ORIGIN_PROVIDE_DEFIND.OTHER,
  },
];
