<div class="d-flex align-items-center gap-3" [formGroup]="formSearchAndFilter">
  <div class="search-box col-3">
    <ng-select
      class="w-100"
      placeholder="Trạng thái"
      [items]="SUBSCRIBE_ACTIVE"
      bindValue="key"
      formControlName="active"
      bindLabel="label"
    ></ng-select>
  </div>
  <div class="search-box col-3">
    <ng-select
      class="w-100"
      placeholder="Ngày tạo"
      [items]="SUBSCRIBE_CREATED"
      bindValue="key"
      formControlName="sortDirection"
      bindLabel="label"
    ></ng-select>
  </div>
</div>
