import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { RealEstateAuditModel } from "src/app/public/models/real-estate-audit.model";

@Component({
  selector: "app-detail-history-modal",
  templateUrl: "./detail-history-modal.component.html",
  styleUrls: ["./detail-history-modal.component.scss"],
  animations: [
    trigger("rotateIcon", [
      state("expanded", style({ transform: "rotate(0deg)" })),
      state("collapsed", style({ transform: "rotate(180deg)" })),
      transition("collapsed <=> expanded", animate("300ms ease-in-out")),
    ]),
  ],
})
export class DetailHistoryModalComponent {
  public numOfRow = [0, 1];
  public collapsed: boolean[] = [];
  public auditCompares: RealEstateAuditModel[];

  constructor(private matDialog: MatDialog) {}

  public onCloseModal() {
    this.matDialog.closeAll();
  }

  public toggleCollapse(index: number) {
    this.collapsed[index] = !this.collapsed[index];
  }
}
