import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { NgbDropdownModule, NgbNavModule } from "@ng-bootstrap/ng-bootstrap";
import { SimplebarAngularModule } from "simplebar-angular";
import { LanguageService } from "src/app/public/services/language.service";
import { TranslateModule } from "@ngx-translate/core";

// Component pages
import { LayoutComponent } from "src/app/public/layouts/layout.component";
import { VerticalComponent } from "src/app/public/layouts/vertical/vertical.component";
import { TopbarComponent } from "src/app/public/layouts/topbar/topbar.component";
import { SidebarComponent } from "src/app/public/layouts/sidebar/sidebar.component";
import { FooterComponent } from "src/app/public/layouts/footer/footer.component";
import { RightsidebarComponent } from "src/app/public/layouts/rightsidebar/rightsidebar.component";
import { HorizontalComponent } from "src/app/public/layouts/horizontal/horizontal.component";
import { HorizontalTopbarComponent } from "src/app/public/layouts/horizontal-topbar/horizontal-topbar.component";
// import { TwoColumnComponent } from "src/app/public/layouts/two-column/two-column.component";
import { TwoColumnSidebarComponent } from "src/app/public/layouts/two-column-sidebar/two-column-sidebar.component";
import { UserComponent } from "src/app/components/user/user.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner"
import { HLDirectivesModule } from "src/app/public/directives/directives.module";

@NgModule({
  declarations: [
    LayoutComponent,
    VerticalComponent,
    TopbarComponent,
    SidebarComponent,
    FooterComponent,
    RightsidebarComponent,
    HorizontalComponent,
    HorizontalTopbarComponent,
    // TwoColumnComponent,
    TwoColumnSidebarComponent,
    UserComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbDropdownModule,
    NgbNavModule,
    SimplebarAngularModule,
    TranslateModule,
    MatProgressSpinnerModule,
    HLDirectivesModule,
  ],
  providers: [LanguageService],
})
export class LayoutsModule {}
