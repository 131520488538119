import { Injectable } from "@angular/core";
import { CategoryService } from "../categories/category.service";

@Injectable({
  providedIn: "root",
})
export class AddressRealEstateService {
  cities: any[] = [];
  districts: any[] = [];
  towns: any[] = [];

  constructor(private categoryService: CategoryService) {
    this.getAllCity();
    this.getAllDistrict();
    this.getAllTown();
  }

  // Lấy ra tên của City bởi cKey.
  public getNameOfCity(cKey: string, cities: any[] = this.cities): string {
    return this.getNameOfKey(cities, cKey);
  }

  // Lấy ra tên của District bởi cKey.
  public getNameOfDistrict(
    cKey: string,
    districts: any[] = this.districts
  ): string {
    return this.getNameOfKey(districts, cKey);
  }

  // Lấy ra tên của Town bởi cKey.
  public getNameOfTown(cKey: string, towns: any[] = this.towns): string {
    return this.getNameOfKey(towns, cKey);
  }

  public getNameOfKey(data: any[], cKey: string): string {
    const result = data.find((c) => c.cKey === cKey);
    return result?.cValue;
  }

  // Get data and storage into variable
  private getAllCity(): void {
    this.categoryService.getAllCity().subscribe((data) => {
      if (data?.length) {
        this.cities = data;
      }
    });
  }

  private getAllDistrict(): void {
    this.categoryService.getAllDistrict().subscribe((data) => {
      if (data?.length) {
        this.districts = data;
      }
    });
  }

  private getAllTown(): void {
    this.categoryService.getAllTown().subscribe((data) => {
      if (data?.length) {
        this.towns = data;
      }
    });
  }
}
