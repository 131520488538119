import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { BehaviorSubject, combineLatest } from "rxjs";
import { RealEstateModel } from "src/app/public/models/real-estate.model";
import { CategoryService } from "src/app/public/services/categories/category.service";

@Component({
  selector: "app-real-estate-detail-overview",
  templateUrl: "./real-estate-detail-overview.component.html",
  styleUrls: ["./real-estate-detail-overview.component.scss"],
})
export class RealEstateDetailOverviewComponent implements OnChanges {
  @Input() land: RealEstateModel;
  product$: BehaviorSubject<RealEstateModel>;

  constructor(private categoryService: CategoryService) {
    this.product$ = new BehaviorSubject<RealEstateModel>(null);
  }

  ngOnChanges({ land }: SimpleChanges): void {
    const { currentValue } = land;
    if (currentValue) {
      combineLatest([
        this.categoryService.getAllType(),
        this.categoryService.getAllDirection(),
      ]).subscribe((data: any) => {
        const type =
          data[0].find((d: any) => d.cKey === currentValue.type)?.cValue ||
          currentValue.type;
        const direction =
          data[1].find((d: any) => d.cKey === currentValue.direction)?.cValue ||
          "__";

        this.product$.next({ ...currentValue, type, direction });
      });
    }
  }
}
