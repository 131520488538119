import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { map } from "rxjs/operators";
import {
  LANDS_TYPE_MAP,
  REAL_ESTATE_STATUS_FOR_ADMIN_APPROVED,
  REAL_ESTATE_URL,
  STATUS_IN_MAP_FOR_APPROVED_PAGE,
} from "src/app/public/constants/real-estate.constant";
import { MapState } from "src/app/public/services/google-maps/map.state";

@Component({
  selector: "app-search-filter-map",
  templateUrl: "./search-filter-map.component.html",
  styleUrls: ["./search-filter-map.component.scss"],
})
export class SearchFilterMapComponent implements OnDestroy, OnInit {
  @Input() searchLandParams: UntypedFormGroup;
  @Output() passNumberFilter: EventEmitter<any> = new EventEmitter();
  public STATUS_APPROVED = REAL_ESTATE_STATUS_FOR_ADMIN_APPROVED;
  public REAL_ESTATE_URL = REAL_ESTATE_URL;
  public isCollapsed = false;
  public numberOfFilter: number;

  constructor(private modal: NgbActiveModal, private mapState: MapState) {}

  ngOnInit(): void {
    this.notAllowPatchEmptyStatus();
  }

  public onCloseModal() {
    this.modal.close();
  }

  private notAllowPatchEmptyStatus() {
    this.searchLandParams?.valueChanges.subscribe((params) => {
      if (!params?.status?.length) {
        this.handlePatchStatus();
      }
    });
  }

  private handlePatchStatus() {
    this.searchLandParams.patchValue({
      status: STATUS_IN_MAP_FOR_APPROVED_PAGE,
    });
  }

  public getControl(name: string) {
    this.mapState.polygon$
      .pipe(
        map((value) => {
          return value != null;
        })
      )
      .subscribe((result) => {
        if (result) {
          this.mapState.allowCallApi$ = true;
        }
      });
    return this.searchLandParams.get(name) as UntypedFormControl;
  }

  public clearFormRealEstate() {
    this.searchLandParams.patchValue({
      status: this.STATUS_APPROVED.map((s) => s.cKey),
      acreageFrom: null,
      acreageTo: null,
      frontWidthFrom: null,
      frontWidthTo: null,
      address: "",
      purpose: LANDS_TYPE_MAP.map((l) => l.cKey),
    });
  }

  public handleCountFilter() {
    const currentFormValues = this.searchLandParams.value;
    const keysToKeep = ["status", "frontWidthTo", "acreageTo", "purpose"];
    // Lọc các key cần trong Object
    const filteredObject = Object.fromEntries(
      Object.entries(currentFormValues).filter(([key]) =>
        keysToKeep.includes(key)
      )
    );
    //Đếm các trường key khác null
    this.numberOfFilter = Object.values(filteredObject).filter((value: any) => {
      return !!value;
    }).length;
    this.searchLandParams.patchValue({
      ...currentFormValues,
    });
  }

  ngOnDestroy(): void {
    this.handleCountFilter();
    this.passNumberFilter.emit(this.numberOfFilter);
  }
}
