export type CallToCustomerModel = {
  type: ACTION_TYPE;
  action: string;
  status: string;
  requestUser: string;
  to: string;
  from: string;
  ext: string;
};

export enum ACTION_TYPE {
  REQUEST = "REQUEST",
  RESPONSE = "RESPONSE",
  NOTIFY = "NOTIFY",
}

export enum STATUS_CALL {
  WAITING = "WAITING",
  CONNECTING = "CONNECTING",
  COMPLETED = "COMPLETED",
}
