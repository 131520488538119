import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { RealEstateModel } from "../models/real-estate.model";
import { CategoryService } from "../services/categories/category.service";
import { RealEstateService } from "../services/real-estate/real-estate.service";
import { removeVietnameseTones } from "../decorators/helper.decorator";
import { AddressModel } from "../models/address.model";
import { RealEsateHttpService } from "src/app/public/services/real-estate/real-esate-http.service";
import { catchError, delay } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ExportRealEstateHelper {
  readonly SIZE_DEFAULT = 100;

  listResults: any = [];
  page = 1;
  cities: any[] = [];
  districts: any[] = [];
  towns: any[] = [];
  landTypes: any[] = [];
  floorMaterials: any[] = [];
  directions: any[] = [];
  eventGetAllData$ = new Subject<boolean>();
  results$ = new Subject<any>();

  constructor(
    private realEstateService: RealEstateService,
    private realEstateHttpService: RealEsateHttpService,
    private categoryService: CategoryService
  ) {
    this.obseverEventGetAllData();
    this.getAllCity();
    this.getAllDistrict();
    this.getAllTown();
    this.getAllLandType();
    this.getAllFloorMaterial();
    this.getAllDirection();
  }

  public export(): Observable<any> {
    this.eventGetAllData$.next(true);
    return this.results$;
  }

  private obseverEventGetAllData(): void {
    const event$ = this.eventGetAllData$.asObservable();
    event$.subscribe((_) => {
      const res$ = this.getRealEstate(this.page, this.SIZE_DEFAULT);
      res$.subscribe((res: any) => {
        this.handlerAfterRequestSuccess(res);
      });
    });
  }

  private handlerAfterRequestSuccess(res: any): void {
    const { totalItems, totalPages, data } = res;
    // transformn data
    const resources = this.transformAllData(data);
    this.realEstateHttpService
      .syncDataWithElasticDB(resources)
      .pipe(
        delay(1000),
        catchError((err) => {
          throw err;
        })
      )
      .subscribe((_) => {
        console.log("Push success!");
      });
    // concat into the results array.
    this.listResults = this.listResults.concat(resources);
    // get length of "results"
    const totalItemsCurrent = this.listResults.length;
    if (totalItemsCurrent / 2 < totalItems && this.page < totalPages) {
      this.page++;
      this.eventGetAllData$.next(true);
    } else {
      this.results$.next(this.listResults);
    }
  }

  private transformAllData(data: RealEstateModel[]) {
    let results: any[] = [];
    data.forEach((d) => {
      const created = {
        create: {
          _index: "hl-real-estate-v1",
          _id: d.id,
          dynamic_templates: { "address.locationPoint": "geo_point" },
        },
      };

      const realEstate = this.transformRealEstate(d);
      results.push(realEstate);
    });

    return results;
  }

  transformRealEstate(d: RealEstateModel): RealEstateModel {
    const city = this.getDataName(this.cities, d.address?.city) || "";
    const district =
      this.getDataName(this.districts, d.address?.district) || "";
    const town = this.getDataName(this.towns, d.address?.town) || "";
    this.getDataName(this.floorMaterials, d.floorMaterial) || "";

    const _location = {
      lat: String(d.address?.latitude || ""),
      lon: String(d.address?.longitude || ""),
    };

    const fullAddress = [
      `${d.address?.houseNbr} ${d.address?.street}`,
      town,
      district,
      city,
    ]
      .filter(Boolean)
      .join(", ");

    const shortAddress = [town, district, city].filter(Boolean).join(", ");

    const _customers = d.customers?.map((d: any) => {
      const addressCustomer = d?.address?.map((add: AddressModel) => {
        const _city = this.getDataName(this.cities, add?.city) || "";
        const _district = this.getDataName(this.districts, add?.district) || "";
        const _town = this.getDataName(this.towns, add?.town) || "";

        const _fullAddress = [
          `${add?.houseNbr} ${add?.street}`,
          _town,
          _district,
          _city,
        ]
          .filter(Boolean)
          .join(", ");
        const _shortAddress = [town, district, city].filter(Boolean).join(", ");

        const _fullAddressNoneSign = removeVietnameseTones(fullAddress);

        return {
          ...add,
          fullAddressNoneSign: _fullAddressNoneSign,
          shortAddress: _shortAddress,
          fullAddress: _fullAddress,
        };
      });

      return {
        ...d,
        address: addressCustomer,
      };
    });

    const address = {
      ...d.address,
      locationPoint: _location,
      fullAddressNoneSign: removeVietnameseTones(fullAddress),
      fullAddress,
      shortAddress,
    };

    return {
      ...d,
      address,
      customers: _customers,
    };
  }

  // call API get real-estate
  private getRealEstate(page: number = 0, limit: number = 12): Observable<any> {
    return this.realEstateService.getRealEstatePostGres({
      page,
      limit,
      orderDirects: "ASC",
    });
  }

  private handlerDownloadFile(results: any[]): boolean {
    try {
      const data =
        "text/json;charset=utf-8," +
        encodeURIComponent(JSON.stringify(results));

      const a = document.createElement("a");
      a.href = "data:" + data;
      a.download = "data.json";
      a.innerHTML = "download JSON";

      const input = document.createElement("input");
      input.style.display = "none";
      input.id = "container";

      input.appendChild(a);
      a.click();
    } catch (error) {
      // Throw error
      throw new Error("Download failed!");
    }

    return true;
  }

  // Get data and storage into variable
  private getAllCity(): void {
    this.categoryService.getAllCity().subscribe((data) => {
      if (data?.length) {
        this.cities = data;
      }
    });
  }

  private getAllDistrict(): void {
    this.categoryService.getAllDistrict().subscribe((data) => {
      if (data?.length) {
        this.districts = data;
      }
    });
  }

  private getAllTown(): void {
    this.categoryService.getAllTown().subscribe((data) => {
      if (data?.length) {
        this.towns = data;
      }
    });
  }

  private getAllLandType(): void {
    this.categoryService.getAllType().subscribe((data) => {
      if (data?.length) {
        this.landTypes = data;
      }
    });
  }

  private getAllFloorMaterial(): void {
    this.categoryService.getAllFloorMaterial().subscribe((data) => {
      if (data?.length) {
        this.floorMaterials = data;
      }
    });
  }

  private getAllDirection(): void {
    this.categoryService.getAllDirection().subscribe((data) => {
      if (data?.length) {
        this.directions = data;
      }
    });
  }

  // Get name of data
  private getDataName(data: any[], cKey: string): string {
    const result = data.find((c) => c.cKey === cKey);
    return result?.cValue;
  }
}
