import { Component } from "@angular/core";
import {
  NgbDropdownModule,
  NgbNavModule,
  NgbTooltipModule,
} from "@ng-bootstrap/ng-bootstrap";
import { TabAllComponent } from "./containers/tab-all/tab-all.component";
import { TabMsgComponent } from "./containers/tab-msg/tab-msg.component";
import { TabNotifyComponent } from "./containers/tab-notify/tab-notify.component";

@Component({
  selector: "app-notification-dropdown",
  templateUrl: "./notification-dropdown.component.html",
  standalone: true,
  imports: [
    NgbNavModule,
    NgbDropdownModule,
    NgbTooltipModule,
    TabAllComponent,
    TabMsgComponent,
    TabNotifyComponent,
  ],
})
export class NotificationDropdownComponent {}
