import { Injectable } from "@angular/core";
import { API_URL } from "@constant/api-url.constant";
import { RegisterNotifcationPayload } from "@model/notification.model";
import { BaseHttpRequest } from "@service/http/base-http-request.service";

@Injectable({
  providedIn: "root",
})
export class SwNotificationHttpService extends BaseHttpRequest {
  public registerNotificationFromServer(payload: RegisterNotifcationPayload) {
    return this.post(API_URL.PUSH_NOTIFICATION_REGISTER, payload);
  }

  public getNotificationOfUser(params: ParamOptions) {
    const { page } = params;
    return this.get(API_URL.NOTIFICATION_OF_USER, {
      params: { ...params, page: Number(page - 1) },
    });
  }

  public changeViewStatus(id: number, payload: UpdateStatusNotification) {
    return this.patch(`${API_URL.NOTIFICATION_CHANGE_STATUS}/${id}`, payload);
  }
}

export interface ParamOptions {
  userId: number;
  isRead?: boolean;
  page: number;
  limit: number;
  doneStatus?: boolean;
}

export interface UpdateStatusNotification {
  id: number;
  readStatus?: boolean;
  lastSeen?: string;
  sendStatus?: boolean;
  doneStatus?: boolean;
}
