import { Component, ContentChild, Input, TemplateRef } from "@angular/core";

@Component({
  selector: "app-table-suppliers",
  templateUrl: "./table-suppliers.component.html",
})
export class TableSuppliersComponent {
  // Content-Child
  @ContentChild("headers") headers: TemplateRef<any>;
  @ContentChild("rows") rows: TemplateRef<any>;

  // Input data
  @Input() data: any[] = [];
}
