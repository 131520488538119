import { UserReq } from "./../../constants/user.constant";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { API_URL } from "../../constants/api-url.constant";
import { BaseHttpRequest } from "../http/base-http-request.service";
import { RefreshTokenReq } from "src/app/public/models/auth.models";

@Injectable({
  providedIn: "root",
})
export class AuthHttpService extends BaseHttpRequest {
  /**
   * @function Login
   * @param payload : UserReq
   * @returns
   */
  public login(payload: UserReq): Observable<any> {
    return this.post<any>(API_URL.LOGIN, payload);
  }

  public refreshToken(payload: RefreshTokenReq): Observable<any> {
    return this.post<any>(API_URL.REFRESH_TOKEN, payload);
  }
}
