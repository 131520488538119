import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from "@angular/core";
import { AuthorizationService } from "src/app/public/services/auth/authorization.service";

@Directive({
  selector: "[hasPermissionAction]",
})
export class CheckPermissionActionDirective implements OnInit {
  private resourceName: string;
  private actionName: string;

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private authorizationService: AuthorizationService
  ) {}

  @Input() set hasPermissionActionResource(service: string) {
    this.resourceName = service;
  }

  @Input() set hasPermissionAction(action: string) {
    this.actionName = action;
  }

  ngOnInit(): void {
    if (
      this.authorizationService.hasPermissionAction(
        this.resourceName,
        this.actionName
      )
    ) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
