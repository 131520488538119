import { Pipe, PipeTransform } from '@angular/core';
import { LAND_STATUS } from "src/app/public/constants/real-estate.constant";

@Pipe({
  name: "getFullStatus",
})
export class GetFullStatusPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): string {
    switch (value) {
      case LAND_STATUS.NEW:
        return "Chờ duyệt";
      case LAND_STATUS.APPROVED_SELL:
        return "Mở bán";
      case LAND_STATUS.REJECTED:
        return "Từ chối";
      case LAND_STATUS.DRAFT:
        return "Bản Nháp";
      case LAND_STATUS.PRICE_DEAL:
        return "Thuyết phục phí";
      case LAND_STATUS.EVALUATING:
        return "Mặt bằng chấm điểm";
      case LAND_STATUS.DEPOSITED:
        return "Đã đặt cọc";
      case LAND_STATUS.NEGOTIATE:
        return "Đang đàm phán";
      case LAND_STATUS.STOP_SELL:
        return "Ngừng giao dịch";
      case LAND_STATUS.SOLD:
        return "Đã giao dịch";
      case LAND_STATUS.APPROVED_DATA:
        return "Đã duyệt";
      case LAND_STATUS.RAW_DATA:
        return "BĐS đăng nhanh"
      default:
        return "Chưa xác định";
    }
  }
}
