<div class="full-screen">
  <button class="customize-btn end-0" (click)="closeModal()">
    <i class="ri-close-line step-icon me-2" ngbTooltip="Đóng"></i>
  </button>

  <div *ngIf="isShowZoom && photos?.length">
    <button
      class="customize-btn start-0 fs-22"
      (click)="zoom(false, false)"
      ngbTooltip="Xem ảnh gốc"
      placement="bottom-start"
    >
      <i class="ri-zoom-out-line"></i>
    </button>
    <button
      class="customize-btn zoom-in-btn fs-22"
      (click)="zoom(true, false)"
      [disabled]="currentScale === MAX_ZOOM_LEVEL"
      [style.cursor]="currentScale === MAX_ZOOM_LEVEL ? 'not-allowed' : null"
      ngbTooltip="Phóng to"
    >
      <i class="ri-zoom-in-line"></i>
    </button>
  </div>

  <swiper
    [loop]="true"
    [initialSlide]="currentSlide"
    [pagination]="{
      type: 'fraction'
    }"
    [navigation]="true"
    [thumbs]="{ swiper: thumbsSwiper }"
    class="customize-slider-detail"
    (init)="handleInit()"
    [loopFillGroupWithBlank]="true"
    [allowTouchMove]="false"
    (wheel)="onWheel($event)"
    (mouseup)="onMouseUp($event)"
    (mousedown)="onMouseDown($event)"
    (mousemove)="onMouseMove($event)"
    (transitionEnd)="handleSlideChange()"
    [style.cursor]="currentScale > 1 ? 'move' : null"
    #swiper
  >
    <ng-container *ngIf="photos?.length">
      <ng-container *ngFor="let media of photos">
        <ng-template swiperSlide>
          <img
            *ngIf="isImage(media.url)"
            [src]="media.url"
            onerror="this.onerror=null; this.src='./assets/images/image-not-available.png'"
            alt="image"
          />
          <video *ngIf="!isImage(media.url)" controls>
            <source [src]="media.url" type="video/mp4" />
            <source [src]="media.url" type="video/ogg" />
          </video>
        </ng-template>
      </ng-container>
    </ng-container>
    <ng-template *ngIf="!photos?.length" swiperSlide>
      <img src="./assets/images/products/coming-soon.png"
    /></ng-template>
  </swiper>
</div>
