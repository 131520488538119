<div [formGroup]="formAddress" class="row">
  <div class="hl-mb-n2">
    <span class="badge badge-soft-primary fs-12"
      ><i class="mdi mdi-circle-medium"></i>Vị trí BĐS</span
    >
  </div>
  <div class="border border-dashed"></div>

  <div class="row py-2 justify-content-between align-items-start">
    <div class="col-6 row">
      <div class="col-md-6 mb-3 form-group" required>
        <label class="form-label">Tỉnh/Thành phố</label>
        <ng-select
          [items]="cities$ | async"
          formControlName="city"
          [searchable]="true"
          [clearable]="false"
          bindValue="cKey"
          bindLabel="cValue"
          placeholder="Chọn tỉnh/thành phố..."
          [readonly]="!isEdit && !!realEstateId"
        ></ng-select>
        <small *ngIf="f['city']?.errors" class="text-danger"
          >Vui lòng chọn Thành phố</small
        >
      </div>
      <div class="col-md-6 mb-3 form-group" required>
        <label class="form-label">Quận/Huyện</label>
        <ng-select
          [items]="districts$ | async"
          formControlName="district"
          bindValue="cKey"
          [searchable]="true"
          [clearable]="false"
          bindLabel="cValue"
          placeholder="Chọn quận/huyện..."
          [readonly]="!isEdit && !!realEstateId"
        ></ng-select>
        <small *ngIf="f['district']?.errors" class="text-danger"
          >Vui lòng chọn Quận/huyện</small
        >
      </div>
      <div class="col-md-6 mb-3 form-group" required>
        <label class="form-label">Xã/Phường</label>
        <ng-select
          [items]="towns$ | async"
          formControlName="town"
          bindValue="cKey"
          [searchable]="true"
          [clearable]="false"
          bindLabel="cValue"
          placeholder="Chọn xã/phường..."
          [readonly]="!isEdit && !!realEstateId"
        ></ng-select>
        <small *ngIf="f['town']?.errors" class="text-danger"
          >Vui lòng chọn Xã/phường</small
        >
      </div>
      <div class="col-md-6 mb-3 form-group" required>
        <label class="form-label">Tên đường</label>
        <input
          [ngClass]="{
            'is-invalid': isEdit && f['street']?.errors
          }"
          [readonly]="!isEdit && !!realEstateId"
          type="name"
          formControlName="street"
          class="form-control"
          placeholder="Nhập tên đường..."
        />
        <small *ngIf="f['street']?.errors" class="text-danger"
          >Vui lòng nhập tên đường</small
        >
      </div>
      <div class="col-md-6 mb-3 form-group" required>
        <label class="form-label">Số nhà</label>
        <input
          [ngClass]="{
            'is-invalid': isEdit && f['houseNbr']?.errors
          }"
          [readonly]="!isEdit && !!realEstateId"
          type="name"
          formControlName="houseNbr"
          class="form-control"
          placeholder="Nhập số nhà"
        />
        <small *ngIf="f['houseNbr']?.errors" class="text-danger"
          >Vui lòng nhập số nhà</small
        >
      </div>
      <div class="col-md-6 mb-3">
        <label class="form-label">Link Street View</label>
        <input
          type="name"
          (change)="convertURL($event)"
          formControlName="streetView"
          class="form-control"
          placeholder="Link street view..."
          [readonly]="!isEdit && !!realEstateId"
        />
      </div>
    </div>

    <div class="col-6 row mb-3 form-group">
      <label class="form-label">Vị trí trên bản đồ</label>
      <div class="map-location">
        <app-map-select-location
          [location]="{
            lat: f['latitude'].value || 21.030870389459476,
            lng: f['longitude'].value || 105.85236893553922
          }"
          (onSelectLocation)="eventSelectLocation($event)"
          [streetView]="{
            streetView: f['streetView'].value
          }"
          [photos]="{
            photos: formRealEstate.controls['photos'].value
          }"
          [readonly]="!isEdit && !!realEstateId"
        >
        </app-map-select-location>
      </div>
    </div>
  </div>
</div>
