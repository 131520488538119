import { Component } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { REGIONS } from "src/app/public/constants/common.constant";

@Component({
  selector: "app-area-notification",
  templateUrl: "./area-notification.component.html",
})
export class AreaNotificationComponent {
  public REGIONS = REGIONS;

  constructor(private modalService: NgbModal) {}

  public handleCloseModal() {
    this.modalService.dismissAll();
  }
}
