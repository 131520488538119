import { Component, Input, ViewChild, ViewEncapsulation } from "@angular/core";
import SwiperCore, { Navigation, Pagination, Thumbs } from "swiper";
import { RealEstatePhotoModel } from "src/app/public/models/real-estate.model";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CheckStyleUrl } from "src/app/public/utils/check-file-type";
import { SwiperComponent } from "swiper/angular";

SwiperCore.use([Navigation, Thumbs, Pagination]);
@Component({
  selector: "app-slider-image-detail-original",
  templateUrl: "./slider-image-detail-original.component.html",
  styleUrls: ["./slider-image-detail-original.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SliderImageDetailOriginalComponent {
  @ViewChild("swiper") swiper: SwiperComponent;
  public readonly MAX_ZOOM_LEVEL = 6;
  public isShowZoom = true;
  public panning = false;
  public pointX = 0;
  public pointY = 0;
  public start = { x: 0, y: 0 };
  public currentScale = 1;

  public thumbsSwiper: any;
  public CheckStyleUrl = CheckStyleUrl;

  @Input() currentSlide: number;
  @Input() photos: RealEstatePhotoModel[] = [];

  constructor(public activeModal: NgbActiveModal) {}

  public closeModal(): void {
    this.activeModal.close();
  }

  public zoom(zoomIn: boolean, wheelZoom: boolean) {
    const currentImg = this.getCurrentImg();
    if (!currentImg) return;

    const zoomFactor = zoomIn ? 0.5 : -0.5;
    const newScale = this.currentScale + zoomFactor;

    if (
      (zoomIn && newScale <= this.MAX_ZOOM_LEVEL && !wheelZoom) ||
      (!zoomIn && newScale >= 1 && wheelZoom)
    ) {
      const boundingRect = currentImg.getBoundingClientRect();
      const zoomCenterX = boundingRect.width / 2;
      const zoomCenterY = boundingRect.height / 2;

      this.currentScale = newScale;
      this.pointX -= zoomCenterX * (newScale - this.currentScale);
      this.pointY -= zoomCenterY * (newScale - this.currentScale);
      this.setTransform();
    } else if (!zoomIn && this.currentScale > 1 && !wheelZoom) {
      this.resetTransform(currentImg);
    }
  }

  public handleSlideChange() {
    const realIndex = this.swiper?.swiperRef?.realIndex;
    const currentPhoto = this.photos[realIndex]?.url;
    const previousImg = this.getPreviousImg();

    this.isShowZoom = currentPhoto !== undefined && this.isImage(currentPhoto);
    if (previousImg) this.resetTransform(previousImg);
  }

  public handleInit() {
    const initPhoto = this.photos[this.currentSlide]?.url;
    this.isShowZoom = initPhoto !== undefined && this.isImage(initPhoto);
  }

  public setTransform() {
    const currentImg = this.getCurrentImg();

    if (currentImg) {
      currentImg.style.transform = `translate(${this.pointX}px, ${this.pointY}px) scale(${this.currentScale})`;

      if (this.currentScale === 1) this.resetTransform(currentImg);
    }
  }

  public onMouseDown(event: any) {
    event.preventDefault();
    if (this.currentScale > 1) {
      this.start = {
        x: event.clientX - this.pointX,
        y: event.clientY - this.pointY,
      };
      this.panning = true;
    }
  }

  public onMouseUp(event: any) {
    event.preventDefault();
    this.panning = false;
  }

  public onMouseMove(event: any) {
    event.preventDefault();
    if (!this.panning) return;
    if (this.currentScale > 1) {
      this.pointX = event.clientX - this.start.x;
      this.pointY = event.clientY - this.start.y;
      this.setTransform();
    }
  }

  public onWheel(event: any) {
    event.preventDefault();
    const delta = event.wheelDelta ? event.wheelDelta : -event.deltaY;

    if (delta > 0 && this.currentScale < this.MAX_ZOOM_LEVEL) {
      this.zoom(true, false);
    } else if (delta < 0 && this.currentScale > 1) {
      this.zoom(false, true);
    }
  }

  public isImage(mediaUrl: string): boolean {
    return CheckStyleUrl(mediaUrl) === "image";
  }

  private getCurrentImg(): HTMLElement | null {
    const activeIndex = this.swiper?.swiperRef?.activeIndex;
    const activeSlide = this.swiper?.swiperRef?.slides[activeIndex];
    return activeSlide?.querySelector("img");
  }

  private getPreviousImg(): HTMLElement | null {
    const previousIndex = this.swiper?.swiperRef?.previousIndex;
    const previousSlide = this.swiper?.swiperRef?.slides[previousIndex];
    return previousSlide?.querySelector("img");
  }

  private resetTransform(element: HTMLElement) {
    element.style.transform = "translate(0px, 0px)";
    this.currentScale = 1;
    this.pointX = 0;
    this.pointY = 0;
  }
}
