// export layout related constants
export const LAYOUT_VERTICAL = "vertical";

export const LAYOUT_HORIZONTAL = "horizontal";

export const LAYOUT_TWOCOLUMN = "twocolumn";

export const LAYOUT_MODE = "light";

export const LAYOUT_WIDTH = "fluid";

export const LAYOUT_POSITION = "fixed";

export const TOPBAR = "light";

export const SIDEBAR_SIZE = "lg";

export const SIDEBAR_VIEW = "default";

export const SIDEBAR_COLOR = "dark";

export const SIDEBAR_IMAGE = "none";
