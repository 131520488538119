import { Component, Input } from "@angular/core";

@Component({
  selector: "app-notify-item",
  templateUrl: "./notify-item.component.html",
  standalone: true,
})
export class NotifyItemComponent {
  @Input() data: { title: string; body: string; time: string; type: string };
}
