import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { FormControl } from "@angular/forms";
import { PRICE_OPTIONS } from "src/app/public/constants/common.constant";

@Component({
  selector: "app-price-unit",
  templateUrl: "./price-unit.component.html",
})
export class PriceUnitComponent implements OnChanges {
  public optionsPrice = PRICE_OPTIONS;

  @Input() disabled: boolean;
  @Input() unitForm = new FormControl("Triệu");

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["unitForm"]) {
      this.unitForm.setValue(changes["unitForm"].currentValue.value);
    }
  }
}
