import { Observable } from "rxjs";
import { CategoryService } from "src/app/public/services/categories/category.service";
import { tap } from "rxjs/operators";

export function GetCodeOfCityUtil(
  cityName: string,
  categoryService: CategoryService
) {
  return new Observable((observable) => {
    if (cityName) {
      categoryService
        .getAllCity()
        .pipe(
          tap((cities) => {
            const city = cities.find((city) => {
              return (
                city.cValue.toLowerCase() === cityName.trim().toLowerCase()
              );
            });
            observable.next(city?.cKey);
          })
        )
        .subscribe((_) => {});
    } else {
      observable.next(cityName);
    }
  });
}
