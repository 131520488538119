import { Component, OnDestroy, OnInit } from "@angular/core";
import { NavigationEnd, Router, Scroll } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { GetParameterByName } from "src/app/public/utils/get-parameter-by-name.util";
import { REAL_ESTATE_URL } from "../../constants/real-estate.constant";
import { MapState } from "../../services/google-maps/map.state";

@Component({
  selector: "app-vertical",
  templateUrl: "./vertical.component.html",
})
export class VerticalComponent implements OnInit, OnDestroy {
  public isCondensed: boolean = false;
  public previousUrl: string;
  public subscription: Subscription = new Subscription();
  public processingPopstate: boolean;
  public mapManagementScreen: boolean;
  public showFullMap$: Observable<boolean>;

  constructor(private router: Router, private mapState: MapState) {
    this.processingPopstate = false;
  }

  ngOnInit(): void {
    this.setDataLayout();
    this.eventListenerResize();
    this.eventListenerPopState();
    this.observerUrlChange();
    this.getShowCardState();
  }

  private getShowCardState() {
    this.showFullMap$ = this.mapState.showFullMap$;
  }

  private setDataLayout() {
    document.documentElement.setAttribute("data-layout", "vertical");
    document.documentElement.setAttribute("data-topbar", "light");
    document.documentElement.setAttribute("data-sidebar", "dark");
    document.documentElement.setAttribute("data-layout-style", "default");
    document.documentElement.setAttribute("data-layout-mode", "light");
    document.documentElement.setAttribute("data-layout-width", "fluid");
    document.documentElement.setAttribute("data-layout-position", "fixed");
    document.documentElement.setAttribute("data-sidebar-image", "none");
    document.documentElement.setAttribute("data-sidebar-size", "sm");
  }

  private observerUrlChange() {
    this.router.events.subscribe((event) => {
      if (event instanceof Scroll && event.routerEvent?.url) {
        this.mapManagementScreen =
          event.routerEvent?.url.includes(REAL_ESTATE_URL.MAP_MANAGEMENT) ||
          event.routerEvent?.url.includes("order-map");

        if (this.mapManagementScreen)
          document.documentElement.setAttribute("data-sidebar-size", "sm");
      }
    });
  }

  private eventListenerResize() {
    window.addEventListener("resize", function () {
      if (window.screen.width <= 767) {
        document.documentElement.setAttribute("data-sidebar-size", "");
      }
    });
  }

  private eventListenerPopState() {
    window.addEventListener("popstate", this.handleClickBack.bind(this));
  }

  private handleClickBack() {
    if (this.processingPopstate) return;

    this.processingPopstate = true;
    this.subscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.previousUrl = e.url;
        const getStatus = GetParameterByName("status", this.previousUrl);
        if (getStatus !== null) {
          history.pushState(null, "", this.previousUrl);
          history.back();
        }
        this.processingPopstate = false;
        this.subscription.unsubscribe();
      }
    });
  }

  /**
   * On mobile toggle button clicked
   */
  public onToggleMobileMenu() {
    const currentSIdebarSize =
      document.documentElement.getAttribute("data-sidebar-size");
    if (window.screen.width >= 767) {
      if (currentSIdebarSize == null) {
        document.documentElement.getAttribute("data-sidebar-size") == null ||
        document.documentElement.getAttribute("data-sidebar-size") == "lg"
          ? document.documentElement.setAttribute("data-sidebar-size", "sm")
          : document.documentElement.setAttribute("data-sidebar-size", "lg");
      } else if (currentSIdebarSize == "md") {
        document.documentElement.getAttribute("data-sidebar-size") == "md"
          ? document.documentElement.setAttribute("data-sidebar-size", "sm")
          : document.documentElement.setAttribute("data-sidebar-size", "md");
      } else {
        document.documentElement.getAttribute("data-sidebar-size") == "sm"
          ? document.documentElement.setAttribute("data-sidebar-size", "lg")
          : document.documentElement.setAttribute("data-sidebar-size", "sm");
      }
    }

    if (window.screen.width <= 767) {
      document.body.classList.toggle("vertical-sidebar-enable");
    }
    this.isCondensed = !this.isCondensed;
  }

  /**
   * on settings button clicked from topbar
   */
  public onSettingsButtonClicked() {
    document.body.classList.toggle("right-bar-enabled");
    const rightBar = document.getElementById("theme-settings-offcanvas");
    if (rightBar != null) {
      rightBar.classList.toggle("show");
      rightBar.setAttribute("style", "visibility: visible;");
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    window.removeEventListener("popstate", this.handleClickBack.bind(this));
  }
}
