<div class="d-flex action-search-container justify-content-end mb-3">
  <button class="btn btn-outline-primary" (click)="openCallHistoryModal()">
    <i class="mdi mdi-plus"></i>
    Thêm lịch sử
  </button>
</div>

<ng-container *ngIf="contactHistories$ | async as contactHistories">
  <ng-container *ngIf="contactHistories?.length">
    <app-table
      [data]="contactHistories"
      [total]="total$ | async"
      [pageSize]="pageSize$ | async"
      [limit]="limit$ | async"
      [page]="page$ | async"
      (pageChange)="pageChange($event)"
      [showSkeleton]="false"
    >
      <ng-template #headers>
        <ng-container *ngFor="let item of COLUMNS">
          <th>{{ item.field }}</th>
        </ng-container>
      </ng-template>

      <ng-template #content let-data>
        <td>
          <div class="text-truncate" [ngbTooltip]="data?.title">
            <span class="title-w">
              {{ data?.title }}
            </span>
          </div>
        </td>
        <td>
          <div class="text-truncate bg-soft-info" [ngbTooltip]="data?.content">
            <span class="content-w d-block">
              {{ data?.content }}
            </span>
          </div>
        </td>
        <td class="position-relative">{{ data?.caller }}</td>
        <td>
          {{ data?.phoneNumber | invisiblePhoneNumber | async }}
        </td>
        <td>
          <button
            ngbTooltip="Bấm để nghe"
            class="btn btn-sm btn-outline-success"
          >
            <i class="ri-volume-up-line fs-18 me-1"></i>
          </button>
        </td>
        <td>{{ data?.propertyStatusBefore | getFullStatus }}</td>
        <td>{{ data?.propertyStatusAfter | getFullStatus }}</td>
        <td>{{ data?.method.name }}</td>
        <td>{{ data?.statusCall.name }}</td>

        <td>
          {{ data?.startTime | date: " HH:mm" }}-{{
            data?.endTime | date: " HH:mm"
          }}
        </td>
        <td>{{ data?.endTime | date: "dd/MM/YYYY" }}</td>
      </ng-template>
    </app-table>
  </ng-container>

  <div *ngIf="!contactHistories?.length" class="card card-light my-4 m-0">
    <div class="card-body text-center text-primary">
      <div>Không có dữ liệu hiển thị</div>
      <i class="fs-24 mdi mdi-database-off-outline"></i>
    </div>
  </div>
</ng-container>

<ng-template>
  <audio controls>
    <source src="horse.ogg" type="audio/ogg" />
    <source src="horse.mp3" type="audio/mpeg" />
    Your browser does not support the audio element.
  </audio>
</ng-template>
