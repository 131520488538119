import {
  CdkVirtualScrollViewport,
  ScrollingModule,
} from "@angular/cdk/scrolling";
import { AsyncPipe, NgFor, NgIf } from "@angular/common";
import {
  AfterViewInit,
  Component,
  inject,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { NotificationModel } from "@model/notification.model";
import { NotificationDataService } from "@service/socket/notification-data.service";
import { SimplebarAngularModule } from "simplebar-angular";
import { NotificationSkeletonComponent } from "../notification-skeleton/notification-skeleton.component";
import { NotifyEmptyComponent } from "../notify-empty/notify-empty.component";
import { NotifyItemComponent } from "../notify-item/notify-item.component";

@Component({
  selector: "app-notify-tab",
  templateUrl: "./notify-tab.component.html",
  styleUrls: ["./notify-tab.component.scss"],
  standalone: true,
  imports: [
    SimplebarAngularModule,
    NotifyEmptyComponent,
    NgIf,
    NotifyItemComponent,
    ScrollingModule,
    NotificationSkeletonComponent,
    AsyncPipe,
    NgFor,
  ],
})
export class NotifyTabComponent implements OnChanges, AfterViewInit {
  public notificationStore = inject(NotificationDataService);

  @ViewChild(CdkVirtualScrollViewport) viewport!: CdkVirtualScrollViewport;

  @Input() type: string = "all";
  @Input() list: NotificationModel[];
  @Input() openTrigger?: boolean;

  ngOnChanges(changes: SimpleChanges) {
    if (changes["openTrigger"]?.currentValue) {
      this.viewport?.checkViewportSize();
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.viewport?.checkViewportSize();
    }, 200);
  }

  onScroll(index: number) {
    const maxItem = 42;
    if (index > 0 && index % maxItem === 0) {
      this.notificationStore.nextPage();
    }
  }
}
