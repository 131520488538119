import { Pipe, PipeTransform } from "@angular/core";
import { combineLatest, Observable, of } from "rxjs";
import { CategoryService } from "src/app/public/services/categories/category.service";
import { AddressModel } from "src/app/public/models/address.model";
import { mergeMap } from "rxjs/operators";

@Pipe({
  name: "getFullAddress",
})
export class GetFullAddressPipe implements PipeTransform {
  public cities: any[];
  public towns: any[];
  public districts: any[];

  constructor(private categoryService: CategoryService) {}

  transform(
    address: AddressModel,
    isFullAddress?: boolean
  ): Observable<string> {
    if (address) {
      return combineLatest([
        this.categoryService.getAllCity(),
        this.categoryService.getAllTown(),
        this.categoryService.getAllDistrict(),
      ]).pipe(
        mergeMap((data) => {
          const _cities = data[0].find((t: any) => t.cKey === address.city);
          const _town = data[1].find((t: any) => t.cKey === address.town);
          const _districts = data[2].find(
            (t: any) => t.cKey === address.district
          );

          const fullDistrict = [_town?.cValue, _districts?.cValue]
            .filter(Boolean)
            .join(" - ");
          const fullHouseNbr = [address.houseNbr, address.street]
            .filter(Boolean)
            .join(" ");
          return of(
            [fullHouseNbr, fullDistrict, _cities?.cValue]
              .filter(Boolean)
              .join(" - ")
          );
        })
      );
    }
    
    return of("");
  }
}
