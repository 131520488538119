<div *ngIf="product$ | async">
  <ul>
    <li class="d-flex align-items-center">
      <i class="ri-price-tag-line"></i>
      <div class="d-flex align-items-center">
        <p class="gray-text">Giá:</p>
        <p>
          {{ (product$ | async)?.price | transformPriceToString }}
        </p>
      </div>
    </li>
    <li class="d-flex align-items-center">
      <i class="ri-home-3-line"></i>
      <p>{{ (product$ | async)?.type || "--" }}</p>
    </li>
    <li class="d-flex align-items-center">
      <i class="ri-bar-chart-line"></i>
      <div class="d-flex align-items-center">
        <p class="gray-text">Diện tích:</p>
        <p>{{ (product$ | async)?.acreage | addMeterSquareCharacter }}</p>
      </div>
    </li>
    <li class="d-flex align-items-center">
      <i class="ri-briefcase-3-line"></i>
      <div class="d-flex align-items-center">
        <p class="gray-text">Mặt tiền:</p>
        <p>{{ (product$ | async)?.frontWidth }}m</p>
      </div>
    </li>
    <li class="d-flex align-items-center">
      <i class="ri-slideshow-line"></i>
      <div class="d-flex align-items-center">
        <p class="gray-text">Số tầng:</p>
        <p>{{ (product$ | async)?.floors }}</p>
      </div>
    </li>
    <li class="d-flex align-items-center">
      <i class="ri-message-line"></i>
      <div class="d-flex align-items-center">
        <p class="gray-text">Hướng nhà:</p>
        <p>{{ (product$ | async)?.direction }}</p>
      </div>
    </li>
    <li class="d-flex align-items-center">
      <i class="ri-quill-pen-line"></i>
      <div class="d-flex align-items-center">
        <p class="gray-text">Đường rộng:</p>
        <p>{{ (product$ | async)?.streetWidth }}m</p>
      </div>
    </li>
  </ul>
</div>
