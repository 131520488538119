import { Component, inject, Input, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import {
  FormSearchRealEstateAudit,
  FormSearchRealEstateAuditHelper,
} from "src/app/public/helpers/form-search-real-estate-audit.helper";
import { RealEstateAuditModel } from "src/app/public/models/real-estate-audit.model";
import { RealEstateAuditService } from "src/app/public/services/real-estate/real-estate-audit.service";
import { DetailHistoryModalComponent } from "../detail-history-modal/detail-history-modal.component";
import { take } from "rxjs/operators";
import { SearchFilterRealEstateAuditComponent } from "src/app/components/filter/search-filter-real-estate-audit/search-filter-real-estate-audit.component";

@Component({
  selector: "app-real-estate-edit-history",
  templateUrl: "./real-estate-edit-history.component.html",
  styleUrls: ["./real-estate-edit-history.component.scss"],
  providers: [RealEstateAuditService],
})
export class RealEstateEditHistoryComponent {
  // inject service
  auditService = inject(RealEstateAuditService);
  formHelper = inject(FormSearchRealEstateAuditHelper);
  dialog = inject(MatDialog);

  @Input("realEstateId") set realEstateId(realEstateId: number) {
    if (realEstateId) {
      this.searchForm.patchValue({ realEstateId });
      this.auditService.params = this.searchForm;
    }
  }

  @ViewChild(SearchFilterRealEstateAuditComponent)
  filterRealEstateAudit: SearchFilterRealEstateAuditComponent;

  searchForm: FormGroup<FormSearchRealEstateAudit> = this.formHelper.form;

  // get in service.
  public total$: Observable<number> = this.auditService.total$;
  public page$: Observable<number> = this.auditService.page$;
  public pageSize$: Observable<number> = this.auditService.pageSize$;
  public limit$: Observable<number> = this.auditService.limit$;
  public realEstateAudits$: Observable<RealEstateAuditModel[]> =
    this.auditService.items$;

  public updatePage(data: number): void {
    this.searchForm.patchValue({
      page: data,
    });
  }

  public onClickDetailHistory(index: number, previous: number) {
    const modalRef = this.dialog.open(DetailHistoryModalComponent, {
      panelClass: "detail-history-modal",
      width: "1300px",
    });

    this.realEstateAudits$.pipe(take(1)).subscribe((audits) => {
      if (index === audits?.length - 1) {
        this.auditService
          .getRealEstateAuditDetail(this.realEstateId, previous)
          .subscribe((prev) => {
            const auditCompares = [
              audits[index],
              prev,
            ] as RealEstateAuditModel[];

            modalRef.componentInstance.auditCompares = auditCompares;
          });
      } else {
        const auditCompares = [audits[index], audits[index + 1]];
        modalRef.componentInstance.auditCompares = auditCompares;
      }
    });
  }

  public onSubmitSearch() {
    this.searchForm.patchValue({});
  }

  public onResetSearch() {
    const rawValues = this.searchForm.getRawValue();
    this.searchForm.reset({
      realEstateId: rawValues.realEstateId,
    });
    this.filterRealEstateAudit.resetForm();
  }
}
