import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { APP_URL } from "src/app/public/constants/url.constant";

@Component({
  selector: "app-error-404",
  templateUrl: "./error-page.component.html",
  styleUrls: ["./error-page.component.scss"],
})

/**
 * 404 Basic Component
 */
export class ErrorPageComponent {
  // set the current year
  year: number = new Date().getFullYear();

  constructor(private router: Router) {}

  public handleRedirectDefault() {
    const url = APP_URL.REAL_ESTATE_MANAGEMENT
    this.router.navigate([url]).then();
  }
}
