import { FormControl } from "@angular/forms";

export function convertPriceUnit(unitValue: string, price: number): number {
  switch (unitValue) {
    case "Triệu":
      return price * 1000000;
    case "Tỷ":
      return price * 1000000000;
    default:
      return price;
  }
}

export function convertPriceUnitReverse(
  unitForm: FormControl,
  price: number
): number {
  switch (true) {
    case price <= 999999999: {
      unitForm.setValue("Triệu");
      return price / 1000000;
    }
    case price >= 999999999: {
      unitForm.setValue("Tỷ");
      return price / 1000000000;
    }
    default:
      return price;
  }
}
