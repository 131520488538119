import { PAGE_DEFAULT } from "../constants/common.constant";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Injectable } from "@angular/core";
import { minLengthValidator } from "../utils/app-validators";

export interface FormSearchRealEstateAudit {
  page: FormControl<number>;
  limit: FormControl<number>;
  createdBy: FormControl<string>;
  createdFrom: FormControl<Date>;
  createdTo: FormControl<Date>;
  status: FormControl<number>;
  sort: FormControl<string>;
  realEstateId: FormControl<number>;
}

@Injectable({
  providedIn: "root",
})
export class FormSearchRealEstateAuditHelper {
  static PAGE_SIZE = 8;

  get form() {
    return FormSearchRealEstateAuditHelper.initForm();
  }

  public static initForm(data?: FormSearchRealEstateAudit): FormGroup {
    return new FormGroup<FormSearchRealEstateAudit>({
      page: new FormControl(PAGE_DEFAULT, {
        nonNullable: true,
      }),
      limit: new FormControl(this.PAGE_SIZE, { nonNullable: true }),
      status: new FormControl(data?.status || null, { updateOn: "submit" }),
      createdFrom: new FormControl(data?.createdFrom || null, {
        updateOn: "submit",
      }),
      createdTo: new FormControl(data?.createdTo || null, {
        updateOn: "submit",
      }),
      createdBy: new FormControl(data?.createdBy || null, {
        updateOn: "submit",
        validators: [
          Validators.compose([Validators.maxLength(10), minLengthValidator]),
        ],
      }),
      sort: new FormControl(data?.sort || null, { updateOn: "submit" }),
      realEstateId: new FormControl(data?.realEstateId || null),
    });
  }
}
