import { NgFor } from "@angular/common";
import { Component } from "@angular/core";

@Component({
  selector: "notification-skeleton",
  templateUrl: "./notification-skeleton.component.html",
  standalone: true,
  imports: [NgFor],
})
export class NotificationSkeletonComponent {}
