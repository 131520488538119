import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from "@angular/core";
import { REAL_ESTATE_STATUS_ALLOW_TO_SELL } from "src/app/public/constants/real-estate.constant";

@Directive({
  selector: "[realEstateAllowToSell]",
  exportAs: "realEstateAllowToSell",
})
export class RealEstateAllowToSellDirective implements OnInit {
  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>
  ) {}

  @Input("realEstateAllowToSell") set realEstateAllowToSell(status: string) {
    const _values = Object.values(REAL_ESTATE_STATUS_ALLOW_TO_SELL);
    const _isAllowForSell = _values.find((v) => v === status);
    if (_isAllowForSell) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  ngOnInit(): void {}
}
