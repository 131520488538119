import { Component, inject, Input } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { CREATE_REAL_ESTATE_STATUS } from "src/app/public/constants/real-estate.constant";
import { CategoryService } from "src/app/public/services/categories/category.service";
import { DecrementFormControl } from "src/app/public/utils/decrement-form-control";
import { IncrementFormControl } from "src/app/public/utils/increment-form-control";

@Component({
  selector: "app-form-common-info",
  templateUrl: "./form-common-info.component.html",
  styleUrls: ["./form-common-info.component.scss"],
})
export class FormCommonInfoComponent {
  private categoryService = inject(CategoryService);

  decrement = DecrementFormControl;
  increment = IncrementFormControl;

  public typeOfRealEstate$: Observable<any> = this.categoryService.getAllType();
  public REAL_ESTATE_STATUS = CREATE_REAL_ESTATE_STATUS;

  @Input() formCommon: UntypedFormGroup;
  @Input() isEdit: boolean;
  @Input() productId: string | number;

  get controls() {
    return this.formCommon.controls;
  }
}
