import { Injectable } from "@angular/core";
import { NOTIFY_TYPE, USER_INFO } from "@constant/common.constant";
import { REQUEST_DEFAULT } from "@constant/http-request.constant";
import { NotificationModel } from "@model/notification.model";
import { StorageService } from "@service/storage/storage.service";
import { BehaviorSubject } from "rxjs";
import { tap } from "rxjs/operators";
import {
  ParamOptions,
  SwNotificationHttpService,
} from "./sw-notification-http.service";

@Injectable({
  providedIn: "root",
})
export class NotificationDataService {
  private _loading = new BehaviorSubject(false);
  private _total = new BehaviorSubject<number>(0);
  private _limit = new BehaviorSubject<number>(REQUEST_DEFAULT.LARGE_LIMIT);
  private _page = new BehaviorSubject<number>(REQUEST_DEFAULT.PAGE);
  private _doneStatus: boolean;

  private _allTypes = new BehaviorSubject<NotificationModel[]>([]);
  private _notifications = new BehaviorSubject<NotificationModel[]>([]);

  constructor(
    private swHttp: SwNotificationHttpService,
    private storageService: StorageService
  ) {}

  set page(value: BehaviorSubject<number>) {
    this._page = value;
  }

  get allTypes$(): BehaviorSubject<NotificationModel[]> {
    if (!this._allTypes.value?.length) {
      this.loadNotifications();
    }
    return this._allTypes;
  }

  get notifications$(): BehaviorSubject<NotificationModel[]> {
    return this._notifications;
  }

  get page$() {
    return this._page;
  }

  get total$() {
    return this._total;
  }

  get loading$() {
    return this._loading;
  }

  set doneStatus(value: boolean) {
    this._doneStatus = value;
  }

  public nextPage(page?: number) {
    const total = this._total.value;
    const _page = page ?? this.page$.value;
    const limit = this._limit.value;

    if (total <= _page * limit) {
      return;
    }

    this._page.next(page ?? this._page.value + 1);

    this.loadNotifications({
      page: this._page.value,
      limit: this._limit.value,
      userId: this.storageService.get(USER_INFO)?.id,
      ...(this._doneStatus ? { doneStatus: this._doneStatus } : {}),
    });
  }

  public loadNotifications(options?: ParamOptions): void {
    if (this._loading.value) return;

    const params = {
      ...options,
      page: options?.page || this._page.value,
      limit: options?.limit || this._limit.value,
      userId: this.storageService.get(USER_INFO)?.id,
    };

    if (params?.page === 1) {
      this._allTypes.next([]);
    }

    if (this._doneStatus) {
      params.doneStatus = false;
    }

    this._loading.next(true);
    this.swHttp
      .getNotificationOfUser(params)
      .pipe(
        tap((data: any) => {
          this._total.next(data?.totalItems);
          this._page.next(params?.page);
          this._limit.next(params?.limit);
          this._allTypes.next([...this._allTypes.value, ...data?.data]);

          this._notifications.next(
            this._allTypes.value.filter(
              (n: NotificationModel) =>
                n.notification.type === NOTIFY_TYPE.NOTIFICATION
            )
          );
        }),
        tap(() => this._loading.next(false))
      )
      .subscribe();
  }

  public reloadList() {
    this._page.next(1);
    this.loadNotifications();
  }
}
