<form [formGroup]="formSearchAuditParams" (ngSubmit)="submitForm($event)">
  <div class="row g-2 align-items-start">
    <div class="col-3">
      <div class="search-box">
        <input
          type="text"
          class="form-control"
          placeholder="Người tạo"
          formControlName="createdBy"
        />
        <i class="ri-phone-line search-icon"></i>
      </div>
      <small
        class="text-danger"
        *ngIf="
          getControl('createdBy').invalid &&
          (getControl('createdBy').dirty || getControl('createdBy').touched)
        "
      >
        Bạn cần nhập từ 3 đến 10 ký tự</small
      >
    </div>
    <div class="col-3">
      <div class="search-box">
        <app-filter-date-range
          placeholder="Ngày sửa BĐS"
          [fromControl]="getControl('createdFrom')"
          [toControl]="getControl('createdTo')"
          (formRangeEvent)="onFormRangeChange($event)"
        ></app-filter-date-range>
      </div>
    </div>
    <div class="col-6 d-flex gap-2 justify-content-end">
      <button type="submit" class="btn btn-primary">
        <i class="ri-search-2-line me-1 align-bottom"></i>
        Tìm kiếm
      </button>
      <button
        type="button"
        class="btn btn-danger"
        (click)="resetSearchEvent.emit()"
      >
        <i class="ri-restart-line me-1 align-bottom"></i>
        Đặt lại
      </button>
    </div>
  </div>
</form>
