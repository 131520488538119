<!-- start page title -->
<div class="row">
  <div class="col-12">
    <div
      class="page-title-box d-sm-flex align-items-center justify-content-between"
    >
      <h4 class="mb-sm-0">{{ title }}</h4>

      <div class="page-title-right">
        <ol class="breadcrumb m-0">
          <ng-container *ngFor="let item of breadcrumbItems">
            <li
              class="breadcrumb-item"
              [ngClass]="{ active: item.active === true }"
            >
              {{ item.label }}
            </li>
          </ng-container>
        </ol>
      </div>
    </div>
  </div>
</div>
<!-- end page title -->
