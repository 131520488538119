import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { LANDS_GROUP_TYPE } from "src/app/public/constants/real-estate.constant";
import { AuthorizationService } from "src/app/public/services/auth/authorization.service";

@Component({
  selector: "real-estate-filter-state",
  templateUrl: "./real-estate-filter-state.component.html",
  styleUrls: ["./real-estate-filter-state.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RealEstateFilterStateComponent implements OnChanges {
  private authorizationService = inject(AuthorizationService);

  @Input() control: FormControl;
  @Input() isMultipleChoose: boolean = false;
  @Input() disabled: boolean;

  public items = LANDS_GROUP_TYPE.filter((i) =>
    this.authorizationService.hasPermissionResource(i.resource)
  ).map((i) => {
    return { id: i.cKey, title: i.cValue };
  });

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.control.patchValue(changes["control"].currentValue.value);
    }
  }

  compareFn(item: any, selected: any) {
    return item.id.toString() === selected.toString();
  }
}
