<form [formGroup]="searchAgentParams">
  <div class="row g-2">
    <!-- NAME  -->
    <div class="col-2">
      <div class="search-box">
        <input
          type="text"
          class="form-control search"
          placeholder="Họ và tên"
          formControlName="name"
        />
        <i class="ri-user-3-line search-icon"></i>
      </div>
    </div>

    <!-- PHONE NUMBER  -->
    <div class="col-2">
      <div class="search-box">
        <input
          type="text"
          class="form-control search"
          placeholder="Số điện thoại"
          formControlName="phone"
        />
        <i class="ri-phone-line search-icon"></i>
      </div>
    </div>

    <!-- EMAIL -->
    <div class="col-2">
      <div class="search-box">
        <input
          type="text"
          class="form-control search"
          placeholder="Email"
          formControlName="email"
        />
        <i class="ri-mail-line search-icon"></i>
      </div>
    </div>

    <!-- AGENT GROUP  -->
    <div class="col-2">
      <div class="search-box">
        <ng-select
          class="col-md-5 p-0 mb-2 w-100"
          placeholder="Nhóm CTV"
        ></ng-select>
      </div>
    </div>

    <!-- SEARCH BUTTON  -->
    <div class="col-2">
      <button type="submit" class="btn btn-primary">
        <i class="ri-search-2-line me-1 align-bottom"></i>
        Tìm kiếm
      </button>
    </div>
  </div>
</form>
