import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup, UntypedFormControl, UntypedFormGroup } from "@angular/forms";

@Component({
  selector: "app-search-filter-real-estate-audit",
  templateUrl: "./search-filter-real-estate-audit.component.html",
})
export class SearchFilterRealEstateAuditComponent {
  @Input() formSearchAuditParams: FormGroup<any>;

  @Output() submitEvent = new EventEmitter();
  @Output() resetSearchEvent = new EventEmitter();

  public formRange: UntypedFormGroup;

  constructor() {}

  public getControl(control: string): UntypedFormControl {
    return this.formSearchAuditParams.controls[control] as UntypedFormControl;
  }

  public onFormRangeChange(form: UntypedFormGroup) {
    this.formRange = form;
  }

  public resetForm() {
    if (this.formRange) {
      this.formRange.reset();
    }
  }

  public submitForm($event: any) {
    this.submitEvent.emit();
  }
}
