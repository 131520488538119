import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";

@Component({
  selector: "app-skeleton-table",
  templateUrl: "./skeleton-table.component.html",
  styleUrls: ["./skeleton-table.component.scss"],
  standalone: true,
  imports: [
    CommonModule
  ]
})
export class SkeletonTableComponent {
  @Input() tableType: string;
  public rowSkeleton: Array<any> = [];

  constructor() {
    for (let i = 0; i < 5; i++) {
      this.rowSkeleton.push(i);
    }
  }
}
