import { inject, Pipe, PipeTransform } from "@angular/core";
import { Observable } from "rxjs";
import { CategoryService } from "../services/categories/category.service";
import { map } from "rxjs/operators";

@Pipe({
  name: "landBusinessStatus",
})
export class LandBusinessStatusPipe implements PipeTransform {
  private categoryService = inject(CategoryService);

  transform(value: string): Observable<string> {
    return this.categoryService.getAllJuridical().pipe(
      map((data) => {
        const d = data?.find((d) => d.cKey === value);
        return d?.cValue || "--";
      })
    );
  }
}
