<div [style.opacity]="disabled ? '0.8' : null">
  <ng-select
    [items]="items"
    [multiple]="isMutipleChoose"
    bindLabel="title"
    bindValue="id"
    placeholder="Loại hình BĐS"
    [clearable]="true"
    [formControl]="formChild"
    [closeOnSelect]="!isMutipleChoose"
    [selectableGroup]="true"
    [selectableGroupAsModel]="false"
    [groupBy]="isMutipleChoose ? 'selectedAllGroup' : ''"
    [style.pointer-events]="disabled ? 'none' : null"
    [class.is-invalid]="isInvalid"
  >
    <ng-template ng-optgroup-tmp let-item$="item$" *ngIf="isMutipleChoose">
      <div class="d-flex align-items-center gap-1">
        <input type="checkbox" [ngModel]="item$.selected" />
        Chọn tất cả
      </div>
    </ng-template>
    <ng-template
      ng-option-tmp
      let-item="item"
      let-item$="item$"
      let-index="index"
    >
      <div class="d-flex align-items-center">
        <input
          *ngIf="isMutipleChoose"
          id="item-{{ index }}"
          type="checkbox"
          [ngModel]="item$.selected"
          [ngModelOptions]="{ standalone: true }"
          class="me-1"
        />
        <span class=""> {{ item["title"] }}</span>
      </div>
    </ng-template>

    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
      <div class="ng-value" *ngFor="let i of items | slice: 0:1">
        <span class="ng-value-label"> {{ $any(i)?.title }}</span>
        <span class="ng-value-icon right" (click)="clear(i)">×</span>
      </div>
      <div class="ng-value" *ngIf="items.length > 1">
        <span class="ng-value-label"> + {{ items.length - 1 }}</span>
      </div>
    </ng-template>
  </ng-select>
</div>
