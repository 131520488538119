import { Pipe, PipeTransform } from "@angular/core";
import { SERVICE_URL } from "../constants/permission.contants";

@Pipe({
  name: "getServiceColor",
})
export class GetServiceColorPipe implements PipeTransform {
  transform(value: any): any {
    switch (value) {
      case SERVICE_URL.API_RESOURCE:
        return "primary";
      case SERVICE_URL.OTHER:
        return "secondary";
      case SERVICE_URL.API_AUTH:
        return "success";
      case SERVICE_URL.API_MESSAGE:
        return "danger";
      case SERVICE_URL.API_USER:
        return "warning";
      case SERVICE_URL.API_CRM:
        return "info";
      case SERVICE_URL.API_LAND:
        return "dark";
    }
  }
}
