import { tap } from "rxjs/operators";
import { StorageService } from "src/app/public/services/storage/storage.service";
import { UserReq } from "./../../constants/user.constant";
import { BehaviorSubject, Observable } from "rxjs";
import { AuthHttpService } from "./auth-http.service";
import { Injectable } from "@angular/core";
import { RefreshTokenReq, User } from "src/app/public/models/auth.models";
import { JwtTokenService } from "src/app/public/services/jwt-token.service";

@Injectable({ providedIn: "root" })

/**
 * Auth-service Component
 */
export class AuthenticationService {
  user!: User;
  currentUserValue: any;
  public loginState$ = new BehaviorSubject<boolean>(false);

  constructor(
    private jwtService: JwtTokenService,
    private authHttp: AuthHttpService,
    private storageService: StorageService
  ) {}

  /**
   * @function login and return token key
   */
  login(payload: UserReq): Observable<boolean> {
    return this.authHttp
      .login(payload)
      .pipe(tap((_) => this.loginState$.next(true)));
  }

  /**
   * @function logout the user
   */
  logout() {
    // logout the user
    this.storageService.deleteAll();
  }

  refreshToken(payload: RefreshTokenReq): Observable<any> {
    return this.authHttp.refreshToken(payload);
  }

  hasPermission(permissions: [string]): boolean {
    const { permission } = this.jwtService.encodeJwt();
    return true;
  }
}
