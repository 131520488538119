<div class="modal-content">
  <div class="modal-header pb-3">
    <h5 class="modal-title">Bộ lọc nâng cao</h5>
    <button
      type="button"
      class="close"
      (click)="onCloseModal()"
      data-dismiss="modal"
      aria-label="Close"
    >
      <span>&times;</span>
    </button>
  </div>
  <hr class="m-0" />

  <div class="modal-body customize-modal-content">
    <ng-container *ngFor="let title of TABS_TITLE; let i = index">
      <div>
        <div
          class="text-dark cursor-pointer"
          (click)="isCollapsed[i] = !isCollapsed[i]"
          [attr.aria-expanded]="isCollapsed[i]"
        >
          <span class="badge badge-soft-primary fs-12"
            ><i
              [ngClass]="
                !isCollapsed[i] ? 'bx bx-caret-down' : 'bx bx-caret-right'
              "
              class="pe-1"
            ></i
            >{{ title }}</span
          >
          <div class="border border-dashed"></div>
        </div>
        <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed[i]">
          <ng-container *ngIf="i === 0">
            <app-filter-address
              [formSearchAndFilter]="searchLandParams"
            ></app-filter-address>
          </ng-container>

          <ng-container *ngIf="i === 1">
            <app-filter-important-info
              [formSearchAndFilter]="searchLandParams"
              [unitForm]="unitForm"
            ></app-filter-important-info>
          </ng-container>

          <ng-container *ngIf="i === 2">
            <app-filter-detail-info
              [formSearchAndFilter]="searchLandParams"
            ></app-filter-detail-info>
          </ng-container>

          <ng-container *ngIf="i === 3">
            <app-filter-common-info
              [formSearchAndFilter]="searchLandParams"
            ></app-filter-common-info>
          </ng-container>

          <ng-container *ngIf="i === 4">
            <app-filter-internal
              [formSearchAndFilter]="searchLandParams"
              [createdDefault]="createdDefault"
              [siteHandoverTimeDefault]="siteHandoverTimeDefault"
              [openTimeDefault]="openTimeDefault"
              [employeeList]="employeeList"
            ></app-filter-internal>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="onResetField()">
      <i class="mdi mdi-restore"></i> Đặt lại
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="onSearchEventEmit(searchLandParams)"
    >
      Tìm kiếm
    </button>
  </div>
</div>
