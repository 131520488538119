import { Pipe, PipeTransform } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CategoryService } from "src/app/public/services/categories/category.service";

@Pipe({
  name: "landType",
})
export class LandTypePipe implements PipeTransform {
  constructor(private categoryService: CategoryService) {}

  transform(value: any): Observable<any> {
    return this.categoryService.getAllType().pipe(
      map((data) => {
        const d = data?.find((d) => d.cKey === value?.toString());
        return d?.cValue || "--";
      })
    );
  }
}
