import { Directive, ElementRef, Input, OnChanges, OnInit } from "@angular/core";

@Directive({
  selector: "[appGetPermissionNames]",
})
export class GetPermissionNamesDirective implements OnInit, OnChanges {
  @Input() list: any[];
  @Input() ids: number[];

  constructor(private el: ElementRef) {}

  ngOnChanges(): void {
    this.execute();
  }

  ngOnInit(): void {
    this.execute();
  }

  execute(): void {
    this.el.nativeElement.textContent = this.getNames().join(", ");
  }

  getNames(): string[] {
    if (this.list?.length && this.ids?.length) {
      return this.list
        .filter((item) => this.ids.includes(item.id))
        .map((item) => item.name);
    }
    return [];
  }
}
