import { Observable } from "rxjs";
import { CategoryService } from "src/app/public/services/categories/category.service";
import { tap } from "rxjs/operators";

export function getCodeOfTownUtil(
  townName: string,
  categoryService: CategoryService
) {
  return new Observable((observable) => {
    categoryService
      .getAllTown()
      .pipe(
        tap((towns) => {
          const town = towns.filter((town) => {
            return town.cValue.toLowerCase() === townName.trim().toLowerCase();
          });
          observable.next(town);
        })
      )
      .subscribe((_) => {});
  });
}
