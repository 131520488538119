import { API_URL } from "src/app/public/constants/api-url.constant";
import { Observable } from "rxjs";
import { BaseHttpRequest } from "./../http/base-http-request.service";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class CredentialHttpService extends BaseHttpRequest {
  getCredential(): Observable<any> {
    return this.get(API_URL.CREDENTIAL) as Observable<any>;
  }
}
