<div id="more-info-container">
  <div class="menu-bar d-flex align-items-end">
    <div class="col-10">
      <mat-tab-group
        class="customize-tab-group"
        [selectedIndex]="tab"
        (selectedTabChange)="selectedTabChange($event)"
      >
        <mat-tab label="Map"></mat-tab>
        <mat-tab label="Streetview"></mat-tab>
      </mat-tab-group>
    </div>
    <div class="col-2 text-end close-modal-btn">
      <button (click)="dialogRef.close()">
        <i class="ri-close-line"></i>
      </button>
    </div>
  </div>

  <div class="mat-card-image w-100 m-0">
    <!-- MAP  -->
    <div class="container-content" *ngIf="tab === 0">
      <div class="map">
        <div class="map-content">
          <agm-map
            class="agm-map"
            [zoom]="20"
            [latitude]="this.lat"
            [longitude]="this.lng"
            [disableDefaultUI]="true"
            [gestureHandling]="'cooperative'"
            draggable="false"
            [maxZoom]="22"
            [minZoom]="14"
            [mapTypeControl]="true"
            [mapTypeId]="'hybrid'"
          >
            <agm-marker
              [latitude]="this.lat | convertToNumber"
              [longitude]="this.lng | convertToNumber"
              [markerDraggable]="false"
            >
            </agm-marker>
          </agm-map>
        </div>
      </div>
    </div>

    <!-- STREETVIEW  -->
    <div class="container-content" *ngIf="tab === 1">
      <h1 class="map">
        <iframe
          *ngIf="linkStreetView?.length"
          [src]="linkStreetView | safeUrl"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </h1>
    </div>
  </div>
</div>
