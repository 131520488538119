export function dmsToDecimal(dms: string) {
  const regex = /(\d+)°(\d+)'([\d.]+)"([NS]) (\d+)°(\d+)'([\d.]+)"([EW])/;
  const match = dms.match(regex);

  if (match) {
    const latDegrees = parseFloat(match[1]);
    const latMinutes = parseFloat(match[2]);
    const latSeconds = parseFloat(match[3]);
    const latDirection = match[4];

    const lngDegrees = parseFloat(match[5]);
    const lngMinutes = parseFloat(match[6]);
    const lngSeconds = parseFloat(match[7]);
    const lngDirection = match[8];

    const latitude =
      latDirection === "N"
        ? latDegrees + latMinutes / 60 + latSeconds / 3600
        : -(latDegrees + latMinutes / 60 + latSeconds / 3600);

    const longitude =
      lngDirection === "E"
        ? lngDegrees + lngMinutes / 60 + lngSeconds / 3600
        : -(lngDegrees + lngMinutes / 60 + lngSeconds / 3600);

    // Sử dụng toFixed để làm tròn đến 6 chữ số thập phân
    const roundedLatitude = latitude.toFixed(6);
    const roundedLongitude = longitude.toFixed(6);

    return {
      lat: parseFloat(roundedLatitude),
      lng: parseFloat(roundedLongitude),
    };
  } else {
    return { lat: 0, lng: 0 };
  }
}
