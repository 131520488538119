<header id="page-topbar">
  <div class="layout-width">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box horizontal-logo">
          <a href="" class="logo logo-dark">
            <span class="logo-sm">
              <img src="assets/images/logo-sm.png" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="assets/images/logo-dark.png" alt="" height="17" />
            </span>
          </a>

          <a href="" class="logo logo-light">
            <span class="logo-sm">
              <img src="assets/images/logo-sm.png" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="assets/images/logos/logo.svg" alt="" height="17" />
            </span>
          </a>
        </div>

        <button
          type="button"
          class="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
          id="topnav-hamburger-icon"
          (click)="toggleMobileMenu($event)"
        >
          <span class="hamburger-icon">
            <span></span>
            <span></span>
            <span></span>
          </span>
        </button>
      </div>

      <div class="d-flex align-items-center">
        <div
          class="dropdown d-md-none topbar-head-dropdown header-item"
          ngbDropdown
        >
          <button
            type="button"
            class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
            id="page-header-search-dropdown"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            ngbDropdownToggle
          >
            <i class="bx bx-search fs-22"></i>
          </button>
          <div
            class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
            aria-labelledby="page-header-search-dropdown"
            ngbDropdownMenu
          >
            <form class="p-3">
              <div class="form-group m-0">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search ..."
                    aria-label="Recipient's username"
                  />
                  <button class="btn btn-primary" type="submit">
                    <i class="mdi mdi-magnify"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="dropdown ms-1 topbar-head-dropdown header-item" ngbDropdown>
          <button
            type="button"
            class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            ngbDropdownToggle
          >
            <img
              *ngIf="flagvalue !== undefined"
              src="{{ flagvalue }}"
              alt="Header Language"
              height="20"
              class="rounded"
            />
            <img
              *ngIf="flagvalue === undefined"
              src="{{ valueset }}"
              alt="Header Language"
              height="20"
              class="rounded"
            />
          </button>
          <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
            <!-- item-->
            <a
              href="javascript:void(0);"
              class="dropdown-item notify-item language py-2"
              data-lang="eng"
              *ngFor="let item of listLang"
              (click)="setLanguage(item.text, item.lang, item.flag)"
              [ngClass]="{ active: cookieValue === item.lang }"
            >
              <img
                src="{{ item.flag }}"
                alt="user-image"
                class="me-2 rounded"
                height="18"
              />
              <span class="align-middle">{{ item.text }}</span>
            </a>
          </div>
        </div>

        <div class="ms-1 header-item d-none d-sm-flex">
          <button
            type="button"
            class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
            data-toggle="fullscreen"
            (click)="fullscreen()"
          >
            <i class="bx bx-fullscreen fs-22"></i>
          </button>
        </div>

        <div class="ms-1 header-item d-none d-sm-flex">
          <button
            type="button"
            class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode"
          >
            <i class="bx bx-moon fs-22" (click)="changeMode('dark')"></i>
            <i class="bx bx-sun fs-22" (click)="changeMode('light')"></i>
          </button>
        </div>

        <app-notification-dropdown></app-notification-dropdown>

        <app-user-info></app-user-info>
      </div>
    </div>
  </div>
</header>
