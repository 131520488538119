import * as XLSX from "xlsx";
import { Observable } from "rxjs";

export function convertFileXlsxToJsonUtil(file: File) {
  return new Observable((observable) => {
    const reader = new FileReader();

    reader.onloadend = function (e) {
      const data = reader?.result;
      const worksheet = XLSX.read(data, { type: "binary", raw: true });
      const jsonData = worksheet.SheetNames.reduce((initial, name) => {
        const sheet = worksheet.Sheets[name];
        // @ts-ignore
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      observable.next(jsonData);
    };

    reader.readAsBinaryString(file);
  }) as Observable<any>;
}

// @ts-ignore
export function convertJsonToXlsxFile(data: Array<any>) {
  if (data.length) {
    const header = DEFINED_COLUMN_XLSX.map((column) => column.name);
    const keys = DEFINED_COLUMN_XLSX.map((column) => column.key);
    const result = data.map((d, index) => {
      return keys.map((k) => d[k]);
    });

    const worksheet = XLSX.utils.json_to_sheet(result);
    XLSX.utils.sheet_add_aoa(worksheet, [header], { origin: "A1" });
    return worksheet;
  }
}

export const DEFINED_COLUMN_XLSX = [
  {
    key: "id",
    name: "STT",
    type: "string",
    required: true,
  },
  {
    key: "houseNbr",
    name: "Số nhà",
  },
  {
    key: "street",
    name: "Tên đường",
    required: true,
  },
  {
    key: "town",
    name: "Xã/phường",
    required: true,
  },
  {
    key: "district",
    name: "Quận/huyện",
    required: true,
  },
  {
    key: "city",
    name: "Tỉnh/Thành phố",
    required: true,
  },
  {
    key: "position",
    name: "Vị trí",
  },
  {
    key: "type",
    name: "Loại hình BĐS",
  },
  {
    key: "frontWidth",
    name: "Mặt tiền",
  },
  {
    key: "acreage",
    name: "Diện tích",
  },
  {
    key: "floors",
    name: "Số tầng",
  },
  {
    key: "price",
    name: "Giá",
  },
  {
    key: "purpose",
    name: "Mục đích",
  },
  {
    key: "customerName",
    name: "Tên khách hàng 1",
  },
  {
    key: "phone1_1",
    name: "KH1_SĐT1",
  },
  {
    key: "phone1_2",
    name: "KH1_SĐT2",
  },
  {
    key: "phone1_3",
    name: "KH1_SĐT3",
  },
  {
    key: "customerName1",
    name: "Tên khách hàng 2",
  },
  {
    key: "phone2_1",
    name: "KH2_SĐT1",
  },
  {
    key: "phone2_2",
    name: "KH2_SĐT2",
  },
  {
    key: "phone2_3",
    name: "KH2_SĐT3",
  },
  {
    key: "nameOfCollobrate",
    name: "Tên cộng tác viên",
  },
  {
    key: "phoneOfCollobrate",
    name: "SĐT",
  },
  {
    key: "tenant",
    name: "Đơn vị đang thuê",
  },
  {
    key: "note",
    name: "ghi chú",
  },
];
