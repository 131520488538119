const ListJs = [
  {
    customer_name: "Mary Cousar",
    email: "marycousar@velzon.com",
    phone: "580-464-4694",
    date: "06 Apr, 2021",
    status: "ACTIVE",
    status_color: "success",
    isSelected: false,
    contact: [
      { phone: "019293129" },
      { phone: "019293129" },
      { phone: "019293129" },
    ],
  },
  {
    customer_name: "Robert McMahon",
    email: "robertmcmahon@velzon.com",
    phone: "786-253-9927",
    date: "12 Jan, 2021",
    status: "ACTIVE",
    status_color: "success",
    isSelected: false,
    contact: [
      { phone: "019293129" },
      { phone: "019293129" },
      { phone: "019293129" },
    ],
  },
  {
    customer_name: "Michael Morris",
    email: "michaelmorris@velzon.com",
    phone: "805-447-8398",
    date: "19 May, 2021",
    status: "BLOCK",
    status_color: "danger",
    isSelected: false,
    contact: [
      { phone: "019293129" },
      { phone: "019293129" },
      { phone: "019293129" },
    ],
  },
  {
    customer_name: "Kevin Dawson",
    email: "kevindawson@velzon.com",
    phone: "213-741-4294",
    date: "14 Apr, 2021",
    status: "ACTIVE",
    status_color: "success",
    isSelected: false,
    contact: [
      { phone: "019293129" },
      { phone: "019293129" },
      { phone: "019293129" },
    ],
  },
  {
    customer_name: "Thomas Taylor",
    email: "marycousar@velzon.com",
    phone: "580-464-4694",
    date: "06 Apr, 2021",
    status: "ACTIVE",
    status_color: "success",
    isSelected: false,
    contact: [
      { phone: "019293129" },
      { phone: "019293129" },
      { phone: "019293129" },
    ],
  },
  {
    customer_name: "Carolyn Jones",
    email: "carolynjones@velzon.com",
    phone: "414-453-5725",
    date: "07 Jun, 2021",
    status: "ACTIVE",
    status_color: "success",
    isSelected: false,
    contact: [
      { phone: "019293129" },
      { phone: "019293129" },
      { phone: "019293129" },
    ],
  },
  {
    customer_name: "Glen Matney",
    email: "glenmatney@velzon.com",
    phone: "515-395-1069",
    date: "02 Nov, 2021",
    status: "ACTIVE",
    status_color: "success",
    isSelected: false,
    contact: [
      { phone: "019293129" },
      { phone: "019293129" },
      { phone: "019293129" },
    ],
  },
  {
    customer_name: "Charles Kubik",
    email: "charleskubik@velzon.com",
    phone: "231-480-8536",
    date: "25 Sep, 2021",
    status: "BLOCK",
    status_color: "danger",
    isSelected: false,
    contact: [
      { phone: "019293129" },
      { phone: "019293129" },
      { phone: "019293129" },
    ],
  },
  {
    customer_name: "Herbert Stokes",
    email: "herbertstokes@velzon.com",
    phone: "312-944-1448",
    date: "20 Jul, 2021",
    status: "BLOCK",
    status_color: "danger",
    isSelected: false,
    contact: [
      { phone: "019293129" },
      { phone: "019293129" },
      { phone: "019293129" },
    ],
  },
  {
    customer_name: "Timothy Smith",
    email: "timothysmith@velzon.com",
    phone: "973-277-6950",
    date: "13 Dec, 2021",
    status: "ACTIVE",
    status_color: "success",
    isSelected: false,
    contact: [
      { phone: "019293129" },
      { phone: "019293129" },
      { phone: "019293129" },
    ],
  },
  {
    customer_name: "Johnny Evans",
    email: "johnnyevans@velzon.com",
    phone: "407-645-1767",
    date: "01 Oct, 2021",
    status: "BLOCK",
    status_color: "danger",
    isSelected: false,
    contact: [
      { phone: "019293129" },
      { phone: "019293129" },
      { phone: "019293129" },
    ],
  },
  {
    customer_name: "Kevin Dawson",
    email: "kevindawson@velzon.com",
    phone: "213-741-4294",
    date: "14 Apr, 2021",
    status: "ACTIVE",
    status_color: "success",
    isSelected: false,
    contact: [
      { phone: "019293129" },
      { phone: "019293129" },
      { phone: "019293129" },
    ],
  },
];

export { ListJs };
