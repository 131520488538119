<div [style.opacity]="disabled ? '0.6' : null">
  <ng-select
    [items]="items"
    [multiple]="false"
    bindLabel="title"
    bindValue="id"
    placeholder="Nhóm BĐS"
    [clearable]="false"
    [formControl]="control"
    [closeOnSelect]="true"
    [compareWith]="compareFn"
    [style.pointer-events]="disabled ? 'none' : null"
  >
  </ng-select>
</div>
