import { Component, Input } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";

@Component({
  selector: "app-form-other-info",
  templateUrl: "./form-other-info.component.html",
})
export class FormOtherInfoComponent {
  @Input() formRealEstate: UntypedFormGroup;
  @Input() isEdit: boolean;
  @Input() realEstateId: string | number;
  public Editor = ClassicEditor;

  get controls() {
    return this.formRealEstate.controls;
  }
}
