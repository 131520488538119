import { AsyncPipe, NgIf } from "@angular/common";
import { Component, inject } from "@angular/core";
import { USER_INFO } from "@constant/common.constant";
import { REQUEST_DEFAULT } from "@constant/http-request.constant";
import {
  NgbDropdownModule,
  NgbNavModule,
  NgbTooltipModule,
} from "@ng-bootstrap/ng-bootstrap";
import {
  EVENT_BUS_EVENTS,
  EventBusService,
} from "@service/common/event-bus.service";
import { NotificationDataService } from "@service/socket/notification-data.service";
import { StorageService } from "@service/storage/storage.service";
import { NotifyTabComponent } from "./containers/notify-tab/notify-tab.component";

@Component({
  selector: "app-notification-dropdown",
  templateUrl: "./notification-dropdown.component.html",
  standalone: true,
  imports: [
    NgbNavModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NotifyTabComponent,
    AsyncPipe,
    NgIf,
  ],
})
export class NotificationDropdownComponent {
  readonly eventBus = inject(EventBusService);
  public notificationData = inject(NotificationDataService);
  readonly storageService = inject(StorageService);

  public unreadNotification$ = this.eventBus.on(
    EVENT_BUS_EVENTS.GET_UNREAD_NOTIFICATION
  );

  public isDropdownOpen: boolean;
  public allTypes$ = this.notificationData.allTypes$;
  public notifications$ = this.notificationData.notifications$;

  public toggleShowUnprocessed(event: Event) {
    const target = event.target as HTMLInputElement;
    const userId = this.storageService.get(USER_INFO)?.id;

    if (!userId) return;

    this.notificationData.doneStatus = target.checked;
    this.notificationData.loadNotifications({
      page: REQUEST_DEFAULT.PAGE,
      limit: REQUEST_DEFAULT.LARGE_LIMIT,
      userId,
      ...(target.checked ? { doneStatus: false } : {}),
    });
  }
}
