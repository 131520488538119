import { AsyncPipe } from "@angular/common";
import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { RouterLink } from "@angular/router";
import { REQUEST_DEFAULT } from "@constant/http-request.constant";
import { BrandModel } from "@model/brand.model";
import { OrderModel } from "@model/order.model";
import { NgbModal, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { ConfirmModalComponent } from "@page/real-estate-management/widgets/confirm-modal/confirm-modal.component";
import { BrandService } from "@service/brand/brand.service";
import { StateContactHistoryService } from "@service/call-history/state-contact-history.service";
import { CustomerOrderService } from "@service/order/customer-order.service";
import { BehaviorSubject, Observable, of } from "rxjs";
import {
  distinctUntilChanged,
  filter,
  mergeMap,
  pairwise,
  startWith,
  switchMap,
  take,
} from "rxjs/operators";

@Component({
  selector: "app-order-info",
  templateUrl: "./order-info.component.html",
  standalone: true,
  imports: [
    NgSelectModule,
    ReactiveFormsModule,
    AsyncPipe,
    NgbTooltipModule,
    RouterLink,
  ],
  providers: [StateContactHistoryService],
})
export class OrderInfoComponent implements OnInit, OnChanges {
  private brandService = inject(BrandService);
  private orderService = inject(CustomerOrderService);
  private modalService = inject(NgbModal);
  private stateContactHistory = inject(StateContactHistoryService);

  @Input() callHistoryForm: FormGroup;
  @Output() submitFormEvent = new EventEmitter();

  public brandList: BrandModel[] = [];
  public orders$ = new BehaviorSubject<OrderModel[]>([]);

  ngOnInit(): void {
    this.loadBrands();
    this.observeBrandChange();
    this.observeOrderChange();
  }

  ngOnChanges(): void {
    this.controls["brand"].valueChanges
      .pipe(
        startWith(this.controls["brand"].value),
        pairwise(),
        filter(([prev, curr]) => !prev && curr),
        switchMap(([, brandId]) => this.handleLoadOrders(brandId).pipe(take(1)))
      )
      .subscribe((orders) => this.orders$.next(orders));
  }

  get controls() {
    return this.callHistoryForm.controls;
  }

  // load toàn bộ list brand
  private loadBrands(page: number = REQUEST_DEFAULT.PAGE): void {
    const brandSupervisorNumber = this.controls?.["phone"]?.value;
    brandSupervisorNumber &&
      this.brandService
        .getListBrand({
          page,
          limit: REQUEST_DEFAULT.BULK_LIMIT,
          brandSupervisorNumber,
        })
        .subscribe((res: any) => {
          this.brandList = this.brandList.concat(...res.data);
          if (this.brandList.length < res.totalItems) {
            this.loadBrands(page + 1);
          }
        });
  }

  // load toàn bộ list order
  private handleLoadOrders(
    brandId: number,
    page = REQUEST_DEFAULT.PAGE,
    caculatedOrders: OrderModel[] = []
  ): Observable<OrderModel[]> {
    if (!brandId) return of([]);

    return this.orderService
      .getListCustomerOrder(
        { page, limit: REQUEST_DEFAULT.BULK_LIMIT },
        brandId
      )
      .pipe(
        mergeMap((res: any) => {
          const newOrders = res.data;
          const orders = caculatedOrders.concat(newOrders);

          if (orders.length < res.totalItems) {
            return this.handleLoadOrders(brandId, page + 1, orders);
          }

          return of(orders);
        })
      );
  }

  // lắng nghe khi control brand thay đổi
  private observeBrandChange(): void {
    const brand = this.controls["brand"].value;

    this.handleLoadOrders(brand).subscribe((orders: OrderModel[]) => {
      if (orders?.length) {
        this.orders$.next(orders);
      }
    });

    this.controls["brand"].valueChanges
      .pipe(
        startWith(brand),
        pairwise(),
        filter(([before, current]) => {
          if (before && current) return before !== current;

          return null;
        }),
        switchMap(([previous, current]) =>
          this.handleBrandChange(previous, current)
        )
      )
      .subscribe((orders: OrderModel[] | null) => {
        if (orders?.length) {
          this.orders$.next(orders);
        }
      });
  }

  // xử lý mở form dirty & mở modal khi brand thay đổi
  private handleBrandChange(previous: number, current: number) {
    const isFormDirty = this.checkFormDirty(["brand", "order"]);
    if (!isFormDirty) {
      this.resetControl(["statusCall", "content", "order"]);
      this.orders$.next([]);
      return this.handleLoadOrders(current);
    }

    return this.openConfirmModal().closed.pipe(
      switchMap((confirmed: boolean) => {
        if (!confirmed) {
          this.resetControl(["statusCall", "content", "order"]);
          this.orders$.next([]);
          return this.handleLoadOrders(current);
        } else {
          this.setValueToControl("brand", previous);
          this.submitFormEvent.emit();
          return of(null);
        }
      })
    );
  }

  // lắng nghe khi control order thay đổi
  private observeOrderChange(): void {
    this.controls["order"].valueChanges
      .pipe(
        startWith(this.controls["order"].value),
        pairwise(),
        distinctUntilChanged(),
        filter(([previous, current]) => {
          if (previous && current) return previous !== current;
          return null;
        })
      )
      .subscribe(([previous]) => {
        const formDirty = this.checkFormDirty(["brand", "order"]);

        if (!formDirty) {
          this.resetControl(["statusCall", "content"]);
          return;
        }

        this.openConfirmModal().closed.subscribe((confirm: boolean) => {
          if (!confirm) {
            this.resetControl(["statusCall", "content"]);
          } else {
            this.setValueToControl("order", previous);
            this.stateContactHistory.reloadHistoryCallOrder(previous);
            this.submitFormEvent.emit();
          }
        });
      });
  }

  private resetControl(fields: string[]) {
    fields.forEach((f) => {
      this.controls[f] ? this.controls[f].reset() : null;
    });
  }

  private setValueToControl(control: string, value: number) {
    this.controls[control].setValue(null, { onlySelf: true });
    this.controls[control].setValue(value, { onlySelf: true });
  }

  private checkFormDirty(avoidKeys: string[]) {
    const listDirty = Object.keys(this.controls)
      .filter((k) => !avoidKeys.includes(k))
      .filter((k) => this.callHistoryForm.get(k).dirty);
    return listDirty.length > 0;
  }

  private openConfirmModal() {
    const modal = this.modalService.open(ConfirmModalComponent, {
      size: "md",
      centered: true,
      backdrop: "static",
    });
    modal.componentInstance.title = "Lưu thông tin đã nhập?";
    modal.componentInstance.content =
      "Bạn có muốn lưu lại trước khi đổi? Các dữ liệu đã điền sẽ bị loại bỏ.";
    modal.componentInstance.btnConfirmTitle = "Lưu và tiếp tục";
    modal.componentInstance.btnRejectTitle = "Bỏ qua lưu và tiếp tục";
    modal.componentInstance.hasClose = true;

    return modal;
  }

  getValueControl(control: string, group?: string): any {
    // get value of form control from a form group
    if (!group) {
      return this.callHistoryForm.controls[control].value;
    }
    return this.callHistoryForm.controls[control].value[group];
  }
}
