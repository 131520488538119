<div #mapContainer class="map-container">
  <agm-map
    [latitude]="land?.address?.latitude | convertToNumber"
    [longitude]="land?.address?.longitude | convertToNumber"
    [(zoom)]="zoom"
    [disableDefaultUI]="true"
    [scrollwheel]="false"
    [gestureHandling]="'cooperative'"
    draggable="false"
    [mapTypeControl]="true"
    [styles]="mapStyles"
  >
    <agm-marker
      [latitude]="land?.address?.latitude | convertToNumber"
      [longitude]="land?.address?.longitude | convertToNumber"
      [markerDraggable]="false"
      (focus)="onMouseOver(infoWindow)"
      (markerClick)="landSelected.emit(land.id)"
      [iconUrl]="
        land?.purpose === LAND_PURPOSE.FOR_SELL
          ? './assets/images/icons/circle-rent.svg'
          : land?.purpose === LAND_PURPOSE.FOR_RENT
          ? './assets/images/icons/circle.svg'
          : './assets/images/icons/for-quick-upload.svg'
      "
    >
      <agm-info-window #infoWindow class="info-window">
        <div>
          <img [src]="land?.photos[0]?.url" alt="" />
        </div>
        <div class="font-bold-900">
          {{ land?.price | transformPriceToString }}
        </div>
        <div>{{ land?.acreage }} ㎡ - mt {{ land?.frontWidth }}</div>
        <div>{{ land?.bedrooms }} pn - {{ land?.toilets }} wc</div>
      </agm-info-window>
    </agm-marker>
  </agm-map>
  <div class="customize-street-view-tab-one">
    <i class="ri-aspect-ratio-line"></i>
  </div>
</div>
