<div>
  <app-search-filter-real-estate-audit
    [formSearchAuditParams]="searchForm"
    (submitEvent)="onSubmitSearch()"
    (resetSearchEvent)="onResetSearch()"
  ></app-search-filter-real-estate-audit>
  <div class="timeline">
    <ng-container *ngIf="realEstateAudits$ | async as realEstateAudits">
      <ng-container *ngFor="let item of realEstateAudits; let index = index">
        <div
          *ngIf="item.previous !== null"
          [ngClass]="{
            'timeline-item': true,
            left: index % 2 === 0,
            right: index % 2 !== 0
          }"
        >
          <i class="icon ri-time-line"></i>
          <div class="date fs-13">{{ item.createdAt | timeAgo }}</div>
          <div
            class="content cursor-pointer"
            (click)="onClickDetailHistory(index, item.previous)"
          >
            <h5 class="fs-14 m-0">Người chỉnh sửa: {{ item.createdBy }}</h5>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div class="float-sm-end mt-3">
    <ngb-pagination
      [pageSize]="limit$ | async"
      [page]="page$ | async"
      (pageChange)="updatePage($event)"
      [collectionSize]="total$ | async"
      [maxSize]="10"
    ></ngb-pagination>
  </div>
</div>
