import { NgForOf } from "@angular/common";
import { Component, inject, Input } from "@angular/core";
import {
  FormArray,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import { RouterLink } from "@angular/router";
import { CallRealEstateModel } from "@component/call-history-form-to-owner/call-history-container.component";
import { InvisiblePhoneNumberDirective } from "@directive/invisible-phone-number.directive";
import { CustomerModel } from "@model/customer.model";
import { NgbActiveModal, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { UiSwitchModule } from "ngx-ui-switch";
import {
  ALIAS_PREFIX,
  ORIGIN_PROVIDE,
} from "src/app/public/constants/common.constant";

@Component({
  selector: "owner-info-component",
  templateUrl: "./owner-info.component.html",
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    UiSwitchModule,
    NgbTooltipModule,
    InvisiblePhoneNumberDirective,
    RouterLink,
    NgForOf,
  ],
})
export class OwnerInfoComponent {
  // Inject Services
  private modalService = inject(NgbActiveModal);

  // Inject variable
  @Input() formData: FormGroup;
  public customer: CustomerModel;

  @Input() addressIndex: number;
  @Input() set realEstate(data: CallRealEstateModel) {
    this.customer = data?.customers?.find((c) => {
      return c.address.find((add) => add?.phone === data?.numberToCall);
    });
  }

  submitForm() {
    this.modalService.close();
    console.log("Submit form");
  }

  onClickCancel($event: MouseEvent): void {
    this.modalService.close();
    console.log("Cancel form");
  }

  getFormArray(control: string) {
    return (this.formData.controls[control] as FormArray)?.controls;
  }

  getValueControl(control: string, group?: string): any {
    // get value of form control from a form group
    if (!group) {
      return this.formData.controls[control].value;
    }
    return this.formData.controls[control].value[group];
  }

  protected readonly ORIGIN_PROVIDE = ORIGIN_PROVIDE;
  protected readonly ALIAS_PREFIX = ALIAS_PREFIX;
}
