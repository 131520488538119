<div class="modal-content">
  <button
    matTooltip="Đóng modal"
    (click)="onClickCloseModal()"
    class="btn btn-danger btn-close-customize"
  >
    <i class="mdi mdi-close-circle-outline fs-16"></i>
  </button>

  <div class="modal-header justify-content-center">
    <h3 class="text-center">
      Khách hàng :
      {{ ownerCall?.fullName }}
      <span class="text-success" *ngIf="ownerCall?.fullName">
        (Đã lưu trên hệ thống)</span
      >
    </h3>
  </div>
  <hr class="m-0" />

  <div class="modal-body customize-modal-content">
    <ul
      ngbNav
      #nav="ngbNav"
      [(activeId)]="activeTab"
      class="nav nav-tabs nav-border-top nav-border-top-success mb-3"
      (navChange)="onNavChange($event)"
    >
      <li [ngbNavItem]="'sell-rent'" class="nav-item">
        <a ngbNavLink
          ><i class="ri-home-5-line align-middle me-1"></i>
          Bán/Cho thuê
        </a>
        <ng-template ngbNavContent>
          <app-call-history-sell-rent
            [data]="historyCallRealEstate"
            [addressIndex]="addressIndex"
          ></app-call-history-sell-rent>
        </ng-template>
      </li>
      <li [ngbNavItem]="'buy-rent'" class="nav-item">
        <a ngbNavLink
          ><i class="ri-home-5-line align-middle me-1"></i>
          Mua/Thuê
        </a>
        <ng-template ngbNavContent>
          <app-call-history-buy-rent
            [data]="historyCallOrder"
          ></app-call-history-buy-rent>
        </ng-template>
      </li>
    </ul>
    <!-- Tab panes -->
    <div class="tab-content">
      <div [ngbNavOutlet]="nav"></div>
    </div>
  </div>
</div>
