import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import Vietnam from "flatpickr/dist/l10n/vn";
import {
  EVENT_BUS_EVENTS,
  EventBusService,
} from "src/app/public/services/common/event-bus.service";

@Component({
  selector: "app-filter-date-range",
  templateUrl: "./filter-date-range.component.html",
})
export class FilterDateRangeComponent implements OnInit, OnChanges {
  private fb = inject(FormBuilder);
  private eventBus = inject(EventBusService);

  @Input() fromControl: UntypedFormControl;
  @Input() toControl: UntypedFormControl;
  @Input() defaultValue: { from: string; to: string };
  @Input() placeholder: string;
  @Input() hasMaxDate: boolean = true;
  @Output() formRangeEvent = new EventEmitter<UntypedFormGroup>();
  @Input() disabled: boolean;
  public today = new Date();
  public formRange: FormGroup = this.initForm();
  public language = Vietnam.vn;

  ngOnInit() {
    this.patchDefaultValue();
    this.observeFormChange();
    this.observeResetFilter();
  }

  ngOnChanges({ disabled }: SimpleChanges): void {
    const dateRange = this.formRange.controls["dateRange"];
    disabled?.currentValue ? dateRange.disable() : dateRange.enable();
  }

  private observeResetFilter() {
    this.eventBus
      .on(EVENT_BUS_EVENTS.RESET_ADVANCED_FILTER)
      .subscribe((isReset: boolean) => {
        if (isReset) {
          this.formRange.reset();
        }
      });
  }

  private initForm() {
    return this.fb.group({
      dateRange: [""],
    });
  }

  private patchDefaultValue() {
    if (this.defaultValue) {
      const { from, to } = this.defaultValue;
      if (from && to) {
        this.formRange.patchValue({
          dateRange: this.isoToString(this.defaultValue),
        });
        this.fromControl.setValue(from);
        this.toControl.setValue(to);
      }
    }
  }

  private isoToString(range: { from: string; to: string }) {
    const startDate = new Date(range?.from).toISOString().split("T")[0];
    const endDate = new Date(range?.to).toISOString().split("T")[0];
    return `${startDate} đến ${endDate}`;
  }

  private observeFormChange() {
    this.formRange.valueChanges.subscribe(({ dateRange }) => {
      this.handleDateChange(dateRange);
    });
  }

  private handleDateChange(dateRange: string) {
    const [start, end] = dateRange?.split(" đến") ?? [];

    if (start && end) {
      const startDate = new Date(start);
      const endDate = new Date(end);

      startDate.setHours(startDate.getHours() + 7);
      endDate.setHours(endDate.getHours() + 30, 59, 59, 59);

      this.fromControl?.setValue(startDate.toISOString(), { emitEvent: false });
      this.toControl?.setValue(endDate.toISOString(), { emitEvent: false });
    } else {
      this.fromControl?.setValue(null, { emitEvent: false });
      this.toControl?.setValue(null, { emitEvent: false });
    }
  }
}
