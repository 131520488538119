<ng-container [formGroup]="formSearchAndFilter">
  <div class="row pt-3 row-cols-5">
    <div class="col mb-3">
      <label class="form-label">Tỉnh/Thành phố</label>
      <ng-select
        [items]="cities$ | async"
        formControlName="city"
        bindLabel="cValue"
        bindValue="cKey"
        placeholder="Chọn tỉnh/thành phố"
      ></ng-select>
    </div>
    <div class="col mb-3 customize-field">
      <label class="form-label">Quận/Huyện</label>
      <ng-select
        [items]="districts$ | async"
        [multiple]="true"
        bindLabel="cValue"
        bindValue="cKey"
        placeholder="Chọn quận/huyện"
        [clearable]="true"
        formControlName="district"
        [closeOnSelect]="false"
        [selectableGroup]="true"
        [selectableGroupAsModel]="false"
        groupBy="selectedAllGroup"
      >
        <ng-template
          ng-option-tmp
          let-item="item"
          let-item$="item$"
          let-index="index"
        >
          <div class="d-flex align-items-center">
            <input
              id="item-{{ index }}"
              type="checkbox"
              [ngModel]="item$.selected"
              [ngModelOptions]="{ standalone: true }"
              class="me-2"
            />
            <span> {{ item["cValue"] }}</span>
          </div>
        </ng-template>

        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
          <div class="ng-value" *ngFor="let i of items | slice: 0:1">
            <span class="ng-value-label"> {{ $any(i)?.cValue }}</span>
            <span class="ng-value-icon right" (click)="clear(i)">×</span>
          </div>
          <div class="ng-value" *ngIf="items.length > 1">
            <span class="ng-value-label"> + {{ items.length - 1 }}</span>
          </div>
        </ng-template>
      </ng-select>
    </div>
    <div class="col mb-3 customize-field">
      <label class="form-label">Xã/Phường</label>
      <ng-select
        [items]="towns$ | async"
        [multiple]="true"
        bindLabel="cValue"
        bindValue="cKey"
        placeholder="Chọn xã/phường"
        [clearable]="true"
        formControlName="town"
        [closeOnSelect]="false"
        [selectableGroup]="true"
        [selectableGroupAsModel]="false"
        groupBy="selectedAllGroup"
      >
        <ng-template
          ng-option-tmp
          let-item="item"
          let-item$="item$"
          let-index="index"
        >
          <div class="d-flex align-items-center">
            <input
              id="item-{{ index }}"
              type="checkbox"
              [ngModel]="item$.selected"
              [ngModelOptions]="{ standalone: true }"
              class="me-2"
            />
            <span> {{ item["cValue"] }}</span>
          </div>
        </ng-template>

        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
          <div class="ng-value" *ngFor="let i of items | slice: 0:1">
            <span class="ng-value-label"> {{ $any(i)?.cValue }}</span>
            <span class="ng-value-icon right" (click)="clear(i)">×</span>
          </div>
          <div class="ng-value" *ngIf="items.length > 1">
            <span class="ng-value-label"> + {{ items.length - 1 }}</span>
          </div>
        </ng-template>
      </ng-select>
    </div>
    <div class="col mb-3">
      <label class="form-label">Tên đường</label>
      <input
        type="text"
        class="form-control"
        placeholder="Nhập tên đường"
        formControlName="street"
      />
    </div>

    <div class="col mb-3">
      <label class="form-label">Số nhà</label>
      <input
        type="text"
        class="form-control"
        placeholder="Nhập số nhà"
        formControlName="houseNbr"
      />
    </div>
  </div>
</ng-container>
