import { Directive, ElementRef, Input, OnChanges, OnInit } from "@angular/core";
import { SERVICE_URL } from "../constants/permission.contants";

@Directive({
  selector: "[appGetNameById]",
})
export class GetNameByIdDirective implements OnInit, OnChanges {
  @Input() id: string | number;
  @Input() list: any[];

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    this.execute();
  }

  ngOnChanges(): void {
    this.execute();
  }

  execute() {
    const element = this.el.nativeElement as HTMLElement;

    this.getColor(element);
    element.innerHTML = this.getName();
  }

  getName(): string {
    if (this.list && this.list.length)
      return this.list?.find((u) => u.id === this.id)?.name || "";
    return "";
  }

  getPathService(): string {
    if (this.list && this.list.length)
      return this.list?.find((u) => u.id === this.id)?.pathService || "";

    return "";
  }

  getColor(element: HTMLElement) {
    const pathService = this.getPathService();

    switch (pathService) {
      case SERVICE_URL.API_RESOURCE:
        element.classList.add("badge-soft-primary");
        break;
      case SERVICE_URL.OTHER:
        element.classList.add("badge-soft-secondary");
        break;
      case SERVICE_URL.API_AUTH:
        element.classList.add("badge-soft-success");
        break;
      case SERVICE_URL.API_MESSAGE:
        element.classList.add("badge-soft-danger");
        break;
      case SERVICE_URL.API_USER:
        element.classList.add("badge-soft-warning");
        break;
      case SERVICE_URL.API_CRM:
        element.classList.add("badge-soft-info");
        break;
      case SERVICE_URL.API_LAND:
        element.classList.add("badge-soft-dark");
        break;
    }
  }
}
