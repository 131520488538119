<ng-container [formGroup]="formSearchAndFilter">
  <div class="row row-cols-5 pt-3">
    <div class="col mb-3">
      <label class="form-label">Nhân viên quản lý</label>
      <ng-select
        placeholder="Chọn nhân viên quản lý"
        [items]="employeeList"
        bindLabel="fullName"
        bindValue="username"
        formControlName="manager"
        dropdownPosition="top"
      ></ng-select>
    </div>
    <div class="col mb-3">
      <label class="form-label">Nhân viên nhập</label>
      <ng-select
        placeholder="Chọn nhân nhập"
        [items]="employeeList"
        bindLabel="fullName"
        bindValue="username"
        formControlName="createdBy"
        dropdownPosition="top"
      ></ng-select>
    </div>
    <div class="col mb-3">
      <label class="form-label">Nhân viên môi giới</label>
      <ng-select
        placeholder="Chọn nhân viên môi giới"
        [items]="employeeList"
        bindLabel="fullName"
        bindValue="username"
        formControlName="brokerage"
        dropdownPosition="top"
      ></ng-select>
    </div>

    <div class="col mb-3">
      <label class="form-label">Ngày nhập BĐS</label>
      <app-filter-date-range
        [fromControl]="getControl('createdAtFrom')"
        [toControl]="getControl('createdAtTo')"
        [defaultValue]="createdDefault"
        placeholder="Chọn ngày tạo BĐS"
      ></app-filter-date-range>
    </div>

    <div class="col mb-3">
      <label class="form-label">Phí môi giới</label>
      <input
        type="number"
        placeholder="Nhập % phí môi giới"
        class="form-control"
        formControlName="brokerageFee"
      />
    </div>
  </div>

  <div class="row row-cols-5">
    <div class="col mb-3">
      <label class="form-label">Ngày mở bán</label>
      <app-filter-date-range
        [fromControl]="getControl('openingTimeFrom')"
        [toControl]="getControl('openingTimeTo')"
        [defaultValue]="openTimeDefault"
        [hasMaxDate]="false"
        placeholder="Chọn ngày mở bán"
      ></app-filter-date-range>
    </div>

    <div class="col mb-3">
      <label class="form-label">Ngày bàn giao mặt bằng</label>
      <app-filter-date-range
        [fromControl]="getControl('siteHandoverTimeFrom')"
        [toControl]="getControl('siteHandoverTimeTo')"
        [defaultValue]="siteHandoverTimeDefault"
        [hasMaxDate]="false"
        placeholder="Chọn ngày bàn giao mặt bằng"
      ></app-filter-date-range>
    </div>
    <div class="col mb-3">
      <label class="form-label">Đã ký hợp đồng môi giới</label>
      <ng-select
        [items]="ACTIVE_STATUS_FILTER"
        bindLabel="label"
        bindValue="key"
        placeholder="Đã ký hợp đồng môi giới"
        dropdownPosition="top"
        formControlName="signedBrokerageContract"
      ></ng-select>
    </div>
  </div>
</ng-container>
