import { Pipe, PipeTransform } from "@angular/core";
import { LAND_STATUS } from "../constants/real-estate.constant";

@Pipe({
  name: "getStatusColor",
})
export class GetStatusColorPipe implements PipeTransform {
  transform(value: string | number): string {
    switch (value) {
      case LAND_STATUS.APPROVED_SELL:
        return "info";
      case LAND_STATUS.PRICE_DEAL:
        return "secondary";
      case LAND_STATUS.EVALUATING:
        return "primary";
      case LAND_STATUS.DEPOSITED:
        return "success";
      case LAND_STATUS.NEGOTIATE:
        return "warning";
      case LAND_STATUS.STOP_SELL:
        return "dark";
      case LAND_STATUS.SOLD:
        return "danger";
      default:
        return "success";
    }
  }
}
