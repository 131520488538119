<div class="full-screen">
  <div
    class="block-image-detail"
    (mouseover)="mouseover()"
    (mouseleave)="mouseleave()"
  >
    <swiper
      #mainSwiper
      [loop]="true"
      [spaceBetween]="10"
      (realIndexChange)="realIndexChange($event)"
      [navigation]="true"
      (click)="onSelectedImage.emit($event)"
      [thumbs]="{ swiper: thumbsSwiper }"
      class="mySwiper2"
    >
      <ng-container *ngIf="images?.length">
        <ng-container *ngFor="let media of images">
          <ng-template swiperSlide>
            <img
              *ngIf="CheckStyleUrl(media.url) === 'image'"
              [src]="media.url | getThumbnail"
              onerror="this.onerror=null; this.src='./assets/images/image-not-available.png'"
              alt="image"
            />
            <video *ngIf="CheckStyleUrl(media.url) === 'video'" controls>
              <source [src]="media.url" type="video/mp4" />
              <source [src]="media.url" type="video/ogg" />
            </video>
          </ng-template>
        </ng-container>
      </ng-container>

      <ng-template *ngIf="!images?.length" swiperSlide
        ><img src="./assets/images/products/coming-soon.png"
      /></ng-template>
    </swiper>
    <div
      *ngIf="isHoverEdit || (!productId && isHoverEdit)"
      class="edit-image border-radius"
    >
      <div class="btn-group">
        <button
          ngbTooltip="Chọn ảnh làm ảnh mặt tiền của BĐS"
          class="btn btn-info mx-1"
          (click)="onSelectImgFront.emit(currentSlide)"
        >
          <i class="bx bx bx-like align-middle me-1 fs-16"></i> Chọn MT
        </button>
        <button class="btn btn-success mx-1" (click)="onAddImage.emit()">
          <i class="ri-image-add-fill align-middle me-1 fs-16"></i> Thêm media
        </button>

        <button
          class="btn btn-warning mx-1"
          (click)="onUpdateImage.emit(currentSlide)"
        >
          <i class="bx bxs-edit align-middle me-1 fs-16"></i> Cập nhật
        </button>
      </div>
    </div>
  </div>
  <swiper
    (swiper)="thumbsSwiper = $event"
    [loop]="false"
    [spaceBetween]="10"
    [slidesPerView]="4"
    [freeMode]="true"
    [watchSlidesProgress]="true"
    class="mySwiper"
  >
    <ng-container *ngIf="images?.length">
      <ng-container *ngFor="let media of images; let i = index">
        <ng-template swiperSlide>
          <div>
            <!-- is Image? -->
            <img
              *ngIf="CheckStyleUrl(media.url) === 'image'"
              [src]="media.url | getSmallImage"
              onerror="this.onerror=null; this.src='./assets/images/image-not-available.png'"
              alt="image"
            />
            <!-- is Video? -->
            <video *ngIf="CheckStyleUrl(media.url) === 'video'" controls>
              <source [src]="media.url" type="video/mp4" />
              <source [src]="media.url" type="video/ogg" />
            </video>

            <button
              *ngIf="isEdit || (!productId && images?.length)"
              (click)="onDeleteImage.emit(i)"
              ngbTooltip="Xoá ảnh"
              class="btn btn-icon text-danger ag-row-position-absolute btn-delete-custom"
            >
              <i class="bx bxs-trash-alt fs-14"></i>
            </button>
            <span *ngIf="i === 0" class="badge text-bg-info fs-10 custom-badge"
              >Mặt tiền</span
            >
          </div>
        </ng-template>
      </ng-container>
    </ng-container>

    <ng-template *ngIf="!images?.length" swiperSlide>
      <img src="./assets/images/products/coming-soon.png"
    /></ng-template>
  </swiper>

  <!-- <small *ngIf="!images?.length" class="d-block text-center text-danger mb-2">
    Vui lòng chọn ảnh BĐS
  </small> -->
</div>
