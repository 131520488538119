export const COLUMNS_OF_LANDS = [
  {
    field: "up",
    headerName: "",
    sortable: false,
  },
  {
    field: "avatar",
    headerName: "Hình ảnh",
    sortable: false,
  },
  {
    field: "status",
    headerName: "Trạng Thái",
    sortable: false,
  },
  {
    field: "houseNbr",
    headerName: "Số nhà",
    sortable: true,
    key: "houseNbr",
  },
  {
    field: "street",
    headerName: "Đường",
    sortable: true,
    key: "street",
  },
  {
    field: "town",
    headerName: "Xã/Phường",
    sortable: true,
    key: "town",
  },
  {
    field: "district",
    headerName: "Quận/Huyện",
    sortable: true,
    key: "district",
  },
  {
    field: "city",
    headerName: "Tỉnh/Thành phố",
    sortable: true,
    key: "city",
  },
  {
    field: "acreage",
    headerName: "Diện Tích",
    sortable: true,
    key: "acreage",
  },
  {
    field: "frontWidth",
    headerName: "Mặt tiền",
    sortable: true,
    key: "frontWidth",
  },
  {
    field: "floors",
    headerName: "Số tầng",
    sortable: true,
    key: "floors",
  },
  {
    field: "price",
    headerName: "Giá",
    sortable: true,
    key: "price",
  },
  {
    field: "siteHandoverTime",
    headerName: "Bàn giao",
    sortable: true,
    key: "siteHandoverTime",
  },
  {
    field: "customerName",
    headerName: "Khách hàng",
    sortable: true,
    key: "ownerName",
  },
  {
    field: "customerPhone",
    headerName: "Số điện thoại",
    sortable: false,
  },
  {
    field: "other",
    headerName: "Khác",
    sortable: false,
  },
];

export const COLUMN_USER_REAL_ESTATE_NEGOTIATE = [
  "",
  "Mã BĐS",
  "Địa chỉ",
  "Loại BĐS",
  "Diện tích",
  "Mặt tiền",
  "Người phụ trách",
];

export const COLUMN_CUSTOMER_ORDERS = [
  "",
  "Tên",
  "Mục đích",
  "Mặt tiền(m)",
  "Diện tích(m2)",
  "Khoảng giá",
  // "Loại tiền",
  "Liên hệ",
  "Ghi chú",
  // "Tiện ích",
  // "Loại BĐS",
  // "Số tầng",
  // "Nhu cầu",
  // "Tầng hầm",
  // "Căn góc",
  // "Thang máy",
  // "Pháp lý",
  "Khác",
];

export const COLUMN_BRAND_ORDERS = [
  "Tên đơn hàng",
  "Khách hàng",
  "Kiểu BĐS",
  "Diện tích(m2)",
  "Số tầng",
  "Khoảng giá",
  "Loại tiền",
  "Loại BĐS",
  "Ghi chú",
  "Hoạt động",
  "Khác",
];

export const COLUMN_ADDRESS_ORDERS = [
  "Tên",
  "Tỉnh/Thành phố",
  "Quận/Huyện",
  "Xã/Phường",
  "Tên đường",
  "Dạng",
  "Ghi chú",
  "Hành động",
  "Khác",
];

export const COLUMNS_USER_HISTORY_INFO: any[] = [
  {
    field: "Tiêu đề",
  },
  {
    field: "Nội dung",
  },
  {
    field: "Người gọi",
  },
  {
    field: "Số điện thoại",
  },
  {
    field: "Ghi Âm",
  },
  {
    field: "TT trước",
  },
  {
    field: "TT sau",
  },
  {
    field: "Phương thức",
  },
  {
    field: "Kết quả cuộc gọi",
  },
  {
    field: "Thời gian",
  },
  {
    field: "Ngày",
  },
];

export const COLUMNS_ORDER_CUSTOMER = [
  {
    label: "ID",
  },
  {
    label: "Tên",
  },
  {
    label: "Hành động",
  },
];

export const COLUMNS_OF_USER = [
  {
    value: "",
    key: "tick",
  },
  {
    value: "Mã KH",
    key: "total",
  },
  {
    value: "Tổ chức",
    key: "total",
  },
  {
    value: "Họ tên KH",
    key: "total",
  },
  {
    value: "Số điện thoại",
    key: "total",
  },
  {
    value: "Email",
    key: "total",
  },
  {
    value: "Đã truy cập",
    key: "active",
  },
  {
    value: "Đã yêu thích",
    key: "active",
  },
  {
    value: "Liên hệ",
    key: "active",
  },
  {
    value: "Đặt lịch",
    key: "active",
  },
  {
    value: "Truy cập lần cuối",
    key: "active",
  },
  {
    value: "Hành động",
    key: "active",
  },
  {
    value: "Ngày đăng ký",
    key: "waiting-approved-rejected",
  },
  {
    value: "Địa chỉ",
    key: "waiting-approved-rejected",
  },
  {
    value: "Duyệt tài khoản",
    key: "waiting-approved",
  },
  {
    value: "Lý do",
    key: "rejected",
  },
  {
    value: "Duyệt lại",
    key: "rejected",
  },
  {
    value: "Ngày truy cập",
    key: "detail",
  },
  {
    value: "BĐS đã xem",
    key: "detail",
  },
  {
    value: "Loại BĐS",
    key: "detail",
  },
  {
    value: "BĐS quan tâm",
    key: "detail",
  },
  {
    value: "Số lần truy cập",
    key: "detail",
  },
  {
    value: "KH tự liên hệ",
    key: "detail",
  },
  {
    value: "Đặt lịch",
    key: "detail",
  },
];

export const COLUMNS_USER = [
  {
    value: "",
    key: "tick",
  },
  {
    value: "Mã KH",
    key: "total",
  },
  {
    value: "Cá nhân/Tổ chức",
    key: "total",
  },
  {
    value: "Họ tên KH",
    key: "total",
  },
  {
    value: "Số điện thoại",
    key: "total",
  },
];
export const COLUMNS_OF_USER_ACTIVE = [
  ...COLUMNS_USER,
  {
    value: "Đã truy cập",
    key: "active",
  },
  {
    value: "Đã yêu thích",
    key: "active",
  },
  {
    value: "Liên hệ",
    key: "active",
  },
  {
    value: "Đặt lịch",
    key: "active",
  },
  {
    value: "Truy cập lần cuối",
    key: "active",
  },
  {
    value: "Hành động",
    key: "active",
  },
];
export const COLUMNS_OF_USER_REJECT = [
  ...COLUMNS_USER,
  {
    value: "Ngày đăng ký",
    key: "waiting-approved-rejected",
  },
  {
    value: "Địa chỉ",
    key: "waiting-approved-rejected",
  },
  {
    value: "Lý do",
    key: "rejected",
  },
  {
    value: "Duyệt lại",
    key: "rejected",
  },
];
export const COLUMNS_OF_USER_WAITING_APPROVED = [
  ...COLUMNS_USER,
  {
    value: "Ngày đăng ký",
    key: "waiting-approved-rejected",
  },
  {
    value: "Địa chỉ",
    key: "waiting-approved-rejected",
  },
  {
    value: "Duyệt tài khoản",
    key: "waiting-approved",
  },
];

export const COLUMN_OF_AGENT = [
  "Mã CTV",
  "",
  "Họ tên",
  "Số điện thoại/Mã giới thiệu",
  "Ngày sinh",
  "Email",
  "Đã giới thiệu",
  "BĐS đã duyệt",
  "BĐS đã giao dịch",
  "Thường trú",
  "Hoạt động",
];

export const COLUMN_OF_AGENT_DETAIL = [
  "",
  "",
  "Mã BĐS",
  "Trạng thái",
  "Địa chỉ",
  "Tên khách hàng",
  "Loại",
  "Giá thành",
  "Diện tích",
  "Người phụ trách",
  "Hành động",
];

export const SUB_COLUMN_OF_AGENT_DETAIL = [
  "Ngày xử lý",
  "Nội dung",
  "Số điện thoại",
  "Người gửi",
  "Ghi âm",
  "Trạng thái trước",
  "Trạng thái sau",
  "Phương thức",
  "Kết quả cuộc gọi",
  "Thời gian",
];

export const COLUMN_OF_EMPLOYEE: {
  label: string;
  sortable: boolean;
  key?: string;
}[] = [
  {
    label: "",
    sortable: false,
  },
  {
    label: "ID",
    sortable: true,
    key: "id",
  },
  {
    label: "Họ và tên",
    sortable: false,
  },
  {
    label: "Số điện thoại",
    sortable: false,
  },
  {
    label: "Email",
    sortable: false,
  },
  {
    label: "Hệ thống truy cập",
    sortable: false,
  },
  {
    label: "3CX Line",
    sortable: false,
  },
  {
    label: "Truy cập lần cuối",
    sortable: true,
    key: "activated",
  },
  {
    label: "Ngày tạo",
    sortable: true,
    key: "created",
  },
  {
    label: "Cập nhật",
    sortable: true,
    key: "updated",
  },
  {
    label: "Khác",
    sortable: false,
  },
];

export const COLUMN_OF_BRAND = [
  "",
  "Logo",
  "Mô hình",
  "Thương hiệu",
  "Ghi chú",
  "Số lượng đơn hàng",
  "Số khu vực quan tâm",
  "Ngày nhập",
  "Ngày đăng ký",
  "Ngày cập nhật",
  "Hoạt động",
];

export const COLUMN_OF_LIST_NOTIFICATION = [
  "Ngày gửi",
  "Tiêu đề",
  "Nội dung",
  "Người gửi",
  "Loại gủi",
  "Người nhận",
];

export const COLUMN_OF_LIST_ACTION = [
  "ID",
  "Tên action",
  "Phương thức",
  "Mô tả",
  "Cập nhật",
  "Khác",
];

export const COLUMN_OF_LIST_SERVICE = [
  "ID",
  "Tên service",
  "URL",
  "Mô tả",
  "Cập nhật",
  "Khác",
];

export const COLUMN_OF_LIST_RESOURCE = [
  "ID",
  "Tên resource",
  "URL",
  "Action",
  "Service",
  "Mô tả",
  "Khác",
];

export const COLUMN_OF_LIST_POLICY = [
  "ID",
  "Nhóm chức năng",
  "Scope",
  "Conditions",
  "Mô tả",
  "Cập nhật",
  "Khác",
];

export const COLUMN_OF_LIST_ROLE = [
  "ID",
  "Nhóm quyền",
  "Policies",
  "Mô tả",
  "Cập nhật",
  "Khác",
];

export const COLUMN_OF_DIVISION = [
  "ID",
  "Tên phòng ban",
  "Mô tả",
  "Cập nhật",
  "Khác",
];

export const COLUMN_OF_GROUP = [
  "ID",
  "Tên nhóm",
  "Phòng ban",
  "Mô tả",
  "Cập nhật",
  "Khác",
];

export const COLUMN_OF_GROUP_USER = [
  "ID",
  "Tên user",
  "Nhóm",
  "Truy cập lần cuối",
  "Khác",
];

export const COLUMN_OF_GROUP_PERMISSION = [
  "ID",
  "Tên nhóm quyền",
  "Mô tả",
  "Cập nhật",
  "Khác",
];

export const COLUMN_OF_EMAIL_VERIFICATION = [
  "",
  "Mã",
  "Tên",
  "Địa chỉ",
  "Người tạo",
  "Ngày tạo",
  "Trạng thái",
  "Email khách hàng",
  "Hành động",
];

export const COLUMN_OF_TASK_FILES = [
  "Tên tệp",
  "Loại",
  "Kích thước",
  "Ngày tải lên",
  "Hành động",
];

export const COLUMN_OF_CALL_HISTORY_ORDER = [
  "Tiêu đề",
  "Nội dung",
  "Người gọi",
  "Số điện thoại",
  "Ghi âm",
  "Phương thức",
  "Kết quả cuộc gọi",
  "Thời gian",
  "Ngày",
];

export const COLUMN_OF_MATCH_ORDERS = [
  "Logo",
  "Mô hình",
  "Thương hiệu",
  "Tên đơn hàng",
  "Khách hàng",
  "Nhu cầu",
  "Mặt tiền",
  "Diện tích",
  "Số tầng",
  "Khoảng giá",
  "Loại tiền",
  "Ghi chú",
];

export const COLUMN_OF_ORDER_REAL_ESTATE_COMPARISION = [
  "Thông tin cơ bản",
  "Bất động sản",
  "Đơn hàng",
  "Khớp",
];

export const COLUMN_OF_DUPLICATE_REAL_ESTATE = ["ID", "Ảnh", "Ngày tạo"];
