import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CheckPermissionServiceDirective } from "src/app/public/directives/check-permission-service.directive";
import { ImagePreviewDirective } from "src/app/public/directives/image-preview.directive";
import { RealEstateAllowToSellDirective } from "src/app/public/directives/real-estate-allow-to-sell.directive";
import { BtnCheckRealEstateMarkDirective } from "src/app/public/directives/real-estate-mark.directive";
import { CheckPermissionActionDirective } from "./check-permission-action.directive";
import { CheckPermissionResourceDirective } from "./check-permission-resource.directive";
import { CompareEditFieldDirective } from "./compare-edit-field.directive";
import { GetNameByIdDirective } from "./get-name-by-id.directive";
import { GetPermissionNamesDirective } from "./get-permission-names.directive";
import { HighlightSystemDirective } from "./highlight-system.directive";
import { InvisiblePhoneNumberDirective } from "./invisible-phone-number.directive";
import { IsNewRealEstateDirective } from "./is-new-real-estate.directive";
import { PercentageDirective } from "./percentage.directive";
import { VideoPreviewDirective } from "./video-preview.directive";
import { OnlyPhoneNumberDirective } from "./only-phone-number.directive";

@NgModule({
  imports: [CommonModule],
  declarations: [
    IsNewRealEstateDirective,
    ImagePreviewDirective,
    InvisiblePhoneNumberDirective,
    VideoPreviewDirective,
    GetNameByIdDirective,
    GetPermissionNamesDirective,
    CheckPermissionServiceDirective,
    CheckPermissionResourceDirective,
    CheckPermissionActionDirective,
    RealEstateAllowToSellDirective,
    BtnCheckRealEstateMarkDirective,
    PercentageDirective,
    CompareEditFieldDirective,
    HighlightSystemDirective,
    OnlyPhoneNumberDirective,
  ],
  exports: [
    IsNewRealEstateDirective,
    ImagePreviewDirective,
    InvisiblePhoneNumberDirective,
    VideoPreviewDirective,
    GetNameByIdDirective,
    GetPermissionNamesDirective,
    CheckPermissionServiceDirective,
    CheckPermissionResourceDirective,
    CheckPermissionActionDirective,
    RealEstateAllowToSellDirective,
    BtnCheckRealEstateMarkDirective,
    PercentageDirective,
    CompareEditFieldDirective,
    HighlightSystemDirective,
    OnlyPhoneNumberDirective,
  ],
})
export class HLDirectivesModule {}
