<div class="row justify-content-center m-0">
  <div class="col-12 p-0">
    <div class="card m-0">
      <div class="card-body p-4 text-center">
        <ng-container
          *ngIf="!(jsonDataErrors$ | async)?.length"
          [ngTemplateOutlet]="beforeUpload"
        >
        </ng-container>

        <ng-container *ngIf="(jsonDataErrors$ | async)?.length">
          <ng-container
            *ngIf="jsonDataErrors$ | async as data"
            [ngTemplateOutlet]="hasDataError"
            [ngTemplateOutletContext]="{ $implicit: data }"
          >
          </ng-container>
        </ng-container>
      </div>
      <!-- end card body -->
    </div>
    <!-- end card -->
  </div>
</div>

<ng-template #hasDataError let-data>
  <div class="avatar-lg mx-auto mt-2">
    <div class="avatar-title bg-light text-warning display-3 rounded-circle">
      <i class="ri-error-warning-fill"></i>
    </div>
  </div>
  <div class="mt-4 pt-2">
    <h4>Upload Hoàn Tất !</h4>
    <p class="mx-4 text-muted">
      Phát hiện <span class="text-danger">{{ data.length }}</span> bản dữ liệu
      lỗi hoặc trùng lặp trong quá quá quá trình tạo mới BĐS.
    </p>

    <div class="mt-4">
      <button
        (click)="downloadFileError()"
        type="button"
        class="btn btn-primary waves-effect waves-light"
        data-bs-toggle="modal"
      >
        <i class="bx bx-save fs-14"></i> Tải về danh sách lỗi
      </button>
    </div>
  </div>
</ng-template>

<ng-template #beforeUpload>
  <div class="avatar-lg mx-auto mt-2">
    <div class="avatar-title bg-light text-success display-3 rounded-circle">
      <i class="ri-checkbox-circle-fill"></i>
    </div>
  </div>
  <div class="mt-4 pt-2">
    <h4>Chuẩn Bị Hoàn Tất !</h4>
    <p class="text-muted mx-4">Tất cả các BĐS đã được xác thực dữ liệu.</p>

    <div *ngIf="newJsonData$ | async as jsonData">
      <p>Số lượng BĐS thêm mới : {{ jsonData.length }}</p>
    </div>

    <div class="mt-4">
      <button
        (click)="activeModal.close()"
        type="button"
        class="mx-2 btn btn-danger waves-effect waves-light"
        data-bs-toggle="modal"
      >
        <i class="bx bx-x-circle fs-14"></i> Huỷ bỏ
      </button>

      <button
        #btnSaveRealEstate
        type="button"
        class="btn btn-primary waves-effect waves-light"
        data-bs-toggle="modal"
      >
        <i class="bx bx-save fs-14"></i> Tiến hành upload BĐS
      </button>
    </div>
  </div>
</ng-template>

<input hidden #fileError />
