<div class="notification-skeleton p-3">
  <div class="d-flex">
    <div class="me-3 position-relative">
      <div
        class="bg-light rounded-circle"
        style="width: 40px; height: 40px"
      ></div>
    </div>
    <div class="flex-1">
      <div class="bg-light rounded w-75 mb-2" style="height: 12px"></div>
      <div class="bg-light rounded w-75 mb-2" style="height: 10px"></div>
      <div class="bg-light rounded w-75 mb-2" style="height: 10px"></div>
      <div class="d-flex my-2">
        <div
          class="bg-light rounded-circle me-3"
          style="width: 24px; height: 24px"
          *ngFor="let i of [1, 2]"
        ></div>
      </div>
      <div class="bg-light rounded w-50" style="height: 8px"></div>
    </div>
    <div class="me-4">
      <div class="bg-light rounded" style="width: 16px; height: 16px"></div>
    </div>
  </div>
</div>
