import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Optional } from "ag-grid-community";
import { RealEstatePhotoModel } from "src/app/public/models/real-estate.model";

@Component({
  selector: "app-real-estate-detail-tabs",
  templateUrl: "./real-estate-detail-tabs.component.html",
  styleUrls: ["./real-estate-detail-tabs.component.scss"],
})
export class RealEstateDetailTabsComponent {
  public tab: number = 0;
  public lat: number;
  public lng: number;
  public linkStreetView: string;
  public thumbsSwiper: any;
  public photos: RealEstatePhotoModel[];

  constructor(
    public dialogRef: MatDialogRef<RealEstateDetailTabsComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.tab = data.tab;
    this.lat = data.lat;
    this.lng = data.lng;
    this.linkStreetView = data.streetView;
    this.photos = data.photos;
  }

  public selectedTabChange(event: any) {
    this.tab = event.index;
  }
}
