import { inject, Injectable } from "@angular/core";
import { REQUEST_DEFAULT } from "@constant/http-request.constant";
import {
  CallHistoryOrderModel,
  ContactHistoryModel,
} from "@model/call-history-info.model";
import { HttpContactHistoryService } from "@service/call-history/http-contact-history.service";
import { BehaviorSubject, Observable, of } from "rxjs";
import { tap } from "rxjs/operators";
import { ContactSearchParams } from "src/app/public/services/call-history/http-history-call.service";

@Injectable()
export class StateContactHistoryService {
  private contactService = inject(HttpContactHistoryService);

  private _contactHistories$ = new BehaviorSubject<ContactHistoryModel[]>(null);
  private _isLoading$ = new BehaviorSubject<Boolean>(null);

  private _historyCallOrders$ = new BehaviorSubject<CallHistoryOrderModel[]>(
    null
  );

  private _total = new BehaviorSubject<number>(0);
  private _page = new BehaviorSubject<number>(0);
  private _pageSize = new BehaviorSubject<number>(0);
  private _limit = new BehaviorSubject<number>(0);

  get isLoading$(): Observable<Boolean> {
    return this._isLoading$.asObservable();
  }

  get contactHistories$(): Observable<ContactHistoryModel[]> {
    return this._contactHistories$.asObservable();
  }

  get historyCallOrders$() {
    return this._historyCallOrders$;
  }

  get total$() {
    return this._total.asObservable();
  }

  get page$() {
    return this._page.asObservable();
  }

  get pageSize$() {
    return this._pageSize.asObservable();
  }

  get limit$() {
    return this._limit.asObservable();
  }

  public getContactHistoriesWithPropertyOwner(params: ContactSearchParams) {
    this._isLoading$.next(true);
    this.contactService
      .search(params)
      .pipe(tap((_) => this._isLoading$.next(false)))
      .subscribe((response) => {

        const _total = response?.totalItems;
        const _page = params?.page + 1;
        const _limit = params?.limit;
        const _pageSize = response?.data?.length;

        this._total.next(_total);
        this._page.next(_page);
        this._limit.next(_limit);
        this._pageSize.next(_pageSize);
        this._contactHistories$.next(response?.data);
      });
  }

  public historyCallOrder(
    orderId: number,
    page: number = REQUEST_DEFAULT.PAGE
  ) {
    if (!orderId) {
      this._contactHistories$.next([]);

      return of([]);
    }

    const params = {
      page,
      limit: REQUEST_DEFAULT.EXTRA_SMALL_LIMIT,
    };

    this._isLoading$.next(true);
    return this.contactService
      .getHistoryCallOrder(orderId, params)
      .pipe(
        tap((data) => {
          const _total = data?.totalItems;
          const _page = params?.page;
          const _limit = params?.limit;
          const _pageSize = data?.data?.length;

          this._total.next(_total);
          this._page.next(_page);
          this._limit.next(_limit);
          this._pageSize.next(_pageSize);
          this._historyCallOrders$.next(data.data);
          this._isLoading$.next(false);
        })
      )
      .subscribe();
  }

  public reloadHistoryCallOrder(orderId: number) {
    this.historyCallOrder(orderId);
  }
}
