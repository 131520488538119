import { LAND_STATUS } from "src/app/public/constants/real-estate.constant";

export const APPROVE_TYPE = {
  APPROVED_DATA: "approved-data",
  APPROVED_SELL: "approved-sell",
  REJECT: "reject",
};

export function GetApproveType(type: string): string {
  switch (type) {
    case LAND_STATUS.REJECTED: // Reject
      return APPROVE_TYPE.REJECT;
    case LAND_STATUS.APPROVED_DATA: // Duyệt data
      return APPROVE_TYPE.APPROVED_DATA;
    case LAND_STATUS.APPROVED_SELL: // Mở bán
      return APPROVE_TYPE.APPROVED_SELL;
    default:
      return APPROVE_TYPE.APPROVED_DATA;
  }
}
