import { Component, Input, OnInit } from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { EmailModel } from "src/app/public/models/email.model";
import { EmailService } from "src/app/public/services/messages/email.service";
import { emailValidator } from "src/app/public/utils/app-validators";
import { GetParameterByName } from "src/app/public/utils/get-parameter-by-name.util";

@Component({
  selector: "app-send-email-modal",
  templateUrl: "./send-email-modal.component.html",
  styleUrls: ["./send-email-modal.component.scss"],
})
export class SendEmailModalComponent implements OnInit {
  @Input() userEmail: string[] = [];

  public isShowReplyTo: boolean = false;
  public isShowCc: boolean = false;
  public isShowSuggestion: boolean = false;
  public productIds: string[] = [];
  public itemId: string | number;

  public sendEmailForm: UntypedFormGroup;
  public recipientControl: UntypedFormControl = new UntypedFormControl("", [
    Validators.required,
    emailValidator,
  ]);

  public ccControl: UntypedFormControl = new UntypedFormControl("", [
    emailValidator,
  ]);

  constructor(
    public modalService: NgbModal,
    private fb: UntypedFormBuilder,
    private emailService: EmailService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.sendEmailForm = this.fb.group({
      type: [""],
      sender: ["cskh@hoaland.com", [Validators.required, emailValidator]],
      bcc: this.fb.array([]),
      cc: this.fb.array([]),
      recipients: this.fb.array(this.userEmail, Validators.required),
      subject: ["BĐS dành cho bạn", Validators.required],
      replyTo: ["", [emailValidator]],
      content: [""],
      productIds: [""],
    });
  }

  get recipients(): UntypedFormArray {
    return this.sendEmailForm.get("recipients") as UntypedFormArray;
  }

  get cc(): UntypedFormArray {
    return this.sendEmailForm.get("cc") as UntypedFormArray;
  }

  get emails() {
    return this.sendEmailForm.controls;
  }

  public eventAddEmail(control: UntypedFormControl, formName: string) {
    if (control.valid) {
      (this.emails[formName] as UntypedFormArray).push(
        new UntypedFormControl(control.value)
      );
      control.reset("");

      this.isShowSuggestion = false;
    }
  }

  public eventDeleteEmail(control: UntypedFormControl, formName: string) {
    if (!control.value) {
      (this.emails[formName] as UntypedFormArray).controls.pop();
    }
  }

  public handleDeleteEmail(index: number, formName: string) {
    (this.emails[formName] as UntypedFormArray).removeAt(index);
  }

  public handleCloseModal() {
    this.modalService.dismissAll();
  }

  public onSendEmailFormSubmit(sendEmail: EmailModel): void {
    if (this.sendEmailForm.valid && this.productIds.length > 0) {
      const payload = {
        ...sendEmail,
        productIds: this.productIds,
      };

      this.emailService
        .sendEmail(payload)
        .pipe(
          tap(() => {
            this.toastr.success("Gửi email thành công.");
          }),
          catchError((error) => {
            this.toastr.error("Gửi email thất bại!");
            return throwError(error);
          })
        )
        .subscribe();
      this.handleCloseModal();
    }
  }

  public handleShowSuggestion(control: UntypedFormControl) {
    control.value
      ? (this.isShowSuggestion = true)
      : (this.isShowSuggestion = false);
  }

  public onPasteEvent(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData.getData("text");
    const urlRegex =
      /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;

    if (urlRegex.test(pastedText)) {
      this.itemId =
        GetParameterByName("itemId", pastedText) ||
        pastedText.match(/\/(\d+)(\/|$)/)?.[1];

      if (
        this.itemId !== null &&
        this.itemId !== undefined &&
        !(this.productIds.length >= 6)
      ) {
        this.productIds.push(this.itemId);
      } else
        this.toastr.error(
          "BĐS không tồn tại hoặc bạn đã gửi vượt quá số lượng BĐS!"
        );
    } else this.toastr.error("Sai URL, vui lòng paste lại URL!");
  }
}
