<ng-container [formGroup]="formRange">
  <div class="search-box">
    <input
      class="flatpickr-input form-control"
      type="text"
      [placeholder]="placeholder"
      mode="range"
      mwlFlatpickr
      altFormat="d/m/Y"
      [locale]="language"
      [altInput]="true"
      [convertModelValue]="false"
      formControlName="dateRange"
      [maxDate]="!!hasMaxDate && today"
    />
    <i class="ri-calendar-2-line search-icon"></i>
  </div>
</ng-container>
