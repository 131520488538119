import { Pipe, PipeTransform } from "@angular/core";
import { CustomerModel } from "src/app/public/models/customer.model";

@Pipe({
  name: "getOwnRealEstate",
})
export class GetOwnRealEstatePipe implements PipeTransform {
  transform(data: CustomerModel[]): string {
    const customer = (data ?? [])[0];
    return customer?.fullName || "No Name";
  }
}
