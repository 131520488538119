import { Injectable } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { FormSearchRealEstate } from "../models/form-search-realestate.model";
import {
  PAGE_DEFAULT,
  SIZE_PAGE_DEFAULT,
} from "./../constants/common.constant";

@Injectable({
  providedIn: "root",
})
export class FormSearchRealEstateHelper {
  readonly #form: FormGroup;

  constructor() {
    this.#form = FormSearchRealEstateHelper.initForm();
  }

  get form() {
    return this.#form as FormGroup;
  }

  private static initForm(data?: FormSearchRealEstate): FormGroup {
    return new FormGroup({
      cenLat: new FormControl(data?.cenLat || null),
      cenLng: new FormControl(data?.cenLng || null),
      zoom: new FormControl(data?.zoom || null),
      groupStatus: new FormControl(data?.groupStatus || null),
      type: new FormControl(data?.type || "", {
        nonNullable: true,
      }),
      search: new FormControl(data?.search || "", {
        nonNullable: true,
      }),
      purpose: new FormControl(data?.purpose || ""),
      priceFrom: new FormControl(data?.priceFrom || ""),
      priceTo: new FormControl(data?.priceTo || ""),
      area: new FormControl(data?.area || ""),
      frontWidth: new FormControl(data?.frontWidth || ""),
      frontWidthFrom: new FormControl(data?.frontWidthFrom || ""),
      frontWidthTo: new FormControl(data?.frontWidthTo || ""),
      acreageFrom: new FormControl(data?.acreageFrom || ""),
      acreageTo: new FormControl(data?.frontWidthTo || ""),
      buildAreaFrom: new FormControl(data?.buildAreaFrom || ""),
      buildAreaTo: new FormControl(data?.buildAreaTo || ""),
      bedRoom: new FormControl(data?.bedRoom || null),
      bathRoom: new FormControl(data?.bathRoom || null),
      page: new FormControl(PAGE_DEFAULT, { nonNullable: true }),
      limit: new FormControl(SIZE_PAGE_DEFAULT, { nonNullable: true }),
      status: new FormControl(data?.status || [""]),
      address: new FormControl(data?.address || ""),
      city: new FormControl(data?.city || null),
      district: new FormControl(data?.district || ""),
      street: new FormControl(data?.street || ""),
      town: new FormControl(data?.town || ""),
      orderColumns: new FormControl("updated"),
      createdAtFrom: new FormControl(data?.createdAtFrom || null),
      createdAtTo: new FormControl(data?.createdAtTo || null),
      createdBy: new FormControl(data?.createdBy || null),
      basement: new FormControl(data?.basement || null),
      corner: new FormControl(data?.corner || null),
      sorts: new FormControl(data?.sorts || null),
      hasLocation: new FormControl(data?.hasLocation || null),
      hasImage: new FormControl(data?.hasImage || null),
      hasVideo: new FormControl(data?.hasVideo || null),
      floorsFrom: new FormControl(data?.floorsFrom || null),
      floorsTo: new FormControl(data?.floorsTo || null),
      houseNbr: new FormControl(data?.houseNbr || null),
      acreageForRentFrom: new FormControl(data?.acreageForRentFrom || null),
      acreageForRentTo: new FormControl(data?.acreageForRentTo || null),
      contractTerm: new FormControl(data?.contractTerm || null),
      signedBrokerageContract: new FormControl(
        data?.signedBrokerageContract || null
      ),
      originProvide: new FormControl(data?.originProvide || null),
      brokerageFee: new FormControl(data?.brokerageFee || null),
      ownerGroup: new FormControl(data?.ownerGroup || null),
      ownerName: new FormControl(data?.ownerName || null),
      brokerage: new FormControl(data?.brokerage || null),
      siteHandoverTimeFrom: new FormControl(data?.siteHandoverTimeFrom || null),
      siteHandoverTimeTo: new FormControl(data?.siteHandoverTimeTo || null),
      landAreaFrom: new FormControl(data?.landAreaFrom || null),
      landAreaTo: new FormControl(data?.landAreaTo || null),
      negotiate: new FormControl(data?.negotiate || null),
      currency: new FormControl(data?.currency || null),
      businessStatus: new FormControl(data?.businessStatus || null),
      id: new FormControl(data?.id || null),
      manager: new FormControl(data?.manager || null),
      openingTimeFrom: new FormControl(data?.openingTimeFrom || null),
      openingTimeTo: new FormControl(data?.openingTimeTo || null),
      elevators: new FormControl(data?.elevators || null),
    });
  }
}
