import { MenuItem } from "src/app/public/layouts/sidebar/menu.model";
import {
  CONFIGURATION_RESOURCES,
  MESSAGE_RESOURCES,
  ORGANIZATION_RESOURCES,
  SERVICE_URL,
} from "src/app/public/constants/permission.contants";

// @TODO:
export const MENU: MenuItem[] = [
  {
    id: 1,
    label: "Bản đồ",
    icon: "ri-map-2-line",
    service: "*",
    link: "/real-estate-management/map-management",
  },
  {
    id: 2,
    label: "",
    isTitle: true,
    service: SERVICE_URL.API_LAND,
  },
  {
    id: 3,
    label: "Quản lý BĐS",
    icon: "ri-community-line",
    service: SERVICE_URL.API_LAND,
    link: "/real-estate-management",
  },
  {
    id: 8,
    label: "Khách hàng",
    icon: "ri-group-line",
    link: "/customer-management",
    service: SERVICE_URL.API_CRM,
  },

  {
    id: 9,
    label: "Email",
    icon: "ri-mail-line",
    service: SERVICE_URL.API_MESSAGE,
    subItems: [
      {
        id: 10,
        parentId: 9,
        label: "Đã nhận",
        icon: "ri-inbox-archive-line",
        resource: MESSAGE_RESOURCES.NOTIFICATIONS,
        link: "/message-management/mailbox",
      },
      {
        id: 11,
        parentId: 9,
        icon: "ri-mail-settings-line",
        label: "DS duyệt nhận email",
        resource: MESSAGE_RESOURCES.NOTIFICATIONS,
        link: "setting-management/email-verification",
      },
      {
        id: 12,
        label: "Cài đặt",
        parentId: 9,
        icon: "ri-mail-settings-line",
        resource: MESSAGE_RESOURCES.NOTIFICATIONS,
        link: "setting-management/send-notify"
      },
    ],
  },
  {
    id: 13,
    label: "",
    isTitle: true,
    service: SERVICE_URL.API_USER,
  },

  {
    id: 17,
    label: "Quản trị",
    icon: "ri-briefcase-fill",
    service: SERVICE_URL.API_USER,
    subItems: [
      {
        id: 18,
        parentId: 17,
        label: "Nhân viên",
        icon: "ri-user-line",
        link: "user-management/organization/employee",
        resource: ORGANIZATION_RESOURCES.EMPLOYEE
      },
      {
        id: 19,
        parentId: 17,
        label: "Nhóm",
        icon: "ri-group-line",
        link: "/user-management/organization/group",
        resource: ORGANIZATION_RESOURCES.GROUP,
      },
      {
        id: 20,
        parentId: 17,
        label: "Phòng ban",
        icon: "ri-stop-circle-line",
        link: "/user-management/organization/division",
        resource: ORGANIZATION_RESOURCES.DIVISION
      },
      {
        id: 14,
        parentId: 17,
        label: "Cộng tác viên",
        icon: "ri-psychotherapy-line",
        resource: ORGANIZATION_RESOURCES.EMPLOYEE,
        link: "/user-management/agent/active"
      }
    ],
  },
  {
    id: 21,
    label: "Cài đặt",
    icon: "ri-settings-5-line",
    service: SERVICE_URL.API_USER,
    subItems: [
      {
        id: 22,
        parentId: 21,
        label: "Phân quyền",
        icon: "ri-shield-keyhole-line",
        resource: CONFIGURATION_RESOURCES.SERVICES,
        subItems: [
          {
            id: 23,
            parentId: 22,
            label: "Nhân viên",
            icon: "ri-file-warning-line",
            resource: CONFIGURATION_RESOURCES.SERVICES,
            subItems: [
              {
                id: 24,
                parentId: 23,
                label: "Actions",
                link: "configuration/actions",
                resource: CONFIGURATION_RESOURCES.ACTIONS
              },
              {
                id: 25,
                parentId: 23,
                label: "Services",
                link: "configuration/services",
                resource: CONFIGURATION_RESOURCES.SERVICES
              },
              {
                id: 26,
                parentId: 23,
                label: "Resources-Action",
                link: "configuration/resources",
                resource: CONFIGURATION_RESOURCES.RESOURCES
              },
              {
                id: 27,
                parentId: 23,
                label: "Quyền - Hành động",
                link: "configuration/policies",
                resource: CONFIGURATION_RESOURCES.POLICIES
              },
              {
                id: 28,
                parentId: 23,
                label: "Nhóm quyền",
                link: "configuration/roles",
                resource: CONFIGURATION_RESOURCES.ROLES
              }
            ]
          },
          {
            id: 29,
            label: "Cộng tác viên",
            icon: "ri-account-circle-line",
            resource: CONFIGURATION_RESOURCES.SERVICES,
            subItems: [
              {
                id: 30,
                label: "Actions",
                link: "configuration/actions",
                resource: CONFIGURATION_RESOURCES.ACTIONS
              },
              {
                id: 31,
                label: "Services",
                link: "configuration/services",
                resource: CONFIGURATION_RESOURCES.SERVICES
              },
              {
                id: 32,
                label: "Resources-Action",
                link: "configuration/resources",
                resource: CONFIGURATION_RESOURCES.RESOURCES
              },
              {
                id: 33,
                label: "Quyền - Hành động",
                link: "configuration/policies",
                resource: CONFIGURATION_RESOURCES.POLICIES
              },
              {
                id: 34,
                label: "Nhóm quyền",
                link: "configuration/roles",
                resource: CONFIGURATION_RESOURCES.ROLES
              }
            ]
          }
        ],
      },
    ],
  },
];
