import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import {
  BOOLEAN_OPTIONS,
  PAGE_DEFAULT,
  SIZE_GET_ALL_DEFAULT,
  TABS_TITLE_SEARCH_ADVANCED,
} from "src/app/public/constants/common.constant";
import { FormSearchRealEstateHelper } from "src/app/public/helpers/form-search-real-estate.helper";
import { EmployeeModel } from "src/app/public/models/employee.model";
import {
  EVENT_BUS_EVENTS,
  EventBusService,
} from "src/app/public/services/common/event-bus.service";
import { UserService } from "src/app/public/services/user/user.service";
import {
  convertPriceUnit,
  convertPriceUnitReverse,
} from "src/app/public/utils/convert-price-unit.util";

@Component({
  selector: "app-full-search",
  templateUrl: "./full-search.component.html",
  styleUrls: ["./full-search.component.scss"],
})
export class FullSearchComponent implements OnInit {
  @Output() onSearchEvent = new EventEmitter();

  public TABS_TITLE = TABS_TITLE_SEARCH_ADVANCED;
  public employeeList: EmployeeModel[] = [];

  public isCollapsed = Array(this.TABS_TITLE.length).fill(false);
  public searchLandParams: FormGroup = this.formSearchHelper.form;
  public createdDefault: { from: string; to: string };
  public siteHandoverTimeDefault: { from: string; to: string };
  public openTimeDefault: { from: string; to: string };
  public unitForm = new FormControl("Triệu");

  constructor(
    private modalService: NgbActiveModal,
    private route: ActivatedRoute,
    private userService: UserService,
    private eventBus: EventBusService,
    private formSearchHelper: FormSearchRealEstateHelper
  ) {}

  ngOnInit(): void {
    this.getAllEmployee();
    this.patchParams();
  }

  public getControl(name: string) {
    return this.searchLandParams.controls[name] as FormControl;
  }

  private getAllEmployee(page?: number) {
    const currentPage = page ? page : PAGE_DEFAULT;

    this.userService
      .getListEmployee({
        page: currentPage,
        limit: SIZE_GET_ALL_DEFAULT,
      })
      .pipe()
      .subscribe((res: any) => {
        this.employeeList = this.employeeList.concat(...res.data);
        if (this.employeeList.length < res.totalItems) {
          this.getAllEmployee(currentPage + 1);
        }
      });
  }

  private patchParams() {
    this.route.queryParams.subscribe((params: any) => {
      const {
        status,
        type,
        purpose,
        priceFrom,
        priceTo,
        ownerGroup,
        originProvide,
        district,
        town,
        basement,
        elevators,
        negotiate,
        ..._params
      } = params;

      this.searchLandParams.patchValue({
        ..._params,
        status: this.convertStringToArray(status),
        type: this.convertStringToArray(type),
        purpose: this.convertStringToArray(purpose),
        priceFrom: convertPriceUnitReverse(this.unitForm, priceFrom),
        priceTo: convertPriceUnitReverse(this.unitForm, priceTo),
        district: this.convertStringToArray(district),
        town: this.convertStringToArray(town),
        negotiate: negotiate ? JSON.parse(negotiate) : null,
        ownerGroup: Number(ownerGroup) || null,
        basement: this.convertBinary(basement),
        elevators: this.convertBinary(elevators),
        originProvide: Number(originProvide) || null,
      });

      this.getDefaultDate(params);
    });
  }

  private convertBinary(value: string) {
    switch (value) {
      case "1":
        return BOOLEAN_OPTIONS.TRUE;
      case "0":
        return BOOLEAN_OPTIONS.FALSE;
      default:
        return null;
    }
  }

  private getDefaultDate(params: any) {
    this.createdDefault = {
      from: params.createdFrom,
      to: params.createdTo,
    };

    this.siteHandoverTimeDefault = {
      from: params.siteHandoverTimeFrom,
      to: params.siteHandoverTimeTo,
    };

    this.openTimeDefault = {
      from: params.openingTimeFrom,
      to: params.openingTimeTo,
    };
  }

  private convertStringToArray(value: string) {
    return value?.split(",").filter((item: string) => item.trim() !== "");
  }

  public onCloseModal() {
    this.modalService.close();
  }

  public onSearchEventEmit(searchForm: FormGroup) {
    if (searchForm.valid) {
      const { priceFrom, priceTo, negotiate, ...paramValues } =
        searchForm.value;
      const searchLandParams = {
        ...paramValues,
        priceFrom: priceFrom
          ? convertPriceUnit(this.unitForm.value, priceFrom)
          : null,
        priceTo: priceTo
          ? convertPriceUnit(this.unitForm.value, priceTo)
          : null,
        negotiate: negotiate ? 1 : null,
      };

      this.onSearchEvent.emit(searchLandParams);

      this.onCloseModal();
    }
  }

  public onResetField() {
    this.searchLandParams.reset();
    this.eventBus.emit({
      name: EVENT_BUS_EVENTS.RESET_ADVANCED_FILTER,
      value: true,
    });
  }
}
