import { Observable } from "rxjs";
import { CategoryService } from "src/app/public/services/categories/category.service";
import { tap } from "rxjs/operators";

export function getCodeOfDistrictUtil(
  districtName: string,
  categoryService: CategoryService
) {
  return new Observable((observable) => {
    categoryService
      .getAllDistrict()
      .pipe(
        tap((districts) => {
          const district = districts.filter((district) => {
            return (
              district.cValue.toLowerCase() ===
              districtName.trim().toLowerCase()
            );
          });
          observable.next(district);
        })
      )
      .subscribe((_) => {});
  });
}
