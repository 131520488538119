import { Component } from "@angular/core";
import { TYPE_OF_NOTIFICATION } from "src/app/public/constants/real-estate.constant";
import Vietnam from "flatpickr/dist/l10n/vn.js";

@Component({
  selector: "app-search-filter-notification",
  templateUrl: "./search-filter-notification.component.html",
})
export class SearchFilterNotificationComponent {
  public items = TYPE_OF_NOTIFICATION;
  public language: any = Vietnam.vn;

  constructor() {}
}
