<div class="p-1 border border-dashed h-100">
  <form [formGroup]="formData" (ngSubmit)="submitForm()">
    <legend>
      <div class="d-flex align-items-center justify-content-between">
        <span class="badge badge-soft-primary fs-14"
          ><i class="mdi mdi-circle-medium"></i>Thông tin cuộc gọi</span
        >
      </div>
    </legend>
    <section class="row">
      <div class="col-md-6 pb-2 form-group" required>
        <label class="form-label">Trạng thái BĐS trước khi gọi</label>
        <ng-select
          [readonly]="true"
          [items]="REAL_ESTATE_STATUS_BEFORE"
          bindValue="cKey"
          [clearable]="false"
          formControlName="propertyStatusBefore"
          bindLabel="cValue"
          placeholder="Phương thức"
        >
        </ng-select>
      </div>

      <div class="col-md-6 pb-2 form-group" required>
        <label class="form-label">Tiêu đề</label>
        <input formControlName="title" class="form-control" />
        <small
          *ngIf="controls['title'].touched && controls['title']?.errors"
          class="text-danger"
          >Trường thông tin bắt buộc</small
        >
      </div>

      <div class="col-md-6 pb-2 form-group" required>
        <label class="form-label">Trạng thái BĐS sau khi gọi</label>
        <ng-select
          [items]="REAL_ESTATE_STATUS_AFTER"
          bindValue="cKey"
          [clearable]="false"
          formControlName="propertyStatusAfter"
          bindLabel="cValue"
          placeholder="Trạng thái BĐS sau khi gọi"
        >
        </ng-select>
        <small
          *ngIf="
            controls['propertyStatusAfter'].touched &&
            controls['propertyStatusAfter']?.errors
          "
          class="text-danger"
          >Trường thông tin bắt buộc</small
        >
      </div>

      <div class="col-md-6 pb-2 form-group" required>
        <label class="form-label">Phương thức</label>
        <ng-select
          [items]="CALL_METHODS"
          bindValue="cValue"
          [clearable]="false"
          formControlName="method"
          bindLabel="key"
          placeholder="Phương thức"
        >
        </ng-select>
        <small
          *ngIf="controls['method'].touched && controls['method']?.errors"
          class="text-danger"
          >Trường thông tin bắt buộc</small
        >
      </div>

      <div class="col-md-6 pb-2">
        <label class="form-label">Phí môi giới</label>
        <div class="input-group">
          <input
            type="number"
            placeholder="Nhập phí môi giới"
            class="form-control col-7"
            formControlName="brokerageFee"
          />
          <ng-select
            [items]="FEE_TYPE"
            placeholder="Loại phí"
            bindLabel="label"
            bindValue="key"
            formControlName="brokerageFeeType"
            class="col-5"
          ></ng-select>
        </div>
      </div>

      <div class="col-md-6 pb-2 form-group" required>
        <label class="form-label">Kết quả cuộc gọi</label>
        <ng-select
          [items]="STATUS_CALL"
          bindValue="value"
          [clearable]="false"
          formControlName="statusCall"
          bindLabel="key"
          placeholder="Chọn kết quả cuộc gọi"
        >
        </ng-select>
        <small
          *ngIf="
            controls['statusCall'].touched && controls['statusCall']?.errors
          "
          class="text-danger"
          >Trường thông tin bắt buộc</small
        >
      </div>

      <div class="col-md-6 pb-2 form-group">
        <label class="form-label">Thành tiền môi giới</label>
        <div
          class="search-box form-control readonly"
          [style.pointer-events]="'none'"
        >
          {{
            formData.controls["brokerageUnit"].value | transformPriceToString
          }}
        </div>
      </div>

      <div class="col mb-3 form-group">
        <label class="form-label">Thời gian bàn giao mặt bằng</label>
        <div class="search-box">
          <input
            class="flatpickr-input form-control"
            type="text"
            placeholder="Chọn thời gian bàn giao mặt bằng"
            mwlFlatpickr
            altFormat="d/m/Y"
            [locale]="language"
            [altInput]="true"
            [convertModelValue]="false"
            formControlName="siteHandoverTime"
          />
          <i class="ri-calendar-2-line search-icon"></i>
        </div>
      </div>

      <div class="col-md-12 pb-2 form-group" required>
        <label class="form-label">Nội dung</label>
        <textarea
          cols="30"
          rows="6"
          type="name"
          formControlName="content"
          class="form-control"
          [class.is-invalid]="
            controls['content'].touched && controls['content'].errors
          "
        ></textarea>
        <small
          *ngIf="controls['content'].touched && controls['content']?.errors"
          class="text-danger"
          >Trường thông tin bắt buộc</small
        >
      </div>
    </section>
  </form>
</div>
