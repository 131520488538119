<form [formGroup]="searchCustomerParams" (submit)="submitEvent.emit()">
  <div class="row g-2">
    <!--HỌ VÀ TÊN-->
    <div class="col-2">
      <div class="search-box">
        <ng-select
          bindValue="key"
          formControlName="status"
          [searchable]="false"
          placeholder="Trạng thái"
          [items]="USER_STATUS"
          bindLabel="label"
        ></ng-select>
      </div>
    </div>
    <div class="col-2">
      <div class="search-box">
        <input
          type="text"
          class="form-control"
          placeholder="Họ và tên"
          formControlName="name"
        />
        <i class="ri-user-3-line search-icon"></i>
      </div>
    </div>

    <!--SỐ ĐIỆN THOẠI-->
    <div class="col-2">
      <div class="search-box">
        <input
          type="text"
          class="form-control"
          placeholder="Số điện thoại"
          formControlName="phone"
        />
        <i class="ri-phone-line search-icon"></i>
      </div>
    </div>

    <!--EMAIL-->
    <div class="col-2">
      <div class="search-box">
        <input
          type="text"
          class="form-control"
          placeholder="Email"
          formControlName="email"
        />
        <i class="ri-mail-line search-icon"></i>
      </div>
    </div>
    <div class="col-2">
      <div class="search-box">
        <app-filter-date-range
          placeholder="Ngày tạo tài khoản"
          [fromControl]="getControl('createdFrom')"
          [toControl]="getControl('createdTo')"
        ></app-filter-date-range>
      </div>
    </div>

    <!--ACTION TÌM KIẾM-->
    <div class="col-2 d-flex">
      <button type="submit" class="btn btn-primary">
        <i class="ri-search-2-line me-1 align-bottom"></i>
        Tìm kiếm
      </button>
      <button
        type="button"
        class="btn btn-danger ms-1"
        (click)="resetEvent.emit()"
      >
        <i class="mdi mdi-restore me-1 align-bottom"></i>
        Đặt lại
      </button>
    </div>
  </div>
</form>
