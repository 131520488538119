import { inject, Injectable, Injector } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  CallOrderModel,
  CallRealEstateModel,
} from "@component/call-history-form-to-owner/call-history-container.component";
import { PHONE_CALL_STATUS } from "@constant/status-call.constant";
import { CustomerModel } from "@model/customer.model";
import { ContactModel } from "@model/order.model";
import { RealEstateModel } from "@model/real-estate.model";
import { ConvertDateTimeToString } from "@util/date-time.util";

@Injectable()
export class RealEstateHistoryFormHelper {
  private readonly formGroup: FormGroup;
  private fb = inject(FormBuilder);
  private injector = inject(Injector);

  constructor() {
    this.formGroup = this.initForm();
  }

  get form() {
    return this.formGroup as FormGroup;
  }

  patchForm(data: any) {
    const customer = (data as CallRealEstateModel)?.customers?.find(
      (c: CustomerModel) => {
        return c.address.find((add) => add?.phone === data?.numberToCall);
      }
    );

    const contact = (data as CallOrderModel)?.contacts?.find(
      (c: ContactModel) => c?.phone === data?.numberToCall
    );

    const phones = customer
      ? customer.address.map((add) => add?.phone)
      : contact
      ? [contact.phone]
      : [];

    const phoneArray = this.form.get("phone") as FormArray;
    phoneArray.clear();

    phones.forEach((phone) => {
      phoneArray.push(this.fb.control(phone, Validators.required));
    });

    if (!data?.address) {
      this.form.patchValue({
        manager: data?.managerBy,
        phoneNumber: data?.numberToCall,
        customerId: data?.customerId,
        fullName: data?.fullName,
        alias: data?.alias ? Number(data?.alias) : null,
        origin: data?.originProvide,
      });
      return;
    }

    this.form.patchValue({
      ...data,
      customerId: data?.customerId,
      fullName: data?.fullName,
      realEstateId: data?.id,
      phoneNumber: data?.numberToCall,
      type: this.convertStringToArray(data?.type),
      propertyStatusBefore: data?.status,
      alias: data?.alias ? Number(data?.alias) : null,
      origin: data?.originProvide,
      brokerageFee: data?.brokerageFee,
      brokerageFeeType: data?.brokerageFeeType,
      title: data?.phoneNumber
        ? `Gọi điện cho ${data?.fullName}`
        : "Note lịch sử",
      statusCall: !data?.phoneNumber
        ? PHONE_CALL_STATUS.NOT_CALL
        : data?.statusCall,
    });
  }

  private convertStringToArray(value: string) {
    return value?.split(",").filter((item: string) => item.trim() !== "");
  }
  // @ts-ignore
  private initForm(data?: RealEstateModel): FormGroup {
    return this.fb.group({
      customerId: [""],
      phone: this.fb.array([]),
      fullName: [""],
      alias: [null],
      origin: [null],
      id: [null, [Validators.required]],
      ext: [null, Validators.required],
      groupId: [null, Validators.required],
      address: ["", [Validators.required]],
      type: ["", [Validators.required]],
      purpose: ["", []],
      realEstateId: [null, [Validators.required]],
      title: ["", Validators.required], // required
      content: ["", [Validators.required]], // required
      method: [1, [Validators.required]],
      statusCall: ["", [Validators.required]], // required
      propertyStatusAfter: ["", [Validators.required]], //required
      propertyStatusBefore: ["null", [Validators.required]], // required
      caller: [null, [Validators.required]],
      manager: [""],
      phoneNumber: [""],
      record: ["https://google.com", [Validators.required]],
      siteHandoverTime: [""],
      brokerageFee: [],
      brokerageUnit: [], // auto tinh toan neu brokerageFee thay doi.
      brokerageFeeType: ["PERCENT"],
      price: [],
      startTime: [
        ConvertDateTimeToString(new Date(), this.injector),
        [Validators.required],
      ],
      endTime: [
        ConvertDateTimeToString(new Date(), this.injector),
        [Validators.required],
      ], // will be updated later.
    });
  }
}
