import { Component, EventEmitter, Input, Output } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
} from "rxjs/operators";
import { RealEstateDetailTabsComponent } from "src/app/components/real-estate-detail-tabs/real-estate-detail-tabs.component";
import { RealEstatePhotoModel } from "src/app/public/models/real-estate.model";

@Component({
  selector: "app-map-select-location",
  templateUrl: "./map-select-location.component.html",
  styleUrls: ["./map-select-location.component.scss"],
})
export class MapSelectLocationComponent {
  // google maps zoom level
  public readonly zoom = 14;
  public inputControl: UntypedFormControl;
  private _lat: number;
  private _lng: number;
  public _streetView: string;
  public _photos: RealEstatePhotoModel[];

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSelectLocation = new EventEmitter();

  @Input() readonly: boolean;

  @Input() set location(value: { lat: string; lng: string }) {
    this._lat = Number(value.lat);
    this._lng = Number(value.lng);

    this.inputControl.patchValue(value.lat + ", " + value.lng);
  }

  @Input() set streetView(value: { streetView: string }) {
    this._streetView = value.streetView;
  }

  @Input() set photos(value: { photos: RealEstatePhotoModel[] }) {
    this._photos = value.photos;
  }

  // initial center position for the map
  get lat() {
    return this._lat;
  }
  get lng() {
    return this._lng;
  }
  get linkStreetView() {
    return this._streetView;
  }

  get listPhotos() {
    return this._photos;
  }

  constructor(public dialog: MatDialog) {
    this.inputControl = new UntypedFormControl();
    this.inputControlObserver();
  }

  private inputControlObserver() {
    // @ts-ignore
    this.inputControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        filter((d) => d !== undefined),
        // @ts-ignore
        switchMap((data: any) => {
          const _d = data.toString().split(",");

          this.location = {
            lat: parseFloat(_d[0]?.trim()).toString() || null,
            lng: parseFloat(_d[1]?.trim()).toString() || null,
          };

          if (this.lat && this.lng) {
            this.onSelectLocation.emit({
              lat: this.lat,
              lng: this.lng,
            });
          }

          return data;
        })
      )
      .subscribe();
  }

  public markerDragEnd($event: any) {
    const { coords } = $event;
    console.log("Drag");
    if (coords) {
      this.onSelectLocation.emit({
        lat: coords?.lat,
        lng: coords?.lng,
      });
    }
  }

  public markers: marker[] = [
    {
      lat: this.lat,
      lng: this.lng,
      label: "VT",
      draggable: true,
    },
  ];

  public clickStreetView(tab: number) {
    this.dialog.open(RealEstateDetailTabsComponent, {
      maxWidth: "100vw",
      maxHeight: "100vh",
      height: "100%",
      width: "100%",
      data: {
        tab: tab,
        lat: this.lat,
        lng: this.lng,
        photos: this.listPhotos ? this.listPhotos : [],
        streetView: this.linkStreetView,
      },
      panelClass: "customize-dialog-container",
    });
  }
}

// just an interface for type safety.
interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}
