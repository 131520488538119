import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "imgSizeThumbnail",
})
export class ImgSizeThumbnailPipe implements PipeTransform {
  public readonly IMAGE_DEFAULT = "./assets/images/products/no-img.webp";
  public SMALL_THUMBNAIL = "380x380";

  transform(url: string): string {
    if (url) {
      return url.replace("0x0", this.SMALL_THUMBNAIL);
    }
    return this.IMAGE_DEFAULT;
  }
}
