import { environment } from "src/environments/environment";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import * as S3 from "aws-sdk/clients/s3";
import { PutObjectRequest } from "aws-sdk/clients/s3";
import { CredentialService } from "../credential/credential.service";
import { v4 as uuid } from "uuid";
import { VIDEO_FILE_TYPE } from "src/app/public/constants/common.constant";

@Injectable({
  providedIn: "root",
})
export class S3UploadService {
  readonly CONFIG_PARAMS: PutObjectRequest = {
    Key: environment.s3Config.key,
    Bucket: environment.s3Config.bucket,
    Body: null,
    ContentType: "",
  };

  readonly s3: S3;
  readonly credential: any;

  constructor(private credentialService: CredentialService) {
    this.credential = this.credentialService.credential;
    this.s3 = new S3({
      accessKeyId: this.credential.AWS_ACCESS_KEY,
      secretAccessKey: this.credential.AWS_SECRET_KEY,
      region: "ap-southeast-1",
    });
  }

  fileUpload(file: any): Observable<any> {
    return Observable.create(
      (observer: {
        next: (arg0: Error | S3.ManagedUpload.SendData) => void;
        complete: () => void;
      }) => {
        const contentType = file.type;
        const newName = file?.oldName
          ? file.oldName
          : this.generateTimeStamp() +
            "_" +
            uuid() +
            "." +
            this.getFileType(file?.name);
        const isVideo =
          VIDEO_FILE_TYPE.includes(contentType) ||
          VIDEO_FILE_TYPE.find((i) => contentType.includes(i));

        if (isVideo) {
          this.CONFIG_PARAMS.Bucket = environment.s3Config.bucket_video;
        } else {
          this.CONFIG_PARAMS.Bucket = environment.s3Config.bucket;
        }

        this.s3.upload(
          {
            ...this.CONFIG_PARAMS,
            ContentType: contentType,
            Body: file,
            Key: newName.toLowerCase(),
          },
          (
            err: Error | S3.ManagedUpload.SendData,
            success: Error | S3.ManagedUpload.SendData
          ) => {
            if (err) {
              observer.next(err);
              throwError(err);
            } else {
              observer.next(success);
              observer.complete();
            }
          }
        );
      }
    ) as Observable<any>;
  }

  deleteFile(url: string): Observable<any> {
    return Observable.create(
      (observer: {
        next: (arg0: Error | S3.ManagedUpload.SendData) => void;
        complete: () => void;
      }) => {
        this.s3.deleteObject(
          {
            Bucket: environment.s3Config.bucket,
            Key: url,
          },
          (err: Error | S3.ManagedUpload.SendData, success: any) => {
            if (err) {
              observer.next(err);
              throwError(err);
            } else {
              observer.next(success);
              observer.complete();
            }
          }
        );
      }
    );
  }

  private getFileType(fileName: string): string {
    return fileName?.split(".").pop();
  }

  private generateTimeStamp() {
    return Math.floor(new Date().getTime() / 1000);
  }
}
