<div class="position-relative d-flex h-100">
  <!-- Start Breadcrumbs -->
  <div class="w-100 overflow-hidden">
    <app-breadcrumbs
      title="Tạo mới BĐS"
      [breadcrumbItems]="BREAD_CRUMB_ITEMS"
    ></app-breadcrumbs>

    <!-- End -->
    <br />
    <!--end row-->
    <div class="row h-100">
      <div class="col-lg-12 h-100">
        <div class="shadow-none mb-lg-0 h-100">
          <div class="h-100">
            <div class="row gx-lg-4 h-100 detail-content pb-5">
              <div class="col-xl-4 h-100 overflow-scroll">
                <div class="card">
                  <div class="card-body">
                    <div class="product-img-slider mt-4">
                      <app-slider-image-detail
                        [photos]="photosRealEstate"
                        (onDeleteImage)="eventDeleteImage($event)"
                        (onUpdateImage)="eventEditImage($event)"
                        (onAddImage)="eventAddImage()"
                        (onSelectImgFront)="eventSelectImgFront($event)"
                      >
                      </app-slider-image-detail>
                    </div>
                    <app-form-common-info
                      [formCommon]="formRealEstate"
                    ></app-form-common-info>
                  </div>
                </div>
              </div>
              <!-- end col -->

              <div class="col-xl-8 overflow-scroll h-100">
                <div class="card">
                  <div class="row card-body">
                    <div class="d-flex">
                      <form
                        [formGroup]="formRealEstate"
                        (submit)="onCreateRealEstate()"
                        class="mt-xl-0 mt-5"
                      >
                        <div class="d-flex z-3 justify-content-end">
                          <div class="flex-shrink-0">
                            <button
                              type="submit"
                              class="btn btn-primary"
                              ngbTooltip="Bấm để tạo BĐS"
                            >
                              <i class="mdi mdi-plus align-bottom"></i> Tạo BĐS
                            </button>
                          </div>
                        </div>

                        <div class="row">
                          <div class="mt-4">
                            <app-form-owner-real-estate
                              [formRealEstate]="formRealEstate"
                            ></app-form-owner-real-estate>
                          </div>

                          <div class="mt-4">
                            <app-form-real-estate-info
                              [formRealEstate]="formRealEstate"
                              [isEdit]="true"
                            ></app-form-real-estate-info>
                          </div>
                          <div class="mt-4">
                            <app-form-detail-info
                              [formRealEstate]="formRealEstate"
                              [unitForm]="unitForm"
                              priceClass="col-8 mb-3"
                            ></app-form-detail-info>
                          </div>

                          <div class="mt-4">
                            <app-form-internal-info
                              [formRealEstate]="formRealEstate"
                            ></app-form-internal-info>
                          </div>
                          <div class="mt-4">
                            <app-form-other-info
                              [formRealEstate]="formRealEstate"
                            >
                            </app-form-other-info>
                          </div>
                        </div>
                      </form>

                      <input
                        #inputFileEdit
                        type="file"
                        multiple="false"
                        accept=".jpg, .jpeg, .png"
                        class="file-upload d-none"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </div>
  <div class="btn-close-dialog-main">
    <button
      *ngIf="true"
      type="button"
      class="btn-close-dialog"
      data-dismiss="modal"
      aria-label="Close"
      (click)="dialogService.closeAll()"
    >
      <i class="ri-close-line"></i>
    </button>
  </div>
</div>
