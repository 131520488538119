import {
  Component,
  inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Vietnam from "flatpickr/dist/l10n/vn";
import { Observable } from "rxjs";
import {
  PAGE_DEFAULT,
  SIZE_GET_ALL_DEFAULT,
} from "src/app/public/constants/common.constant";
import { EmployeeModel } from "src/app/public/models/employee.model";
import { CategoryService } from "src/app/public/services/categories/category.service";
import { UserService } from "src/app/public/services/user/user.service";

@Component({
  selector: "app-form-internal-info",
  templateUrl: "./form-internal-info.component.html",
})
export class FormInternalInfoComponent implements OnInit, OnChanges {
  private userService = inject(UserService);
  private categoryService = inject(CategoryService);

  public Editor = ClassicEditor;
  public employeeList: EmployeeModel[] = [];
  public jurdical$: Observable<any>;
  public language = Vietnam.vn;

  @Input() formRealEstate: FormGroup;
  @Input() isEdit: boolean;
  @Input() realEstateId: string | number;

  ngOnInit(): void {
    this.getAllEmployee();
    this.getAllJurdical();
  }

  ngOnChanges({ isEdit }: SimpleChanges): void {
    const { siteHandoverTime, contractTerm, note } = this.controls;

    setTimeout(() => {
      const action =
        (isEdit?.currentValue && this.realEstateId) || !this.realEstateId
          ? "enable"
          : "disable";
      siteHandoverTime[action]();
      contractTerm[action]();
      note[action]();
    });
  }

  get controls() {
    return this.formRealEstate.controls;
  }

  private getAllEmployee(page?: number) {
    const currentPage = page ? page : PAGE_DEFAULT;

    this.userService
      .getListEmployee({
        page: currentPage,
        limit: SIZE_GET_ALL_DEFAULT,
      })
      .subscribe((res: any) => {
        this.employeeList = this.employeeList.concat(...res.data);
        if (this.employeeList.length < res.totalItems) {
          this.getAllEmployee(currentPage + 1);
        }
      });
  }

  private getAllJurdical() {
    this.jurdical$ = this.categoryService.getAllJuridical();
  }
}
