import { Component, inject, OnInit } from "@angular/core";
import {
  EVENT_BUS_EVENTS,
  EventBusService,
} from "@service/common/event-bus.service";
import { SwNotificationService } from "@service/socket/sw-notification.service";
import { UserService } from "@service/user/user.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  private swNotification = inject(SwNotificationService);
  private eventBus = inject(EventBusService);
  private userService = inject(UserService);

  ngOnInit(): void {
    this.subscribeToLoginEvents();
    this.subscribeNotificationsIfLoggedIn();
  }

  private subscribeToLoginEvents(): void {
    this.eventBus.on(EVENT_BUS_EVENTS.LOGIN_SUCCESS).subscribe(() => {
      this.swNotification.subscribeToNotifications();
    });
  }

  private subscribeNotificationsIfLoggedIn(): void {
    const user = !!this.userService.user$.getValue();
    if (user) {
      this.swNotification.subscribeToNotifications();
    } else {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.getRegistrations().then((registrations) => {
          registrations.forEach((registration) => registration.unregister());
        });
      }
    }
  }
}
