import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";

@Directive({
  selector: "[appVideoPreview]",
})
export class VideoPreviewDirective implements OnChanges {
  @Input() appVideoPreview: any;

  constructor(private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    let reader = new FileReader();
    let el = this.el;

    // @TODO: chưa work.
    reader.onloadend = (e: any) => {
      const contents = e.target.result;
      const uint8Array = new Uint8Array(contents);
      const arrayBuffer = uint8Array.buffer;
      const blob = new Blob([arrayBuffer]);

      el.nativeElement.src = URL.createObjectURL(blob);
    };

    if (this.appVideoPreview) {
      return reader.readAsDataURL(this.appVideoPreview);
    }
  }
}
