import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
// @ts-ignore
import cities from "src/assets/data/gadm41_cities.json";
// @ts-ignore
import districts from "src/assets/data/gadm41_districts.json";
// @ts-ignore
import wards from "src/assets/data/gadm41_wards.json";
import { getAddressType } from "src/app/public/decorators/helper.decorator";
import { DEFINE_TYPE_ADDRESS } from "src/app/public/constants/common.constant";

const SETTING_ZOOM = {
  WARD: 14,
  DISTRICT: 12,
  CITY: 10,
  OTHER: 18,
};

@Injectable({
  providedIn: "root",
})
export class PolygonAreaService {
  private polygons$ = new BehaviorSubject<any[]>([]);
  private location$ = new BehaviorSubject<any>(null);
  private zoom$ = new BehaviorSubject<any>(null);

  cities = cities;
  districts = districts;
  wards = wards;

  constructor() {
    this.cities = cities.features as Array<any>;
    this.districts = districts.features as Array<any>;
    this.wards = wards.features as Array<any>;
  }

  // For polygons
  set setArea(data: any) {
    const area = this.getPointsOfArea(data) || [];
    this.polygons$.next(area);
  }

  public onArea(): Observable<any> {
    return this.polygons$.asObservable();
  }

  // For location
  set setLocation(data: { lat: number; lng: number }) {
    this.location$.next(data);
  }

  public onLocation(): Observable<any> {
    return this.location$.asObservable();
  }

  // For zoom
  set setZoom(data: any) {
    const type = getAddressType(data);
    // @ts-ignore
    this.zoom$.next(SETTING_ZOOM[type]);
  }

  public onZoom(): Observable<any> {
    return this.zoom$.asObservable();
  }

  /**
   * @function: Get polygon by city | district | ward
   * @param data
   * @private
   */
  getPointsOfArea(data: any): any {
    const type = getAddressType(data);
    if (type === DEFINE_TYPE_ADDRESS.WARD) {
      const isExistDistrict = data?.districtName.replace(/ /g, "");
      const isExistProvince = data?.cityName.replace(/ /g, "");
      let coordinates = null;

      if (isExistDistrict) {
        // @ts-ignore
        coordinates = this.wards.find(
          (w: { properties: { NAME_3: string; NAME_2: string } }) =>
            this.stringToSlug(w.properties?.NAME_3?.toLowerCase()) ===
              this.stringToSlug(
                data?.towName
                  .toLowerCase()
                  .replace(/(?:tt.)/gm, "")
                  .replace(/ /g, "")
              ) &&
            this.stringToSlug(w.properties.NAME_2?.toLowerCase()) ===
              this.stringToSlug(
                data?.districtName.replace(/ /g, "").toLowerCase()
              )
        )?.geometry.coordinates;
      } else if (isExistProvince) {
        // @ts-ignore
        coordinates = this.wards.find(
          (w: { properties: { NAME_3: string; NAME_1: string } }) =>
            this.stringToSlug(w.properties?.NAME_3?.toLowerCase()) ===
              this.stringToSlug(
                data?.towName
                  .toLowerCase()
                  .replace(/(?:tt.)/gm, "")
                  .replace(/ /g, "")
              ) &&
            this.stringToSlug(w.properties.NAME_1?.toLowerCase()) ===
              this.stringToSlug(data?.cityName.replace(/ /g, "").toLowerCase())
        )?.geometry.coordinates;
      }
      return coordinates?.map((city: any[][]) => {
        return city[0].map((c) => {
          return {
            lng: c[0],
            lat: c[1],
          };
        });
      });
    } else if (type === DEFINE_TYPE_ADDRESS.DISTRICT) {
      // @ts-ignore
      const coordinates = this.districts.find(
        (d: { properties: { VARNAME_2: string; NAME_1: string } }) =>
          d.properties.VARNAME_2.toLowerCase() ===
            this.stringToSlug(
              data?.districtName.replace(/ /g, "").toLowerCase()
            ) &&
          this.stringToSlug(d.properties.NAME_1.toLowerCase()) ===
            this.stringToSlug(data?.cityName.replace(/ /g, "").toLowerCase())
      )?.geometry.coordinates;
      return coordinates?.map((city: any[][]) => {
        return city[0].map((c) => {
          return {
            lng: c[0],
            lat: c[1],
          };
        });
      });
    } else if (type === DEFINE_TYPE_ADDRESS.CITY) {
      const coordinates = this.cities.find(
        ({ properties }: any) =>
          properties.VARNAME_1.toLowerCase() ===
          this.stringToSlug(data?.cityName.replace(/ /g, "").toLowerCase())
      )?.geometry.coordinates;
      return coordinates?.map((city: any[][]) => {
        return city[0].map((c) => {
          return {
            lng: c[0],
            lat: c[1],
          };
        });
      });
    } else return [];
  }

  private stringToSlug(str: String): String {
    // remove accents
    const from =
        "àáãảạăằắẳẵặâầấẩẫậèéẻẽẹêềếểễệđùúủũụưừứửữựòóỏõọôồốổỗộơờớởỡợìíỉĩịäëïîöüûñçýỳỹỵỷ",
      to =
        "aaaaaaaaaaaaaaaaaeeeeeeeeeeeduuuuuuuuuuuoooooooooooooooooiiiiiaeiiouuncyyyyy";
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(RegExp(from[i], "gi"), to[i]);
    }

    str = str
      .trim()
      .replace(/[^a-z0-9\-]/g, "-")
      .replace(/-+/g, "-");

    return str;
  }
}
