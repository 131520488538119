import { DatePipe, NgClass, NgFor } from "@angular/common";
import { Component, inject, Input } from "@angular/core";
import { RouterModule } from "@angular/router";
import { IsNewItemDirective } from "@directive/is-new-item.directive";
import { NotificationModel } from "@model/notification.model";
import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { GetAvatarImagePipe } from "@pipe/get-avatar-image.pipe";
import { TransformToTimeAgoPipe } from "@pipe/transform-to-time-ago.pipe";
import { SwNotificationService } from "@service/socket/sw-notification.service";
import { tap } from "rxjs/operators";

@Component({
  selector: "app-notify-item",
  templateUrl: "./notify-item.component.html",
  styleUrls: ["./notify-item.component.scss"],
  standalone: true,
  imports: [
    NgbTooltipModule,
    TransformToTimeAgoPipe,
    GetAvatarImagePipe,
    IsNewItemDirective,
    RouterModule,
    NgFor,
    NgClass,
  ],
  providers: [DatePipe],
})
export class NotifyItemComponent {
  private swNotificationService = inject(SwNotificationService);

  public data: NotificationModel;

  @Input() set notification(value: NotificationModel) {
    if (value) {
      const parsedData = JSON.parse(value.notification.data as any);

      parsedData.orders = JSON.parse(JSON.parse(parsedData.orders));

      this.data = {
        ...value,
        notification: {
          ...value.notification,
          data: parsedData,
        },
      };
    }
  }

  get notificationData() {
    return this.data?.notification?.data;
  }

  public updateNotificationStatus(
    id: number,
    payload: Partial<{ doneStatus: boolean; readStatus: boolean }>,
    event?: Event
  ) {
    event?.stopPropagation();

    this.swNotificationService
      .changeViewStatus(id, { id, ...payload })
      .pipe(
        tap(() => {
          if (this.data?.id === id) {
            this.data = { ...this.data, ...payload };
          }
        })
      )
      .subscribe();
  }

  public onChangeViewStatus(event: Event, readStatus: boolean, id: number) {
    if (readStatus) return;
    this.updateNotificationStatus(id, { readStatus: true }, event);
  }

  public onChangeDoneStatus(event: Event, doneStatus: boolean, id: number) {
    if (doneStatus) return;
    this.updateNotificationStatus(
      id,
      { readStatus: true, doneStatus: true },
      event
    );
  }
}
