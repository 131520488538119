import { Component, Input } from "@angular/core";
import { FormGroup, UntypedFormControl } from "@angular/forms";
import { ACTIVE_STATUS_FILTER } from "src/app/public/constants/common.constant";
import { EmployeeModel } from "src/app/public/models/employee.model";

@Component({
  selector: "app-filter-internal",
  templateUrl: "./filter-internal.component.html",
})
export class FilterInternalComponent {
  @Input() formSearchAndFilter: FormGroup;
  @Input() employeeList: EmployeeModel[] = [];
  @Input() createdDefault: { from: string; to: string };
  @Input() siteHandoverTimeDefault: { from: string; to: string };
  @Input() openTimeDefault: { from: string; to: string };

  public ACTIVE_STATUS_FILTER = ACTIVE_STATUS_FILTER;

  public getControl(name: string) {
    return this.formSearchAndFilter.controls[name] as UntypedFormControl;
  }
}
