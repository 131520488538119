<div class="p-2">
  <div class="d-flex align-items-end gap-2 group-btn justify-content-end">
    <button class="btn btn-success" (click)="submitForm()">
      <i class="mdi mdi-content-save"></i>
      Lưu
    </button>
  </div>
  <div class="d-flex">
    <div class="col-4 align-self-stretch">
      <app-order-owner [callHistoryForm]="callHistoryForm"></app-order-owner>
    </div>
    <div class="col-4 align-self-stretch">
      <app-order-info
        [callHistoryForm]="callHistoryForm"
        (submitFormEvent)="submitForm()"
      ></app-order-info>
    </div>
    <div class="col-4 align-self-stretch">
      <app-call-history-order-form
        [callHistoryForm]="callHistoryForm"
      ></app-call-history-order-form>
    </div>
  </div>
  <div class="row align-self-stretch">
    <call-history-order-list
      [callHistoryForm]="callHistoryForm"
    ></call-history-order-list>
  </div>
</div>
